import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import AuthContext from "../../../utility/context/auth-context";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import {
  setOnboardingStatusAction,
  setTrainerData,
} from "../../../redux/actions/registration/OnboardingAction";
import { countrysEnum } from "../../../enums/enum";
import modalContext from "../../../utility/context/modal-context";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";

interface onboardingFiledsValues {
  firstName: string;
  lastName: string;
  companyName: string;
  addressPostalCode: string;
  addressCity: string;
  addressCountry: number;
  vatId: string;
  address: string;
  houseNumber: string;
  phoneNumberCountryCode: number;
  phoneNumber: string;
  birthDate: string;
  preferredPronoun: number;
  companyType: number,
  isSmallCompany: boolean
}

const TrainerOnboardingForm = () => {
  const dispatch = useDispatch();
  const onboardingState = useSelector((state) => state.onboarding.userData);
  const initDateofBirth: string = onboardingState.dateOfBirth?.substring(0, 10);
  const { i18n , t} = useTranslation();

  const [cookies, setCookie, removeCookie] = useCookies(['OnboardingState']);
  const firstnamePlaceholder = t(
    "firstnamePlaceholder"
  );
  const lastnamePlaceholder = t("lastnamePlaceholder");
  const companynamePlaceholder = t(
    "companynamePlaceholder"
  );
  const addressPlaceholder = t("addressPlaceholder");
  const houseNumberPlaceholder = t("houseNumberPlaceholder"
  );
  const cityPlaceholder = t("cityPlaceholder");
  const vatIDPlaceholder = t("vatIDPlaceholder");
  const phonePlaceholder = t("phonePlaceholder");


  const initialValues: onboardingFiledsValues = {
    firstName: onboardingState.firstName || "",
    lastName: onboardingState.lastName || "",
    companyName: onboardingState.companyName|| "",
    addressPostalCode: onboardingState.addressPostalCode|| "",
    addressCity: onboardingState.addressCity|| "",
    addressCountry: onboardingState.addressCountry|| 4,
    vatId: onboardingState.vatID|| "",
    address: onboardingState.address || "",
    houseNumber: onboardingState.houseNumber || "",
    phoneNumberCountryCode: onboardingState.phoneNumberCountryCode || 49,
    phoneNumber: onboardingState.phoneNumber || "",
    birthDate:
      onboardingState.dateOfBirth !== null || onboardingState.dateOfBirth
        ? initDateofBirth
        : "2000-01-01",
    preferredPronoun: onboardingState.preferredPronoun|| 2,
    companyType: onboardingState.companyType|| 1 ,
    isSmallCompany: onboardingState.isSmallCompany|| false


  };



  const history = useHistory();
  const modalCtx = useContext<any>(modalContext);

  useEffect(() => {
    dispatch(setTrainerData());
    dispatch(setOnboardingStatusAction(0));
  }, []);



  const getPrefixArray = () => {
    let prefixArray = [];
    // TODO @Ali: Need to add only the countrycodes for countries that exist in the country selection
    // for (let i = 1; i < 54; i++) {
    //   prefixArray[i] = i;
    // }

    //prefixArray[0] = 31; // Netherlands
    //prefixArray[1] = 32; // Belgium
    //prefixArray[2] = 33; // France
    prefixArray[0] = 41; // Switzerland
    prefixArray[1] = 43; // Austria
    //prefixArray[5] = 44; // UK
    //prefixArray[6] = 45; // Denmark
    //prefixArray[7] = 46; // Sweden
    //prefixArray[8] = 47; // Norway
    prefixArray[2] = 49; // Germany
    prefixArray[3] = 352; // Luxemburg
    //prefixArray[11] = 358; // Finland

    return prefixArray;
  };

  const coutryArray = Array.from(Array(4).keys());
  const authCtx = useContext(AuthContext);

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup.string().required(t("last name is required")),
    companyName: yup
      .string()
      .required(t("errCompanyName")),
    addressPostalCode: yup
      .string()
      .required(t("postal code is required")),
    addressCity: yup
      .string()
      .required(t("city is required" )),
    addressCountry: yup
      .number()
      .required(t("country is required")),
    //vatId: yup.string().required(t("VAT-ID is required" )),
    address: yup.string().required(t("address is required" )),
    houseNumber: yup
      .string()
      .required(t("house number is required" )),
    phoneNumberCountryCode: yup
      .number()
      .required(t("errPhoneNumberCountryCode" )),
    phoneNumber: yup
      .string()
      .required(t("phone number is required"))
      .matches(/^[0-9]/, t("errInvalidMobileNumber")),
    birthDate: yup
      .string()
      .required(t("errBirthDate")),
    preferredPronoun: yup
      .number()
      .required(t("errPronoun")),
    companyType: yup
      .number()
      .required(t("errTypeOfCompany")),
    isSmallCompany: yup
      .boolean()
      .required(t("errTypeOfCompany")),
  });

  const handleSubmit = async (data: onboardingFiledsValues) => {
    const birthDateFormated = data.birthDate + "T00:00:00.000Z"; //TODO Implemet ISO methode
    const fetchData = JSON.stringify({
      ...onboardingState,
      ...data,
      dateOfBirth: birthDateFormated,
      onboardingStatus: 1,
      isSmallCompany: Boolean(data.isSmallCompany),
      companyType: Number(data.companyType),
      addressCountry: Number(data.addressCountry),
      preferredPronoun: Number(data.preferredPronoun),
    })

    setCookie("OnboardingState", fetchData,{path:'/'});
    dispatch(setIsLoading(true));

    fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/trainers/user`,
      {
        method: "PUT",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: fetchData,
      }
    )
      .then(async (data) => {
        setTimeout(() => dispatch(setIsLoading(false)),400)

        if (data.status === 200) {
          history.replace("/registration-process-about");
        } else {
          const errorCode = await data.json();
          modalCtx.setModalType(0);
          modalCtx.setMessage(errorCode.message);
          modalCtx.setIsActive(true);
        }
      })
      .catch((e) => {
        modalCtx.setModalType(0);
        modalCtx.setMessage(e.message);
        modalCtx.setIsActive(true);
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema,
    onSubmit: async (values: onboardingFiledsValues) => {
      handleSubmit(values);
    },
  });


  const handleFiledHasError = (name: string): string | undefined => {
    const formikNew = formik as any;
    return (formikNew.touched[name] && formikNew.errors[name]) || undefined;
  };



  return onboardingState.id ?  (
    <div className="TrainerOnboardingForm">
      <h1>{t("Contact information")}</h1>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstname">
              {t("firstname")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"firstname"}
              {...formik.getFieldProps("firstName")}
              placeholder={firstnamePlaceholder}
            />
            {handleFiledHasError("firstName") ? (
              <div className="error">{handleFiledHasError("firstName")}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="lastname">
              {t("lastname")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"lastname"}
              {...formik.getFieldProps("lastName")}
              placeholder={lastnamePlaceholder}
            />
            {handleFiledHasError("lastName") ? (
              <div className="error">{handleFiledHasError("lastName")}</div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="companyName">
              {t("company name")}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"companyName"}
              {...formik.getFieldProps("companyName")}
              placeholder={companynamePlaceholder}
            />
            {handleFiledHasError("companyName") ? (
              <div className="error">{handleFiledHasError("companyName")}</div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="address">
              {t("address")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"address"}
              {...formik.getFieldProps("address")}
              placeholder={addressPlaceholder}
            />
            {handleFiledHasError("address") ? (
              <div className="error">{handleFiledHasError("address")}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="houseNumber">
              {t("house number")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"houseNumber"}
              {...formik.getFieldProps("houseNumber")}
              placeholder={houseNumberPlaceholder}
            />
            {handleFiledHasError("houseNumber") ? (
              <div className="error">{handleFiledHasError("houseNumber")}</div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="postalCode">
              {t("postal code")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"postalCode"}
              {...formik.getFieldProps("addressPostalCode")}
              placeholder={"45290"}
            />
            {handleFiledHasError("addressPostalCode") ? (
              <div className="error">
                {handleFiledHasError("addressPostalCode")}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="city">
              {t("city")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"city"}
              {...formik.getFieldProps("addressCity")}
              placeholder={cityPlaceholder}
            />
            {handleFiledHasError("addressCity") ? (
              <div className="error">{handleFiledHasError("addressCity")}</div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="country">
              {t("country")}{" "}
              <span className="required">*</span>
            </label>
            <select id={"country"} {...formik.getFieldProps("addressCountry")}>
            {/*  {coutryArray.map((v) => (
                <option key={v} value={v}>
                  {countrysEnum[v]}
                </option>
              ))}*/}
              <option value={4}>{t(countrysEnum[4])}</option>
              <option value={5}>{t(countrysEnum[5])} </option>
              <option value={8}>{t(countrysEnum[8])} </option>
              <option value={10}>{t(countrysEnum[10])} </option>
            </select>
            {handleFiledHasError("addressCountry") ? (
              <div className="error">
                {handleFiledHasError("addressCountry")}
              </div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="typeOfCompany">
              {t("type of company")}
              <span className="required">*</span>
            </label>
            <select
              id={"companyType"}
              {...formik.getFieldProps("companyType")}>
              <option value={1}>{t("sole proprietorship")}</option>
              <option value={2}>{t("corporation")}</option>
              {formik.values.addressCountry == 4 ? <option value={3}>{t("partnership")}</option> : null}
             {/* <option>{t("enterprise Individuelle")}</option>*/}

            </select>
            {handleFiledHasError("companyType") ? (
              <div className="error">{handleFiledHasError("companyType")}</div>
            ) : null}
          </div>

          {formik.values.companyType == 1 ? <div className="form-group">
            <label htmlFor="isSmallCompany">
              {t("smallCompany?")}
              <span className="required">*</span>
            </label>
            <select
              disabled={false}
              id={"isSmallCompany"}
              {...formik.getFieldProps("isSmallCompany")}
            >
              <option value={0}>{t("no")}</option>
              <option value={1}>{t("yes")}</option>
            </select>
            {handleFiledHasError("isSmallCompany") ? (
              <div className="error">{handleFiledHasError("isSmallCompany")}</div>
            ) : null}
          </div> : null}
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="vatId">
              {t("VAT-ID")}
            </label>
            <input
              type="text"
              id={"vatId"}
              {...formik.getFieldProps("vatId")}
              placeholder={vatIDPlaceholder}
            />
            {handleFiledHasError("vatId") ? (
              <div className="error">{handleFiledHasError("vatId")}</div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="prefix">
              {t("prefix")}{" "}
              <span className="required">*</span>
            </label>
            <select
              id={"prefix"}
              {...formik.getFieldProps("phoneNumberCountryCode")}
            >
              {getPrefixArray().map((v) => (
                <option key={v} value={v}>
                  +{v}
                </option>
              ))}

            </select>
            {handleFiledHasError("phoneNumberCountryCode") ? (
              <div className="error">
                {handleFiledHasError("phoneNumberCountryCode")}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="phone">
              {t("phone")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id={"phone"}
              {...formik.getFieldProps("phoneNumber")}
              placeholder={phonePlaceholder}
            />
            {handleFiledHasError("phoneNumber") ? (
              <div className="error">{handleFiledHasError("phoneNumber")}</div>
            ) : null}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="birthDate">
              {t("date of birth")}{" "}
              <span className="required">*</span>
            </label>
{/*            <input
              type="date"
              id={"birthDate"}
              {...formik.getFieldProps("birthDate")}
              placeholder={"01.01.2000"}
            />*/}
            <DatePicker
              id={"dateOfBirth"}

              onChange={(date) => {
                if(date !== null){
                  formik.setFieldValue("birthDate",(DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")))
                }
              }}
              placeholderText={t("choose a date")}
              selected={DateTime.fromISO(formik.values.birthDate).toJSDate()}
              dateFormat="dd.MM.yyyy"
              locale={i18n.language === "de" ? de : ""}
              calendarStartDay={1}
            />
            {handleFiledHasError("birthDate") ? (
              <div className="error">{handleFiledHasError("birthDate")}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="preferredPronoun">
              {t("preferred pronoun")}{" "}
              <span className="required">*</span>
            </label>
            <select
              id={"preferredPronoun"}
              {...formik.getFieldProps("preferredPronoun")}
            >
              <option value={1}>{t("she/her")}</option>
              <option value={2}>{t("he/his")}</option>
              <option value={3}>{t("Diverse")}</option>
            </select>
            {handleFiledHasError("preferredPronoun") ? (
              <div className="error">
                {handleFiledHasError("preferredPronoun")}
              </div>
            ) : null}
          </div>
        </div>
        <button type={"submit"}>{t("nextStep")}</button>
      </form>
    </div>
  ) : null;
};

export default TrainerOnboardingForm;
