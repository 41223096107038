import AuthContext from "../../utility/context/auth-context";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserDataFromStore,
  getPublicTrainerData,
  setOnboardingStatusAction,
  setTrainerData
} from "../../redux/actions/registration/OnboardingAction";
import {  setBusinesCenterPage } from "../../redux/actions/layout/layoutAction";
import { ReactComponent as MyInvoiceSVG } from "../../images/component_svg/myInvoice.svg";
import { ReactComponent as DownArrowSVG } from "../../images/component_svg/downArrowSvg.svg";
import { ReactComponent as BurgerSvg } from "../../images/component_svg/burgerMenu.svg";
import { ReactComponent as CloseSvg } from "../../images/component_svg/close.svg";
import { ReactComponent as AboutSvg } from "../../images/component_svg/about.svg";
import {
  ReactComponent as PackagesSubscriptionsIcon,
  ReactComponent as PackagesSvg
} from "../../images/component_svg/businessCenterSvgs/packagesSubscriptionsIcon.svg";
import { ReactComponent as LogoutSvg } from "../../images/component_svg/logout.svg";
import { ReactComponent as DashboardSvg } from "../../images/component_svg/dashboard.svg";
import { ReactComponent as BusinessCenterSvg } from "../../images/component_svg/businessCenter.svg";
import { ReactComponent as MySpaceSvg } from "../../images/component_svg/mySpace.svg";
import { ReactComponent as GuideSvg } from "../../images/component_svg/guide.svg";
import { ReactComponent as BookingSvg } from "../../images/component_svg/booking.svg";
import { ReactComponent as ChangePasswordSVG } from "../../images/component_svg/changePassword.svg";
import { ReactComponent as LogoutSVG } from "../../images/component_svg/logout.svg";
import ModalContext from "../../utility/context/modal-context";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { ReactComponent as ServiceIcon } from "../../images/component_svg/businessCenterSvgs/serviceIcon.svg";
import { ReactComponent as CalendarIcon } from "../../images/component_svg/businessCenterSvgs/calendarIcon.svg";
import { ReactComponent as MarketingIcon } from "../../images/component_svg/businessCenterSvgs/marketingIcon.svg";
import { ReactComponent as AccountingIcon } from "../../images/component_svg/businessCenterSvgs/accountingIcon.svg";
import { ReactComponent as ReportIcon } from "../../images/component_svg/businessCenterSvgs/reportIcon.svg";
import {
  ReactComponent as BusinessSettingsIcon
} from "../../images/component_svg/businessCenterSvgs/businessSettingsIcon.svg";
import {
  ReactComponent as EditYourSpaceIcon
} from "../../images/component_svg/businessCenterSvgs/editYourSpaceIcon.svg";
import {
  ReactComponent as ContactInformationIcon
} from "../../images/component_svg/businessCenterSvgs/contactInformationIcon.svg";
import { ReactComponent as AboutYouIcon } from "../../images/component_svg/businessCenterSvgs/aboutYouIcon.svg";

const ProfileNavigation = () => {
  const profilePicturePlaceholder =
    "https://intern-creativemindz.de/wp-content/uploads/user3.svg";
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const dispatch = useDispatch();
  const publicTrainerData = useSelector(
    (state) => state.onboarding.publicTrainerData
  );
  const hasService = useSelector(state => state.businessCenter.hasService);
  const trainerID = useSelector((state) => state.onboarding.trainerId);
  const customerData = useSelector((state) => state.customer);
  const userData = useSelector((state) => state.onboarding.userData);
  const loadPage = useSelector((state) => state.layout.businessCenterPage);
  const navigationPopup = useRef<any>();
  const [sideNavigationIsOpen, setSideNavigationIsOpen] = useState(false);
  const [sideNavigationBusinessCenterDropdown, setSideNavigationBusinessCenterDropdown] = useState(false);
  const pathname = useLocation().pathname;
  const trainerSpaceId = getPublicTrainerData(
    history.location.pathname.split("/")[2]
  );
  const [togglePopUp, setTogglePopUp] = useState(false);
  const modalCtx = useContext<any>(ModalContext);
  const localStorageUserDataObject: any = localStorage.getItem("userData");
  const LocalStorageUserData: {
    email: string;
    role: string;
    trainerId: string;
    userId: string;
    userName: string;
  } = JSON.parse(localStorageUserDataObject);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(trainerSpaceId);
    dispatch(setTrainerData());
  }, [authCtx.role]);

  useEffect(() => {
    if (togglePopUp) {
      if (!authCtx.isLoggedIn) {
        setTogglePopUp(false);
      } else {
        navigationPopup.current.style.display = "flex";
        setTimeout(() => (navigationPopup.current.style.opacity = 1), 100);
      }
    } else {
      navigationPopup.current.style.opacity = 0;
      setTimeout(() => (navigationPopup.current.style.display = "none"), 500);
    }
  }, [togglePopUp, authCtx.isLoggedIn]);

  const logoutHandler = () => {
    authCtx.logout();
    dispatch(deleteUserDataFromStore());
    dispatch(setOnboardingStatusAction(null));
    setSideNavigationIsOpen(false);

  };

  const loginHandler = () => {
    // @ts-ignore
    modalCtx.setModalType(1);
    // @ts-ignore
    modalCtx.setIsActive(true);
    setSideNavigationIsOpen(false);
  };

  const handleRedirectionToHome = () => {
    if (authCtx.role === "Trainer") {

    } else {
      history.replace(`/me/${publicTrainerData.trainerSpace?.spaceUrl}`);
    }
  };

  let navTitle;

  if (
    `/me/${publicTrainerData.trainerSpace?.spaceUrl}/business-center` ===
    pathname ||
    LocalStorageUserData?.role === "Trainer"
    || !trainerID
  ) {
    navTitle = t("skulp");
  } else if (publicTrainerData.trainerSpace?.nickname != null) {
    navTitle = `${publicTrainerData.trainerSpace.nickname}`;
  } else {
    navTitle = `${publicTrainerData.firstName} ${publicTrainerData.lastName}`;
  }



  return (
    <div className="profileNavigation">
      <div className="navLeft">
        <h1 style={authCtx.role === "Trainer" ? { cursor: "default" } : {}}
            onClick={handleRedirectionToHome}>{navTitle}</h1>

        {authCtx.role !== "Trainer" && trainerID ? (
          <ul>
            <li
              className={
                pathname ===
                `/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-profile`
                  ? "activeLi"
                  : ""
              }
            >
              <Link
                to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-profile`}
              >
                {t("About")}
              </Link>
            </li>
            <li
              className={
                pathname ===
                `/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking` ||
                pathname === "/trainer-bookingTwo"
                  ? "activeLi"
                  : ""
              }
            >
              <Link
                to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking`}
              >
                {t("booking")}
              </Link>
            </li>
            <li
              className={
                pathname ===
                `/me/${publicTrainerData.trainerSpace.spaceUrl}/packages`
                  ? "activeLi"
                  : ""
              }
            >
              <Link
                to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/packages`}
              >{t("Packages")}</Link>
            </li>
          </ul>
        ) : (
          trainerID && <ul>
            <li
              className={
                pathname ===
                `/me/${userData.trainerSpace.spaceUrl}/trainer-dashboard`
                || pathname === `/me/${userData.trainerSpace.spaceUrl}/business-center-invoices`
                  ? "activeLi"
                  : ""
              }
            >
              <Link to={`/me/${userData.trainerSpace.spaceUrl}/trainer-dashboard`}>
                {t("Dashboard")}
              </Link>
            </li>
            <li
              className={
                pathname ===
                `/me/${userData.trainerSpace.spaceUrl}/business-center`
                  ? "activeLi"
                  : ""
              }
            >
              <Link to={`/me/${userData.trainerSpace.spaceUrl}/business-center`}>
                {t("Business Center")}
              </Link>
            </li>
            <li className={pathname === "packages" ? "activeLi" : ""}>
              <Link
                to={`/me/${userData.trainerSpace.spaceUrl}/trainer-profile`}
              >
                {t("My Space")}
              </Link>
            </li>
            {/*            <li className={pathname === "packages" ? "activeLi" : ""}>
              <Link
                to={`/me/${userData.trainerSpace.spaceUrl}/trainer-booking`}
              >
                {t("Guide")}
              </Link>
            </li>*/}
            <li className={"guide"}>
              <a
                onClick={() => setSideNavigationIsOpen(false)}
                href="https://intercom.help/skulp/de/collections/3782203-trainer" target={"_blank"}>
                {t("guide")}
              </a>
            </li>
          </ul>
        )}
      </div>

      <div className="navRight">
        <div className="reverse_button">
          <div className="languageButton">
            <p
              className={i18n.language === "de" ? "active" : ""}
              onClick={() => {
                changeLanguage("de");
                localStorage.setItem("language", "de");
              }}

            >{t("DE")}</p>
            <p
              className={i18n.language === "en" ? "active" : ""}
              onClick={() => {
                changeLanguage("en");
                localStorage.setItem("language", "en");
              }}
            >{t("EN")}</p>
          </div>
        </div>
        {authCtx.isLoggedIn ? (
          <div
            className={"userChipNavigation"}
            onClick={() => setTogglePopUp(!togglePopUp)}
          >
            {authCtx.role === "Trainer" ? (
              <p>{`${userData.firstName} ${userData.lastName}`}</p>
            ) : null}
            {authCtx.role === "User" ? (
              <p>{`${customerData?.firstName} ${customerData?.lastName}`}</p>
            ) : null}
            <DownArrowSVG
              style={togglePopUp ? { transform: "rotate(180deg)" } : {}}
            />
            <img
              src={
                authCtx.role === "Trainer"
                  ? userData.profilePictureUri != "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${userData.profilePictureUri}/download` : profilePicturePlaceholder
                  : customerData.profilePictureUri != "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${customerData.profilePictureUri}/download` : profilePicturePlaceholder
              }
              alt="navigationPicture"
            />
          </div>
        ) : (
          <button onClick={loginHandler}>
            {t("Login")}
          </button>
        )}
        <div
          ref={navigationPopup}
          className="navigationPopUp"
          style={togglePopUp && authCtx.isLoggedIn ? {} : {}}
        >
          {LocalStorageUserData?.role === "Trainer" ? (
            <ul onClick={() => setTogglePopUp(false)}>
              <hr />
              <li onClick={() => {
                history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/business-center/contact`);
              }}>
                <ChangePasswordSVG />
                <Link to={"#"}>
                  {t("changePassword")}
                </Link>
              </li>
              <li onClick={logoutHandler}>
                <LogoutSVG />
                <Link to={"#"}>
                  {t("Logout")}
                </Link>
              </li>
              <hr />

            </ul>
          ) : null}
          {/*TODO: localisation of the User Navigation list*/}
          {authCtx.role === "User" ? (
            <ul onClick={() => setTogglePopUp(false)}>
              <li>
                <MyInvoiceSVG onClick={() => setTogglePopUp(false)} />
                <Link
                  to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/customer-dashboard`}
                >
                  {t("my dashboard")}
                </Link>
              </li>
              <li>
                <MyInvoiceSVG onClick={() => setTogglePopUp(false)} />
                <Link
                  to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/customer-invoices`}
                >
                  {t("invoices")}
                </Link>
              </li>
              <li>
                <MyInvoiceSVG onClick={() => setTogglePopUp(false)} />
                <Link
                  to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/customer-personal-information`}
                >
                  {t("personalInformation")}
                </Link>
              </li>

              <hr />
              <li onClick={logoutHandler}>
                <LogoutSVG />
                <Link to={"#"}>
                  {t("Logout")}
                </Link>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
      <div className="navRightResponsive">
        <button onClick={() => setSideNavigationIsOpen(!sideNavigationIsOpen)} className="burgerButton">
          <BurgerSvg />
        </button>
      </div>
      {/*//SIDE NAVIGATION*/}
      <div className="sideMenu" style={sideNavigationIsOpen ? { right: 0 } : {}}>
        <div className="sideNavigationHeader">
          <CloseSvg onClick={() => setSideNavigationIsOpen(!sideNavigationIsOpen)} />
        </div>
        <div className="sideNavigationBody">
          <h1>{t("Navigation")}</h1>

          {authCtx.role !== "Trainer" && trainerID ? (
            <ul>
              <li className={pathname === `/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-profile`
                ? "activeLi"
                : ""
              }
              >
                <AboutSvg />
                <Link
                  onClick={() => setSideNavigationIsOpen(false)}
                  to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-profile`}
                >
                  {t("About")}
                </Link>
              </li>
              <li
                className={pathname === `/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking` || pathname === "/trainer-bookingTwo" ? "activeLi" : ""}>
                <BookingSvg />
                <Link
                  onClick={() => setSideNavigationIsOpen(false)}
                  to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking`}
                >
                  {t("booking")}
                </Link>
              </li>
              <li
                className={
                  pathname ===
                  `/me/${publicTrainerData.trainerSpace.spaceUrl}/packages`
                    ? "activeLi"
                    : ""
                }
              >
                <PackagesSvg />
                <Link
                  onClick={() => setSideNavigationIsOpen(false)}
                  to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/packages`}
                >{t("Packages")}</Link>
              </li>
              {authCtx.isLoggedIn ? <li onClick={() => setTogglePopUp(!togglePopUp)}>
                <img
                  className={"sideNavigationProfilePicture"}
                  src={
                    authCtx.role === "Trainer"
                      ? userData.profilePictureUri != "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${userData.profilePictureUri}/download` : profilePicturePlaceholder
                      : customerData.profilePictureUri != "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${customerData.profilePictureUri}/download` : profilePicturePlaceholder
                  }
                  alt="navigationPicture"
                />
                {authCtx.role === "Trainer" ? (
                  <p>{`${userData.firstName} ${userData.lastName}`}</p>
                ) : null}
                {authCtx.role === "User" ? (
                  <p>{`${customerData?.firstName} ${customerData?.lastName}`}</p>
                ) : null}
                <DownArrowSVG style={togglePopUp ? { transform: "rotate(180deg)" } : {}} />


              </li> : null}
              {authCtx.role === "User" && togglePopUp ? (
                <ul className={"profileMenu"} onClick={() => setTogglePopUp(false)}>
                  <li>
                    <MyInvoiceSVG onClick={() => setTogglePopUp(false)} />
                    <Link
                      onClick={() => setSideNavigationIsOpen(false)}
                      to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/customer-dashboard`}
                    >
                      {t("my dashboard")}
                    </Link>
                  </li>
                  <li>
                    <MyInvoiceSVG onClick={() => setTogglePopUp(false)} />
                    <Link
                      onClick={() => setSideNavigationIsOpen(false)}
                      to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/customer-invoices`}
                    >
                      {t("invoices")}
                    </Link>
                  </li>
                  <li>
                    <MyInvoiceSVG onClick={() => setTogglePopUp(false)} />
                    <Link
                      onClick={() => setSideNavigationIsOpen(false)}
                      to={`/me/${publicTrainerData.trainerSpace.spaceUrl}/customer/customer-personal-information`}
                    >
                      {t("personalInformation")}
                    </Link>
                  </li>
                </ul>
              ) : null}
            </ul>
          ) : (
            trainerID && <ul>

              <li
                className={
                  pathname ===
                  `/me/${userData.trainerSpace.spaceUrl}/trainer-dashboard`
                  || pathname === `/me/${userData.trainerSpace.spaceUrl}/business-center-invoices`
                    ? "activeLi"
                    : ""
                }
              >
                <DashboardSvg />

                <Link
                  onClick={() => setSideNavigationIsOpen(false)}
                  to={`/me/${userData.trainerSpace.spaceUrl}/trainer-dashboard`}>
                  {t("Dashboard")}
                </Link>
              </li>
              <li className={pathname === `/me/${userData.trainerSpace.spaceUrl}/business-center` ? "activeLi" : ""}
              >
                <BusinessCenterSvg />

                <Link
                  onClick={() => {
                    setSideNavigationIsOpen(false);
                    setSideNavigationBusinessCenterDropdown(true);
                  }}
                  to={`/me/${userData.trainerSpace.spaceUrl}/business-center`}>
                  {t("Business Center")}
                  <div className="SideNavigationBusinessCenterDropdownList"
                       style={!sideNavigationBusinessCenterDropdown ? { display: "none" } : {}}>
                    <ul>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/service`}>
                        <li
                          className={loadPage === 0 ? "active" : ""}
                          onClick={() => {
                            dispatch(setBusinesCenterPage(0));
                            setSideNavigationIsOpen(false);
                          }}
                        >
                          <ServiceIcon />
                          <p>
                            {t("services")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/package`}>

                        <li className={loadPage === 1 ? "active" : hasService ? "" : "disabled"}
                            onClick={() => {
                              if (hasService) {
                                dispatch(setBusinesCenterPage(1));
                                setSideNavigationIsOpen(false);
                              }
                            }}
                        >
                          <PackagesSubscriptionsIcon />
                          <p>
                            {t("Packages")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/calendar`}>

                        <li
                          className={loadPage === 2 ? "active" : ""}
                          onClick={() => {
                            dispatch(setBusinesCenterPage(2));
                            setSideNavigationIsOpen(false);
                          }}>
                          <CalendarIcon />
                          <p>
                            {t("calendar")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/marketing`}>
                        <li
                          className={loadPage === 3 ? "active" : ""}
                          onClick={() => {
                            dispatch(setBusinesCenterPage(3));
                            setSideNavigationIsOpen(false);
                          }}
                        >
                          <MarketingIcon />
                          <p>
                            {t("marketing")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/accounting`}>
                        <li className={loadPage === 4 ? "active" : ""} onClick={() => {
                          dispatch(setBusinesCenterPage(4));
                          setSideNavigationIsOpen(false);
                        }}>
                          <AccountingIcon />
                          <p>
                            {t("accounting")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/report`}>
                        <li className={loadPage === 5 ? "active" : ""} onClick={() => {
                          dispatch(setBusinesCenterPage(5));
                          setSideNavigationIsOpen(false);
                        }}>
                          <ReportIcon />
                          <p>
                            {t("report")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/businessSettings`}>

                        <li className={loadPage === 6 ? "active" : ""} onClick={() => {
                          dispatch(setBusinesCenterPage(6));
                          setSideNavigationIsOpen(false);
                        }}>
                          <BusinessSettingsIcon />
                          <p>
                            {t("businessSettings")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/editSpace`}>
                        <li className={loadPage === 7 ? "active" : ""} onClick={() => {
                          dispatch(setBusinesCenterPage(7));
                          setSideNavigationIsOpen(false);
                        }}>
                          <EditYourSpaceIcon />
                          <p>
                            {t("editYourSpace")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/contact`}>

                        <li className={loadPage === 8 ? "active" : ""} onClick={() => {
                          dispatch(setBusinesCenterPage(8));
                          setSideNavigationIsOpen(false);
                        }}>
                          <ContactInformationIcon />
                          <p>
                            {t("Contact information")}
                          </p>
                        </li>
                      </Link>
                      <Link to={`/me/${history.location.pathname.split("/")[2]}/business-center/about`}>

                        <li className={loadPage === 9 ? "active" : ""} onClick={() => {
                          dispatch(setBusinesCenterPage(9));
                          setSideNavigationIsOpen(false);
                        }}>
                          <AboutYouIcon />
                          <p>
                            {t("aboutYou")}
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </Link>
                <DownArrowSVG
                  style={sideNavigationBusinessCenterDropdown ? { transform: "rotate(180deg)" } : {}}
                  onClick={() => setSideNavigationBusinessCenterDropdown(!sideNavigationBusinessCenterDropdown)} />

              </li>
              <li className={pathname === "packages" ? "activeLi" : ""}>
                <MySpaceSvg />

                <Link
                  onClick={() => setSideNavigationIsOpen(false)}
                  to={`/me/${userData.trainerSpace.spaceUrl}/trainer-profile`}
                >

                  {t("My Space")}
                </Link>
              </li>
              <li className={"guide"}>
                <GuideSvg />
                <a
                  onClick={() => setSideNavigationIsOpen(false)}
                  href="https://secure.skulp.de/knowledge" target={"_blank"}>
                  {t("guide")}
                </a>
              </li>

              {/*            <li className={pathname === "packages" ? "activeLi" : ""}>
              <Link
                to={`/me/${userData.trainerSpace.spaceUrl}/trainer-booking`}
              >
                {t("Guide")}
              </Link>
            </li>*/}
            </ul>
          )}
        </div>
        <div className="sideNavigationFooter">
          {authCtx.isLoggedIn ? <div className="logout-button" onClick={() => logoutHandler()}>
              <LogoutSvg />
              <p>{t("logout")}</p>
            </div> :
            <div className="logout-button" onClick={() => loginHandler()}>
              <LogoutSvg />
              <p>{t("login")}</p>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default ProfileNavigation;
