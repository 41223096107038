import React, { useContext, useEffect } from "react";

import { countrysEnum, passwordRegex } from "../../../enums/enum";
import modalContext from "../../../utility/context/modal-context";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../utility/context/auth-context";
import { DateTime } from "luxon";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import { setOnboardingStatusAction, setTrainerData } from "../../../redux/actions/registration/OnboardingAction";

interface onboardingFiledsValues {
    firstName: string;
    lastName: string;
    companyName: string;
    addressPostalCode: string;
    addressCity: string;
    addressCountry: number;
    vatId: string;
    address: string;
    houseNumber: string;
    phoneNumberCountryCode: number;
    phoneNumber: string;
    birthDate: string;
    preferredPronoun: number;
    password: string;
    confirmPassword: string;
}

const ContactInfoForm = () => {
    const userData = useSelector((state) => state.onboarding.userData);

    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);
    const modalCtx = useContext<any>(modalContext);
    const dispatch = useDispatch();

    //const coutryArray = Array.from(Array(12).keys());

    const getPrefixArray = () => {
        let prefixArray = [];

        //prefixArray[0] = 31; // Netherlands
        //prefixArray[1] = 32; // Belgium
        //prefixArray[2] = 33; // France
        prefixArray[3] = 41; // Switzerland
        prefixArray[4] = 43; // Austria
        //prefixArray[5] = 44; // UK
        //prefixArray[6] = 45; // Denmark
        //prefixArray[7] = 46; // Sweden
        //prefixArray[8] = 47; // Norway
        prefixArray[9] = 49; // Germany
        prefixArray[10] = 352; // Luxemburg
        //prefixArray[11] = 358; // Finland

        return prefixArray;
    };

    const initialValues: onboardingFiledsValues = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        companyName: userData.companyName,
        addressPostalCode: userData.addressPostalCode,
        addressCity: userData.addressCity,
        addressCountry: userData.addressCountry || 4,
        vatId: userData.vatID,
        address: userData.address,
        houseNumber: userData.houseNumber,
        phoneNumberCountryCode: userData.phoneNumberCountryCode,
        phoneNumber: userData.phoneNumber,
        birthDate:
            userData.dateOfBirth !== null || userData.dateOfBirth
                ? DateTime.fromISO(userData.dateOfBirth).toFormat("yyyy-MM-dd")
                : "2000-02-02",
        preferredPronoun: userData.preferredPronoun,
        password: '',
        confirmPassword: ''
    };

    let validationSchema = yup.object({
        firstName: yup
            .string()
            .required(t("first name is required")),
        lastName: yup.string().required(t("last name is required")),
        //companyName: yup.string().required(t("errCompanyName")),
        addressPostalCode: yup
            .string()
            .required(t("postal code is required")),
        addressCity: yup
            .string()
            .required(t("city is required")),
        addressCountry: yup
            .number()
            .required(t("country is required")),
        //vatId: yup.string().required(t("VAT-ID is required")),
        address: yup.string().required(t("address is required")),
        houseNumber: yup
            .string()
            .required(t("house number is required")),
        phoneNumberCountryCode: yup
            .number()
            .required(t("errPhoneNumberCountryCode")),
        phoneNumber: yup
            .string()
            .required(t("phone number is required"))
            .matches(/^[0-9]/, t("errInvalidMobileNumber")),
        birthDate: yup
            .string()
            .required(t("errBirthDate")),
        preferredPronoun: yup
            .number()
            .required(t("errPronoun")),

        password: yup
            .string()
            .min(8, "min")
            .matches(/^(?=.*[0-9])/, "number")
            .matches(/^(?=.*[A-Z])/, "uppercase")
            .matches(/^(?=.*[a-z])/, "lowercase")
            .matches(/^(?=.*[!@#\$%\^&\*])/, "specialChar"),

        confirmPassword: yup
            .string()
            .min(8, t("must be at least 8 characters long"))
            .when("password", {
                is: (val: any) => (val && val.length > 0 ? true : false),
                then: yup
                    .string()
                    .oneOf(
                        [yup.ref("password")],
                        t("errMatchConfirmPassoword")
                    ),
            })
    });
    const { i18n } = useTranslation();
    useEffect(() => {
        dispatch(setTrainerData());
    }, []);
    const handleSubmit = async (data: onboardingFiledsValues) => {
        const birthDateFormated = DateTime.fromISO(data.birthDate).toISO({ includeOffset: false }) + "Z";
        dispatch(setIsLoading(true));

        fetch(
            `${process.env.REACT_APP_BE_BASEURL}/api/trainers/user`,
            {
                method: "PUT",
                headers: {
                    "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authCtx.token,
                },
                body: JSON.stringify({
                    ...userData,
                    ...data,
                    companyType: 1,
                    dateOfBirth: birthDateFormated,
                    addressCountry: Number(data.addressCountry),
                    preferredPronoun: Number(data.preferredPronoun),
                }),
            }
        )
            .then(async (data) => {
                dispatch(setIsLoading(false));
                const res = await data.json();
                if (data.status === 200) {
                    modalCtx.setModalType(0);
                    modalCtx.setMessage(res.message);
                    modalCtx.setIsActive(true);
                } else {
                    modalCtx.setModalType(0);
                    modalCtx.setMessage(res.message);
                    modalCtx.setIsActive(true);
                }
            })
            .catch((e) => {
                dispatch(setIsLoading(false));

                modalCtx.setModalType(0);
                modalCtx.setMessage(e.message);
                modalCtx.setIsActive(true);
            });

        if (data.password && data.confirmPassword && !formik.errors.password && !formik.errors.confirmPassword &&  data.password.match(passwordRegex)) {
            const localStorageUserDataObject = localStorage.getItem('userData');
            const userData = JSON.parse(localStorageUserDataObject || '');

            if (userData && userData.userId) {
                dispatch(setIsLoading(true));

                const response = await fetch(
                    `${process.env.REACT_APP_BE_BASEURL}/api/User/${userData.userId}/password`,
                    {
                        method: "PUT",
                        body: JSON.stringify({
                            password: data.password,
                        }),
                        headers: {
                            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

                            "Content-Type": "application/json",
                            Authorization: "Bearer " + authCtx.token,
                        },
                    }
                );

                if (response.ok) {
                    modalCtx.setModalType(0);
                    modalCtx.setMessage(t("Your Password has Changed successfully"));
                    modalCtx.setIsActive(true);
                    dispatch(setIsLoading(false));

                } else {
                    modalCtx.setModalType(0);
                    modalCtx.setMessage(t("An Error has occurred "));
                    modalCtx.setIsActive(true);
                    dispatch(setIsLoading(false));

                }
            }
        }

    };

    useEffect(() => {
        formik.setFieldValue("firstName",userData.firstName)
        formik.setFieldValue("lastName",userData.lastName)
        formik.setFieldValue("companyName",userData.companyName)
        formik.setFieldValue("addressPostalCode",userData.addressPostalCode)
        formik.setFieldValue("addressCity",userData.addressCity)
        formik.setFieldValue("addressCountry",userData.addressCountry)
        formik.setFieldValue("vatId",userData.vatID)
        formik.setFieldValue("address",userData.address)
        formik.setFieldValue("houseNumber",userData.houseNumber)
        formik.setFieldValue("phoneNumberCountryCode",userData.phoneNumberCountryCode)
        formik.setFieldValue("phoneNumber",userData.phoneNumber)
        formik.setFieldValue("preferredPronoun",userData.preferredPronoun)
        formik.setFieldValue("birthDate",userData.dateOfBirth !== null || userData.dateOfBirth
          ? DateTime.fromISO(userData.dateOfBirth).toFormat("yyyy-MM-dd")
          : "2000-02-02")
    },[userData])

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: onboardingFiledsValues) => {
            handleSubmit(values);
        },
    });

    const handleFiledHasError = (name: string): string | undefined => {
        const formikNew = formik as any;
        return (formikNew.touched[name] && formikNew.errors[name]) || undefined;
    };

    return (
        <div>
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(formik.values) }}>
                <div className="contacInfoContainer">
                    <div className="contacInfoContainerBox">
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="firstname">
                                    {t("firstname")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={"firstname"}
                                    {...formik.getFieldProps("firstName")}
                                    placeholder={t("firstnamePlaceholder")}
                                />
                                {handleFiledHasError("firstName") ? (
                                    <div className="error">{handleFiledHasError("firstName")}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">
                                    {t("lastname")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={"lastname"}
                                    {...formik.getFieldProps("lastName")}
                                    placeholder={t("lastnamePlaceholder")}
                                />
                                {handleFiledHasError("lastName") ? (
                                    <div className="error">{handleFiledHasError("lastName")}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="companyName">
                                    {t("company name")}
                                </label>
                                <input
                                    type="text"
                                    id={"companyName"}
                                    {...formik.getFieldProps("companyName")}
                                    placeholder={t("companynamePlaceholder")}
                                />
                                {handleFiledHasError("companyName") ? (
                                    <div className="error">{handleFiledHasError("companyName")}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="address">
                                    {t("address")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={"address"}
                                    {...formik.getFieldProps("address")}
                                    placeholder={t("addressPlaceholder")}
                                />
                                {handleFiledHasError("address") ? (
                                    <div className="error">{handleFiledHasError("address")}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="houseNumber">
                                    {t("house number")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={"houseNumber"}
                                    {...formik.getFieldProps("houseNumber")}
                                    placeholder={t("houseNumberPlaceholder")}
                                />
                                {handleFiledHasError("houseNumber") ? (
                                    <div className="error">{handleFiledHasError("houseNumber")}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="postalCode">
                                    {t("postal code")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={"postalCode"}
                                    {...formik.getFieldProps("addressPostalCode")}
                                    placeholder={"45290"}
                                />
                                {handleFiledHasError("addressPostalCode") ? (
                                    <div className="error">
                                        {handleFiledHasError("addressPostalCode")}
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">
                                    {t("city")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={"city"}
                                    {...formik.getFieldProps("addressCity")}
                                    placeholder={t("cityPlaceholder")}
                                />
                                {handleFiledHasError("addressCity") ? (
                                    <div className="error">{handleFiledHasError("addressCity")}</div>
                                ) : null}
                            </div>
                        </div>

                    </div>
                    <div className="contacInfoContainerBox">
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="country">
                                    {t("country")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <select id={"country"} {...formik.getFieldProps("addressCountry")}>
                                    <option value={4}>{t(countrysEnum[4])} </option>
                                    <option value={5}>{t(countrysEnum[5])} </option>
                                    <option value={8}>{t(countrysEnum[8])} </option>
                                    <option value={10}>{t(countrysEnum[10])} </option>
                                </select>
                                {handleFiledHasError("addressCountry") ? (
                                    <div className="error">
                                        {handleFiledHasError("addressCountry")}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="vatId">
                                    {t("VAT-ID")}
                                </label>
                                <input
                                    type="text"
                                    id={"vatId"}
                                    {...formik.getFieldProps("vatId")}
                                    placeholder={t("vatIDPlaceholder")}
                                />
                                {handleFiledHasError("vatId") ? (
                                    <div className="error">{handleFiledHasError("vatId")}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="prefix">
                                    {t("prefix")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <select
                                    id={"prefix"}
                                    {...formik.getFieldProps("phoneNumberCountryCode")}
                                >
                                    {getPrefixArray().map((v) => (
                                        <option key={v} value={v}>
                                            +{v}
                                        </option>
                                    ))}
                                </select>
                                {handleFiledHasError("phoneNumberCountryCode") ? (
                                    <div className="error">
                                        {handleFiledHasError("phoneNumberCountryCode")}
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">
                                    {t("phone")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id={"phone"}
                                    {...formik.getFieldProps("phoneNumber")}
                                    placeholder={t("phonePlaceholder")}
                                />
                                {handleFiledHasError("phoneNumber") ? (
                                    <div className="error">{handleFiledHasError("phoneNumber")}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="birthDate">
                                    {t("date of birth")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <DatePicker
                                  id={"birthDate"}
                                  onChange={(date) => {
                                      // @ts-ignore
                                      if(date !== null && date instanceof Date){
                                          formik.setFieldValue("birthDate",(DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")))
                                      }
                                  }}
                                  selected={formik.values.birthDate === "" ? null : DateTime.fromISO(formik.values.birthDate).toJSDate()}
                                  dateFormat="dd.MM.yyyy"
                                  locale={i18n.language === "de" ? de : ""}
                                  calendarStartDay={1}
                                  placeholderText={t("choose a date")}
                                />
                                {handleFiledHasError("birthDate") ? (
                                    <div className="error">{handleFiledHasError("birthDate")}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="preferredPronoun">
                                    {t("preferred pronoun")}{" "}
                                    <span className="required">*</span>
                                </label>
                                <select
                                    id={"preferredPronoun"}
                                    {...formik.getFieldProps("preferredPronoun")}
                                >
                                    <option value={1}>{t("she/her")}</option>
                                    <option value={2}>{t("he/his")}</option>
                                    <option value={3}>{t("Diverse")}</option>
                                </select>
                                {handleFiledHasError("preferredPronoun") ? (
                                    <div className="error">
                                        {handleFiledHasError("preferredPronoun")}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="contacInfoContainerBox ">
                        <div className="form-row">
                            <div className="form-group">
                                <span className="password-title"> {t("changePassword")} </span>
                                <label htmlFor="password    ">
                                    {t("set new password")}{" "}
                                </label>
                                <input
                                    type="password"
                                    id={"password"}
                                    {...formik.getFieldProps("password")}
                                />
                                {formik.getFieldProps("confirmPassword").value &&
                                    formik.getFieldProps("password").value &&
                                    formik.touched.password &&
                                    formik?.errors?.password?.includes("required") ? (
                                    <div className="validation-error yellow-global">
                                        {formik.errors.password}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="contacInfoContainerBox">
                        <div className="form-row">

                            <div className="form-group form-password">

                                <label htmlFor="passwordConfirmation">
                                    {t("confirm new password")}{" "}
                                </label>
                                <input
                                    type="password"
                                    id={"confirmPassword"}
                                    {...formik.getFieldProps("confirmPassword")}
                                />
                                {formik.getFieldProps("confirmPassword").value &&
                                    formik.getFieldProps("password").value &&
                                    formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                    <div className="validation-error yellow-global">
                                        {formik.errors.confirmPassword}
                                    </div>
                                ) : null}
                            </div>

                        </div>
                    </div>

                </div>
                <button type={"submit"}>{t("save")}</button>
            </form>
        </div>
    )
}

export default ContactInfoForm;
