import { countrysEnum } from "../../../enums/enum";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const ServiceTypeOnSiteCoach = (props: any) => {
  const { t } = useTranslation()
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);

  return (
    <div className="serviceTypeOnSiteCoach">
      <div className="serviceTypeOnSiteClient">
        <div className="form-row">
          <input type="text" placeholder={"address"}
                 disabled={true}
                 value={props.address}
                 onChange={(e) => props.setAddress(e.target.value)}

          />
        <input type="text" placeholder={"House number"} className="houseNumber"
                 disabled={true}
                 value={props.houseNumber}
               onChange={(e) => props.setHouseNumber(e.target.value)}

        />
        </div>
      <div className="form-row">
          <input type="text" placeholder={"postal"}
                 disabled={true}
                 value={props.postalCode}
                 onChange={(e) => props.setPostalCode(e.target.value)}

          />
          <input type="text" placeholder={"City"} className="city"
                 disabled={true}
                 value={props.city}
                 onChange={(e) => props.setCity(e.target.value)}
          />
          <input name="country" id="country" className={"country"}
                 disabled={true}
                 value={t(countrysEnum[props.country])}
                 onChange={(e) => props.setCountry(e.target.value)}

          />
        </div>
      </div>
    </div>
  );

};

export default ServiceTypeOnSiteCoach;
