import { useState } from "preact/hooks";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

type personalUrlProps = {
  personalUrl: string;
  setPersonalUrl: any;
  handleSpaceUrl: any;
  errObject?: any;
};

const PersonalUrl = (props: personalUrlProps) => {
  const { t } = useTranslation();
  const spaceUrlPlaceholder = t("sebastian-backes")
  const [personalUrlCookie] = useCookies(['PersonalUrlCookie']);

  return (
    <div className="PersonalUrlSection">
      <div className="card">
        <div className="card_content">
          <div className="form-group">
            <label>
              {t("personalizeUrl")}
              <p className={"muted"}>
                {t("chooseUrl")}
              </p>
            </label>

            <div className="setUrl">
              <label htmlFor="personalUrl">{t("editSpaceUrl")}</label>
              <input
                type="text"
                id={"personalUrl"}
                value={props.personalUrl}
                placeholder={"sebastian-backes"}
                onChange={(e) => props.handleSpaceUrl(e)}
              />
              {props.errObject?.personalUrl && (
                <div className="validation-error yellow-global mt-2">
                  {props.errObject.personalUrl}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalUrl;
