import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from "react-query";
import {store} from "./redux/storeConfig/store";


import './scss/core.scss'


import App, { ScrollToTop } from "./App";
import {AuthContextProvider} from './utility/context/auth-context';
import {Provider} from "react-redux";
import { CookiesProvider } from "react-cookie";

// Create a client
const queryClient = new QueryClient();

ReactDOM.render(

    <Provider store={store}>

        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
              <CookiesProvider >
                <BrowserRouter>
                  <ScrollToTop>
                  </ScrollToTop>
                        <App/>
                </BrowserRouter>
              </CookiesProvider>
            </AuthContextProvider>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
