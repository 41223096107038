import React from "react";
import Invoices from "../../../pages/Trainer/Invoices/Invoices";

const Accounting = () => {

  return(
      <Invoices />
  )
}
export default Accounting
