import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../utility/context/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCalendarItem, setWeeklyView } from "../../redux/actions/businessCenter/calendarAction";
import { ReactComponent as PersonalSVG } from "../../images/component_svg/personalTraining.svg";
import { ReactComponent as SmallGroupSVG } from "../../images/component_svg/smallGroup.svg";
import { ReactComponent as VirtualSVG } from "../../images/component_svg/virtual.svg";
import { ReactComponent as OnSiteCoachSVG } from "../../images/component_svg/onSiteCoach.svg";
import { ReactComponent as OnSiteClientSVG } from "../../images/component_svg/onSiteClient.svg";
import { selectedCalendarItemType } from "../TrainerBusinessCenter/Calendar/WeeklyView";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";


const DashboardCalendar = () => {

  const dispatch = useDispatch<any>();
  const weeklyViewData = useSelector(state => state.businessCenter.calendar.weeklyView);
  const [calendarDays, setCalendarDays] = useState(3);
  const { i18n } = useTranslation();

  const handleResize = () => {
    if (window.innerWidth < 700) {
      setCalendarDays(1);
    } else if (window.innerWidth < 950) {
      setCalendarDays(2);
    } else {
      setCalendarDays(3);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  useEffect(() => {
    dispatch(setWeeklyView());
  }, []);

  return (
    <div className="dashboard-calendar">
      <div className="weeklyView">
        <div className="calendar">
          <div className="card">
            <div className="image-container">
            </div>
            <FullCalendar
              plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]}
              events={weeklyViewData}
              eventContent={(args) => <EventItem args={args} />}
              slotMinTime={"00:00:00"}
              slotMaxTime={"24:00:00"}
              titleRangeSeparator={" - "}
              allDaySlot={false}
              locale={i18n.language}

              dayHeaderFormat={{ weekday: "long", omitCommas: false }}
              //slotDuration={"00:05:00"}
              firstDay={1}
              height={"100%"}
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
                hour12: false

              }}
              views={{
                timeGridThreeDay: {
                  type: "timeGrid",
                  duration: { days: calendarDays },
                  slotMinTime: "00:00:00",
                  slotMaxTime: "24:00:00",
                  weekends: true
                }
              }}
              initialView={"timeGridThreeDay"}

              titleFormat={{}}

              headerToolbar={{
                left: "",
                center: "prev,title,next today",
                right: ""
              }}
            />
          </div>
        </div>
      </div>
    </div>

  );
};
export default DashboardCalendar;


const EventItem = ({ args }: any) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [numberOfParticipants, setNumberOfParticipans] = useState(0);
  const [maxNumberOfParticipants, setMaxNumberOfParticipans] = useState(0);
  const [participantsArray, setParticipantsArray] = useState([]);
  const authCtx = useContext(AuthContext);

  const getNumberParticipants = async () => {
    if (args.event.extendedProps.serviceId >= 0) {
      const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/small-group-participants/${args.event.extendedProps.serviceId}/${DateTime.fromISO(args.event.extendedProps.startDate).toUTC()}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
            "Content-Type": "text/plain",
            Authorization: "Bearer " + authCtx.token
          }
        });

        if (response.ok) {
          const res = await response.json();
          setNumberOfParticipans(res.participantsCount);
          setMaxNumberOfParticipans(res.maximumParticipants);
        }
      } catch (e) {
      }
    }
  };
  const getParticipants = async () => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/calendar/small-group-participants-detail/${args.event.extendedProps.serviceId}/${DateTime.fromISO(args.event.extendedProps.startDate).toISODate()}`;

    try {

      if(args.event.extendedProps.serviceId !== null && args.event.extendedProps.serviceId !== undefined) {

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

            "Content-Type": "text/plain",
            Authorization: "Bearer " + authCtx.token
          }
        });
        if (response.ok) {
          const res = await response.json();
          setParticipantsArray(res);
        }
      }
    } catch (e) {

    }
  };

  useEffect(() => {
    getNumberParticipants();
    getParticipants();
  }, []);


  const handleSelectedCalendarItem = (valid: boolean) => {
    if (valid) {
      const data: selectedCalendarItemType = {
        id: args.event.publicId,
        category: args.event.extendedProps.category,
        serviceType: args.event.extendedProps.serviceType,
        duration: args.event.extendedProps.durationSession,
        name: args.event.title,
        startDate: args.event.extendedProps.startDate,
        endDate: args.event.extendedProps.endDate,
        maximumParticipants: 10,
        ParticipantsCount: 5,
        description: args.event.extendedProps.description,
        difficultyLevel: args.event.extendedProps.difficultyLevel
      };

      dispatch(setSelectedCalendarItem(data));
    }
  };

  return (
    <div className="eventItem"
         style={args.event.extendedProps.serviceType !== -1 ? args.event.extendedProps.serviceType !== 0 ? {
           backgroundColor: "#FDFC00",
           color: "#232323",
           fill: "black",
           padding: 10
         } : { backgroundColor: "#FF045D", color: "white", fill: "white" } : {
           background: "rgba(255,255,255,.2)",
           color: "white",
           fill: "white",
           padding: 10
         }} onClick={() => handleSelectedCalendarItem(args.event.extendedProps.serviceType != -1)}>
      <div className="content-container">
        <div className="text-container" style={{ justifyContent: "flex-start" }}>
          <h1>{args.event.title}</h1>
          <p className="date" style={{ display: "flex", gap: ".1rem" }}>{args.timeText} |
            {args.event.extendedProps.serviceType !== -1 ?
              <p>{args.event.extendedProps.durationSession} {t("minutes")}</p> : null}
          </p>
          {args.event.extendedProps.category == 0 && args.event.extendedProps.customer ? (
            <p>{args.event.extendedProps?.customer.firstName} {args.event.extendedProps?.customer.lastName}</p>) : ""}

          {args.event.extendedProps.category === 1 ?
            <div className="smallGroupParticipants" style={{fontSize:10}}>{numberOfParticipants}/{maxNumberOfParticipants}</div> : null}
          <div className="participantsRow" style={{display:"flex",gap:5,flexWrap:"wrap"}}>
            {args.event.extendedProps?.category == 1 ? participantsArray?.map((name:{firstName:string,lastName:string},index:number,array) => <p>{name.firstName} {name.lastName}{index !== array.length -1 ? ", ": ""} </p>) : null}

          </div>

{/*          {args.event.extendedProps.serviceType === 0 ? <p>{t("virtual")}</p> : null}
          {args.event.extendedProps.serviceType === 1 ? <p>{t("on site coach")}</p> : null}
          {args.event.extendedProps.serviceType === 2 ? <p>{t("onSiteClient")}</p> : null}*/}
        </div>

        <div className="icon-container" style={{flex:1}}>
          <div className="category">
            {args.event.extendedProps.category === 0 ? <PersonalSVG /> : null}
            {args.event.extendedProps.category === 1 ? <SmallGroupSVG
              style={args.event.extendedProps.serviceType != 0 ? { stroke: "black" } : { stroke: "white" }} /> : null}
          </div>
          <div className="serviceType">
            {args.event.extendedProps.serviceType === 0 ? <VirtualSVG /> : null}
            {args.event.extendedProps.serviceType === 1 ? <OnSiteCoachSVG /> : null}
            {args.event.extendedProps.serviceType === 2 ? <OnSiteClientSVG /> : null}
          </div>
        </div>
      </div>
    </div>
  )
    ;
};
