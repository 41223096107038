// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
 export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
  }

  // ** Returns short month of passed date
  export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }

    if (toTimeForCurrentDay && isToday(date)) {
      formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
  }

  /**
   ** Return if user is logged in
   ** This is completely up to you and how you want to store the token in your frontend application
   *  ? e.g. If you are using cookies to store the application please update this function
   */
  export const isUserLoggedIn = () => localStorage.getItem('userData')
  export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const createObjectArrayFromStringArray = (disciplines) => {
      let helperArray = []
      disciplines.forEach((e,index) => {
        helperArray.push({key:index, value: e})
      })
    return helperArray
}

export const createStringArrayFromObjectArray = (disciplines) => {
    let helperArray = []
    disciplines.forEach((e) => {
      helperArray.push(e.value)
    })
    return helperArray
}

export const range = (range) => {
   return [...Array(range).keys()]
}

export const LocalStorageUserData = () => {
  const localStorageUserDataObject  = localStorage.getItem('userData');
  return JSON.parse(localStorageUserDataObject)
}

