import React from "react";
import mailSVG from '../../../images/component_svg/mail.svg'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";


const ResendPasswordInstructions = () => {
  const { i18n , t} = useTranslation();

    const userdata = useSelector(state => state.registration)
  const dispatch = useDispatch();
    const handleResendActivationEmail = async () => {
      dispatch(setIsLoading(true));

      const response = await fetch(`${process.env.REACT_APP_BE_BASEURL}/api/User/forgotPassword/resend-email/${userdata.email.replace("@","%40")}`,
            {
                method: 'GET',
                headers: {
                  "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

                  'Content-Type': 'application/json',
                }
            })
      setTimeout(() => dispatch(setIsLoading(false)),200)

      if(response.ok){

      }else{

      }
    }

    return (
        <div className="resendInstructions">
            <h1>{t("instructionSent")}</h1>
            <img src={mailSVG} alt=""/>
            <p>{t("instructionsHowToResetPassword")}</p>
            <p>{userdata.email}</p>
            <hr/>
            <button type={"submit"} onClick={handleResendActivationEmail}>
                {t("resendInstruction")}
            </button>

        </div>

    )

}

export default ResendPasswordInstructions
