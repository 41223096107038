import { SubscriptionAndPackageItemType } from "./PackagesAndSubscription";
import SubscriptionImage1 from "../../../images/subscription1.png";
import SubscriptionImage2 from "../../../images/subscription2.png";
import SubscriptionImage3 from "../../../images/subscription3.png";
import { useEffect, useState } from "react";
import { getPackages, selectedPackageForBooking } from "../../../redux/actions/packages/packagesAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { currencySymbolEnumFromIndex } from "../../../enums/enum";


const SubscriptionAndPackageItem = ({ item }: { item: any }) => {
  const [selectedImage, setSelectedImage] = useState<number>(Math.floor(Math.random() * 3));
  const imageArray = [SubscriptionImage1, SubscriptionImage2, SubscriptionImage3];
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const price = item.price.toString().split(".");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const daysAfterFirstBooked = t("daysAfterFirstBooked");

  const getServiceDisciplinesArray = () => {
    let helperArray: any = [];
    item.packageServices?.forEach((item: any) => {
      item.service.disciplines.forEach((item: any) => {
        if (!helperArray.includes(item)) {
          helperArray.push(item);
        }
      });
    });
    return helperArray;
  };
// TODO: Get total Count From the Backend
  const getTotalCount = () => {
    let count: number = 0;
    item.packageServices?.forEach((item: any) => {
      count += item.totalCount;
    });
    return count;
  };

  const selectForBooking = () => {
    dispatch(selectedPackageForBooking({
      ...item,
      totalCount: getTotalCount(),
      disciplines: getServiceDisciplinesArray()
    }));
    history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-bookingTwo`);
  };

  return (
    <div className="card">
      <div className={"background"}
           style={{ background: `url(${imageArray[selectedImage]}) no-repeat bottom right` }}></div>
      <div className="serviceScopeAndPrice">
        <p className="scope">{item.name}</p>
        <div className="price">
          <span className="from">{t("from")}</span>
          <p className={"amount"}>{price[0]}<sup className={"priceSup"}>{price[1]}</sup></p><p
          className={"currency"}>{currencySymbolEnumFromIndex[publicTrainerData.currency]}</p>
        </div>
      </div>
      <div className="muted">
      {/*  <p>{getTotalCount()} {t("creditsFor")} {item.serviceCategory === 0 ? <p>{t("personalTraining")}</p> : <p>{t("Small Group")}</p>}</p>*/}
        {item.packageServices.map((item: any) => {
            return (
              <p style={{
                marginBottom: "2px",
              }}>{ ` ${item.totalCount !== null ? item.totalCount + ' x ' : t('unlimited') } ${item.service.name}` } </p>
            );
          })}
      </div>
      <div className="description">
        <p>{item.description}</p>
      </div>
      <div className="bottom">
        <div className="disclaimer">
          <p>{t("startDate")}:{"\t"} {t("afterFirstBookedSession")}</p>
          <p>{t("expirationDate")} :{"\t"}{item.canExpire ? `${item.validForNumberOfWeeks * 7} ${daysAfterFirstBooked}` :
            <p>{t("never")}</p>} </p>
        </div>
        <button onClick={selectForBooking}>{t("buyPackage")}</button>
      </div>
    </div>
  );
};

export default SubscriptionAndPackageItem;

