import React from "react";
import TrainerOnboardingForm from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingForm";

const TrainerOnboardingPage = () => {
    return (
        <div className="TrainerOnboardingPage">
            <div className="skulp_background_init_page">
                <div className="image"/>
            </div>
            <TrainerOnboardingForm />
        </div>
    )

}

export default TrainerOnboardingPage;
