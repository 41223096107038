import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../.././scss/pages/MarketingReferral.scss";
import { setTrainerData } from "../../redux/actions/registration/OnboardingAction";
import { changeNavigation } from "../../redux/actions/layout/layoutAction";
import Referral from "../../components/TrainerBusinessCenter/Marketing/Referral";
import PromoCode from "../../components/TrainerBusinessCenter/Marketing/PromoCode";
import Email from "../../components/TrainerBusinessCenter/Marketing/Email";
import { useTranslation } from "react-i18next";
import { getServices } from "../../redux/actions/businessCenter/serviceActions";
import CRM from "../../components/TrainerBusinessCenter/Marketing/CRM/CRM";


const MarketingReferral = () => {
  const [tab, setTab] = useState<number>(0);
  const { t , i18n } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.onboarding.userData);

  useEffect(() => {
    dispatch(changeNavigation(1));
    dispatch(setTrainerData());
    dispatch(getServices(userData.id, true));
  }, []);

  return (
    <div className="marketingreferral">
      <div className="market-ref">
        <div className="businessCenterService">
          <div className="businessCenterHeader">
            <h1>
              {t("Marketing")}
            </h1>
            <hr />
          </div>
          <div className="serviceTabs">
{/*            <div
              className={`crateNewServiceTab ${tab === 0 ? "active" : ""}`}
              onClick={() => setTab(0)}
            >
              <p>
                {t("Referral")}
              </p>
            </div>*/}
            <div
              className={`enableServiceTab ${tab === 0 ? "active" : ""}`}
              onClick={() => setTab(0)}
            >
              <p>
                {t("PromoCodes")}
              </p>
            </div>
            <div
              className={`disableServiceTab ${tab === 1 ? "active" : ""}`}
              onClick={() => setTab(1)}
            >
              <p>
                {t("CRM")}
              </p>
            </div>
{/*            <div
              className={`disableServiceTab ${tab === 3 ? "active" : ""}`}
              onClick={() => setTab(3)}
            >
              <p>
                {t("CRM")}
              </p>
            </div>*/}
          </div>

       {/*   {tab === 0 ? <Referral /> : null}*/}

          {tab === 0 ? <PromoCode /> : null}

          {tab === 1 ? <CRM /> : null}
        </div>
      </div>
    </div>
  );
};

export default MarketingReferral;
