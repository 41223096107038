import { ReactComponent as VirtualSvg } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as OnSiteSvg } from "../../../images/component_svg/locationSVG.svg";
import { ReactComponent as EditSvg } from "../../../images/component_svg/edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { setEditService } from "../../../redux/actions/businessCenter/serviceActions";
import { currencySymbolEnum } from "../../../enums/enum";

import { useTranslation } from "react-i18next";

const BusinessCenterPersonalTrainingServiceListItem = ({
  e,
  handleDisable,
  handleEnable,
  forEnabled,
  handleDelete,
  setSelectedService,
  isPopupEnabled,
  setTab,
  setSessionType,
}: any) => {
  const dispatch = useDispatch();
  const userData = useSelector(state =>  state.onboarding.userData);
  const handleEdit = () => {
    dispatch(setEditService(e));
    setSelectedService(e.id);
    setTab(0);
    setSessionType(0);
  };
  const { t } = useTranslation();
  const currencySymbol = Object.values(currencySymbolEnum)[userData.currency];

  return (
    <div key={e.id} className="serviceRow">
      <p>{e.name}</p>
      <p>
        {e.durationInMinutes.map((n: any, index: number) => (
          <span className={"durationSpan"}>
            {n}min{e.durationInMinutes.length - 1 > index ? "," : ""}
          </span>
        ))}
      </p>
      <div className="virtual_price">
        <VirtualSvg />
        {e.virtualFee.toString().split(".")[0]}
        <sup>{e.virtualFee.toString().split(".")[1]}</sup>{currencySymbol}
      </div>
      <div className="onSite_price">
        <OnSiteSvg />
        {e.onsiteFee.toString().split(".")[0]}
        <sup>{e.onsiteFee.toString().split(".")[1]}</sup>{currencySymbol}
      </div>
      <div className="edit" onClick={() => { setSelectedService(e.id) }}>
        <EditSvg />
      </div>
      <div className="EditPopup" style={isPopupEnabled ? { display: "flex" } : {}}>
        {forEnabled ? (
          <p onClick={handleEdit}>{t("edit")}</p>
        ) :
          (<p onClick={handleDelete}>{t("delete")}</p>)
        }
        <hr />
        {forEnabled ? (
          <p onClick={handleDisable}>{t("disable")}</p>
        ) : (
          <p onClick={handleEnable}>{t("enable")}</p>
        )}
      </div>
    </div>
  );
};
export default BusinessCenterPersonalTrainingServiceListItem;
