import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const TrainerOnboardingProcessBusinessSeviceFee = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="TrainerOnboardingProcessBusinessSeviceFee">
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h2>
              {t("Service Fee")}
            </h2>
            <p>
              {t("ourServiceFee")}
            </p>
            <hr />
          </div>
          <div className="feeOptions">
            <input
              type="radio"
              id={"passFee"}
              name={"chooseFee"}
              checked={props.feeType === 2}
              onChange={() => props.setFeeType(2)}
            />
            <label htmlFor={"passFee"}>
              <h6>
                {t("Pass fee onto clients")}
              </h6>
              <p>
                {t("Your client pays service fee on transactions.")}
              </p>
            </label>
            <input
              type="radio"
              id={"absorbFee"}
              name={"chooseFee"}
              checked={props.feeType === 1}
              onChange={() => props.setFeeType(1)}
            />
            <label htmlFor={"absorbFee"}>
              <h6>
                {t("Absorb fee")}
              </h6>
              <p>
                {t("You pay service fee on all transactions.")}
              </p>
            </label>
            <input
              type="radio"
              id={"splittFee"}
              name={"chooseFee"}
              checked={props.feeType === 3}
              onChange={() => props.setFeeType(3)}
            />
            <label htmlFor={"splittFee"}>
              <h6>
                {t("Split fee")}
              </h6>
              <p>
                {t("You and your clients pay each half the service fee on the transactions.")}
              </p>
            </label>
            {props.errObject?.feeType && props.feeType === null && (
              <div className="validation-error yellow-global">
                {props.errObject.feeType}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TrainerOnboardingProcessBusinessSeviceFee;
