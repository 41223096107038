const initState = {
    email: '',
    registrationStep: 'login'
}

const SignUpFormsReducer = (state = initState, action: any) => {
    switch (action.type) {
        case "handleRegstrationForm" :
            return {...state, ...action.payload};
        case "setEmail":
            return {...state,email:action.payload}
        default :
            return {...state}
    }
}

export default SignUpFormsReducer;
