import { countrysEnum } from "../../../enums/enum";
import { DateTime } from "luxon";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const PersonalTrainingOverview = () => {
  const selectedForBooking = useSelector(state => state.businessCenter.services.selectedForBooking);
  const { t } = useTranslation();

  return(
    <div className="overview-items">
      <div className="overview-item">
        <p className={"muted"}>{t("How do you want to train?")}</p>
        <p>
          {selectedForBooking.category === 0 ? t("personalTraining") : null}
          {selectedForBooking.category === 1 ? t("Small Group") : null}
        </p>
      </div>
      <div className="overview-item">
        <p className={"muted"}>{t("serviceAndDuration")}</p>
        <p>{selectedForBooking.name} – {selectedForBooking.selectedDuration} {t("min")}</p>
      </div>
      <div className="overview-item">
        <p className={"muted"}>{t("service type")}</p>
        <p>
          {selectedForBooking.selectedServiceType === 0 ? t("virtual") : null}
          {selectedForBooking.selectedServiceType === 1 ? t("on site coach") : null}
          {selectedForBooking.selectedServiceType === 2 ? t("onSiteClient") : null}
        </p>
      </div>
      {selectedForBooking.category === 1 || selectedForBooking.selectedServiceType === 0 ? null :
        <div className="overview-item">
          <p className={"muted"}>{t("location")}</p>
          <p>{selectedForBooking.address}{"\t"}{selectedForBooking.houseNumber}</p>
          <p>{selectedForBooking.postalCode} {selectedForBooking.city}, {countrysEnum[selectedForBooking.country]}</p>
        </div>}
      {selectedForBooking.category === 0 ? null : <div className="overview-item">
        <p className={"muted"}>{t("dateAndTime")}</p>
        <p>{DateTime.fromISO(selectedForBooking.startTime).toUTC().toJSON().substring(0, 10)} - {selectedForBooking.startTime}</p>
      </div>}
    </div>
  )
}

export default PersonalTrainingOverview
