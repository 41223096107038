import React from "react";
import mailSVG from '../../../images/component_svg/mail.svg'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";

const EMailVerificationForm = () => {
  const { i18n } = useTranslation();

    const userdata = useSelector(state => state.registration)
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleResendActivationEmail = async () => {
      dispatch(setIsLoading(true));

      const response = await fetch(`${process.env.REACT_APP_BE_BASEURL}/api/Registration/resend-email/${userdata.email.replace("@","%40")}`,
            {
                method: 'GET',
                headers: {
                  "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

                  'Content-Type': 'application/json',
                }
            })
      setTimeout(() => dispatch(setIsLoading(false)),200)

      if(response.ok){
        const res = await response.json();
      }

    }

    return (
        <div className="emailVerificationForm">
            <h1>{t("emailVerification")}</h1>
            <img src={mailSVG} alt=""/>
            <p>{t("To_complete_the_setup_and_login")}</p>
            <p>{userdata.email}</p>
            <hr/>
            <button type={"submit"} onClick={handleResendActivationEmail}>
                {t("Resend confirmation Email")}
            </button>

        </div>

    )

}

export default EMailVerificationForm
