import { setServiceFee, setTotalPrice, setTravelExpenses } from "../../actions/booking/bookingAction";
import { DateTime } from "luxon";

export type serviceBookingRequest = {
  serviceId?: number,
  serviceType?: number,
  disciplines?: Array<string>,
  durationInMinutes?: number,
  startTime: string,
  endTime: string,
  serviceCategory?: number,
  serviceAmount: number,
  serviceFee: number,
  travelExpense: number
  totalPrice: number
}
export type bookingDataType = {
  serviceBookingRequests: Array<serviceBookingRequest>
  promotionCodeCode: string,
  packageId?: number | null,
}


export type selectedTimeType = {
  startDate: string,
  endDate: string,
  totalPrice: number,
  serviceFee: number,
  servicePrice: number,
  travelExpense: number,
  service?: any,
  isRecalculate?:boolean,
  packageId?: number

}

export type InvoiceItemType = {
  name: string,
  trainerId: number,
  customerId: number,
  invoiceNumber: number,
  invoiceDate: string,
  netAmount: number
  vatAmount: number
  grossAmount: number
  invoiceUrl: string
  fileName: string
  customerFirstName: string
  customerLastName: string
  isVoucher:boolean | null
}

export  type InvoiceType = {
  customerInvoices: Array<InvoiceItemType>,
  skulpInvoices: Array<InvoiceItemType>
}


export type bookingPolicyType = {
  id: string,
  userId: string,
  startTime: string,
  endTime: string
}
type bookingReducerType = {
  selectingFromCancellation: boolean,
  selectingFromPackage: boolean,
  availableTimesDuration: Array<any>,
  selectedTime: Array<selectedTimeType>,
  totalPrice: number,
  bookingPolicy: bookingPolicyType,
  recalculation: Array<serviceBookingRequest>
  isRecalculate: boolean,
  needUpdate: boolean
  travelData: {
    outwardJourneyDuration: number,
    returnJourneyDuration: number
  },
  trainerInvoices: Array<InvoiceType>,
  availableSmallGroupSessions : Array<any>
}

const initState: bookingReducerType = {
  selectingFromCancellation: false,
  selectingFromPackage: false,
  availableTimesDuration: [],
  selectedTime: [],
  recalculation: [],
  isRecalculate: false,
  needUpdate: false,
  totalPrice: 0,
  bookingPolicy: {
    id: "",
    userId: "",
    startTime: "",
    endTime: ""
  },
  travelData: {
    outwardJourneyDuration: -1,
    returnJourneyDuration: -1
  },
  trainerInvoices: [],
  availableSmallGroupSessions: []
};

const BookingReducer = (state = initState, action: any) => {
  switch (action.type) {

    case "setRecalculation":
      return { ...state, recalculation: action.payload,needUpdate: false};
    case "setNeedUpdateToFalse":
      return { ...state, needUpdate: false};
    case "selectingFromPackage":
      return { ...state, selectingFromPackage: action.payload };
    case "selectingFromCancellation":
      return {...state, selectingFromCancellation: action.payload}
    case "getInvoices":
      return { ...state, trainerInvoices: action.payload };
    case "getTravelData":
      return { ...state, travelData: action.payload };
    case "availableSmallGroupSessions":
      return { ...state, availableSmallGroupSessions: action.payload };
    case "getBookingPolicy":
      return { ...state, bookingPolicy: action.payload };
    case "availableTimesDuration":
      return { ...state, availableTimesDuration: [...action.payload] };

    case "addSelectedTime": {
      let helperArray = state.selectedTime;
      helperArray.push({ ...action.payload, totalPrice: 0 });
      return { ...state, selectedTime:  [...helperArray ] ,needUpdate:true,recalculation:[]  };
    }
    case "setSelectedTime": {
      return { ...state, selectedTime: [...action.payload],recalculation:[],needUpdate: true  };
    }
    case "dropSelectedTime": {
      let helperArray = state.selectedTime;
      helperArray = helperArray.filter((s: any) => s.startDate !== action.payload);
      return { ...state, selectedTime: helperArray};
    }
    case "eraseSelectedTime":
      return { ...state, selectedTime: [], recalculation: [],needUpdate: false };

    case "updateSelectedTime": {
      let helperObject = state.selectedTime.filter(s => s.startDate === action.payload.startDate);
      helperObject[0].totalPrice = action.payload.totalPrice.toFixed(2);
      helperObject[0].serviceFee = action.payload.serviceFee.toFixed(2);
      helperObject[0].servicePrice = action.payload.servicePrice.toFixed(2);
      helperObject[0].travelExpense = action.payload.travelExpense.toFixed(2);
      helperObject[0].packageId = action.payload.packageId;

      let helperArray = state.selectedTime.filter(s => s.startDate !== action.payload.startDate);
      helperArray.push({ ...helperObject[0] });
      return { ...state, selectedTime: [...helperArray] };
    }
    case "setTotalPrice":
      return { ...state, totalPrice: action.payload };

    case "setServiceFee":
      return { ...state, serviceFee: action.payload };

    case "setTravelExpenses":
      return { ...state, travelExpenses: action.payload };


    default:
      return { ...state };
  }
};

export default BookingReducer;
