import { Dispatch } from "react";
import i18n from "i18next";
import { setIsLoading } from "../layout/layoutAction";
import { replace } from "formik";

export const setOnboardingStatusAction = (payload: any) => {
  return {
    type: "updateOnboardingStatus",
    payload: payload
  };
};

export const deleteUserDataFromStore = () => {
  return {
    type: "deleteData",
    payload: {
      onboardingStatus: null
    }
  };

};


export const setTrainerData = () => async (dispatch: Dispatch<any>) => {

  let userData = localStorage.getItem("userData");
  // @ts-ignore
  userData = JSON.parse(userData);
  //TODO: CHECK ts-Ignore
  // @ts-ignore
  if (userData?.userId != null && userData.role === "Trainer") {
    dispatch(setIsLoading(true));

    fetch(`${process.env.REACT_APP_BE_BASEURL}/api/trainers/user`,
      {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      }).then((data) => data.json())
      .then((data) => {
        setTimeout(() => dispatch(setIsLoading(false)), 400);

        let formatedData = data.data;
        formatedData.experiences.map((e: any, i: number) => {
          data.data.experiences[i] = { ...data.data.experiences[i], from: e?.periodFrom.substring(0, 10) };
          data.data.experiences[i] = {
            ...data.data.experiences[i],
            to: e?.periodTo === null ? null : e?.periodTo.substring(0, 10)
          };
          //return formatedData;
        });
        return formatedData;
      })
      .then((data) => {
        dispatch({
          type: "setTrainerData",
          payload: data
        });
        localStorage.setItem("trainerId", data.id);
        localStorage.setItem("pictureId", data.profilePictureUri);
      }).catch((err) => {
    });
  }
};
export const getPublicTrainerData = (spaceUrl: string, fromProfile?: boolean) => async (dispatch: Dispatch<any>) => {


  if (spaceUrl && spaceUrl !== "trainer") {
    dispatch(setIsLoading(true));

    fetch(`${process.env.REACT_APP_BE_BASEURL}/api/trainers/${spaceUrl}/public`,
      {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          "Content-Type": "application/json"
        }
      }).then(data => data.json())
      .then((data) => {
        setTimeout(() => dispatch(setIsLoading(false)), 400);

        if (!data.data && fromProfile) {
          dispatch({
            type: "setTrainerId",
            payload: null
          });
        }

        let formatedData = data.data;
        formatedData.experiences.map((e: any, i: number) => {
          data.data.experiences[i] = { ...data.data.experiences[i], from: e.periodFrom.substring(0, 10) };
          data.data.experiences[i] = { ...data.data.experiences[i], to: e.periodTo.substring(0, 10) };
        });
        return formatedData;
      })
      .then((data) => {
        dispatch({
          type: "getPublicTrainerData",
          payload: data
        });
        localStorage.setItem("trainerId", data.id);
      }).catch((err) => {
      if (spaceUrl !== "trainer" && spaceUrl!=="undefined" ) {
        window.location.replace("/trainerNotFound");
      }
    });
  }
};

