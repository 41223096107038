const initState = {
    navigationStatus: 0,
    footerStatus: 0,
    language: 0,
    businessCenterPage: 0,
    isLoading : false

}


const LayoutReducer = (state = initState, action: any) => {
    switch (action.type) {
        case "changeNavigation": {
            return {...state, navigationStatus: action.payload}
        }
        case "changeFooter" :
            return {...state, footerStatus: action.payload}
        case "changeLanguage":
            return{...state,language: action.payload}
        case "businessCenterPage":
            return {...state, businessCenterPage: action.payload}
        case "setIsLoading" :
        return {...state,isLoading: action.payload }
        default :
            return {...state}
    }
}

export default LayoutReducer;
