import { apiURIs } from "../apiPaths";
import RequestService, { methodEnum } from "../service";
import { setIsLoading } from "../../redux/actions/layout/layoutAction";

interface callInterface {
  endpoint: string;
  method: methodEnum;
  headers?: any;
  body?: any;
}
class Services {



  handleResponse = async ({ endpoint, method, body = ""}: callInterface,dispatch:any) => {


    try {
      const request = await RequestService.call({
        endpoint,
        method,
        body,
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      if (request.status === 201 || request.status === 200) {
        return {
          success: true
        }
      } else {
        return {
          success: false,
        }
      }
    } catch (error: any) {
      if (error.response) {
        return {
          success: false,
          message: error.response.data.message,
        }
      } else {
        return {
          success: false,
        }
      }
    }
  }

  async createSmallGroup(formData: any,dispatch:any) {
    dispatch(setIsLoading(true))

    return this.handleResponse({
      endpoint: apiURIs.createSmallGroup,
      method: methodEnum.post,
      body: formData,
    },dispatch)
  }

  async updateSmallGroup(formData: any,dispatch:any) {
    dispatch(setIsLoading(true))

    return this.handleResponse({
      endpoint: `${apiURIs.updateSmallGroup}${formData.id}`,
      method: methodEnum.put,
      body: formData
    },dispatch)
  }
}

export default new Services();
