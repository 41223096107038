import { addSelectedTime, dropSelectedTime } from "../../../redux/actions/booking/bookingAction";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useEffect } from "react";


const ChooseTimeItem = ({ item }: { item: { startDate: string, endDate: string } }) => {
  const dispatch = useDispatch()
  const available = useSelector(state => state.booking.selectedTime.filter((s: any) => s.startDate === item.startDate).length) > 0
  const selectedTimeLength = useSelector(state => state.booking.selectedTime.length)
  const selectedPackageItem = useSelector(state => state.customer.selectedPackageItem)
  const selectedCreditItem  = useSelector(state => state.customer.selectedCreditItem);


  return (
    <div className="chooseTimeItem" key={item.startDate}>
      <input type="checkbox" id={`item${item.startDate}`} name={'choosTimeItem'} checked={available}
        onChange={(e) => {
          if (e.target.checked) {
            if (selectedCreditItem.id) {
              if (selectedTimeLength < 1) {
                dispatch(addSelectedTime(item));
                return;
              }
              return;
            }
            else if (selectedPackageItem.id !== -1) {
              if (selectedPackageItem.isUnlimited) {
                dispatch(addSelectedTime(item))
              }
              else if (selectedPackageItem.count > selectedTimeLength) {
                dispatch(addSelectedTime(item))
              }
            } else {
              dispatch(addSelectedTime(item))
            }
          } else {
            dispatch(dropSelectedTime(item.startDate))
          }
        }}
      />
      <label

        htmlFor={`item${item.startDate}`}>{DateTime.fromISO(item.startDate).toFormat("HH:mm")} - {DateTime.fromISO(item.endDate).toFormat("HH:mm")}</label>
    </div>
  )
}

export default ChooseTimeItem
