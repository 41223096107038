import { countrysEnum, currencySymbolEnum, feeTypeEnum, feeTypeValue } from "../../../enums/enum";
import QuestionSVG from "../../../images/component_svg/Question.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropSelectedTime, setTotalPrice, updateSelectedTime } from "../../../redux/actions/booking/bookingAction";
import { ReactComponent as DeleteXSVG } from "./../../../images/component_svg/deleteX.svg";
import { useTranslation } from "react-i18next";

const OrderItem = ({ serviceFee }: any) => {
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const selectedPackageForBooking = useSelector(state => state.package.selectedPackageForBooking)
  const [servicePrice, setServicePrice] = useState<number>(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currencySymbol = Object.values(currencySymbolEnum)[publicTrainerData.currency];

  return (
    <div className="order-item-group">

      <div className="order-item">
        <p>
          {selectedPackageForBooking.name}
        </p>
        <p>{selectedPackageForBooking.price} {currencySymbol}</p>
      </div>

      <div className="order-item">
        <p>{t("service fee")} 
        <span className="info"> 
          <img src={QuestionSVG} />
          <span className="messageContainer">{t("Revenue from this fee allows us to power your experience and support your coach.")}</span>
        </span>
        </p>
        <p className={"price"}>{serviceFee.toFixed(2)} {currencySymbol}</p>
      </div>
    </div>
  );
};
export default OrderItem;
