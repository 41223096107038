import React, {useEffect, useState} from "react";
import BookingOverview from "./BookingOverview";
import BookingCalendar from "./BookingCalendar";
import BookingSignUp from "./BookingSignUp";
import BookingPayment from "./BookingPayment";
import {useDispatch, useSelector} from "react-redux";
import {getPublicTrainerData, setTrainerData} from "../../../redux/actions/registration/OnboardingAction";
import {useHistory} from "react-router-dom";
import {eraseSelectedTime, getAvailableTimesDuration} from "../../../redux/actions/booking/bookingAction";
import PackagesAndSubscriptionPurchaseType from "./PackagesAndSubscriptionPurchaseType";
import PackagesAndSubscriptionOverview from "./PackagesAndSubscriptionOverview";
import { useTranslation } from "react-i18next";


const PackageBookingContent = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const { t } = useTranslation();







  useEffect(() => {
    dispatch(getPublicTrainerData(history.location.pathname.split('/')[2]))

  },[])
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)
  const selectedPackageForBooking = useSelector(state => state.package.selectedPackageForBooking)

  return (
    <div className="bookingStepsFiveToSeven">
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h2>{t("bookingTitlePackage")}</h2>
            <hr/>
          </div>
          <PackagesAndSubscriptionOverview/>
          {/* <PackagesAndSubscriptionPurchaseType />*/}
          <BookingSignUp />
          <button style={{width:200}} onClick={() => {
            history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/packages`)
          }}>{t("back")}</button>
        </div>
      </div>
    </div>
  )

}

export default PackageBookingContent
