import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'preact/hooks';

const InputTimerPicker = ({ time, setTime, onError, disabled }: { time: any, setTime: any, onError?: any, disabled?: boolean }) => {

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <TimePicker
                value={time || DateTime.now()}
                onChange={(newValue) => {
                    setTime(newValue);
                }}
                ampm={false}
                renderInput={(params) => <TextField {...params} />}
                onError={(reason, value) => onError && onError(reason, value)}
                disabled = {disabled}
            />
        </LocalizationProvider>
    )
}
export default InputTimerPicker;                                                                                                                                                                                                                                            