import React from "react";
import { useTranslation } from "react-i18next";


const TrainerOnboardingBottomBar = (props: any) => {
    const { t } = useTranslation();
    return(
        <div className="bottom_navigation">
            <button type={'button'} onClick={props.handlePreviousStep}>{t("previousStep")}</button>
            <button type={"submit"}>{t("nextStep")}</button>
        </div>
    )
}

export default TrainerOnboardingBottomBar;
