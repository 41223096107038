import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    const handleRedirection = (url) => {
        window.open(url,'_blank')
    }

    const privacyUrl = "https://skulp.de/datenschutz";
    const DatenschutzUrl = "https://skulp.de/impressum";

    return (
        <footer>
            <h1>{t("skulp")}</h1>
            <div className="footer_links">
                <ul>
                    <li>
                        <Link to={'#'} onClick={() => handleRedirection(privacyUrl)} >{t("privacy")} </Link>
                    </li>
                    <li>
                        <Link to={'#'} onClick={() => handleRedirection(DatenschutzUrl)} >{t("imprint")}</Link>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;
