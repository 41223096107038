import { countrysEnum } from "../../../enums/enum";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const ServiceTypeOnSiteClient = (props: any) => {
  const { t } = useTranslation();
  const addressPlaceholder = t("addressPlaceholder");
  const houseNumberPlaceholder = t("house number");
  const cityPlaceholder = t("cityPlaceholder");
  const postalCodePlaceholder = t("postal code");

  const coutryArray = Array.from(Array(12).keys());
  const selectingFromCancellation = useSelector(state => state.booking.selectingFromCancellation);
  const customerData = useSelector(state => state.customer);
  const isBookingFromCancellation = selectingFromCancellation;
  const dispatch = useDispatch();
  return (
    <div className="serviceTypeOnSiteClient">
      <div className="form-row">
        <input
          disabled={isBookingFromCancellation}
          type="text" placeholder={addressPlaceholder} required={true}
            value={props.address} onChange={e => {
            props.setAddress(e.target.value)
            props.setSelectedDiscipline({ id: -1, value: "" });
            props.setDuration(-1);
          }} />
        <input
          disabled={isBookingFromCancellation}
          type="text" placeholder={houseNumberPlaceholder} required={true}
          value={props.houseNumber} onChange={e => {
            props.setHouseNumber(e.target.value)
            props.setSelectedDiscipline({ id: -1, value: "" });
            props.setDuration(-1);
          }} />
      </div>
      <div className="form-row">
        <input
          disabled={isBookingFromCancellation}
          type="text" placeholder={postalCodePlaceholder} className="postal" required={true}
          value={props.postalCode}
          onChange={(e) => {
            props.setPostalCode(e.target.value);
            props.setSelectedDiscipline({ id: -1, value: "" });
            props.setDuration(-1);
          }}
        />
        <input
          disabled={isBookingFromCancellation}
          type="text" placeholder={cityPlaceholder} className="city" required={true}
          value={props.city}
          onChange={e => {
            props.setCity(e.target.value)
            props.setSelectedDiscipline({ id: -1, value: "" });
            props.setDuration(-1);
          }}
        />
        <select
          disabled={isBookingFromCancellation}
          name="country" id="country" className={"country"} value={props.country} required={true}
          onChange={e => {
            props.setCountry(e.target.value)
            props.setSelectedDiscipline({ id: -1, value: "" });
            props.setDuration(-1);
          }}>
          {/*{coutryArray.map((v) => (<option key={v} value={v}>{countrysEnum[v]}</option>))}*/}
          <option value={4}>{t(countrysEnum[4])}</option>
          <option value={5}>{t(countrysEnum[5])}</option>
          <option value={8}>{t(countrysEnum[8])}</option>
          <option value={10}>{t(countrysEnum[10])}</option>
        </select>
      </div>

    </div >
  );

};

export default ServiceTypeOnSiteClient;
