
export const changeNavigation = (payload: number) => {
    return {
        type: 'changeNavigation',
        payload: payload
    }
}

export const changeFooter = (payload: number) => {
    return {
        type: 'changeFooter',
        payload: payload
    }
}

export const changeLanguage = (payload: number) => {
    return {
        type: 'changeLanguage',
        payload: payload
    }
}

export const setBusinesCenterPage = (payload: number) => {
    return {
        type: 'businessCenterPage',
        payload: payload
    }
}

export const setIsLoading = (payload: boolean) => {
    return {
        type: 'setIsLoading',
        payload: payload
    }
}
