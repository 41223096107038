import { ReactComponent as DeleteSVG } from "../../../images/component_svg/delete.svg";
import { ReactComponent as AddSVG } from "../../../images/component_svg/add.svg";
import { ReactComponent as VirtualSVG } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as LocationSVG } from "../../../images/component_svg/locationSVG.svg";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWeeklyView } from "../../../redux/actions/businessCenter/calendarAction";
import { DateTime } from 'luxon';
import { useTranslation } from "react-i18next";
import InputTimerPicker from "../../common/InputTimerPicker";

const DayofWeekRow = (props: any) => {
  const {
    slots,
    newSlot,
    index,
    deleteSlot,
    handleSlotsToUpdate,
    handleSlotsToggle,
    updateDayState,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const dayOfWeekArray = useSelector(
    (state) => state.businessCenter.calendar.dayOfWeek[0][props.dayOfWeek]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWeeklyView());
    if (dayOfWeekArray?.length > 0) {
      setIsActive(true);
    }
  }, []);

  const handleDayCheckbox = (index: string) => {
    setIsActive(!isActive);
    updateDayState(index, !isActive);
  };
  const { t } = useTranslation();

  return (
    <div className="weekday">
      <div className="checkbox_pink">
        <input
          type="checkbox"
          id={props.labelId}
          checked={isActive}
          onChange={() => handleDayCheckbox(index)}
        />
        <label htmlFor={props.labelId}>
          <span className="fillElement" />
        </label>
        <p>
          {t(props.labelId)}
        </p>
      </div>
      <div className="availableTimeCol">
        {slots.map((e: any, i: number) => (
          <AvailableTime
            key={i}
            dayOfWeek={e}
            deleteSlot={deleteSlot}
            handleSlotsToUpdate={handleSlotsToUpdate}
            handleSlotsToggle={handleSlotsToggle}
            parentIndex={index}
            slotIndex={i}
            isActive={isActive}
            identifier={e.id}
            newSlot={newSlot}
          />
        ))}
      </div>
    </div>
  );
};

const AvailableTime = ({
  isActive,
  dayOfWeek,
  parentIndex,
  slotIndex,
  newSlot,
  deleteSlot,
  handleSlotsToUpdate,
  handleSlotsToggle,
}: any) => {
 
  const userData = useSelector((state) => state.onboarding.userData);

  const parsedStartTime = DateTime.fromFormat(dayOfWeek.startTime, "HH:mm:ss");
  const [startTime, setStartTime] = useState(parsedStartTime);

  const parsedEndTime = DateTime.fromFormat(dayOfWeek.endTime, "HH:mm:ss");
  const [endTime, setEndTime] = useState(parsedEndTime);

  const { isVirtual, onSite, onSiteClient, onSiteCoach } = userData;

  const isOnSite = onSite || onSiteClient || onSiteCoach;

  const handleChange = (value: any, type: string) => {
    let startTimeParsed = startTime;
    let endTimeParsed = endTime;

    if(value === null || value === ""){
      return
    }

    if (type === "startTime") {
      setStartTime(value);
      startTimeParsed = value;
    } else {
      setEndTime(value);
      endTimeParsed = value;
    }

    handleSlotsToUpdate(
      parentIndex,
      slotIndex,
      startTimeParsed?.toFormat("HH:mm:ss"),
      endTimeParsed?.toFormat("HH:mm:ss"),
      );
  };
  
  const { t } = useTranslation();

  return (
    <div className="availableTime">

        <InputTimerPicker
          time={startTime}
          setTime={(t: any) => {handleChange(t,"startTime")}}
          onError={(err: any, temp: any) => console.log("error" , temp)}
          disabled={!isActive}
        />
      <p>
        {t("to")}
      </p>

      <InputTimerPicker
            time={endTime}
            setTime={(t: any) => {handleChange(t,"")}}
            onError={(err: any, temp: any) => console.log("error" , temp)}
            disabled={!isActive}
      />

      <div className="icon_row">
        <DeleteSVG onClick={() => deleteSlot(parentIndex, slotIndex)} />
        <AddSVG onClick={() => newSlot(parentIndex)} />
        <VirtualSVG
          onClick={() =>
            isVirtual && handleSlotsToggle(
              parentIndex,
              slotIndex,
              !dayOfWeek.allowedVirtual,
              "virtual"
            )
          }
          className={isVirtual ? dayOfWeek.allowedVirtual ? "icon_active" : "" : "icon_disabled"}
        />
        <LocationSVG
          onClick={() =>
            isOnSite && handleSlotsToggle(
              parentIndex,
              slotIndex,
              !dayOfWeek.allowedOnSite,
              "site"
            )
          }
          className={isOnSite ? dayOfWeek.allowedOnSite ? "icon_active" : "" : "icon_disabled"}
        />
      </div>
    </div>
  );
};

export default DayofWeekRow;

/*
if(props.data){
    setObject({
        ...object,
        startTime: props.data.startTime,
        endTime: props.data.endTime,
        allowedVirtual: props.data.allowedVirtual,
        allowedOnSite: props.data.allowedOnSite
    })
                           dispatch(createAvailableTime(object, localData))

}*/
