import React, {useEffect, useState} from "react";
import BookingOverview from "./BookingOverview";
import BookingCalendar from "./BookingCalendar";
import BookingSignUp from "./BookingSignUp";
import BookingPayment from "./BookingPayment";
import {useDispatch, useSelector} from "react-redux";
import {getPublicTrainerData, setTrainerData} from "../../../redux/actions/registration/OnboardingAction";
import {useHistory} from "react-router-dom";
import {eraseSelectedTime, getAvailableTimesDuration, setSelectingFromCancellation} from "../../../redux/actions/booking/bookingAction";
import PackagesAndSubscriptionPurchaseType from "./PackagesAndSubscriptionPurchaseType";
import { useTranslation } from "react-i18next";
import { eraseSelectedPackageItemForBooking } from "../../../redux/actions/customer/customerAction";
import { useCookies } from "react-cookie";


const BookingStepsFiveToSeven = ({date,setDate,isSummary}: {date:Date, setDate:any,isSummary:boolean }) => {
    const dispatch = useDispatch()
    const history = useHistory();








    useEffect(() => {
        //TODO: TrainerDataCall after Testing
        //dispatch(getPublicTrainerData(history.location.pathname.split('/')[2]))

        return () => {
            dispatch(eraseSelectedTime())
            dispatch(eraseSelectedPackageItemForBooking());
            dispatch(setSelectingFromCancellation(false));

        }
    },[])
    const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)
    const selectedService = useSelector(state => state.businessCenter.services.selectedForBooking)
    const { t } = useTranslation();

    return (
        <div className={`bookingStepsFiveToSeven ${isSummary ? "isSummary-Overview" : ""}`}  >
            <div className="card">
                <div className="card_content">
                    <div className="card_heading">
                        {selectedService.category === 0 ? <h2>{t("bookingTitlePrivate")}</h2>:<h2>{t("bookingTitleGroup")}</h2>} <h2>{publicTrainerData.firstName} {publicTrainerData.lastName}</h2>
                        <hr/>
                    </div>
                    <BookingOverview/>
                    {selectedService.category === 2 ? <PackagesAndSubscriptionPurchaseType /> :""}

                    {selectedService.category === 1 ? null :<BookingCalendar
                        date={date}
                        setDate={setDate}
                    />}

                    <BookingSignUp />

                    <button style={{width:200}} onClick={() => {
                        history.replace(`/me/${history.location.pathname.split('/')[2]}/trainer-booking`)
                    }}>{t("back")}</button>
                </div>
            </div>
        </div>
    )

}

export default BookingStepsFiveToSeven
