import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalContext from "../../../utility/context/modal-context";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import i18n from "i18next";
import AuthContext from "../../../utility/context/auth-context";
import { passwordRegex } from "../../../enums/enum";

interface passwordFieldsValues {
  password: string;
  confirmPassword: string;
}

const initialValues: passwordFieldsValues = {
  password: "",
  confirmPassword: "",
};





const NewPassword = () => {
  const [activeErros, setActiveErrors] = useState<any>([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const modalCtx = useContext<any>(ModalContext);

  let code = query.get("code");


  useEffect(() => {
    if (!!code) {
     // loginLink(authCtx, history, code, userId,dispatch);
    }
  },[])

  const { t } = useTranslation();

  const errorItems = [
    {
      slug: "number",
      errorDescription: t("include at least one number"),
    },
    {
      slug: "min",
      errorDescription: t("must be at least 8 characters long"),
    },
    {
      slug: "max",
      errorDescription: t("must be maximum 16 characters"),
    },
    {
      slug: "specialChar",
      errorDescription: t("include at least 1 special character (!@#$%^&*)"),
    },
    {
      slug: "uppercase",
      errorDescription: t("include at least 1 uppercase letter"),
    },
    {
      slug: "lowercase",
      errorDescription: t("include at least 1 lowercase letter"),
    },
  ];

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t("password is required"))
      .min(8, "min")
      .matches(/^(?=.*[0-9])/, "number")
      .matches(/^(?=.*[A-Z])/, "uppercase")
      .matches(/^(?=.*[a-z])/, "lowercase")
      .matches(/^(?=.*[!@#\$%\^&\*])/, "specialChar"),
    confirmPassword: yup
      .string()
      .min(8,t("must be at least 8 characters long"))
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            t("errMatchConfirmPassoword")
          ),
      })
      .required((t("errMatchConfirmPassoword"))),
  });
  const { i18n } = useTranslation();

  async function changePasswordHandler(password: string) {
    dispatch(setIsLoading(true));

    const response = await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/User/${code}/new-password`,
      {
        method: "PUT",
        body: JSON.stringify({
          password: password,
        }),
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          "Content-Type": "application/json",
        },
      }
    );
    setTimeout(() => dispatch(setIsLoading(false)),200)

    if (response.ok) {
      const res = await response.json()
      modalCtx.setModalType(0);
      modalCtx.setMessage(res.message);
      modalCtx.setIsActive(true);
      history.replace("/");
    } else {
      const res = await response.json()

      modalCtx.setModalType(0);
      modalCtx.setMessage(res.message);
      modalCtx.setIsActive(true);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: (values) => {
      validationSchema.validate(values, { abortEarly: false }).catch((err) => {
        setActiveErrors(JSON.parse(JSON.stringify(err, null, 2))["errors"]);
      });
    },
    onSubmit: async (values: passwordFieldsValues) => {
      if (values.password.match(passwordRegex)) {
        changePasswordHandler(values.password);
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("errPasswordInvalid"));
        modalCtx.setIsActive(true);
      }
    },
  });

  return (
    <div className="trainerOnboardingSetPasswordPage">
      <div className="skulp_background_init_page">
        <div className="image" />
      </div>
      <div className="treinerOnboardingSetPasswordForm">
        <form onSubmit={formik.handleSubmit}>
          <h1>
            {t("New password")}
          </h1>
          <div className="form-group">
            <label htmlFor="password">
              {t("enter password")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="password"
              id={"password"}
              placeholder={t("enter new password")}
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password &&
            formik?.errors?.password?.includes("required") ? (
              <div className="validation-error yellow-global">
                {formik.errors.password}
              </div>
            ) : null}

            {formik.touched.password && (
              <ul className="errors-list">
                {!formik?.errors?.password?.includes("required")
                  ? errorItems.map((err) => {
                      if (activeErros.includes(err.slug)) {
                        return (
                          <li className="password-err validation-error yellow-global">
                            {err.errorDescription}
                          </li>
                        );
                      }
                    })
                  : null}
              </ul>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">
              {t("confirm password")}{" "}
              <span className="required">*</span>
            </label>
            <input
              type="password"
              id={"confirmPassword"}
              placeholder={t("confirm new password")}
              {...formik.getFieldProps("confirmPassword")}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="validation-error yellow-global">
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>
          <button type={"submit"}>
            {t("changePassword")}
          </button>
        </form>
      </div>
    </div>
  );
};

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};



export default NewPassword;
