import React, { useEffect } from "react";
import BusinessCenterPackageServiceListItem from "./BusinessCenterPackageServiceListItem";
import { useDispatch, useSelector } from "react-redux";
import { getTrainerPackages } from "../../../redux/actions/packages/packagesAction";
import { packageObject } from "../../../redux/reducers/packages/packagesReducer";



const BusinessCenterPackagesEnable = () => {

  const dispatch = useDispatch()
  const enabledPackages = useSelector(state => state.package.enabledOrDisablePackages)

  useEffect(() =>{
    dispatch(getTrainerPackages(true))
  } ,[]);


  return (
    <div className="businessCenterPackagesEnabled">
      <div className="card">
          <div className="servicesListPackages">
            {(enabledPackages || []).map((item:packageObject) => (
              <BusinessCenterPackageServiceListItem forEnabled={true} item={item} />
            ))}
          </div>
      </div>
    </div>
  );
};
export default BusinessCenterPackagesEnable;

