import { loadCancelledBookings, loadUpcomingSessions } from "../../actions/customer/customerAction";

export type CustomerObjectType = {
  id: number,
  trainerId: number,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  preferredPronoun: number,
  address: string,
  houseNumber: string,
  addressCity: string,
  addressPostalCode: string,
  companyName: string,
  packages: Array<any>,
  upcomingSessions: upcomingSessionsType,
  selectedPackageItem: selectedItem,
  selectedCreditItem: selectedItem,
  cancelledBookings: Array<any>
  addressCountry: number

}
export type upcomingSessionsType = {
  upcomingBookings: Array<upcomingBookingsType>,
  nextBookingStartTime: string,
  lastBookingEndTime: string
}

export type ActionType = {
  type: string,
  payload: any
}

export type upcomingBookingsType = {
  bookingId: string,
  service: upcomingBookingsServiceType,
  serviceType: number,
  difficultyLevel: number;
  disciplines: Array<any>
  durationInMinutes: number,
  startTime: string,
  endTime: string,
  serviceCategory: number
  trainerAddress?: {address: string, houseNumber: string, postalCode: string, city: string},
}

export interface upcomingBookingsServiceType {
  name: string,
  description: string,
  category: number,

}

export type selectedItem = {
  id: number,
  packageId: number,
  category: number,
  name: string,
  description: string,
  serviceTypes: Array<number>,
  disciplines: Array<string>,
  durationInMinutes: Array<number>,
  onsiteFee: number,
  virtualFee: number,
  enabled: boolean,
  bookings: Array<any>,
  groupPrice: number,
  date: string,
  startTime: string,
  maximumParticipants: number,
  maximumParticipantsInWaitingList: number,
  difficultyLevel: number,
  dayOfWeeks: Array<number>,
  weekRepeats: number,
  location: string,
  address: string,
  postalCode: number,
  city: string,
  country: number,
  houseNumber: string

}

const initState: any = {
  id: -1,
  trainerId: -1,
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  preferredPronoun: -1,
  address: "",
  houseNumber: "",
  addressCity: "",
  addressPostalCode: "",
  companyName: "",
  packages: [],
  cancelledBookings: [],
  country: -1,
  upcomingSessions: {
    upcomingBookings: [],
    nextBookingStartTime: "",
    lastBookingEndTime: ""
  },
  selectedPackageItem: {
    id: -1,
    packageId: -1,
    category: -1,
    name: "",
    description: "",
    serviceTypes: [],
    disciplines: [],
    durationInMinutes: [],
    onsiteFee: -1,
    virtualFee: -1,
    enabled: false,
    bookings: [],
    groupPrice: -1,
    date: "",
    startTime: "",
    maximumParticipants: -1,
    maximumParticipantsInWaitingList: -1,
    difficultyLevel: -1,
    dayOfWeeks: [],
    weekRepeats: -1,
    location: "",
    address: "",
    postalCode: -1,
    city: "",
    country: -1,
    houseNumber: ""
  },
  selectedCreditItem: {
    packageId: -1,
    category: -1,
    name: "",
    description: "",
    serviceTypes: [],
    disciplines: [],
    durationInMinutes: [],
    onsiteFee: -1,
    virtualFee: -1,
    enabled: false,
    bookings: [],
    groupPrice: -1,
    date: "",
    startTime: "",
    maximumParticipants: -1,
    maximumParticipantsInWaitingList: -1,
    difficultyLevel: -1,
    dayOfWeeks: [],
    weekRepeats: -1,
    location: "",
    address: "",
    postalCode: -1,
    city: "",
    country: -1,
    houseNumber: ""
  }
};

const CustomerReducer = (state = initState, action: ActionType) => {
  switch (action.type) {
    case "loadCancelledBookings":
      return { ...state, cancelledBookings: action.payload };
    case "setSelectedPackageItem":
      return { ...state, selectedPackageItem: action.payload };
    case "setSelectedCreditItem":
      return { ...state, selectedCreditItem: action.payload };
    case "loadUpcomingSessions":
      return { ...state, upcomingSessions: action.payload };
    case "loadCustomerPackages" :
      return { ...state, packages: action.payload };
    case "setCustomerID" :
      return { ...state, customerID: action.payload };
    case "setFirstName" :
      return { ...state, firstName: action.payload };
    case "setLastName" :
      return { ...state, lastName: action.payload };
    case "setAddress" :
      return { ...state, address: action.payload };
    case "setHouseNumber":
      return { ...state, houseNumber: action.payload };
    case "setAddressCity":
      return { ...state, addressCity: action.payload };
    case "setAddressPostalCode":
      return { ...state, addressPostalCode: action.payload };
    case "setDateOfBirth" :
      return { ...state, dateOfBirth: action.payload };
    case "setPreferredPronoun" :
      return { ...state, setPreferredPronoun: Number(action.payload) };
    case "setCountry":
      return { ...state, country: action.payload };
    case "setCustomerObject" :
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default CustomerReducer;
