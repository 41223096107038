import React, {useContext, useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import {setOnboardingStatusAction, setTrainerData} from "../../redux/actions/registration/OnboardingAction";
import TrainerOnboardingBottomBar from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingBottomBar";
import {useHistory} from "react-router-dom";
// @ts-ignore
import FullCalendar from '@fullcalendar/react' // must go before plugins
// @ts-ignore
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
// @ts-ignore
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
// @ts-ignore
import timeGridPlugin from '@fullcalendar/timegrid';
// @ts-ignore
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import authContext from "../../utility/context/auth-context";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../redux/actions/layout/layoutAction";


const TrainerOnboardingCalendar = () => {
    const dispatch = useDispatch()
    const userData = useSelector(state => state.onboarding.userData)
    const { i18n , t} = useTranslation();

    useEffect(() => {
        dispatch(setTrainerData());
        dispatch(setOnboardingStatusAction(6))

    },[])

    type CalendarObject = {

        startTime : string,
        endTime: string,
        id: number,
        description: string,
        title: string

    }

    const authCtx = useContext(authContext);
    const history = useHistory();
    const [calendarData, setCalendarData] = useState([]);

    const handleSubmit =  () => {
        history.replace(`/me/${userData.trainerSpace.spaceUrl}`)
    }

    const handlePreviousStep = () => {
        history.replace('/registration-process-marketing')
    }

    const handleLoginToGoogle = () => {

        const data = {}
        dispatch(setIsLoading(true));
        fetch(`${process.env.REACT_APP_BE_BASEURL}/api/Calendar`,
            {
                method: 'GET',
                headers: {
                    "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authCtx.token
                }
            }).then(data => data.json()).then(data => {
            setTimeout(() => dispatch(setIsLoading(false)),400)
            if (data.status !== 200) {
                let calendarObject :any  = []
                data.forEach((e:CalendarObject) => {
                    calendarObject.push({
                        start: e.startTime,
                        end: e.endTime,
                        id: 1,
                        title: e.title,
                        color: 'white',
                        textColor: 'black'
                    })
                })
                setCalendarData(calendarObject);
            } else {
            }
        })
    }


    return (
        <div className={"initialView"}>
            <div className="content">
                <div className="pageTitle">
                    <h1>Calendar Integration</h1>
                    <hr/>
                </div>
                <form onSubmit={handleSubmit}>
                    {/* <div className="trainerOnboardingTestSection">
                        <div className="card">
                            <div className="card_content">
                                <div className="calendarTestContainer">
                                    <h1>Login in to your Calendar Account</h1>
                                    <button type={'button'} className={'googleCalendarButton'}
                                            onClick={handleLoginToGoogle}>Login to your Google Calendar
                                    </button>
                                    <div className="calendar">
                                        <FullCalendar
                                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                                            headerToolbar={{center: 'dayGridMonth,timeGridWeek,timeGridThreeDay,timeGridDay'}}
                                            events={calendarData}
                                            dateClick={handleLoginToGoogle}

                                            views={{
                                                timeGridThreeDay: {
                                                    type: 'timeGrid',
                                                    duration: {days: 3},
                                                    buttonText: '3 day',
                                                    slotMinTime: '08:00:00',
                                                    slotMaxTime: '20:00:00',
                                                    weekends: true
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <TrainerOnboardingBottomBar handlePreviousStep={handlePreviousStep}/>

                </form>

            </div>
        </div>
    )
}

export default TrainerOnboardingCalendar;
