import axios from "axios";
import i18n from "i18next";

export const service = axios.create({
  baseURL: process.env.REACT_APP_BE_BASEURL,
},);


export enum methodEnum {
  get = "get",
  post = "post",
  patch = "patch",
  put = "put",
  delete = "delete",
}

interface callInterface {
  endpoint: string;
  method: methodEnum;
  headers?: any;
  body?: any;
}

class RequestService {

  call({ endpoint, method, headers = {}, body = "" }: callInterface) {

    const callSetup = {
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        ...headers,

      },
      //validateStatus: () => true,
    };

    if (["post", "patch", "put"].includes(method) && body) {
      return service[method](endpoint, body, callSetup);
    }

    return service[method](endpoint);
  }
}

export default new RequestService();
