
export const setPersonalSpacePreviewData =  (payload :any) => {
    return  {
        type: "setPersonalSpacePreviewData",
        payload : payload
    }
}



