import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { daysAndValues } from "../../../enums/enum";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const BusinessCenterServiceCreateRecurring = ({
  startDate,
  weekRepeats,
  errObject,
}: any) => {
  const repeatWeekArray = Array.from(Array(52).keys());
  const { t } = useTranslation();
  const weeks = t("weeks");
  const week = t("week");

  useEffect(() => {
    let now = DateTime.now();
    const repeatInDays = (weekRepeats > 0 ? weekRepeats : 1) * 7;
    if (startDate) {
      now = DateTime.fromISO(startDate);
    }
    now = now.plus({ days: repeatInDays });

    setEndOfClass(now.setLocale("de-DE").toFormat('dd.LL.yyyy'));
  }, [startDate]);

  const editServiceSelector = useSelector(state => state.businessCenter.services.editService)

  const isEditingService = editServiceSelector.id !== -1;

  const [endOfClass, setEndOfClass] = useState<string>(
    DateTime.now().setLocale("de-DE").toFormat('dd.LL.yyyy')
  );
  const dispatch = useDispatch();
  const smallGroupState = useSelector(
    (state) => state.businessCenter.services.smallGroup
  );

  const handleEndOfClass = (event: any) => {
    const weekRepeats = parseInt(event.target.value);
    let now = DateTime.now();
    if (startDate) {
      now = DateTime.fromISO(startDate);
    }
    now = now.plus({ days: weekRepeats * 7 });
    setEndOfClass(now.setLocale("de-DE").toFormat('dd.LL.yyyy'));
    dispatch({
      type: "updateCreateServiceGroup",
      payload: { weekRepeats },
    });
  };

  const handleStateChanges = (val: number) => {
    let newData = [...smallGroupState.dayOfWeeks];
    if (newData.indexOf(val) !== -1) {
      newData = newData.filter((value) => { 
        return value != val;
      });
    } else {
      newData.push(val);
    }
    dispatch({
      type: "updateCreateServiceGroup",
      payload: { dayOfWeeks: newData },
    });
  };

  return (
    <>
      <div className="checkbox-weekdays-row">
        {daysAndValues.map((day) => (
          <div className= {`checkbox_pink ${isEditingService ? "checkbox_pink-disabled" : ""}`} >
            <input
              disabled={isEditingService}
              type="checkbox"
              id={day.key}
              onChange={() => !isEditingService && handleStateChanges(day.value)}
              checked={smallGroupState.dayOfWeeks.includes(day.value)}
            />
            <label htmlFor={day.key}>
              <span className="fillElement" />
            </label>
            <p onClick={() => !isEditingService && handleStateChanges(day.value)}>
              {t(day.key)}
            </p>
          </div>
        ))}
      </div>
      {errObject?.dayOfWeeks && smallGroupState.dayOfWeeks?.length < 1 && (
        <div className="validation-error yellow-global col-12">
          {errObject?.dayOfWeeks}
        </div>
      )}
      <div className="repeating_row">
        <label>
          {t("repeat for")}
        </label>
        <select disabled={isEditingService} id={"repeatFor"} onChange={handleEndOfClass}>
          {repeatWeekArray.map((v) => (
            <option
              selected={
                smallGroupState.weekRepeats > 0 &&
                smallGroupState.weekRepeats === v + 1
              }
              value={v + 1}
            >
              {v + 1} {v > 0 ? weeks : week}
            </option>
          ))}
        </select>

        <label>
          {t("currentClassWillEnd")}
        </label>
        <input className={isEditingService ? "currentDisabled" : ""} type={"text"} disabled value={endOfClass} />
      </div>
      {errObject?.weekRepeats && !smallGroupState.weekRepeats && (
        <div className="validation-error yellow-global col-12">
          {errObject?.weekRepeats}
        </div>
      )}
    </>
  );
};

export default BusinessCenterServiceCreateRecurring;
