import { ReactComponent as SearchSvg } from "../../../../images/component_svg/search_white.svg";
import { ReactComponent as ArrowSvg } from "../../../../images/component_svg/arrowDownSVG.svg";
import { ReactComponent as DeleteSvg } from "../../../../images/component_svg/delete.svg";
import { useTranslation } from "react-i18next";
import CRMTableRow from "./CRMTableRow";
import { Dispatch, useEffect, useState } from "react";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { setIsLoading } from "../../../../redux/actions/layout/layoutAction";


const Crm = () => {

  const userData = useSelector((state) => state.onboarding.userData);
  const dispatch = useDispatch();
  const [customerCredits,setCustomerCredits] = useState([])

  const getCustomerCredits = async () => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/trainers/customers-credits`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      dispatch(setIsLoading(true));

      const res = await response.json();

      if (response.ok) {
        setCustomerCredits(res);
        setTimeout(() => dispatch(setIsLoading(false)),400)


      } else {
        setTimeout(() => dispatch(setIsLoading(false)),400)


      }
    } catch (error) {

    }
  };


useEffect(() => {
  getCustomerCredits();

},[])

const { t, i18n } = useTranslation();
return (
  <div className="crm">
    <div className="card">
      <div className="card_content">
        <div className="card_heading">
          {/*<input id={"search"} type="text" name={"search"} placeholder={t("search name")} />
          <button type={"submit"}><SearchSvg /></button>*/}
        </div>

        <div className="crm-table">
          <div className="table-header">
            <div className="muted">{t("clients")}</div>
            <div className="muted">{t("open credits")}</div>
            <div className="muted">{t("date")}</div>
          </div>
          {
            customerCredits.map((item:any) => {
              return(
                <CRMTableRow getCustomerCredits={getCustomerCredits} item={item} />
              )
            })
          }


        </div>

      </div>
    </div>
  </div>
);

}
;
export default Crm;
