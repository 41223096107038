import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";


const TrainerOnboardingTop = ({isLoggedIn} : any) => {
    const { t } = useTranslation();


    const {onboardingStatus} = useSelector(state => state.onboarding);


    //TODO - Change from list items to a Grid system
    return(
        <div className={'trainerOnboardingTop'} style={onboardingStatus === null ||  onboardingStatus=== -1 || !isLoggedIn ? {display: "none"}  : {display: "flex"}}>
            <ul>
                <li className={onboardingStatus > 0 ? 'done' : ''}>
                    <h2 className={onboardingStatus === 0 || onboardingStatus === 'Step0' ? "active_form": ''}>1</h2>
                    <p>{t("contactInfo")}</p>
                </li>
                <li  className={onboardingStatus > 1 ? 'done' : ''}>
                    <h2 className={onboardingStatus === 1 || onboardingStatus === 'Step1' ?"active_form": ''}>2</h2>
                    <p>{t("aboutYou")}</p>
                </li>
                <li  className={onboardingStatus > 2 ? 'done' : ''}>
                    <h2 className={onboardingStatus === 2 || onboardingStatus === 'Step2' ?"active_form": ''}>3</h2>
                    <p>{t("businessSettings")}</p>
                </li>
                <li  className={onboardingStatus > 3 ? 'done' : ''}>
                    <h2 className={onboardingStatus === 3 || onboardingStatus === 'Step3'?"active_form": ''}>4</h2>
                    <p>{t("editYourSpace")}</p>
                </li>
                <li  className={onboardingStatus > 4 ? 'done' : ''}>
                    <h2 className={onboardingStatus === 4 || onboardingStatus === 'Step4' ?"active_form": ''}>5</h2>
                    <p>{t("yourPayment")}</p>
                </li>

            </ul>
        </div>
    )
}

export default TrainerOnboardingTop;
