import personalSpaceImg from '../../../images/personalSpace.png'
const initState = {
    onboardingStatus: null,
    userData: {
        onboardingStatus: null,
        id : -1,
        userId : null,
        firstName: null,
        lastName: null,
        address: null,
        houseNumber: null,
        phoneNumberCountryCode: null,
        phoneNumber: null,
        preferredPronoun: null,
        dateOfBirth: null,
        profilePictureUri: null,
        spokenLanguages: [],
        websiteDiscoverySource: null,
        socialMediaAccounts: [],
        specialties: [],
        yearsOfExperience: null,
        experiences: [],
        educations: [],
        visionAndMission: null,
        timezone: null,
        currency: null,
        disciplines: [],
        cancellationPolicy: null,
        trainerSpace: {
            logoUrl: null,
            spaceUrl: null,
            name: null,
            nickname:null,
            slogan:null
        }
        //TODO ADD Expected information
    },
    trainerId: -1,
    publicTrainerData:{
        onboardingStatus: null,
        id : -1,
        userId : null,
        firstName: null,
        lastName: null,
        address: null,
        houseNumber: null,
        phoneNumberCountryCode: null,
        phoneNumber: null,
        preferredPronoun: null,
        dateOfBirth: null,
        profilePictureUri: null,
        spokenLanguages: [],
        websiteDiscoverySource: null,
        socialMediaAccounts: [],
        specialties: [],
        yearsOfExperience: null,
        experiences: [],
        educations: [],
        visionAndMission: null,
        timezone: null,
        currency: null,
        disciplines: [],
        cancellationPolicy: null,
        trainerSpace: {
            logoUrl: "",
            spaceUrl: null,
            name: null,
            nickname:null,
            slogan:null
        }
    }
}


const OnboardingReducer = (state = initState, action: any) => {
    switch (action.type) {
        case "updateOnboardingStatus" :
            return {...state, onboardingStatus: action.payload};
        case "setTrainerData":{
            return {...state, userData: {...action.payload}, trainerId: action.payload.id}
        }
        case "getPublicTrainerData":{
            return {...state, publicTrainerData: {...action.payload}}
        }
        case "deleteData":{
            return {...state,...action.payload}
        }
        case "setTrainerId":
            return { ...state, trainerId: action.payload }
        default :
            return {...state}
    }
}

export default OnboardingReducer;
