import React, { Fragment } from 'react';
import Footer from "./Footer";
import MainNavigation from "./MainNavigation";
import NavigationBar from "./NavigationBar";
import {useSelector} from "react-redux";
import ProfileNavigation from "./ProfileNavigation";
import ProfileNavigationForPreview from "./ProfileNavigationForPreview";
import Spinner from "./Spinner";

const Layout = (props:any) => {
    const navigationStatus = useSelector(state => state.layout.navigationStatus)
    const isLoading = useSelector(state => state.layout.isLoading)

  return (
    <Fragment>
        {navigationStatus === 0 ?  <NavigationBar /> : null }
        {navigationStatus === 1 ?  <ProfileNavigation /> : null }
        {navigationStatus === 20 ? <ProfileNavigationForPreview />  : null }
      <main>
        <div className="loadingScreen" style={isLoading ? {display: "flex"} : {}}>
          <div className="spinner-border SpinnerStyles" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
        {props.children}
      </main>
        <Footer />
    </Fragment>
  );
};

export default Layout;
// <MainNavigation />
