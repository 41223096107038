import { Dispatch, useContext } from "react";
import { service } from "../../../shared/service";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { setIsLoading } from "../layout/layoutAction";
import { serializeQueryParams } from "../../../utility/url";

export const getServices =
  (
    trainerId: number,
    enabled?: boolean,
    selectedDiscipline: { id: number; value: string } = {
      id: -1,
      value: ""
    },
    serviceType: number = -1,
    duration: number = -1,
    category: number = -1
  ) =>
    async (dispatch: Dispatch<any>) => {
      try {
        const params = {
          trainerId: trainerId,
          enabled: enabled,
          serviceType: serviceType === -1 ? undefined : serviceType, // -1 is using as empty value in several calls. TODO: need to refactor this after changing other calls to use undefined as empty value
          duration: duration === -1 ? undefined : duration,
          category: category === -1 ? undefined : category,
        }
        let url = `${process.env.REACT_APP_BE_BASEURL}/api/services?${serializeQueryParams(params)}`

        // @ts-ignore
        if (selectedDiscipline.value != null && selectedDiscipline.value != "") {
          // @ts-ignore
          let disiciplines = selectedDiscipline.value.split("/");
          disiciplines.forEach((e: string, i: number) => {
            disiciplines[i] = e.trim();
            url += `${
              disiciplines[i] != null && disiciplines[i] != ""
                ? `&disiciplines=${disiciplines[i].replace(/\s/g, "%20")}`
                : ""
            }`;
          });
        }
        if (trainerId != null) {
          dispatch(setIsLoading(true));

          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

              accept: "text/plain",
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          });
          setTimeout(() => dispatch(setIsLoading(false)),400)

          const data = await response.json();
          if (response.ok) {
            dispatch({
              type: "getServices",
              payload: data
            });
          }
        }
      } catch (error) {
      }
    };

    export const getServiceById = (args: {trainerId: number, enabled?: boolean, serviceId: number, category: number}) => async (dispatch: Dispatch<any>) => {
      const {trainerId, serviceId, category, enabled} = args;
      try {
        const params = {
          trainerId: trainerId,
          enabled: enabled,
          serviceId: serviceId,
          category: category === -1 ? undefined : category,
        };
        let url = `${process.env.REACT_APP_BE_BASEURL}/api/services?${serializeQueryParams(params)}`;

        if (trainerId != null) {
          dispatch(setIsLoading(true));

          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

              accept: "text/plain",
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          });
          setTimeout(() => dispatch(setIsLoading(false)),400)

        const data = await response.json();
        if (response.ok) {
          const servicefilterById = data.filter((s: any) => s.id === serviceId);
          dispatch({
            type: "getServices",
            payload: servicefilterById,
          });
        }
      }
    } catch (error) {
    }
  };

export const hasServices =
  (trainerId: number) => async (dispatch: Dispatch<any>) => {

    try {
      let url = `${process.env.REACT_APP_BE_BASEURL
        }/api/services?trainerId=${trainerId}&enabled=${true}`;

      if (trainerId != null) {
        dispatch(setIsLoading(true));

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

            accept: "text/plain",
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        });
        setTimeout(() => dispatch(setIsLoading(false)),400)

        const data = await response.json();
        if (response.ok) {
          dispatch({
            type: "hasService",
            payload: Boolean(data.length > 0)
          });
        }
      }
    } catch (error) {
    }
  };

export const selectedForBookingAction = (payload: any) => ({
  type: "setSelectedForBooking",
  payload: payload
});

export const setServiceToUpdate = (payload: any) => ({
  type: "setServiceToUpdate",
  payload: payload
});

export const setEditService = (payload: any) => ({
  type: "setEditService",
  payload: payload
});

export const resetEditService = () => ({
  type: "resetEditService"
});

export const setEditServiceGroup = (payload: any) => ({
  type: "setEditServiceGroup",
  payload: payload,
});

export const setPromoCode = (payload: any) => ({
  type: 'setPromoCode',
  payload: payload,
})

export const resetPromoCode = () => ({
  type: 'resetPromoCode'
})

export const getPromoCodes = () =>
  async (dispatch: Dispatch<any>) => {

    try {
      let url = `${process.env.REACT_APP_BE_BASEURL}/api/trainers/promotion-codes`;
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          accept: "text/plain",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      const data = await response.json();
      if (response.ok) {

        dispatch({
          type: "getPromoCodes",
          payload: data,
        });
      }
    } catch (error) {
    }
  }
