import React, { useContext, useEffect, useState } from "react";
import { range } from "../../../utility/hooks/Utils";
import { useDispatch, useSelector } from "react-redux";
import SelectableDiscipline from "./SelectableDiscipline";
import { getServices } from "../../../redux/actions/businessCenter/serviceActions";
import { ServiceArrayType } from "../../../redux/reducers/businessCenter/businessCenterReducer";
import {
  eraseServiceCountList, getTrainerPackages,
  setPackageCanExpire,
  setPackageCategory,
  setPackageDescription,
  setPackageName,
  setPackagePrice,
  setPackageValidForNumberOfWeeks
} from "../../../redux/actions/packages/packagesAction";
import {
  createPackageType,
  serviceCountListType
} from "../../../redux/reducers/packages/packagesReducer";
import ModalContext from "../../../utility/context/modal-context";
import { useTranslation } from "react-i18next";
import PriceInput from "../../common/priceInput";
import { currencySymbolEnumFromIndex } from "../../../enums/enum";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";

const intErrObject = {
  name: "",
  serviceCountList: "",
  description: "",
  validForNumberOfWeeks: "",
  canExpire: "",
  price: ""
};

const CreateNewPackage = ({
                            category,
                            setCategory
                          }: {
  category: number;
  setCategory: any;
}) => {
  const userData = useSelector((state) => state.onboarding.userData);
  const serviceArray = useSelector(
    (state) => state.businessCenter.services.serviceArray
  );
  const createPackage: createPackageType = useSelector(
    (state) => state.package.createPackage
  );
  const [errObject, setErrObject] = useState<any>(intErrObject);
  const { t } = useTranslation();
  const weeks = t("weeks");
  const week = t("week");
  const modalCtx = useContext<any>(ModalContext);

  interface createPackageFetchData {
    trainerId: number;
    serviceCategory: number;
    name: string;
    description: string;
    serviceCountList: Array<serviceCountListType>;
    validForNumberOfWeeks: number;
    price: number;
    canExpire: boolean;
  }

  const dispatch = useDispatch();
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);

  useEffect(() => {
    dispatch(getTrainerPackages(true));
  }, []);

  useEffect(() => {
    dispatch(
      getServices(
        userData.id,
        true,
        { id: -1, value: "" },
        -1,
        -1,
        createPackage.category
      )
    );
    dispatch(eraseServiceCountList());
  }, [createPackage.category]);

  const handleValidateInputs = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let status = true;
      const errros = JSON.parse(JSON.stringify(intErrObject));
      if (!createPackage.name) {
        errros.name = t("errName");
        status = false;
      }

      if (createPackage.category === null) {
        errros.category = t("Please select category");
        status = false;
      }

      if (createPackage.canExpire === true) {
        if (!createPackage.validForNumberOfWeeks) {
          errros.validForNumberOfWeeks = t(
            "errValidForNumberOfWeeks"
          );
          status = false;
        }
      }

      if (createPackage.price < 0.1) {
        errros.price = t("errPrice");
        status = false;
      }

      if (!createPackage.description) {
        errros.description = t("errDescription");
        status = false;
      }
      if (createPackage.serviceCountList?.length < 1) {
        errros.serviceCountList = t("errServiceCountList"
        );
        status = false;
      }
      setErrObject(errros);
      resolve(status);
    });
  };
  const { i18n } = useTranslation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isFormValid = await handleValidateInputs();
    if (!isFormValid) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("please fill all the required fields"));
      modalCtx.setIsActive(true);
      return;
    }
    const data: createPackageFetchData = {
      trainerId: userData.id,
      name: createPackage.name,
      serviceCategory: createPackage.category,
      canExpire: createPackage.canExpire,
      description: createPackage.description,
      serviceCountList: createPackage.serviceCountList,
      validForNumberOfWeeks: createPackage.validForNumberOfWeeks,
      price: createPackage.price
    };

    const url = `${process.env.REACT_APP_BE_BASEURL}/api/packages`;

    try {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      const res = await response.json();
      dispatch(setIsLoading(false));

      if (response.ok) {
        modalCtx.setModalType(0);
        modalCtx.setMessage(res.message);
        modalCtx.setIsActive(true);
        dispatch({ type: "resetCreatePackage" });
        dispatch(setPackageCategory(1));
        dispatch(setPackageCategory(0));
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(res.message);
        modalCtx.setIsActive(true);
      }
    } catch (error) {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <div className="createNewPackage">
      <div className="card">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nameOfPackage">
              {t("nameOfPackage")}
            </label>
            <input
              type={"text"}
              id={"nameOfPackage"}
              value={createPackage.name}
              onChange={(e) => dispatch(setPackageName(e.target.value))}
              maxLength={50}
            />
            {errObject?.name && !createPackage.name && (
              <div className="validation-error yellow-global col-12">
                {errObject?.name}
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="description">
              {t("description")}
            </label>
            <textarea
              id={"description"}
              value={createPackage.description}
              onChange={(e) => dispatch(setPackageDescription(e.target.value))}
              maxLength={500}
            />
            {errObject?.description && !createPackage.description && (
              <div className="validation-error yellow-global col-12">
                {errObject?.description}
              </div>
            )}
          </div>

          <div className="selectCategory">
            <div className="form-row">
              <input
                type={"radio"}
                value={category}
                id={"personalTrainingSelection"}
                checked={createPackage.category === 0}
                name={"serviceType"}
                onChange={(e) => dispatch(setPackageCategory(0))}
              />
              <label htmlFor={"personalTrainingSelection"}>
                {t("personalTraining")}
              </label>
              <input
                type={"radio"}
                value={category}
                id={"smallGroupSelection"}
                name={"serviceType"}
                checked={createPackage.category === 1}
                onChange={(e) => dispatch(setPackageCategory(1))}
              />
              <label htmlFor={"smallGroupSelection"}>
                {t("Small Group")}
              </label>
            </div>
            {errObject?.category && createPackage.category === null && (
              <div className="validation-error yellow-global col-12">
                {errObject?.category}
              </div>
            )}
          </div>
          <div className="selectDisciplines">
            {serviceArray?.map((item: ServiceArrayType) => {
              return <SelectableDiscipline key={item.id} item={item} />;
            })}
            {errObject?.serviceCountList &&
              createPackage.serviceCountList?.length < 1 && (
                <div className="validation-error yellow-global col-12">
                  {errObject?.serviceCountList}
                </div>
              )}
          </div>
          <div className="expire">
            <div className="packageExpireDate">
              <label>
                {t("WhenShouldThisPackageExpire")}
              </label>
              <select
                value={Number(createPackage.canExpire)}
                onChange={(e) =>
                  dispatch(setPackageCanExpire(Number(e.target.value)))
                }
              >
                <option value={Number(1)}>
                  {t("certain weeks")}
                </option>
                <option value={Number(0)}>{t("never")}</option>
              </select>
            </div>
            <div
              className="weeks"
              style={createPackage.canExpire ? {} : { display: "none" }}
            >
              <select
                id={"weeks"}
                value={createPackage.validForNumberOfWeeks}
                onChange={(e) =>
                  dispatch(
                    setPackageValidForNumberOfWeeks(Number(e.target.value))
                  )
                }
              >
                {range(52).map((item: number) => (
                  <option key={item + 1} value={item + 1}>
                    {item + 1} {item !== 0 ? weeks : week}
                  </option>
                ))}
              </select>
              {errObject?.validForNumberOfWeeks &&
                !createPackage.validForNumberOfWeeks && (
                  <div className="validation-error yellow-global col-12">
                    {errObject?.category}
                  </div>
                )}
              {t("afterFirstSession")}
            </div>
            <p></p>
          </div>
          <div className="bottom">
            <div className="price">
              <div className="form-group">
                <label htmlFor="offerPackage">
                  {t("offerAsPackage")}
                </label>
                <PriceInput
                  price={createPackage.price}
                  handleOnChange={(value: string) =>
                    dispatch(setPackagePrice(Number(value)))}
                  currency={userData.currency}
                  //errObject={errObject?.groupPrice}
                />
                {errObject?.price && createPackage.price < 0.1 && (
                  <div className="validation-error yellow-global col-12">
                    {errObject?.price}
                  </div>
                )}
              </div>
            </div>
            <button type={"submit"}>
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewPackage;
