import { ReactComponent as ServiceIcon } from "../../images/component_svg/businessCenterSvgs/serviceIcon.svg";
import {
  ReactComponent as PackagesSubscriptionsIcon
} from "../../images/component_svg/businessCenterSvgs/packagesSubscriptionsIcon.svg";
import { ReactComponent as CalendarIcon } from "../../images/component_svg/businessCenterSvgs/calendarIcon.svg";
import { ReactComponent as MarketingIcon } from "../../images/component_svg/businessCenterSvgs/marketingIcon.svg";
import { ReactComponent as AccountingIcon } from "../../images/component_svg/businessCenterSvgs/accountingIcon.svg";
import { ReactComponent as ReportIcon } from "../../images/component_svg/businessCenterSvgs/reportIcon.svg";
import {
  ReactComponent as BusinessSettingsIcon
} from "../../images/component_svg/businessCenterSvgs/businessSettingsIcon.svg";
import { ReactComponent as PaymentIcon } from "../../images/component_svg/businessCenterSvgs/paymentIcon.svg";
import {
  ReactComponent as EditYourSpaceIcon
} from "../../images/component_svg/businessCenterSvgs/editYourSpaceIcon.svg";
import {
  ReactComponent as ContactInformationIcon
} from "../../images/component_svg/businessCenterSvgs/contactInformationIcon.svg";
import { ReactComponent as AboutYouIcon } from "../../images/component_svg/businessCenterSvgs/aboutYouIcon.svg";
import { useEffect, useState } from "react";
import { hasServices } from "../../redux/actions/businessCenter/serviceActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setBusinesCenterPage } from "../../redux/actions/layout/layoutAction";
import { useHistory, useParams, useRouteMatch, Link } from "react-router-dom";

type BusinessCenterSideNavigationProps = {
  loadPage: number;
  setLoadPage: any;
};

const BusinessCenterSideNavigation = ({
                                        loadPage,
                                        setLoadPage
                                      }: BusinessCenterSideNavigationProps) => {
  // const navigate =
  const dispatch = useDispatch();
  const userData = useSelector(state => state.onboarding.userData);
  const hasService = useSelector(state => state.businessCenter.hasService);
  const businessCenterPage = useSelector(state => state.layout.businessCenterPage);
  const { t } = useTranslation();
  const history = useHistory();
  const [path, setPath] = useState("");
  const { id, tab } = useParams<{ id: string, tab: string }>();

  enum businessCenterTab {
    "service",
    "package",
    "calendar",
    "marketing",
    "accounting",
    "report",
    "businessSettings",
    "editSpace",
    "contact",
    "about"
  }

  useEffect(() => {
    const selectedTabArray = history.location.pathname.split("/");
    const selectedTabName: string = selectedTabArray[selectedTabArray.length - 1].toString();
    setPath(selectedTabName);
    console.log(selectedTabName)
    switch (selectedTabName) {
      case "service":
        dispatch(setBusinesCenterPage(0));
        break;
      case "package":
        dispatch(setBusinesCenterPage(1));
        break;
      case "calendar":
        dispatch(setBusinesCenterPage(2));
        break;
      case "business-center-calendar":
        dispatch(setBusinesCenterPage(2));
        break;
      case "marketing":
        dispatch(setBusinesCenterPage(3));
        break;
      case "accounting":
        dispatch(setBusinesCenterPage(4));
        break;
        case "business-center-invoices":
        dispatch(setBusinesCenterPage(4));
        break;
      case "report":
        dispatch(setBusinesCenterPage(5));
        break;
      case "businessSettings":
        dispatch(setBusinesCenterPage(6));
        break;
      case "editSpace":
        dispatch(setBusinesCenterPage(7));
        break;
      case "contact":
        dispatch(setBusinesCenterPage(8));
        break;
      case "about":
        dispatch(setBusinesCenterPage(9));
        break;
      case "business-center":
        dispatch(setBusinesCenterPage(0));
        break;
      default:
        break;
    }
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(hasServices(userData.id));
  }, [userData.id, businessCenterPage]);

  return (
    <div className="businessCenterSideNavigation">
      <ul>
        <Link to={`/me/${id}/business-center/service`}>
          <li
            className={businessCenterPage === 0 ? "active" : ""}
            onClick={() => dispatch(setBusinesCenterPage(0))}
          >
            <ServiceIcon />
            <p>
              {t("services")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/package`}>
          <li className={businessCenterPage === 1 ? "active" : hasService ? "" : "disabled"}
              onClick={() => {
                if (hasService) {
                  dispatch(setBusinesCenterPage(1));
                }
              }}
          >
            <PackagesSubscriptionsIcon />
            <p>{t("packages")}</p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/calendar`}>

          <li
            className={businessCenterPage === 2 ? "active" : ""}
            onClick={() => dispatch(setBusinesCenterPage(2))}>
            <CalendarIcon />
            <p>
              {t("calendar")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/marketing`}>

          <li
            className={businessCenterPage === 3 ? "active" : ""}
            onClick={() => dispatch(setBusinesCenterPage(3))}
          >
            <MarketingIcon />
            <p>
              {t("marketing")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/accounting`}>

          <li className={businessCenterPage === 4 ? "active" : ""} onClick={() => dispatch(setBusinesCenterPage(4))}>
            <AccountingIcon />
            <p>
              {t("accounting")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/report`}>

          <li className={businessCenterPage === 5 ? "active" : ""} onClick={() => dispatch(setBusinesCenterPage(5))}>
            <ReportIcon />
            <p>
              {t("report")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/businessSettings`}>

          <li className={businessCenterPage === 6 ? "active" : ""} onClick={() => dispatch(setBusinesCenterPage(6))}>
            <BusinessSettingsIcon />
            <p>
              {t("businessSettings")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/editSpace`}>

          <li className={businessCenterPage === 7 ? "active" : ""} onClick={() => dispatch(setBusinesCenterPage(7))}>
            <EditYourSpaceIcon />
            <p>
              {t("editYourSpace")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/contact`}>

          <li className={businessCenterPage === 8 ? "active" : ""} onClick={() => dispatch(setBusinesCenterPage(8))}>
            <ContactInformationIcon />
            <p>
              {t("Contact information")}
            </p>
          </li>
        </Link>
        <Link to={`/me/${id}/business-center/about`}>

          <li className={businessCenterPage === 9 ? "active" : ""} onClick={() => dispatch(setBusinesCenterPage(9))}>
            <AboutYouIcon />
            <p>
              {t("aboutYou")}
            </p>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default BusinessCenterSideNavigation;
