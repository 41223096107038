import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../utility/context/auth-context";
import { ReactComponent as SmilieSVG } from "../../../images/component_svg/smilySVG.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalContext from "../../../utility/context/modal-context";
import { countrysEnum, emailRegex, passwordRegex, PlatformType } from "../../../enums/enum";
import { setTrainerData } from "../../../redux/actions/registration/OnboardingAction";
import { loadCustomerData } from "../../../redux/actions/customer/customerAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";

const BookingSignUp = () => {
  const { i18n , t} = useTranslation();

  interface customerRegistrationValues {
    firstName: string;
    lastName: string;
    addressCountry: number;
    email:string;
    password: string;
    confirmPassword:string;
  }

  const initialValues: customerRegistrationValues = {
    firstName: "",
    lastName: "",
    addressCountry: 4,
    email:"",
    password: "",
    confirmPassword:""

  };

  const validationSchemaLogin = yup.object({
    email: yup
      .string()
      .email(t("errEmailInvalid"))
      .required(t("email is required")),
    password: yup
      .string()
      .required(t("password is required"))
  });

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup.string().required(t("last name is required")),
    email: yup
      .string()
      .matches(emailRegex, t("errEmailInvalid"))
      .required(t("email is required")),
    addressCountry: yup
      .number()
      .required(t("country is required")),
    password: yup
      .string()
      .required(t("password is required"))
      .min(8, "min")
      .matches(/^(?=.*[0-9])/, "number")
      .matches(/^(?=.*[A-Z])/, "uppercase")
      .matches(/^(?=.*[a-z])/, "lowercase")
      .matches(/^(?=.*[!@#\$%\^&\*])/, "specialChar"),
    confirmPassword: yup
      .string()
      .min(8, t("must be at least 8 characters long"))
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            t("errMatchConfirmPassoword")
          ),
      })
      .required((t("errMatchConfirmPassoword"))),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: (values) => {
      validationSchema.validate(values, { abortEarly: false }).catch((err) => {
        setActiveErrors(JSON.parse(JSON.stringify(err, null, 2))["errors"]);
      });
    },
    onSubmit: async (values: customerRegistrationValues) => {
      onSubmitRegistration(values);
    },
  });
  const handleFiledHasError = (name: string): string | undefined => {
    const formikNew = formik as any;
    return (formikNew.touched[name] && formikNew.errors[name]) || undefined;
  };

  const errorItems = [
    {
      slug: "number",
      errorDescription: t("include at least one number"),
    },
    {
      slug: "min",
      errorDescription: t("must be at least 8 characters long"),
    },
    {
      slug: "max",
      errorDescription: t("must be maximum 16 characters"),
    },
    {
      slug: "specialChar",
      errorDescription: t("include at least 1 special character (!@#$%^&*)"),
    },
    {
      slug: "uppercase",
      errorDescription: t("include at least 1 uppercase letter"),
    },
    {
      slug: "lowercase",
      errorDescription: t("include at least 1 lowercase letter"),
    },
  ];

  const [activeErros, setActiveErrors] = useState<any>([]);

  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const modalCtx = useContext<any>(ModalContext);
  const [signType, setSignType] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const coutryArray = Array.from(Array(12).keys());

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [country, setCountry] = useState<number>(4);

  const dispatch = useDispatch<any>();


  const enterEmailPlaceholder = t("enter email");
  const firstnamePlaceholder = t("firstnamePlaceholder");
  const lastnamePlaceholder = t("lastnamePlaceholder");
  const chooseCountry = t("choose your country");
  const enterPasswordPlaceholder = t("enter new password");
  const confirmPasswordPlaceholder = t("confirm new password");


  const typeEmailPlaceholder = t("typeEmail");
  const passwordPlaceholder = t("password");
  const emailPlaceholder = t("Email");
  const history = useHistory();

  const authCtx = useContext(AuthContext);

  const localStorageUserDataObject: any = localStorage.getItem("userData");
  const LocalStorageUserData: { email: string, role: string, trainerId: string, userId: string, userName: string } = JSON.parse(localStorageUserDataObject);


  const onSubmitRegistration = async (values: customerRegistrationValues) => {


    if (!authCtx.isLoggedIn) {

      if (values.password.match(passwordRegex) && values.password === values.confirmPassword) {
        type registrateCustomerObject = {
          email: string,
          password: string,
          addressCountry: number,
          firstName: string,
          lastName: string,
          trainerId: number
        }

        const data: registrateCustomerObject = {
          email: values.email,
          password: values.password,
          addressCountry: Number(values.addressCountry),
          firstName: values.firstName,
          lastName: values.lastName,
          trainerId: publicTrainerData.id
        };

        const url = `${process.env.REACT_APP_BE_BASEURL}/api/Registration/customer`;
        dispatch(setIsLoading(true));

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        });

        setTimeout(() => dispatch(setIsLoading(false)),400)

        const res = await response.json();

        if (response.ok) {

          modalCtx.setModalType(0);
          //modalCtx.setMessage(t("registration was successful, please check your E-Mails"));
          modalCtx.setMessage(res.message);
          modalCtx.setIsActive(true);

        } else {
          modalCtx.setModalType(0);
          modalCtx.setMessage(res.message);
          modalCtx.setIsActive(true);
        }
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("errPasswordInvalid"));
        modalCtx.setIsActive(true);
      }

    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("you are already logged in"));
      modalCtx.setIsActive(true);
    }
  };

  const onSubmitLogin = async (e: any) => {
    e.preventDefault();
    if (!authCtx.isLoggedIn) {
      const url = `${process.env.REACT_APP_BE_BASEURL}/api/User/token`;
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
          returnSecureToken: true
        }),
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          "Content-Type": "application/json",
          "PlatformType": PlatformType.Web,
        }
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      if (response.ok) {
        const data = await response.json();
        let expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 43200);

        var userData = {
          "userId": data.userId,
          "email": data.email,
          "role": data.roles[0],
          "userName": data.userName
        };


        // @ts-ignore
        authCtx.login(data.token, userData, expirationTime.toISOString());

        dispatch(loadCustomerData(data.userId));

      } else {
        const data = await response.json();

        modalCtx.setModalType(0);
        modalCtx.setMessage(data.message);
        modalCtx.setIsActive(true);

      }
    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("you are already logged in"));
      modalCtx.setIsActive(true);
    }
  };

  useEffect(() => {
    if(history.location.search.split("=").length > 0){
      setEmail(history.location.search.split("=")[2]);
    }
  },[])

  return (
    <div className="bookingSignUp" id="bookingSignUp">
      <h5>{t("SignUp")}</h5>
      <div className="signTypeTabs">
        <div className="tab">
          <input type="radio" id={"signIn"} name="signType"
                 checked={signType === 0}
                 onClick={() => setSignType(0)} />
          <label htmlFor="signIn">{t("signIn")}</label>
        </div>
        <div className="tab">
          <input type="radio" id={"signUp"} name="signType"
                 checked={signType === 1}
                 onClick={() => setSignType(1)} />
          <label htmlFor="signUp">{t("SignUp")}</label>
        </div>
      </div>
      {!authCtx.isLoggedIn || LocalStorageUserData?.role === "Trainer" ? signType === 0 ?
          <div className="signIn">
            <form onSubmit={onSubmitLogin}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="email">{t(emailPlaceholder)}</label>
                  <input type="text" placeholder={emailPlaceholder}
                         value={email}
                         onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">{t("password")}</label>
                  <input type="password" id={"password"} placeholder={passwordPlaceholder}
                         value={password}
                         onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <button>{t("signIn")}</button>
            </form>
          </div>
          :
          <div className="signUp">
            <form onSubmit={formik.handleSubmit} className={"registration"} id={"form2"}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstname">
                    {t("firstname")}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id={"firstname"}
                    {...formik.getFieldProps("firstName")}
                    placeholder={firstnamePlaceholder}
                  />
                  {handleFiledHasError("firstName") ? (
                    <div className="error">{handleFiledHasError("firstName")}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="lastname">
                    {t("lastname")}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id={"lastname"}
                    {...formik.getFieldProps("lastName")}
                    placeholder={lastnamePlaceholder}
                  />
                  {handleFiledHasError("lastName") ? (
                    <div className="error">{handleFiledHasError("lastName")}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-row">

                <div className="form-group">
                  <label htmlFor="country">
                    {t("country")}{" "}
                    <span className="required">*</span>
                  </label>
                  <select id={"country"} {...formik.getFieldProps("addressCountry")}>
                    {/*  {coutryArray.map((v) => (
                <option key={v} value={v}>
                  {countrysEnum[v]}
                </option>
              ))}*/}
                    <option value={4}>{t(countrysEnum[4])}</option>
                    <option value={5}>{t(countrysEnum[5])} </option>
                    <option value={8}>{t(countrysEnum[8])} </option>
                    <option value={10}>{t(countrysEnum[10])} </option>
                  </select>
                  {handleFiledHasError("addressCountry") ? (
                    <div className="error">
                      {handleFiledHasError("addressCountry")}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    {t("email")}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id={"email"}
                    placeholder={t("enter email")}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="password">
                    {t("enter password")}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    id={"password"}
                    {...formik.getFieldProps("password")}
                    placeholder={enterPasswordPlaceholder}
                  />
                  {formik.touched.password &&
                  formik?.errors?.password?.includes("required") ? (
                    <div className="validation-error yellow-global">
                      {formik.errors.password}
                    </div>
                  ) : null}

                  {formik.touched.password && (
                    <ul className="errors-list">
                      {!formik?.errors?.password?.includes("required")
                        ? errorItems.map((err) => {
                          if (activeErros.includes(err.slug)) {
                            return (
                              <li className="password-err validation-error yellow-global">
                                {err.errorDescription}
                              </li>
                            );
                          }
                        })
                        : null}
                    </ul>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">
                    {t("confirm password")}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    id={"confirmPassword"}
                    {...formik.getFieldProps("confirmPassword")}
                    placeholder={confirmPasswordPlaceholder}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="validation-error yellow-global">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <button>{t("SignUp")}</button>
            </form>
          </div> :
        <div className={"loggedIn"}>
          <SmilieSVG />
          <p>{t("alreadySignedIn1")}<br />{t("alreadySignedIn2")}</p>
        </div>}

    </div>
  );
};
export default BookingSignUp;
/*
<div className="signUp">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="firstname">{t("firstname"} <span
                                        className="required">*</span></label>
                                    <input type="text" id={'firstname'} required={true}
                                           value={firstname}
                                           placeholder={firstnamePlaceholder}
                                           onChange={(e) => setFirstname(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastname">{t("lastname")} <span
                                        className="required">*</span></label>
                                    <input type="text" id={'lastname'} required={true} value={lastname}
                                           placeholder={lastnamePlaceholder}
                                           onChange={(e) => setLastname(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="email">{t(enterEmailPlaceholder)}</label>
                                    <input type="text" placeholder={typeEmailPlaceholder}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">{t("enterPassword")}</label>
                                    <input type="password" id={'password'} placeholder={enterPasswordPlaceholder}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="birthDate">{t("dateOfBirth")} <span
                                        className="required">*</span></label>
                                    <input type="date" id={'birthDate'} required={true} value={birthDate}
                                           placeholder={'01.01.2000'}
                                           onChange={(e) => setBirthDate(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pronoun">{t("preferredPronoun")} <span
                                        className="required">*</span></label>
                                    <select id={'pronoun'} value={pronoun} required={true}
                                            onChange={(e) => setPronoun(e.target.value)}>
                                        <option value={1}>she/her</option>
                                        <option value={2}>he/his</option>
                                        <option value={3}>Diverse</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="address">{t("address")} <span
                                        className="required">*</span></label>
                                    <input type="text" id={'address'} required={true} value={address}
                                           placeholder={addressPlaceholder}
                                           onChange={(e) => setAddress(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="houseNumber">{t("house number")} <span
                                        className="required">*</span></label>
                                    <input type="text" id={'houseNumber'} required={true} value={houseNumber}
                                           placeholder={houseNumberPlaceholder}
                                           onChange={(e) => serHouseNumber(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="postalCode">{t("postal code")} <span
                                        className="required">*</span></label>
                                    <input type="text" id={'postalCode'} required={true} value={postalCode}
                                           placeholder={'45290'}
                                           onChange={(e) => setPostalCode(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="city">{t("city")} <span
                                        className="required">*</span></label>
                                    <input type="text" id={'city'} required={true} value={city}
                                           placeholder={cityPlaceholder}
                                           onChange={(e) => setCity(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="company">{t("companyname")}<span
                                        className="required">*</span></label>
                                    <input type="text" id={'company'} required={true} value={company}
                                           placeholder={companyNameOptPlaceholder}
                                           onChange={(e) => setCompany(e.target.value)}/>
                                </div>
                                <button>{t("signUp")}</button>
                            </div>

                        </form>
                    </div>

*
* */
