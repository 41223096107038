import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { countrysEnum, serviceTypeEnum } from "../../../enums/enum";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import PersonalTrainingOverview from "./PersonalTrainingOverview";
import SmallGroupOverview from "./SmallGroupOverview";
import PackagesAndSubscriptionOverview from "./PackagesAndSubscriptionOverview";
import { useTranslation } from "react-i18next";


const BookingOverview = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const selectedForBooking = useSelector(state => state.businessCenter.services.selectedForBooking);
  const selectedTime = useSelector(state => state.booking.selectedTime);
  const spaceUrl = history.location.pathname.split("/")[2];


  useEffect(() => {
    if (selectedForBooking.id === -1) {
      //history.replace(`/me/${spaceUrl}/trainer-booking`);
    }
  }, [selectedForBooking]);

  return (
    <div className="overview">
      <h5>{t("Overview")}</h5>

      {selectedForBooking.category === 0 ? <PersonalTrainingOverview/> : null}
      {selectedForBooking.category === 1 ? <SmallGroupOverview /> : null}
    </div>
  );
};

export default BookingOverview;
