import { useTranslation } from "react-i18next";
import {
  timeZoneEnum,
  currencySymbolEnum
} from "../../../enums/enum";

const TrainerOnboardingProcessBusinessTimezoneCurrency = (props: any) => {
  const timezonesArray = Array.from(Array(33).keys());
  const currencyArray = Array.from(Array(8).keys());

  const currencySymbolArray = Object.values(currencySymbolEnum);
  const { t } = useTranslation();
  return (
    <div className="TrainerOnboardingProcessBusinessTimezoneCurrency">
      <div className="card">
        <div className="card_content">
          <div className="timezoneCurrency">
            <div className="super-label">
              <label htmlFor="timezoneInput">
                <p>
                  {t("timezone")}
                </p>
                <span className={"sub-label"}>
                  {t("Please select your timezone and currency.")}
                </span>
              </label>
              <select
                name="timezone"
                id="timezoneInput"
                value={props.timezone}
                onChange={(e) => props.setTimezone(e.target.value)}
              >
                {/*         {timezonesArray.map((v) => (
                  <option key={v} value={v}>
                    {timeZoneEnum[v]}
                  </option>
                ))}*/}
                <option value={13}>{"+01:00 Berlin, Paris, Brussels, Amsterdam, Budapest"}</option>
              </select>
              {props?.errObject?.timezone && (
                <div className="validation-error yellow-global">
                  {props?.errObject?.timezone}
                </div>
              )}
            </div>
            <div className="super-label">
              <label htmlFor="currencyInput">
                <p>
                  {t("currency")}
                </p>
                <span className={"sub-label"}>
                  {t("selectCurrency")}
                </span>
              </label>
              <select
                name="currency"
                id="currencyInput"
                value={currencySymbolArray[props.currency]}
                disabled={!!props.disableCurrency ? props.disableCurrency : false}
                onChange={(e) => props.setCurrency( currencySymbolArray.findIndex(curr => curr === e.target.value)) }
              >
                {currencySymbolArray.map((v: string, index: Number) => {
                  if (index === 3 || index === 0)
                    return (<option key={v} value={v}>
                      {v}
                    </option>);
                })}
              </select>
              {props?.errObject?.currency && (
                <div className="validation-error yellow-global">
                  {props?.errObject?.currency}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerOnboardingProcessBusinessTimezoneCurrency;
