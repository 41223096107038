import closeSVG from "../../../images/component_svg/close.svg";
import React, { useContext, useState } from "react";
import ModalContext from "../../../utility/context/modal-context";
import { useTranslation } from "react-i18next";

const TrainerOnboardingProcessBusinessDisciplines = (props: any) => {
  const [disciplines, setDisciplines] = useState("");

  const { t } = useTranslation();
  const disciplinesPlaceholder = t(
    "e.g. Yoga"
  );
  const modalCtx = useContext<any>(ModalContext);

  const handleOnKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      let isDuplicated: boolean = false;

      props.specialties.forEach((e: { key: string; value: string }) => {
        if (e.value.toString() === disciplines) {
          isDuplicated = true;
        }
      });
      if (!isDuplicated) {
        setTimeout(
          () =>
            props.setSpecialties([
              ...props.specialties,
              {
                key: Date.now(),
                value: disciplines,
              },
            ]),
          300
        );
        setDisciplines("");
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("discipline already selected"));
        modalCtx.setIsActive(true);
      }
    }
  };

  return (
    <div className={"trainerOnboardingProcessBusinessDisciplines"}>
      <div className="card">
        <div className="card_content">
          <div className="form-group">
            <label htmlFor="specialties">
              {t("disciplines")}
              <span className={"required"}>*</span>
              <p className={"muted"}>
                {t("You can add disciplines by writing them down and pressing enter.")}
              </p>
              <input
                type="text"
                id={"specialties"}
                value={disciplines}
                placeholder={disciplinesPlaceholder}
                onChange={(e) => setDisciplines(e.target.value)}
                onKeyPress={(event) => handleOnKeyPress(event)}
              />
            </label>
            <div className="specalties_added">
              {props.specialties.map(
                (value: { key: string; value: string }) => (
                  <div key={value.key} className="specaltie">
                    {value.value}
                    <span
                      onClick={(e) => {
                        props.setSpecialties([
                          ...props.specialties.filter(
                            (e: { key: string; value: string }) =>
                              e.key !== value.key
                          ),
                        ]);
                      }}
                    >
                      <img src={closeSVG} alt="deleteSVG" />
                    </span>
                  </div>
                )
              )}
            </div>
            {props.specialtiesErr && props.specialties.length < 1 && (
              <div className="validation-error yellow-global">
                {props.specialtiesErr}{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerOnboardingProcessBusinessDisciplines;
