import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import AuthContext from "../../../utility/context/auth-context";
import { getUserData } from "../../../utility/hooks/Utils";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { queries } from "@testing-library/react";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import { passwordRegex, PlatformType } from "../../../enums/enum";
import ModalContext from "../../../utility/context/modal-context";

interface passwordFieldsValues {
  password: string;
  confirmPassword: string;
}

const initialValues: passwordFieldsValues = {
  password: "",
  confirmPassword: "",
};



const TrainerOnboardingSetPasswordForm = () => {
  const { i18n , t} = useTranslation();
  const modalCtx = useContext<any>(ModalContext);

  const disptch = useDispatch();

  const errorItems = [
    {
      slug: "number",
      errorDescription: t("include at least one number"),
    },
    {
      slug: "min",
      errorDescription: t("must be at least 8 characters long"),
    },
    {
      slug: "max",
      errorDescription: t("must be maximum 16 characters"),
    },
    {
      slug: "specialChar",
      errorDescription: t("include at least 1 special character (!@#$%^&*)"),
    },
    {
      slug: "uppercase",
      errorDescription: t("include at least 1 uppercase letter"),
    },
    {
      slug: "lowercase",
      errorDescription: t("include at least 1 lowercase letter"),
    },
  ];


  const [activeErros, setActiveErrors] = useState<any>([]);

  const authCtx = useContext(AuthContext);
  const userData = getUserData();

  const enterPasswordPlaceholder = t("enter password");
  const confirmPasswordPlaceholder = t("confirm password");
  const dispatch = useDispatch()
  const history = useHistory();
  const query = useQuery();


  let code = query.get("code");
  let userId = query.get("userId");


  useEffect(() => {
    if (!!code) {
      loginLink(authCtx, history, code, userId,dispatch);
    }
  },[])

  async function changePasswordHandler(password: string) {
    dispatch(setIsLoading(true));

    await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/User/${userData.userId}/password`,
      {
        method: "PUT",
        body: JSON.stringify({
          password: password,
        }),
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      }
    ).then(async (data) => {
      setTimeout(() => dispatch(setIsLoading(false)),400)

    });

    history.replace("/registration-process");
  }

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t("password is required"))
      .min(8, t("must be at least 8 characters long"))
      .matches(/^(?=.*[0-9])/, "number")
      .matches(/^(?=.*[A-Z])/, "uppercase")
      .matches(/^(?=.*[a-z])/, "lowercase")
      .matches(/^(?=.*[!@#\$%\^&\*])/, "specialChar"),
    confirmPassword: yup
      .string()
      .min(8, t("must be at least 8 characters long"))
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            t("errMatchConfirmPassword")
          ),
      })
      .required(t("errMatchConfirmPassword")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate: (values) => {
      validationSchema.validate(values, { abortEarly: false }).catch((err) => {
        setActiveErrors(JSON.parse(JSON.stringify(err, null, 2))["errors"]);
      });
    },
    onSubmit: async (values: passwordFieldsValues) => {
      if (values.password.match(passwordRegex)) {
        changePasswordHandler(values.password);
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("errPasswordInvalid"));
        modalCtx.setIsActive(true);
      }
    },
  });

  return (
    <div className="treinerOnboardingSetPasswordForm">
      <form onSubmit={formik.handleSubmit}>
        <h1>{t("setYourPassword")}</h1>
        <div className="form-group">
          <label htmlFor="email">
            {t("enter password")}{" "}
            <span className="required">*</span>
          </label>
          <input
            type="password"
            id={"email"}
            {...formik.getFieldProps("password")}
            placeholder={enterPasswordPlaceholder}
          />
          {formik.touched.password &&
          formik?.errors?.password?.includes("required") ? (
            <div className="validation-error yellow-global">
              {formik.errors.password}
            </div>
          ) : null}

          {formik.touched.password && (
            <ul className="errors-list">
              {!formik?.errors?.password?.includes("required")
                ? errorItems.map((err) => {
                    if (activeErros.includes(err.slug)) {
                      return (
                        <li className="password-err validation-error yellow-global">
                          {err.errorDescription}
                        </li>
                      );
                    }
                  })
                : null}
            </ul>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">
            {t("confirm password")}{" "}
            <span className="required">*</span>
          </label>
          <input
            type="password"
            id={"confirmPassword"}
            {...formik.getFieldProps("confirmPassword")}
            placeholder={confirmPasswordPlaceholder}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="validation-error yellow-global">
              {formik.errors.confirmPassword}
            </div>
          ) : null}
        </div>
        <button type={"submit"}>
          {t("SignUp")}
        </button>
      </form>
      <p>
        <a href="https://skulp.de/datenschutz"  target={"_blank"}>
          {t("policyAndTermsOfUse")}
        </a>
      </p>
    </div>
  );
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const loginLink =  async (authCtx: any, history: any, code: any, userId: any,dispatch:any) =>  {
  let expirationTime = new Date();
  expirationTime.setHours(expirationTime.getHours() + 43200);
  const url = `${process.env.REACT_APP_BE_BASEURL}/api/User/token/code`;

  dispatch(setIsLoading(true))

  const body = {
    "code": code,
    "userId": userId
  }

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
      "Content-Type": "application/json",
      'PlatformType': PlatformType.Web,
    },
  });
  setTimeout(() => dispatch(setIsLoading(false)),400)

  if (response.ok) {
    const res = await response.json();

    var userData = {
      userId: userId,
      role: res.roles[0],
    };

    authCtx.login(res.token, userData, expirationTime.toISOString());

  }


};

export default TrainerOnboardingSetPasswordForm;
