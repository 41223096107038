import { useState } from "react";
import { useTranslation } from "react-i18next";


const PackagesAndSubscriptionPurchaseType = () => {
  const [purchaseType, setPurchaseType] = useState<number>(0);
  const { t } = useTranslation();

  return (
    <div className="packagesAndSubscriptionPurchaseType">
      <div className="header">
        <h5>{t("chooseYourPurchase")}</h5>
        <div className="muted">{t("ChooseFollowingDescriptions")}</div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <input type="radio" id={"oneTimePackage"} checked={true} name={"purchaseType"} value={0}
                 onChange={(e) => setPurchaseType(Number(e.target.value))} />
          <label htmlFor={"oneTimePackage"}>
            <h6>{t("oneTimePackage")}</h6>
            <p>{t("oneTimePackageDescription")}</p>
          </label>
        </div>
{/*        <div className="form-group">
          <input type="radio" id={"Subscription"} name={"purchaseType"} value={1}
                 onChange={(e) => setPurchaseType(Number(e.target.value))} />
          <label htmlFor={"Subscription"}>
            <h6>{t("Subscription")}</h6>
            <p>{t("subscriptionDescription")}</p>
          </label>
        </div>*/}
      </div>
    </div>
  );

};
export default PackagesAndSubscriptionPurchaseType;
