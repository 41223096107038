import search from "../../images/component_svg/search.svg";
import CancelSessionModal from "./CancelSessionModal";
import React, { useState } from "react";
import { upcomingBookingsType, upcomingSessionsType } from "../../redux/reducers/customer/customerReducer";
import { ServiceCategoryEnum, serviceTypeEnum } from "../../enums/enum";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const UpcomingSessionItem = ({item}: {item: upcomingBookingsType}) => {
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)
  const parsedDate = DateTime.fromISO(item.startTime).setLocale('de-DE');
  const { t } = useTranslation();
  return(
    <div  className="tr-col">
      <div className="td-col">{parsedDate.toFormat("dd.MM.yyyy")} | {parsedDate.toLocaleString(DateTime.TIME_24_SIMPLE)} h | {item.durationInMinutes} {t("minutes")} </div>
      <div className="td-col">{item.service.name || ""}</div>
      <div className="td-col">{t(ServiceCategoryEnum[item.serviceCategory])} </div>
      <div className="td-col">{t(serviceTypeEnum[item.serviceType])}</div>
      <div className="td-col">{publicTrainerData.firstName} {publicTrainerData.lastName}</div>
      <div className="td-col">
        <img
          src={search}
          alt="search"
          className="upcom-table-search-icon"
          onClick={() => setCancelModal(!cancelModal)}
        />
        {cancelModal && (
          <CancelSessionModal item={item} setCancleModal={setCancelModal} />
        )}
      </div>
    </div>
  )
}

export default UpcomingSessionItem;
