import { useContext, useRef } from "react";
import axios from "axios";
import { ReactComponent as GoogleSvg} from "../../../images/component_svg/btn_google_light_normal_ios.svg";
import { ReactComponent as CopySvg } from "../../../images/component_svg/copy.svg";
import ModalContext from "../../../utility/context/modal-context";
import AuthContext from "../../../utility/context/auth-context";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CalendarSynchronization = () => {
  const modalCtx = useContext<any>(ModalContext);
  const authCtx = useContext(AuthContext);
  const copyButton = useRef<any>();
  const { i18n, t } = useTranslation();
  const userData = useSelector(state => state.onboarding.userData);

  const handleGoogleCalendarSync = async (e: any) => {
    e.preventDefault();
    localStorage.setItem("googleStatus",userData.googleCalendarAuthStatus);
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/calendar/google/sync`;
    axios(url, {
      method: "GET",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
        "Content-Type": "text/plain",
        Authorization: "Bearer " + authCtx.token,
      },
    })
      .then((res) => {
        const link = res.data;
        window.open(link, "_blank", "noopener,noreferrer");
      })
      .catch((err) => {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("Sync failed! Please try again."));
        modalCtx.setIsActive(true);
      });
  };
  const copyText = () => {
    copyButton.current.style.animationFillMode = "forwards";
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(
        "webcal://www.skulp.com/Sebastian-Backes"
      );
  };

  return (
    <div className="calendarSynchronization">
      <div className="row">
        <div className="card importCalendarCard">
          <div className="card_heading">
            <h4>{t("import calendar")}</h4>
            <p className="sub-header">
              {t("Import your calendar to sync your courses and your personal appointments.")}
            </p>
            <hr />
          </div>
          <div className="card_content" onClick={handleGoogleCalendarSync}>
            <GoogleSvg className="svg-google"/>
            <p className="text-google">Sign in with Google</p>
          </div>
          {/*          <ul>
          {userData.googleCalendarAuthStatus === 2 ? (<li className={"googleCalendarSyncWarning"}>{t("please tick the required fields so that you can use your SKULP calendar without restrictions")}</li>) : ""}
         {userData.googleCalendarAuthStatus === 3 ? (<li className={"googleCalendarSyncWarning"}>{t("google email is already used by another user")}</li>) : ""}
          </ul>*/}

        </div>
        {/*<div className="card exportCalendarCard">
          <div className="card_heading">
            <h4>export calendar</h4>
            <p className="sub-header">
              Export your calendar to sync your courses and
            </p>
            <p className="sub-header">your Google calendar.</p>
            <hr />
          </div>
          <div className="card_content">
            <div className={"exportCalendar"}>
              <div className={"copyLink"}>
                <p>1. Copy the link below:</p>
                <div className="link">
                  <p>webcal://www.skulp.com/Sebastian-Back…</p>
                  <CopySvg ref={copyButton} onClick={copyText} />
                </div>
              </div>
              <p>
                2. Open your Google calendar. On the left hand side under „other
                calendars“ click on the plus (+) button.
              </p>
              <p>3. Click on „From URL“.</p>
              <p>4. Paste the URL into the box labeled URL.</p>
              <p>5. Click on „Add to calendar“.</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default CalendarSynchronization;
