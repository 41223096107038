import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const TrainerOnboardingProcessBusinessCancellationPolicies = (props: any) => {
  const { t } = useTranslation();
  const no = t("no");
  const yes = t("yes");
  const hours = t("hours");
  const hour = t("hour");

  const fullSlection = useRef<any>();
  const halfSlection = useRef<any>();

  const fullRefundValueisNotZero: boolean = props.fullRefundValue !== 0;

  const handleCancellationisDisabled = (e: any) => {
    props.setCancellationAllowed(Number(e.target.value));

    if (Number(e.target.value) === 0) {
      props.setFullRefundisActive(false);
      props.setHalfRefundisActive(false);
      props.setFullRefundValue(0);
      props.setHalfRefundValue(0);
    } else {
      props.setFullRefundisActive(true);
      props.setHalfRefundisActive(true);
    }
  };

  const handleCancelationType = (e: any) => {};
  return (
    <div className="trainerOnboardingProcessBusinessCancellationPolicies">
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h2>
              {t("Cancellation Policies")}
            </h2>
            <p>
              {t("Choose your cancellation options.")}
            </p>
            <hr />
          </div>
          <div className="cancellationPolicies">
            <div className="form-group">
              <label>
                {t("Cancellation allowed")}
              </label>
              <select
                name="cancellationAllowed"
                id="cancellationAllowedSelection"
                value={props.cancelltionAllowed}
                onChange={(e) => handleCancellationisDisabled(e)}
              >
                <option value={1}>{yes}</option>
                <option value={0}>{no}</option>
              </select>
              {props.errObject?.cancellationAllowed &&
                props.cancelltionAllowed === null && (
                  <div className="validation-error yellow-global">
                    {props.errObject.cancellationAllowed}
                  </div>
                )}
            </div>
            <p>{t("your customer receives")}</p>
            <div className="form-row">
              <div className="form-group">
                <input
                  id={"fullRefund"}
                  type="checkbox"
                  disabled={props.cancelltionAllowed === 0}
                  checked={props.fullRefundisActive}
                  onChange={(e: any) => {
                    props.setFullRefundisActive(e.target.checked);
                    props.setFullRefundValue(0);
                    if (!e.target.checked) {
                      props.setHalfRefundisActive(1);
                    }
                  }}
                />
                <label htmlFor="fullRefund">100%</label>
              </div>
              <select
                name="hoursForFullRefund"
                id="hoursForFullRefund"
                className={"hours"}
                ref={fullSlection}
                required={true}
                value={props.fullRefundValue}
                disabled={
                  props.cancellationAllowed == 0 || !props.fullRefundisActive
                }
                onChange={(e) => {
                  props.setFullRefundValue(e.target.value);
                  if (props.halfRefundValue >= e.target.value) {
                    props.setHalfRefundValue(0);
                  }
                }}
              >
                <option disabled value={0}>
                  {t("cancellation deadline")}
                </option>
                <option value={1}>1 {hour}</option>
                <option value={2}>2 {hours}</option>
                <option value={3}>3 {hours}</option>
                <option value={4}>4 {hours}</option>
                <option value={5}>5 {hours}</option>
                <option value={6}>6 {hours}</option>
                <option value={7}>7 {hours}</option>
                <option value={8}>8 {hours}</option>
                <option value={9}>9 {hours}</option>
                <option value={10}>10 {hours}</option>
                <option value={11}>11 {hours}</option>
                <option value={12}>12 {hours}</option>
                <option value={24}>24 {hours}</option>
                <option value={36}>36 {hours}</option>
                <option value={48}>48 {hours}</option>
                <option value={62}>62 {hours}</option>
              </select>
              {props.cancelltionAllowed === 1 &&
              props.fullRefundisActive &&
              props.fullRefundValue > 0 ? (
                <p>
                  {t("If your client cancels {{fullCancellationDeadline}} hours before, she will get 100% refund.",{fullCancellationDeadline: props.fullRefundValue})}

                </p>
              ) : (
                <p></p>
              )}
              {props.errObject?.fullRefundValue && !props.fullRefundValue && (
                <div className="validation-error yellow-global">
                  {props.errObject.fullRefundValue}
                </div>
              )}
            </div>
            <p>{t("your customer receives")}</p> 
            <div className="form-row">
              <div className="form-group">
                <input
                  id={"halfRefund"}
                  type="checkbox"
                  checked={props.halfRefundisActive}
                  disabled={props.cancelltionAllowed === 0}
                  required={
                    props.cancelltionAllowed && !props.fullRefundisActive
                  }
                  onChange={(e: any) => {
                    props.setHalfRefundisActive(e.target.checked);
                    props.setHalfRefundValue(0);
                    if (!e.target.checked) {
                      props.setFullRefundisActive(1);
                    }
                  }}
                />
                <label htmlFor="halfRefund">50%</label>
              </div>
              <select
                name="hoursForHalfRefund"
                id="hoursForHalfRefund"
                className={"hours"}
                value={props.halfRefundValue}
                ref={halfSlection}
                required={true}
                disabled={
                  props.cancellationAllowed === 0 || !props.halfRefundisActive
                }
                onChange={(e) => {
                  props.setHalfRefundValue(e.target.value);
                }}
              >
                <option disabled value={0}>
                  {t("cancellation deadline")}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 1 && fullRefundValueisNotZero
                  }
                  value={1}
                >
                  1 {hour}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 2 && fullRefundValueisNotZero
                  }
                  value={2}
                >
                  2 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 3 && fullRefundValueisNotZero
                  }
                  value={3}
                >
                  3 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 4 && fullRefundValueisNotZero
                  }
                  value={4}
                >
                  4 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 5 && fullRefundValueisNotZero
                  }
                  value={5}
                >
                  5 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 6 && fullRefundValueisNotZero
                  }
                  value={6}
                >
                  6 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 7 && fullRefundValueisNotZero
                  }
                  value={7}
                >
                  7 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 8 && fullRefundValueisNotZero
                  }
                  value={8}
                >
                  8 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 9 && fullRefundValueisNotZero
                  }
                  value={9}
                >
                  9 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 10 && fullRefundValueisNotZero
                  }
                  value={10}
                >
                  10 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 11 && fullRefundValueisNotZero
                  }
                  value={11}
                >
                  11 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 12 && fullRefundValueisNotZero
                  }
                  value={12}
                >
                  12 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 24 && fullRefundValueisNotZero
                  }
                  value={24}
                >
                  24 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 36 && fullRefundValueisNotZero
                  }
                  value={36}
                >
                  36 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 48 && fullRefundValueisNotZero
                  }
                  value={48}
                >
                  48 {hours}
                </option>
                <option
                  disabled={
                    props.fullRefundValue <= 62 && fullRefundValueisNotZero
                  }
                  value={62}
                >
                  62 {hours}
                </option>
              </select>
              {props.cancelltionAllowed === 1 &&
              props.halfRefundisActive &&
              props.halfRefundValue > 0 ? (
                <p>
                  {t("If your client cancels {{halfCancellationDeadline}} hours before, she will get 50% refund.",{halfCancellationDeadline: props.halfRefundValue})}
                </p>
              ) : (
                <p></p>
              )}
              {props.errObject?.halfRefundValue && !props.halfRefundValue && (
                <div className="validation-error yellow-global">
                  {props.errObject.halfRefundValue}
                </div>
              )}
            </div>
            <p className={"noRefundText"} style={props.halfRefundValue > 0 || props.fullRefundValue > 0 ? {} : {display:"none"}}>
              {t("If your client cancels within {{hours}} hours before session starts, she will get 0% refund.", { hours: props.halfRefundisActive && props.halfRefundValue >0  ? props.halfRefundValue : props.fullRefundValue > 0 ? props.fullRefundValue : null })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerOnboardingProcessBusinessCancellationPolicies;
