import RequestService, {methodEnum} from "../service";
import {apiURIs} from "../apiPaths";
import { useContext } from "react";
import AuthContext from "../../utility/context/auth-context";



class BookingsService {

    async createDayOfWeek(formData: any) {
        return await RequestService.call({
            endpoint: apiURIs.createDayOfWeek,
            method: methodEnum.put,
            body: formData,
        });
    }

    async dateOverride(formData: any, date: string) {

        return await RequestService.call({
            endpoint: apiURIs.dateOverride(date),
            method: methodEnum.put,
            body: formData,
        })
    }
    async getOverrideData(formData: any, date: string) {
        return await RequestService.call({
            endpoint: apiURIs.getOverrideData(date),
            method: methodEnum.get,
            body: formData,
        });
    }
}

export default new BookingsService()
