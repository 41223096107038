import { useSelector } from "react-redux";
import { selectedTimeType } from "../../../redux/reducers/booking/bookingReducer";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

const SmallGroupOverview = () => {
  const selectedTime = useSelector(state => state.booking.selectedTime);
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
const history = useHistory()
  useEffect(() => {
    if(selectedTime.length === 0 ){
      history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking`);
    }
  },[selectedTime])

  return (
    <div className="smallGroupOverview">
      {
        selectedTime.map((item: selectedTimeType, index:number) => (
          <div className="multiple-overview-items">
            <p className="muted">Session {index+1}</p>
            <SmallGroupOverviewItem item={item}  />
          </div>

        ))
       }
    </div>
  );

};
export default SmallGroupOverview;


// smallGroupOverviewItemComponent

const SmallGroupOverviewItem = ({ item }: { item: selectedTimeType }) => {
  const { t } = useTranslation();


  return (
    <div className="smallGroupOverviewItem">
      <p>{t("smallGroupSession")},</p>
      <p>{item.service.durationInMinutes} {t("minutes")}, </p>
      {item.service?.serviceTypes[0] === 0 ? <p>{t("virtual")}, </p> : null}
      {item.service?.serviceTypes[0]  === 1 ? <p>{t("on site coach")}, </p> : null}
      <p>{DateTime.fromISO(item.startDate).toFormat("dd.MM.yyyy")} | {DateTime.fromISO(item.startDate).toFormat("HH:mm")} {new Date(item.startDate).toLocaleTimeString().substring(8,11)}</p>

    </div>
  );
};
