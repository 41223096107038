
type reportReducerType = {
    monthlyRevenues: Array<any>,
}
const initailState: reportReducerType = {
    monthlyRevenues: [],
};

const ReportReducer = (state = initailState, action: any) => {
    switch (action.type) {
        default:
            return {
                ...state
            }
    }
}

export default ReportReducer;