import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import AuthContext from "../../../utility/context/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { SignUpFormsAction } from "../../../redux/actions/registration/SignUpFormsAction";
import { setTrainerData } from "../../../redux/actions/registration/OnboardingAction";
import ModalContext from "../../../utility/context/modal-context";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import { emailRegex, PlatformType } from "../../../enums/enum";


interface signupFieldsValues {
  email: string;
  password: string;
}

const initialValues: signupFieldsValues = {
  email: "",
  password: ""
};

const TrainerLoginForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const onboardingState = useSelector((state) => state.onboarding);
  const customerData = useSelector(state => state.customer)
  const authCtx = useContext(AuthContext);
  const userData = useSelector((state) => state.onboarding.userData);
  let token = query.get("token");
  let userId = query.get("userId");
  if (!!token) {
    loginLink(authCtx, history, token, userId);
  }
  const modalCtx = useContext<any>(ModalContext);
  const { i18n, t } = useTranslation();

  const newLogin = async (bodyData: signupFieldsValues) => {
    dispatch(setIsLoading(true));

    const response = await fetch(`${process.env.REACT_APP_BE_BASEURL}/api/User/token`, {
      method: "POST",
      body: JSON.stringify({
        ...bodyData,
        returnSecureToken: true
      }),
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
        "Content-Type": "application/json",
        'PlatformType': PlatformType.Web,
      }
    });
    setTimeout(() => dispatch(setIsLoading(false)),200)

    if (response.ok) {
      const res = await response.json();
      let expirationTime = new Date();
      expirationTime.setHours(expirationTime.getHours() + 43200);

      const userData = {
        userId: res.userId,
        email: res.email,
        role: res.roles[0],
        userName: res.userName,
        trainerId: onboardingState.userData.id
      };
      //TODO NEED the right TS implementation

      // @ts-ignore
      authCtx.login(res.token, userData, expirationTime.toISOString());


    if(userData.role === "Trainer"){
      const response2 = await fetch(`${process.env.REACT_APP_BE_BASEURL}/api/trainers/user`,
        {
          method: "GET",
          headers: {
            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
            "Content-Type": "application/json",
            Authorization: "Bearer " + res.token
          }
        }
      );
      setTimeout(() => dispatch(setIsLoading(false)),200)

      if(response2.ok){
        const res2 = await response2.json();
        const { data } = res2;
        dispatch(setTrainerData());
        if (data.onboardingStatus === 0 || data.onboardingStatus === null) {
          history.replace("/registration-process");
        } else if (
          data.onboardingStatus === 1 ||
          data.onboardingStatus === "Step1"
        ) {
          history.replace("/registration-process-about");
        } else if (
          data.onboardingStatus === 2 ||
          data.onboardingStatus === "Step2"
        ) {
          history.replace("/registration-process-business-settings");
        } else if (
          data.onboardingStatus === 3 ||
          data.onboardingStatus === "Step3"
        ) {
          history.replace("/registration-process-edit-your-space");
        } else if (
          data.onboardingStatus === 4 ||
          data.onboardingStatus === "Step4"
        ) {
          history.replace("/registration-process-payment");
          //history.replace('/registration-process-payment')
        } else if (
          data.onboardingStatus === 5 ||
          data.onboardingStatus === "Step5"
        ) {
          history.replace(`/me/${data.trainerSpace.spaceUrl}/trainer-dashboard`);
        } else if (
          data.onboardingStatus === 6 ||
          data.onboardingStatus === "Step6"
        ) {
          history.replace("/registration-process-calendar");
        }

      }else{
        const data = await response2.json();

        modalCtx.setModalType(0);
        modalCtx.setMessage(data.Message);
        modalCtx.setIsActive(true);
      }

    }else if(userData.role === "User"){
      history.replace(`/me/${customerData.trainer.trainerSpace.spaceUrl}`);

    }


    }else{
      const res = await response.json();
      modalCtx.setModalType(0);
      modalCtx.setMessage(res.message);
      modalCtx.setIsActive(true);

    }

  };



  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(emailRegex, t("errEmailInvalid"))
      .required(t("email is required")),
    password: yup
      .string()
      .required(t("password is required"))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: signupFieldsValues) => {
      await newLogin(values);
      setTimeout(() => dispatch(setIsLoading(false)),200)

    }
  });

  // @ts-ignore
  return (
    <div className="trainerLoginForm">
      <h1>{t("Login")}</h1>
      <p className={"haveAccount"}>
        {t("haventRegistered")}{" "}
        <a
          onClick={() =>
            dispatch(SignUpFormsAction({ registrationStep: "signup" }))
          }
        >
          {t("registration")}
        </a>
      </p>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">
            {t("email")}{" "}
            <span className="required">*</span>
          </label>
          <input
            type="text"
            id={"email"}
            placeholder={t("enter email")}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="password">
            {t("enter password")}{" "}
            <span className="required">*</span>
          </label>
          <input
            type="password"
            id={"password"}
            placeholder={t("enter password")}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="error">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="forgotPassword">
          <p
            onClick={() =>
              dispatch(SignUpFormsAction({ registrationStep: "resetPassword" }))
            }
          >
            {t("Forgot your password?")}
          </p>
        </div>
        <button type={"submit"}>
          {t("Login")}
        </button>
      </form>
      <p>
        <a href="https://skulp.de/datenschutz" target={"_blank"}>
          {t("policyAndTermsOfUse")}
        </a>
      </p>
    </div>
  );
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const loginLink = (authCtx: any, history: any, token: any, userId: any) => {
  let expirationTime = new Date();
  expirationTime.setHours(expirationTime.getHours() + 43000);

  var userData = {
    userId: userId
  };

  authCtx.login(token, userData, expirationTime.toISOString());
  history.replace("/change-password");


};

export default TrainerLoginForm;
