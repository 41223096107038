import React, { useEffect, useState } from "react";
import { changeNavigation } from "../../../redux/actions/layout/layoutAction";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BookingStepsFiveToSeven from "./BookingStepsFiveToSeven";
import BookingSummary from "./BookingSummary";
import { DateTime } from "luxon";
import { useCookies } from "react-cookie";
import { setSelectedTime, setTravelData } from "../../../redux/actions/booking/bookingAction";
import { selectedForBookingAction } from "../../../redux/actions/businessCenter/serviceActions";
import {
  setSelectedCreditItemForBooking,
  setSelectedPackageItemForBooking
} from "../../../redux/actions/customer/customerAction";
import {
  eraseSelectedPackageForBooking,
  selectedPackageForBooking
} from "../../../redux/actions/packages/packagesAction";
import PackageBookingContent from "./PackageBookingContent";
import PackageBookingSummary from "./PackageBookingSummary";

const TrainerBookingPageTwo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dateNow = DateTime.now().set({hour:0,minute:0,second:0}).toJSDate();
  const [date, setDate] = useState<Date>(dateNow);

  const selectedTime = useSelector(state => state.booking.selectedTime);
  const travelData = useSelector(state => state.booking.travelData);
  const selectedPackage = useSelector(state => state.customer.selectedPackageItem);
  const packageForBooking = useSelector(state => state.package.selectedPackageForBooking);
  const selectedForBooking = useSelector(state => state.businessCenter.services.selectedForBooking);

  const [cookiesSelectedTime, setCookieSelectedTime, removeCookieSelectedTime] = useCookies(["SelectedTimeCookie","travelData"]);
  const [cookiesSelectedPackage, setCookieSelectedPackage, removeCookieSelectedPackage] = useCookies(["SelectedPackageCookie","SelectedPackageForBooking"]);
  const [cookiesSelectedForBooking, setCookieSelectedForBooking, removeCookieSelectedForBooking] = useCookies(["SelectedForBookingCookie"]);
  const [isSummary,setIsSummary] = useState(false);
  const { id } = useParams<{id:string}>()


  useEffect(() => {
    if (selectedTime.length > 0 || selectedForBooking.id !== -1 || selectedPackage.id !== -1 || packageForBooking.id !== -1) {
      setCookieSelectedTime("SelectedTimeCookie", JSON.stringify(selectedTime), { path: "/me" });
      setCookieSelectedTime("travelData", JSON.stringify(travelData), { path: "/me" });
      setCookieSelectedPackage("SelectedPackageCookie", JSON.stringify(selectedPackage), { path: "/me" });
      setCookieSelectedPackage("SelectedPackageForBooking", JSON.stringify(packageForBooking), { path: "/me" });
      setCookieSelectedForBooking("SelectedForBookingCookie", JSON.stringify(selectedForBooking), { path: "/me" });
    }
    if (selectedForBooking.category === 1) {
      setCookieSelectedTime("SelectedTimeCookie", JSON.stringify(selectedTime),{path:"/me"});
    }
  }, [selectedTime, selectedForBooking, selectedPackage]);

  useEffect(() => {
    if (selectedForBooking.id === -1 && selectedPackage.id === -1 && packageForBooking.id === -1 && (cookiesSelectedPackage.SelectedPackageForBooking || cookiesSelectedPackage.SelectedPackageForBooking && cookiesSelectedPackage.SelectedPackageCookie) ) {
      dispatchCookies();
    }else{
      if(selectedForBooking.id === -1 && selectedPackage.id === -1 && packageForBooking.id === -1){
        history.replace(`/me/${id}/trainer-booking`)
      }
    }

    return () => {
      dispatch(eraseSelectedPackageForBooking());
      dispatch(setSelectedCreditItemForBooking({}))
    }
  }, []);


  useEffect(() => {
    setDate(DateTime.now().set({hour:0,minute:0,second:0}).toJSDate());
  },[])


  const dispatchCookies = () => {

    if (cookiesSelectedForBooking.SelectedForBookingCookie?.id) {
      dispatch(selectedForBookingAction([cookiesSelectedForBooking.SelectedForBookingCookie]));
    }
    dispatch(setSelectedPackageItemForBooking(cookiesSelectedPackage.SelectedPackageCookie))
    dispatch(selectedPackageForBooking(cookiesSelectedPackage.SelectedPackageForBooking))
    dispatch(setTravelData(cookiesSelectedTime.travelData));

    if (cookiesSelectedTime.SelectedTimeCookie.length > 0) {
      dispatch(setSelectedTime(cookiesSelectedTime.SelectedTimeCookie));
    }

  };


  useEffect(() => {
    dispatch(changeNavigation(1));

  }, []);



  return (
    <div className={"trainerBookingPageTwo"} >
      <div className="initialView">
        <div className="content">
          <div className="row">
            {packageForBooking.id !== -1? <PackageBookingContent/> :
            <BookingStepsFiveToSeven
              isSummary={isSummary}
              date={date}
              setDate={setDate}
            />}
            <div className="transitionButton" onClick={() => setIsSummary(!isSummary)}>

            </div>

            {packageForBooking.id !== -1? <PackageBookingSummary  isSummary={isSummary}
              /> :
              <BookingSummary isSummary={isSummary}
              />}

          </div>
        </div>
      </div>
    </div>
  );

};

export default TrainerBookingPageTwo;
