import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTrainerData } from "../../redux/actions/registration/OnboardingAction";
import { changeNavigation, setBusinesCenterPage } from "../../redux/actions/layout/layoutAction";

import BusinessCenterSideNavigation from "../../components/layout/BusinessCenterSideNavigation";
import BusinessCenterService from "../../components/TrainerBusinessCenter/Service/BusinessCenterService";
import BusinessCenterCalendar from "../../components/TrainerBusinessCenter/Calendar/BusinessCenterCalendar";
import MarketingReferral from "../MarketingReferral";
import { setWeeklyView } from "../../redux/actions/businessCenter/calendarAction";
import BusinessCenterPackageAndSubscriptions
  from "../../components/TrainerBusinessCenter/PackagesAndSubscriptions/BusinessCenterPackageAndSubscriptions";
import AboutYou from "../../components/TrainerBusinessCenter/AboutYou/AboutYou";
import Accounting from "../../components/TrainerBusinessCenter/Accounting/Accounting";
import EditSpace from "../../components/TrainerBusinessCenter/EditSpace/EditSpace";
import Payment from "../../components/TrainerBusinessCenter/Payment/Payment";
import BusinessSettings from "../../components/TrainerBusinessCenter/BusinessSettings/BusinessSettings";
import Report from "../../components/TrainerBusinessCenter/Report/Report";
import ContactInformation from "../../components/TrainerBusinessCenter/ContactInformation/ContactInformation";
import { hasServices, resetEditService } from "../../redux/actions/businessCenter/serviceActions";
import { getBookingPolicy } from "../../redux/actions/booking/bookingAction";
import { useCookies } from "react-cookie";
import { Route, Switch, useHistory } from "react-router-dom";

const TrainerBusinessCenter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavigation(1));
    dispatch(setTrainerData());
    dispatch(setWeeklyView());
    dispatch(getBookingPolicy());

  }, []);

  const [loadPage, setLoadPage] = useState<number>(0);
  const userData = useSelector(state => state.onboarding.userData);
  const businessCenterPage = useSelector(state => state.layout.businessCenterPage);
/*  const hasService = useSelector(state => state.businessCenter.hasService);
  const history = useHistory();*/

 // const [pageStatus, setPageStatus, removePageStatus] = useCookies(["BusinessCenterPageCookie"]);


/*
  useEffect(() => {
    dispatch(resetEditService());
    setPageStatus("BusinessCenterPageCookie", businessCenterPage, { path: "/me" });

    /!*    return () => {
          removePageStatus("BusinessCenterPageCookie",{ path: "/me" } )
        }*!/

  }, [businessCenterPage,loadPage]);
*/

/*  useEffect(() => {
    if (Number(pageStatus.BusinessCenterPageCookie) >= 0) {
      setLoadPage(Number(pageStatus.BusinessCenterPageCookie));
      dispatch(setBusinesCenterPage(Number(pageStatus.BusinessCenterPageCookie)));
    }

    const pathArray = history.location.pathname.split("/");
    if (pathArray[pathArray.length - 1] == "business-center-invoices") {
      setLoadPage(Number(4));
      setPageStatus("BusinessCenterPageCookie", 4, { path: "/me" });
      dispatch(setBusinesCenterPage(4));
    } else if (history.location.pathname.includes("business-center-calendar")) {
      setLoadPage(2);
      setPageStatus("BusinessCenterPageCookie", 2, { path: "/me" });
      dispatch(setBusinesCenterPage(2));
    }


    setLoadPage(businessCenterPage);


  }, []);*/

  useEffect(() => {
    dispatch(hasServices(userData.id));
  }, [userData.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loadPage, businessCenterPage]);

  /*  useEffect(() => {
      if (businessCenterPage !== 0) {
        setLoadPage(businessCenterPage);
        //setTimeout(() => { dispatch(setBusinesCenterPage(0)) }, 500);
      }
    }, [businessCenterPage]);*/

  return (
    <div className="trainerBusinessCenter">
      <BusinessCenterSideNavigation
        loadPage={loadPage}
        setLoadPage={setLoadPage}
      />

      <div className="trainerBusinessCenterContent">

        <Switch>
          <Route exact path="/me/:id/business-center">
            <BusinessCenterService />
          </Route>
          <Route exact path="/me/:id/business-center/service">
            <BusinessCenterService />
          </Route>
          <Route exact path="/me/:id/business-center/package">
            <BusinessCenterPackageAndSubscriptions />
          </Route>
          <Route exact path="/me/:id/business-center/calendar">
            <BusinessCenterCalendar />
          </Route>
          <Route exact path="/me/:id/business-center-calendar">
            <BusinessCenterCalendar />
          </Route>
          <Route exact path="/me/:id/business-center/marketing">
            <MarketingReferral />
          </Route>
          <Route exact path="/me/:id/business-center/accounting">
            <Accounting />
          </Route>
          <Route exact path="/me/:id/business-center-invoices">
            <Accounting />
          </Route>
          <Route exact path="/me/:id/business-center/report">
            <Report />
          </Route>
          <Route path="/me/:id/business-center/businessSettings">
            <BusinessSettings />
          </Route>
          <Route path="/me/:id/business-center/editSpace">
            <EditSpace />
          </Route>
          <Route path="/me/:id/business-center/contact">
            <ContactInformation />
          </Route>
          <Route path="/me/:id/business-center/about">
            <AboutYou />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default TrainerBusinessCenter;
