import {  serviceCountListType } from "../../reducers/packages/packagesReducer";
import { Dispatch } from "redux";
import { SignUpFormsAction } from "../registration/SignUpFormsAction";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { setIsLoading } from "../layout/layoutAction";


//load packages of the Trainer
export const getPackages = (trainerId: number) => async (dispatch:Dispatch<any>) => {

  const url = `${process.env.REACT_APP_BE_BASEURL}/api/packages/trainer/${trainerId}`;
  try {
    dispatch(setIsLoading(true));

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        'accept': 'text/plain'
      },

    });
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response.ok) {
      const res = await response.json();
      dispatch({
        type:"getPackages",
        payload: res
      })
    }
  }catch (error){
  }

}

export const getTrainerPackages = (isEnabled: boolean) => async (dispatch: Dispatch<any>) => {

  try {

    let url = `${process.env.REACT_APP_BE_BASEURL}/api/packages/trainer-packages?isEnabled=${isEnabled}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        accept: "text/plain",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });

    if (response.ok) {
      const data = await response.json();

      dispatch({
        type: "getEnabledOrDisabledPackages",
        payload: data
      });
    }
  } catch (error) {
  }
};


export const selectedPackageForBooking = (payload: any) => {
  return{
    type:"selectedPackageForBooking",
    payload: payload
  }
}

export const eraseSelectedPackageForBooking = () => {
  return{
    type:"eraseSelectedPackageForBooking",
    payload : -1
  }
}



//handle Service-Count List
export const addServiceCountList = (payload:serviceCountListType) => {
  return {
    type:"addServiceCountList",
    payload:payload
  }
}
export const editServiceCountList = (payload:serviceCountListType) => {
  return {
    type:"editServiceCountList",
    payload: payload

  }
}

export const dropServiceCountList = (payload:serviceCountListType) => {
  return {
    type:"dropServiceCountList",
    payload:payload
  }
}
export const eraseServiceCountList = () => {
  return {
    type:"eraseServiceCountList",
    payload: []

  }
}

//Setter for Package Creation

export const setPackageCategory = (payload: number) => {
  return{
    type:"setPackageCategory",
    payload: payload
  }
}
export const setPackageName = (payload: string) => {
  return{
    type:"setPackageName",
    payload: payload
  }
}
export const setPackageDescription = (payload: string) => {
  return{
    type:"setPackageDescription",
    payload: payload
  }
}
export const setPackageCanExpire = (payload: number) => {
  return{
    type:"setPackageCanExpire",
    payload: Boolean(payload)
  }
}

export const setPackagePrice = (payload: number) => {
  return{
    type:"setPackagePrice",
    payload: payload
  }
}
export const setPackageTrainerId = (payload: number) => {
  return{
    type:"setPackageTrainerId",
    payload: payload
  }
}

export const setPackageValidForNumberOfWeeks = (payload: number) => {
  return{
    type:"setPackageValidForNumberOfWeeks",
    payload: payload
  }
}



