import { ReactComponent as LocationSVG } from "../../../images/component_svg/locationSVG.svg";


import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPublicTrainerData } from "../../../redux/actions/registration/OnboardingAction";
import { changeNavigation } from "../../../redux/actions/layout/layoutAction";

import { countrysEnum, educationEnum, spokenLanguagesEnum } from "../../../enums/enum";
import { useHistory, Redirect } from "react-router-dom";
import { DateTime } from "luxon";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import SocialMediaAccount from "./SocialMediaAccount";

const TrainerProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const profilePicturePlaceholder =
    "https://intern-creativemindz.de/wp-content/uploads/user3.svg";
  const [cookiesSelectedTime, setCookieSelectedTime, removeCookieSelectedTime] = useCookies(["SelectedTimeCookie", "travelData"]);
  const [cookiesSelectedPackage, setCookieSelectedPackage, removeCookieSelectedPackage] = useCookies(["SelectedPackageCookie"]);
  const [cookiesSelectedForBooking, setCookieSelectedForBooking, removeCookieSelectedForBooking] = useCookies(["SelectedForBookingCookie"]);


  useEffect(() => {
    removeCookieSelectedForBooking("SelectedForBookingCookie", { path: "/me" });
    removeCookieSelectedTime("SelectedTimeCookie", { path: "/me" });
    removeCookieSelectedTime("travelData", { path: "/me" });
    removeCookieSelectedPackage("SelectedPackageCookie", { path: "/me" });
  }, []);


  useEffect(() => {
    dispatch(getPublicTrainerData(history.location.pathname.split("/")[2], true));
    dispatch(changeNavigation(1));
  }, []);
  const publicTrainerData = useSelector((state) => state.onboarding.publicTrainerData);
  const trainerID = useSelector((state) => state.onboarding.trainerId);

  const parseExperienceDate = (date: string) => DateTime.fromFormat(date, "yyyy-MM-dd").setLocale("de-DE").toFormat("dd.MM.yyyy");

  const handleRedirectToPersonalWebsite = (personalUrl: string) => {
    window.open(personalUrl, "_blank");
  };


  return (
    <Fragment>
      {!trainerID ?
        (
          <Redirect to="/trainerNotFound" />
        ) :

        <div className="trainerProfile">
          <div className="initialView ">
            <div className="content">
              <div className="profileImageAndAbout">
                <img
                  src={publicTrainerData.profilePictureUri != "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${publicTrainerData.profilePictureUri}/download` : profilePicturePlaceholder}
                  alt="profileImage"
                  className={"profileImage"}
                />
                <div className="card">
                  <div className="card-content aboutTrainer">
                    <div className="left">
                      <div className="trainerNameAndLocation">
                        <h1>{`${publicTrainerData.firstName} ${publicTrainerData.lastName}` || ""}</h1>
                        <div className="location">
                          <LocationSVG fill={"white"} />
                          <p>{publicTrainerData.addressCity}, {countrysEnum[publicTrainerData.addressCountry]}</p>
                        </div>
                      </div>
                      <div className="spokenLanguages textWrapper">
                        <p>{t("spoken languages")}</p>
                        <h5>
                          {publicTrainerData.spokenLanguages.map(
                            (e: number, index: number) => (
                              <span key={index}>
                            {index === publicTrainerData.spokenLanguages.length - 1
                              ? t(spokenLanguagesEnum[e])
                              : `${t(spokenLanguagesEnum[e])},`}{" "}
                          </span>
                            )
                          )}
                        </h5>
                      </div>
                      <div className="personalWebsite textWrapper">
                        <h6 onClick={() => handleRedirectToPersonalWebsite(publicTrainerData.personalWebsite)}
                            style={{ cursor: "pointer" }}>{publicTrainerData.personalWebsite}</h6>
                      </div>
                      <div className="socialMediaAccounts">
                        {publicTrainerData?.socialMediaAccounts.map((media: any) => {
                          return (<SocialMediaAccount media={media} />);
                        })}
                        {/*    <img src={FacebookSVG} alt="FacebookSVG" />
                    <img src={InstagramSVG} alt="InstagramSVG" />*/}
                      </div>
                      <div className="seal">

                        {publicTrainerData.partnerPlatform !== null && !!publicTrainerData.personalFitness?.badgeUrl ? (
                          <>
                            <p>{t("quality seal")}</p>
                            <img className={"pf-badge"} src={publicTrainerData.personalFitness?.badgeUrl} />
                          </>

                        ) : null}
                      </div>
                    </div>
                    <div className="right">
                      <div className="disciplines textWrapper">
                        <p>{t("Disciplines")}</p>
                        <h4>
                          {publicTrainerData.disciplines.map((e: number, index: number) => (
                            <span className={"capital"} key={index}>
                          {index === publicTrainerData.disciplines.length - 1
                            ? `${e}`
                            : `${e},`}{" "}
                        </span>
                          ))}
                        </h4>
                      </div>
                      <div className="visionAndMission textWrapper">
                        <p>{t(`vision & mission`)}</p>
                        <h6>{publicTrainerData.visionAndMission}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trainerEducationAndExperiences">
                <div className="card education">
                  <div className="card-content">
                    <p>{t("education")}</p>
                    <table>
                      <tbody>
                      {publicTrainerData.educations.map((e: any) => (
                        <tr key={e.id}>
                          <td>{e.yearOfDegree}</td>
                          <td>{e.institution}</td>
                          <td>
                            {e.focus} ({educationEnum[e.degree]})
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card experiences">
                  <div className="card-content">
                    <p>{t("experience")}</p>
                    <table>
                      <tbody>
                      {publicTrainerData.experiences.map((e: any) => {
                        return (
                          <tr key={e.id}>
                            <td>
                              {" "}
                              {parseExperienceDate(e.from)} - {parseExperienceDate(e.to)}
                            </td>
                            <td>{e.position}</td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </Fragment>

  );
};

export default TrainerProfile;
