import React from 'react';
import './style.scss';

const Spinner = () => {
  return (
    <div className="container">
      <div className="spinner-border SpinnerStyles" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  );
};

export default Spinner;
