import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TrainerReviews from './TrainerReviews';

import {
  setTrainerData,
} from '../../../redux/actions/registration/OnboardingAction';
import {changeNavigation } from '../../../redux/actions/layout/layoutAction'

import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const PersonalSitePreview = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.onboarding.userData);
  const spacePreview = useSelector((state) => state.spacePreview);
  const [showSpinner, setShowSpinner] = useState(false);
  const history = useHistory()
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setTrainerData());
    dispatch(changeNavigation(20));
  }, []);

  return (
    <div className="trainer-personal-site">
      <button className={'backButton'} onClick={history.goBack}>{t("Back to Edit Your Space")}</button>

      <div className="image-container">
        <img src={spacePreview.logoUrl != null ? spacePreview.logoUrl : ''} className="img-fluid" alt="personalProfile" />
        <div className="bottom-label container row d-flex">
          <div className="col-sm-8 mr-auto">
            <h3 className="text-yellow">{spacePreview.name != null ?spacePreview.name : `${userData.firstName} ${userData.lastName}`}</h3>
            <h6 className="text-white">{spacePreview.slogan !== null ? spacePreview.slogan : t("trainerSince")}</h6>
          </div>

          <div className="col-sm-2">
            <span className="btn">{/*place bubble/chat icon here */}</span>
          </div>
        </div>
      </div>
 {/*     <TrainerReviews />*/}
    </div>
  );
};

export default PersonalSitePreview;
