import { Bar } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


const BarChart = ({ values, labels }: { values: Array<number>, labels: Array<string> }) => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        display: false,
      },
      title: {
        display: true,
      },
    },
  };

  const { t } = useTranslation();

  const data = {
    labels,
    datasets: [{
      label: t("Sales development"),
      data: values || 0,
      backgroundColor: 'rgba(212,211,0,255)',
    }]
  };

  return (
    <div className='barChart'>
    <Bar options={options} data={data} />
    </div>
  )
}

export default BarChart;
