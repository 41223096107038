import { setPackageValidForNumberOfWeeks } from "../../actions/packages/packagesAction";

export interface serviceCountListType {
  serviceId: number,
  count: number | string | null
}

export interface createPackageType {
  trainerId: number,
  name: string,
  description: string,
  serviceType: number,
  category: number,
  serviceCountList: Array<serviceCountListType>,
  validForNumberOfWeeks: number,
  price: number,
  canExpire: boolean
}

//TODO: implement type of packages
interface PackageReducerType {
  createPackage: createPackageType,
  packages: any,
  selectedPackageForBooking: any,
  enabledOrDisablePackages: Array<packageObject>
}

interface ServiceObject {
  "service": {
    "id": number,
    "category": number,
    "name": string,
    "description": string,
    "serviceTypes": Array<number>,
    "disciplines": Array<string>,
    "durationInMinutes": Array<number>,
    "onsiteFee": number,
    "virtualFee": number,
    "enabled": boolean,
    "bookings": Array<any>,
    "groupPrice": number,
    "date": string,
    "startTime": string,
    "maximumParticipants": number,
    "maximumParticipantsInWaitingList": number,
    "difficultyLevel": number,
    "dayOfWeeks": Array<number>,
    "weekRepeats": number,
    "location": number,
    "address": string,
    "postalCode": string,
    "city": string,
    "country": string,
    "houseNumber": string,
    "isRecurring": boolean
  },
}

interface PackageServices {
      "id": number,
      service: ServiceObject,
      "totalCount": number,
      "remainingCount": number
}

export type packageObject = {
  "id": number,
  "trainerId": number,
  "isTemplate": boolean,
  "templateId": number,
  "customerId": number,
  "isEnabled": boolean,
  "name": string,
  "description": string,
  "serviceCategory": number,
  "serviceCountList": number,
  "packageServices":Array<PackageServices>,
  "price": number,
  "canExpire": true,
  "validForNumberOfWeeks": number,
  "expirationDateTime": string,
  "isExpired": string,
  "packageCheckoutSessionUrl": string,
  "invoice": any
}

const initState: PackageReducerType = {
  createPackage: {
    name: "",
    description: "",
    serviceType: 0,
    category: 0,
    validForNumberOfWeeks: 1,
    trainerId: -1,
    price: 0,
    serviceCountList: [],
    canExpire: true
  },
  enabledOrDisablePackages: [],
  packages: [],
  selectedPackageForBooking: {
    id: -1
  }
};

const PackageReducer = (state = initState, action: any) => {
  switch (action.type) {

    case "selectedPackageForBooking":
      return { ...state, selectedPackageForBooking: action.payload };

    case "getPackages":
      return { ...state, packages: action.payload };
    
    case "getEnabledOrDisabledPackages":
      return {...state, enabledOrDisablePackages: action.payload}

    case "eraseSelectedPackageForBooking":
      return {...state, selectedPackageForBooking: {id:-1}}

    case "addServiceCountList": {
      let helperArray: Array<serviceCountListType> = state.createPackage.serviceCountList;
      helperArray.push(action.payload);
      return {
        ...state, createPackage: {
          ...state.createPackage,
          serviceCountList: helperArray
        }
      };
    }

    case "editServiceCountList": {
      let helperArray: Array<serviceCountListType> = state.createPackage.serviceCountList.filter((s: serviceCountListType) => s.serviceId !== action.payload.serviceId);
      helperArray.push(action.payload);
      return {
        ...state,
        createPackage: {
          ...state.createPackage,
          serviceCountList: helperArray
        }
      };
    }

    case "dropServiceCountList": {
      let helperArray: Array<serviceCountListType> = state.createPackage.serviceCountList.filter((s: serviceCountListType) => s.serviceId !== action.payload.serviceId);
      return {
        ...state, createPackage: {
          ...state.createPackage,
          serviceCountList: helperArray
        }
      };
    }

    case "eraseServiceCountList":
      return {
        ...state, createPackage: {
          ...state.createPackage,
          serviceCountList: []
        }
      };

    case "setPackageName": {
      return {
        ...state, createPackage: {
          ...state.createPackage,
          name: action.payload
        }
      };
    }

    case "setPackageDescription": {
      return {
        ...state, createPackage: {
          ...state.createPackage,
          description: action.payload
        }
      };
    }

    case "setPackageCategory": {
      return {
        ...state, createPackage: {
          ...state.createPackage,
          category: action.payload
        }
      };
    }

    case "setPackageCanExpire": {
      return {
        ...state, createPackage: {
          ...state.createPackage,
          canExpire: action.payload
        }
      };
    }

    case "setPackagePrice": {
      return {
        ...state, createPackage: {
          ...state.createPackage,
          price: action.payload
        }
      };
    }

    case "setPackageTrainerId": {
      return {
        ...state, createPackage: {
          ...state.createPackage,
          trainerId: action.payload
        }
      };
    }

    case "setPackageValidForNumberOfWeeks": {
      return {
        ...state, createPackage: {
          ...state.createPackage,
          validForNumberOfWeeks: action.payload
        }
      };
    }

    case "resetCreatePackage": {
      return {
        ...state,
          ...initState
      };
    }
    
    default:
      return { ...state };
  }
};

export default PackageReducer;
