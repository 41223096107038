const initState = {
    nickname: null,
    name: null,
    slogan: null,
    spaceUrl: null,
    logoUrl: null,

}


const PersonalSpaceReducer = (state = initState, action: any) => {
    switch (action.type) {
        case "setPersonalSpacePreviewData":
            return {...state, ...action.payload}

        default:
            return {...state}
    }
}

export default PersonalSpaceReducer;
