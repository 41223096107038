/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import TrainerReviewCard from './TrainerReviewCard';
import './TrainerReviews.style.scss';
import { useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../utility/context/auth-context";
import { Review, reviewItem } from "./index";

const reviews2 = [
  {
    imgURL: 'https://via.placeholder.com/150',
    userId: '1',
    rating: 4,
    heading: 'Review Heading',
    content:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem quos quae non soluta numquam inventore perspiciatis expedita maxime laboriosam cumque error laborum, repudiandae repellendus itaque blanditiis rerum dolorum facere placeat.',
  },
  {
    imgURL: 'https://via.placeholder.com/150',
    userId: '2',
    rating: 4,
    heading: 'Review Heading',
    content:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem quos quae non soluta numquam inventore perspiciatis expedita maxime laboriosam cumque error laborum, repudiandae repellendus itaque blanditiis rerum dolorum facere placeat.',
  },
  {
    imgURL: 'https://via.placeholder.com/150',
    userId: '3',
    rating: 2,
    heading: 'Review Heading',
    content:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem quos quae non soluta numquam inventore perspiciatis expedita maxime laboriosam cumque error laborum, repudiandae repellendus itaque blanditiis rerum dolorum facere placeat.',
  },
];

const TrainerReviews = ({ reviews }: { reviews:Review }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();


  return (
    <div className="content mt-5 py-5 trainer-reviews">
      <div className="col-md-12">
        <h1 className="text-center"> {t("reviews")}</h1>


        <div className="reviews">
          {reviews.items.map((review:reviewItem) => (
            <TrainerReviewCard key={review.id} review={review} />
          ))}
        </div>
        <div className="text-end mb-5">
      {/*    <a href="" className="view-more" onClick={viewMore}>
            {t("viewMore")}
          </a>*/}
        </div>
      </div>
    </div>
  );
};

export default TrainerReviews;
