import { ReactComponent as DownArrow } from "../../images/component_svg/downArrowInputTime.svg";
import React, { useState } from "react";
import ServiceDropdownListItem from "./ServiceDropdownListItem";
import {ReactComponent as InfinitySVG}  from "../../images/component_svg/path.svg";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";


const CurrentPackage =  ({item}:{item:any}) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [packageId,setPackageId] = useState(item.id);
  const [count,setCount] = useState(item.totalCount);
  const { t } = useTranslation();

  const getTotalCount = () => {
    let count :number = 0
    item?.packageServices?.forEach((item:any) => {
       count += item?.totalCount;
      })
    return count
  }

  const getRemainingCount = () => {
    let count :number = 0;
    item?.packageServices?.forEach((item:any) => {
      if(item?.remainingCount === null){
      count += item?.totalCount;
      }else{
        count += item?.remainingCount;
      }
    })
    return count
  }
  
  const unlimitedServicesCount = (item.packageServices.filter(((item: any) => item.totalCount === null)) || []).length;
  const servicesCount = item.packageServices.length;

  const isUnlimitedPackage = unlimitedServicesCount > 0 && unlimitedServicesCount <= servicesCount;
  return(
    <div className="package">
      <div className="tr-tabl">
        <div className="td-tabl td-nm">{item.name}</div>

        <div className="td-tabl">{!isUnlimitedPackage ? `${getRemainingCount()} / ${getTotalCount()} ${t("remaining")}` : unlimitedServicesCount === servicesCount ? t('unlimited') : '' } </div>
        <div className="td-tabl">{item.canExpire ?  item.expirationDateTime ?  DateTime.fromISO(item.expirationDateTime).toFormat("dd.MM.yyyy") : "-" : <InfinitySVG height={20}  width={30} />}</div>


        <div className="td-tabl td-col ">
          <div className="btn-box" onClick={() =>  setOpenDropdown(!openDropdown)} >
            <DownArrow style={openDropdown? {transform: "rotate(180deg)",transition:"200ms"} : {transform: "rotate(0deg)",transition:"200ms"}}/>
          </div>
        </div>
      </div>
      <div className={`dropdown`} style={openDropdown ? {height: "100%"} : {}}  >
        {item.packageServices?.map((item:any) => {
          return( <ServiceDropdownListItem item={item} packageId={packageId} count={getRemainingCount()}/>
          )
        }) }
      </div>
    </div>

  )
}
export default CurrentPackage;
