import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import addSVG from "../../../images/component_svg/add.svg";
import { ReactComponent as DeleteSVG } from "../../../images/component_svg/delete.svg";
import ModalContext from "../../../utility/context/modal-context";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import de from "date-fns/locale/de";
import i18n from "i18next";

const TrainerOnboardingProcessAboutExpertise = (props: any) => {
  const [experienceRowCount, setExperienceRowCount] = useState<number>(
    props.experiences.length - 1
  );
  const [educationRowCount, setEducationRowCount] = useState<number>(
    props.educations.length - 1
  );
  const modalCtx = useContext<any>(ModalContext);
  const { t } = useTranslation();

  const fieldOfActivityPlaceholder = t(
    "activities..."
  );
  const institutionPlaceholder = t(
    "institution..."
  );
  const positionPlaceholder = t("your position...");
  const fieldsOfFocusPlaceholder = t(
    "focus..."
  );
  const yourVisionMissionPlaceholder = t(
    "Tell us what you strive for..."
  );

  const bachelor = t("bachelor");
  const master = t("master");
  const other = t("Other");
  const choose = t("choose");

  const handleExperienceChange = (e?: any, dataId?: number, dateValue?: Date, dateName?: string) => {
    let id: number;
    let name;
    let value;
    if (!!e) {
      id = e.target?.dataset?.id;
      name = e.target.name;
      value = e.target.value;
    } else {
      if (dataId !== null && dataId !== undefined) {
        id = dataId;
      }
      if (dateValue !== null && dateValue !== undefined) {
        value = DateTime.fromJSDate(dateValue).toFormat("yyyy-MM-dd");
      }
      if(dateValue === undefined){
        value = "";
      }
      if (dateName !== null && dateName !== undefined) {
        name = dateName;
      }
    }


    const prevExperience = props.experiences as any;
    if (prevExperience.length < 1) {
      return;
    }
    const index = prevExperience.findIndex((exp: any) => {
      return exp.id === id;
    });
    if (!prevExperience[index]) {
      return;
    }
/*    if (name === "periodTo") {
      const expData = prevExperience[index] as any;
      const from = new Date(expData.periodForm);
      const to = new Date(value);
      if (from.getTime() > to.getTime()) {
        value = "";
        modalCtx.setModalType(0);
        modalCtx.setMessage(`"To" filed date can't be less then "From"`);
        modalCtx.setIsActive(true);
      }
    }*/
    prevExperience[index][name] = value;
    props.setExperiences([...prevExperience]);
  };

  const handleDeleteExpRow = (id: string) => {
    const prevExperience = props.experiences as any;
    const index = prevExperience.findIndex((exp: any) => {
      return exp.id === id;
    });
    prevExperience.splice(index, index + 1);
    props.setExperiences([...prevExperience]);
  };

  //TODO Dynamic Growing State for Table implementation needs to be improved
  //TODO FEATURE: Delete Experience & Education Line
  const creteFormRowsExperience = (data: any) => {
    return (
      <tr key={data.id}>
        <td>
          <input
            type="text"
            placeholder={positionPlaceholder}
            value={data.position}
            data-id={data.id}
            name="position"
            onChange={handleExperienceChange}
          />
        </td>
        <td>
{/*          <input
            type="date"
            value={data.periodForm}
            name="periodForm"
            data-id={data.id}
            data-date=""
            data-date-format="dd.mm.yyyy"
            onChange={handleExperienceChange}
          />*/}
          <DatePicker
            id={"periodForm"}
            onChange={(date) => {
              if (date !== null) {
                handleExperienceChange(null, data.id, date, "periodForm");
              }else{
                handleExperienceChange(null, data.id, undefined, "periodForm");
              }
            }}
            selected={data?.periodForm === "" ? null : DateTime.fromISO(data?.periodForm)?.toJSDate()}
            placeholderText={t("choose a date")}
            dateFormat="dd.MM.yyyy"
            locale={i18n.language === "de" ? de : ""}
            calendarStartDay={1}
          />
        </td>
        <td>
{/*          <input
            type="date"
            value={data.periodTo}
            name="periodTo"
            placeholder="dd-mm-yy"
            data-id={data.id}
            data-date=""
            data-date-format="DD MMMM YYYY"
            onChange={handleExperienceChange}
          />*/}
          <DatePicker
            id={"periodTo"}
            onChange={(date) => {

              if (date !== null) {
                handleExperienceChange(null, data.id, date, "periodTo");
              }else{
                handleExperienceChange(null, data.id, undefined, "periodTo");
              }
            }}
            selected={data?.periodTo === "" ? null : DateTime.fromISO(data?.periodTo)?.toJSDate()}
            placeholderText={t("choose a date")}
            dateFormat="dd.MM.yyyy"
            locale={i18n.language === "de" ? de : ""}
            calendarStartDay={1}
          />
        </td>
        <td>
          <input
            type="text"
            placeholder={fieldOfActivityPlaceholder}
            value={data.Activities}
            name="Activities"
            data-id={data.id}
            onChange={handleExperienceChange}
          />
        </td>
        <td
          className={"deleteField"}
          style={
            props.experiences.length === 1
              ? { display: "none" }
              : { display: "flex" }
          }
          data-id={data.id}
          onClick={() => handleDeleteExpRow(data.id)}
        >
          <DeleteSVG />
        </td>
      </tr>
    );
  };

  const getYears = () => {
    let prefixArray = [];
    for (let i = 1970; i <= 2022; i++) {
      prefixArray[i] = i;
    }
    return prefixArray;
  };

  const handleEductionChange = (e: any) => {
    let id = e.target?.dataset?.id;
    const { name, value } = e.target;
    const prevEducation = props.educations as any;
    if (prevEducation.length < 1) {
      return;
    }
    const index = prevEducation.findIndex((edu: any) => {
      return edu.id === id;
    });
    if (!prevEducation[index]) {
      return;
    }
    prevEducation[index][name] = value;
    props.setEducations([...prevEducation]);
  };

  const handleDeleteEduRow = (id: string) => {
    const prevEducation = props.educations as any;
    const index = prevEducation.findIndex((edu: any) => {
      return edu.id === id;
    });
    prevEducation.splice(index, index + 1);
    props.setEducations([...prevEducation]);
  };

  //TODO When a Degree or Degree Year is not selected, there will be no error
  const creteFormRowsEducation = (data: any) => {
    // @ts-ignore
    return (
      <tr key={data.id}>
        <td>
          <input
            type="text"
            placeholder={institutionPlaceholder}
            name="institution"
            value={data.institution}
            data-id={data.id}
            onChange={handleEductionChange}

          />
        </td>
        <td>
          <select
            value={data.degree}
            name="degree"
            data-id={data.id}
            onChange={handleEductionChange}
          >
            <option value={0}>{choose}</option>
            <option value={1}>{bachelor}</option>
            <option value={2}>{master}</option>
            <option value={3}>{other}</option>
          </select>
        </td>
        <td>
          <select
            value={data.yearOfDegree}
            name="yearOfDegree"
            data-id={data.id}
            onChange={handleEductionChange}
          >
            <option value={0}>{t("choose")}</option>
            {getYears().map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input
            type="text"
            placeholder={fieldsOfFocusPlaceholder}
            value={data.focus}
            name="focus"
            data-id={data.id}
            onChange={handleEductionChange}
          />
        </td>
        <td
          className={"deleteField"}
          style={
            props.educations.length === 1
              ? { display: "none" }
              : { display: "flex" }
          }
          onClick={() => handleDeleteEduRow(data.id)}
        >
          <DeleteSVG />
        </td>
      </tr>
    );
  };

  const handleAddExperience = (e: any) => {
    e.preventDefault();
    const expRowCount = experienceRowCount + 1;
    const newExperience = {
      field: String(expRowCount),
      id: String(expRowCount),
      position: "",
      periodForm: DateTime.now().minus({day:1}).toFormat("yyyy-MM-dd"),
      periodTo: DateTime.now().toFormat("yyyy-MM-dd"),
      Activities: "",
      error: false
    };
    const prevExperience = props.experiences as any;
    prevExperience.push(newExperience);
    props.setExperiences(prevExperience);
    setExperienceRowCount(expRowCount);
  };

  const handleAddEducation = (e: any) => {
    const edRowCount = educationRowCount + 1;
    const newEducation = {
      // field: String(edRowCount),
      id: String(edRowCount),
      institution: "",
      yearOfDegree: "",
      degree: "",
      focus: "",
      error: false
    };
    const prevEducation = props.educations as any;
    prevEducation.push(newEducation);
    props.setEducations(prevEducation);
    setEducationRowCount(edRowCount);
  };

  return (
    <div className="TrainerOnboardingProcessAboutExpertise">
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h1>
              {t("experienceCapital")}
            </h1>
            <p>
              {t("tellMoreAboutYourExperience")}
            </p>
            <hr />
          </div>
          <div className="experience">
            <label>
              {t("experience")}{" "}
            </label>
            <table>
              <tbody>
              <tr>
                <th>
                  {t("position")}
                </th>
                <th>
                  {t("from")}
                </th>
                <th>
                  {t("to")}
                </th>
                <th>
                  {t("field of activity")}
                </th>
                <th
                  className={"deleteTh"}
                  style={
                    props.experiences.length === 1
                      ? { display: "none" }
                      : { display: "" }
                  }
                />
              </tr>
              {(props.experiences || []).map((data: any) =>
                creteFormRowsExperience(data)
              )}
              </tbody>
            </table>
            <button type={"button"} onClick={handleAddExperience}>
              {t("addLine")}{" "}
              <span>
                <img src={addSVG} alt="addSVG" />
              </span>
            </button>
          </div>
          <hr />
          <div className="education">
            <label>
              {t("education")}
            </label>
            <table>
              <tbody>
              <tr>
                <th>
                  {t("Institution")}
                </th>
                <th>
                  {t("degree")}
                </th>
                <th>
                  {t("year of degree")}
                </th>
                <th>
                  {t("fieldsOfFocus")}
                </th>
                <th
                  className={"deleteTh"}
                  style={
                    props.educations.length === 1
                      ? { display: "none" }
                      : { display: "" }
                  }
                />
              </tr>
              {(props.educations || []).map((data: any) =>
                creteFormRowsEducation(data)
              )}
              </tbody>
            </table>
            <button type={"button"} onClick={handleAddEducation}>
              {t("addLine")}{" "}
              <span>
                <img src={addSVG} alt="addSVG" />
              </span>
            </button>
          </div>
          <hr className={"form-devider"} />
          <div className="vision">
            <label htmlFor="vision">
              {" "}
              {t("Your vision & mission")}{" "}
            </label>
            <textarea
              name="vision"
              id="vision"
              placeholder={yourVisionMissionPlaceholder}
              value={props.visionAndMission}
              onChange={(e) => props.setVisionAndMission(e.target.value)}
            />
          </div>
          {props?.isSubmitButton && (
            <div className="breakTime col-6">
              <button type={"submit"}>
                {t("save")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainerOnboardingProcessAboutExpertise;
