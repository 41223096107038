import React, { useState, useEffect } from "react";
import question from "../../../images/component_svg/Question.svg";
import { countrysEnum, currencySymbolEnum } from "../../../enums/enum";
import { getLatLng, getGeocode } from "use-places-autocomplete";
import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";

interface mapCordinates {
  lat: Number;
  lng: Number;
  radius?: Number;
}

const mapCircleStyle = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#fdfc0096",
  fillOpacity: 0.35,
  draggable: false
};

const mapKey: string = process.env.REACT_APP_GOOGLE_MAP_KEY || "";
const TrainerOnboardingProcessBusinessTrainingsOptions = (props: any) => {
  const [mapObj, setMapObj] = useState<any>(null);
  const [mp, setMp] = useState<any>(null);
  const [cityCircleObj, setCityCircleObj] = useState<any>(null);
  const [markerObj, setMarkerObj] = useState<any>(null);
  const [timeoutOutLocation, setTimeoutOutLocation] = useState<any>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(13.74);
  const [position, setPosition] = useState<any>({
    lat: 40.756795,
    lng: -73.954298
  });
  const breakTimeArray = Array.from(Array(7).keys());
  const countryArray = Array.from(Array(12).keys());

  const { t, i18n } = useTranslation();
  const addressPlaceholder = t("addressPlaceholder");
  const houseNumberPlaceholder = t(
    "houseNumberPlaceholder"
  );
  const cityPlaceholder = t("cityPlaceholder");
  const postalCodePlaceholder = t("postal code");
  const yes = t("yes");
  const no = t("no");
  const minutes = t("minutes");
  const minute = t("minute");
  const minuteCapital = t("Minute");
  const getZoom = (num: number) => {
    let zoom =
      Math.log2((4500 * Math.cos((position.lat * Math.PI) / 180)) / num) + 3;
    return zoom < 14 ? zoom : 13.74;
  };
  const getKmMeters = (km: number) => 1000 * km;
  const handleChange = (state: string, e: any) => {
    const { value } = e.target;
    props[state](value);
  };

  useEffect(() => {
    if (!mapObj || props.onSiteClient === 0) return;
    if (timeoutOutLocation) clearTimeout(timeoutOutLocation);
    setTimeoutOutLocation(setTimeout(() => handleUpdateLocation(), 500));
  }, [props.address, props.postalCode, props.city, props.country]);

  const updatMapCenter = ({ lat, lng }: mapCordinates) => {
    setPosition({ lat, lng });
  };

  const updateMapCircile = (center: mapCordinates, km?: any) => {
    if (!mapObj) return;
    const kms = getKmMeters(km || props.numberOfKm);
    cityCircleObj?.setCenter(center);
    cityCircleObj?.setRadius(kms > 0 ? kms : 1000);
  };

  const updateMarkerPosition = (cordinates: mapCordinates) => {
    if (!mapObj) return;
    markerObj?.setPosition(cordinates);
  };

  const getAddress = () => {
    const { address, postalCode, city, country } = props;
    let addressObj: string = ``;
    if (address) addressObj += `${address}, `;
    if (postalCode) addressObj += `${postalCode}, `;
    if (city) addressObj += `${city}, `;
    if (country) addressObj += `${countrysEnum[country]}`;
    return addressObj;
  };

  const handleUpdateLocation = () => {
    if (!mapObj || props.onSiteClient === 0) return null;
    const address = getAddress();
    const parameter = {
      address
    };

    getGeocode(parameter)
      .then((res) => {
        return getLatLng(res[0]);
      })
      .then((latlng) => {
        updatMapCenter(latlng);
        updateMapCircile(latlng);
        updateMarkerPosition(latlng);
      })
      .catch((Err) => {
      });
  };

  const handleOnSite = (e: any) => {
    props.setOnsite(Number(e.target.value));
    if (!Boolean(Number(e.target.value))) {
      props.setOnsiteCoach(0);
      props.setOnsiteClient(0);
      props.setAddress("");
      props.setPostalCode("");
      props.setCity("");
      props.setVirtualBreakTime(0);
      props.setClientWorkoutBreakTime(0);
      props.setTrainerWorkoutBreakTime(0);
      props.setIsVirtual(Boolean(1));
      props.setTravelExpanses(0);
      props.setNumberOfKm(0);

    } else {
      props.setOnsiteCoach(1);
    }
  };
  const handleOnSiteCoach = (e: any) => {
    props.setOnsiteCoach(Number(e.target.value));
    if (!Boolean(Number(e.target.value))) {
      props.setVirtualBreakTime(0);
      props.setClientWorkoutBreakTime(0);
      props.setTrainerWorkoutBreakTime(0);
      props.setOnsiteClient(1);
    }
  };
  const handleOnSiteClient = (e: any) => {
    props.setOnsiteClient(Number(e.target.value));
    if (!Boolean(Number(e.target.value))) {
      props.setTravelExpanses(0);
      props.setNumberOfKm(0);
      props.setOnsiteCoach(1);
    }
  };
  const handleIsVirtual = (e: any) => {
    props.setIsVirtual(Number(e.target.value));
    if (Boolean(e.target.value)) {
      props.setVirtualLink("");
      props.setVirtualBreakTime(0);
      /*      props.setClientWorkoutBreakTime(0);
            props.setTrainerWorkoutBreakTime(0);*/
      props.setOnsite(1);
      props.setOnsiteCoach(1);
      props.setOnsiteClient(1);

    }
  };

  const loadMap = async (map: any, maps: any) => {
    const address = getAddress();
    setMapObj(maps);
    setMp(map);
    if (address) {
      const parameter = {
        address
      };

      getGeocode(parameter)
        .then((res) => {
          return getLatLng(res[0]);
        })
        .then(async (latlng) => {
          updatMapCenter(latlng);
          const kms = getKmMeters(props.numberOfKm);
          if (props.numberOfKm) {
            const zl = getZoom(props.numberOfKm);
            setZoomLevel(zl);
          }
          const cityCircle = await new maps.Circle({
            ...mapCircleStyle,
            map,
            center: latlng,
            radius: kms ? kms : 1000
          });
          setCityCircleObj(cityCircle);
          const mapMarker = await new maps.Marker({
            position: latlng,
            map,
            draggable: false
          });
          setMarkerObj(mapMarker);
        })
        .catch((Err) => {
        });
      return;
    }

    const cityCircle = await new maps.Circle({
      ...mapCircleStyle,
      map,
      center: position,
      radius: 1000
    });
    setCityCircleObj(cityCircle);
    const mapMarker = await new maps.Marker({
      position: position,
      map,
      draggable: false
    });
    setMarkerObj(mapMarker);
  };

  const handleChangeNumber = (num: number) => {
    const zl = getZoom(num);
    setZoomLevel(zl);
    updateMapCircile(position, num);
  };

  const currencySymbol = Object.values(currencySymbolEnum)[props.currency];



  return (
    <div className="trainerOnboardingProcessBusinessTrainingsOptions">
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h2>
              {t("Workout Options")}
            </h2>
            <p>
              {t("Please choose your workout options.")}
            </p>
            <hr />
          </div>
          <div className="trainingsOptions">
            <div className="onSite">
              <label>
                {t("on site")}
              </label>
              <select value={props.onSite} onChange={handleOnSite}>
                <option value={1}>{yes}</option>
                <option value={0}>{no}</option>
              </select>
              {props.errObject?.onSite && props.onSite === null && (
                <div className="validation-error yellow-global">
                  {props.errObject.onSite}
                </div>
              )}
            </div>
            {props.onSite ? (
              <div className="homebase">
                <span className="homebaseHead">
                  <h6>
                    {t("workoutBase")}
                  </h6>
                  <div className="question-img-container">
                    <p
                      className="question-info-text">{t("Workout base is the location where you spend most of your working time.")}</p>
                    <img src={question} alt="questionSVG" />
                  </div>

                </span>
                <div className="form-row">
                  <input
                    type="text"
                    placeholder={addressPlaceholder}
                    className="address"
                    disabled={Boolean(!props.onSite)}
                    value={props.address}
                    onChange={(e) => handleChange("setAddress", e)}
                  />
                  {props.errObject?.address && !props.address && (
                    <div className="validation-error yellow-global">
                      {props.errObject.address}
                    </div>
                  )}
                  <input
                    type="text"
                    name="housenumber"
                    placeholder={houseNumberPlaceholder}
                    className="housenumber"
                    disabled={Boolean(!props.onSite)}
                    value={props.housenumber}
                    onChange={(e) => handleChange("setHousenumber", e)}
                  />
                  {props.errObject?.housenumber && !props.housenumber && (
                    <div className="validation-error yellow-global">
                      {props.errObject.housenumber}
                    </div>
                  )}
                </div>
                <div className="form-row">
                  <div className="postal">
                    <input
                      type="text"
                      placeholder={postalCodePlaceholder}
                      disabled={Boolean(!props.onSite)}
                      value={props.postalCode}
                      onChange={(e) => handleChange("setPostalCode", e)}
                    />
                    {props.errObject?.postalCode && !props.postalCode && (
                      <div className="validation-error yellow-global">
                        {props.errObject.postalCode}
                      </div>
                    )}
                  </div>
                  <div className="city">
                    <input
                      type="text"
                      placeholder={cityPlaceholder}
                      disabled={Boolean(!props.onSite)}
                      value={props.city}
                      onChange={(e) => handleChange("setCity", e)}
                    />
                    {props.errObject?.city && !props.city && (
                      <div className="validation-error yellow-global">
                        {props.errObject.city}
                      </div>
                    )}
                  </div>
                  <div className="country">
                    <select
                      name="country"
                      id="country"
                      value={props.country}
                      disabled={Boolean(!props.onSite)}
                      onChange={(e) => handleChange("setCountry", e)}
                    >
                      {/*        {countryArray.map((v) => (
                        <option key={v} value={v}>
                          {countrysEnum[v]}
                        </option>
                      ))}*/}
                      <option value={4}>{t(countrysEnum[4])} </option>
                      <option value={5}>{t(countrysEnum[5])} </option>
                      <option value={8}>{t(countrysEnum[8])} </option>
                      <option value={10}>{t(countrysEnum[10])} </option>
                      required
                    </select>
                    {props.errObject?.country && !props.country && (
                      <div className="validation-error yellow-global mt-2">
                        {props.errObject.country}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {props.onSite ? (
              <div className="onSiteCoach">
                <label>
                  {t("business settings - on site coach")}
                </label>
                <select
                  disabled={!Boolean(props.onSite)}
                  value={props.onSiteCoach}
                  onChange={handleOnSiteCoach}
                >
                  <option value={1}>{yes}</option>
                  <option value={0}>{no}</option>
                </select>
                {props.errObject?.onSiteCoach && props.onSiteCoach === null && (
                  <div className="validation-error yellow-global">
                    {props.errObject.onSiteCoach}
                  </div>
                )}
              </div>
            ) : null}
            {/* Workout base section */}

            {/* BreakTime after section */}
            {props.onSite && props.onSiteCoach ? (
              <div className="breakTime">
                <label htmlFor="breakTime">
                  {t("break time after sessions")}
                </label>
                <select
                  id={"breakTime"}
                  value={props.trainerWorkoutBreakTime}
                  disabled={Boolean(!props.onSite)}
                  onChange={(e) =>
                    props.setTrainerWorkoutBreakTime(Number(e.target.value))
                  }
                >
                  {breakTimeArray.map((v) => (
                    <option value={v * 5} key={v * 5}>
                      {v * 5} {(v === 0 && i18n.language == "de") ? minuteCapital : ((v === 0 && i18n.language == "en") ? minute : minutes)}
                    </option>
                  ))}
                </select>
                {props.errObject?.trainerBreakTime &&
                  !props.trainerWorkoutBreakTime && (
                    <div className="validation-error yellow-global">
                      {props.errObject.trainerBreakTime}
                    </div>
                  )}
              </div>
            ) : null}
            <div className="distanceOption">
              {props.onSite ? (
                <div className="onSiteCoach">
                  <label>
                    {t("business settings - on site client")}
                  </label>
                  <select
                    disabled={Boolean(!props.onSite)}
                    value={props.onSiteClient}
                    onChange={handleOnSiteClient}
                  >
                    <option value={1}>{yes}</option>
                    <option value={0}>{no}</option>
                  </select>
                  {props.errObject?.onSiteClient && !props.onSiteClient && (
                    <div className="validation-error yellow-global">
                      {props.errObject.onSiteClient}
                    </div>
                  )}
                </div>
              ) : null}
              {props.onSiteClient && props.onSiteClient ? (
                <div className="perimeterandMap">
                  <div className="leftSide">
                    <div className="parameterHead">
                      <div className={"label"}>
                        <p>
                          {t("marketArea")}
                        </p>
                        <img src={question} alt="questionSVG" />
                      </div>
                      <p className={"numberOfKilometer"}>
                        {props.numberOfKm}
                        <span>km</span>
                      </p>
                    </div>
                    <div className="map">
                      <div style={{ height: "50vh", width: "100%" }}>
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: mapKey,
                            libraries: "places,geometry"
                          }}
                          center={position}
                          zoom={zoomLevel}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) =>
                            loadMap(map, maps)
                          }
                        />
                      </div>
                    </div>
                    <input
                      type="range"
                      min="1"
                      max="200"
                      value={props.numberOfKm}
                      disabled={
                        !Boolean(props.onSite) || !Boolean(props.onSiteClient)
                      }
                      onChange={(e) => {
                        handleChangeNumber(Number(e.target.value));
                        props.setNumberOfKm(Number(e.target.value));
                      }}
                    />
                    {props.errObject?.numberOfKm && !props.numberOfKm && (
                      <div className="validation-error yellow-global">
                        {props.errObject.numberOfKm}
                      </div>
                    )}
                    <div className="breakTime">
                      <label htmlFor="breakTime">
                        {t("break time after sessions")}
                      </label>
                      <select
                        id={"breakTime"}
                        disabled={
                          !Boolean(props.onSite) || !Boolean(props.onSiteClient)
                        }
                        value={props.clientWorkoutBreakTime}
                        onChange={(e) =>
                          props.setClientWorkoutBreakTime(
                            Number(e.target.value)
                          )
                        }
                      >
                        {breakTimeArray.map((v) => (
                          <option value={v * 5} key={v}>
                            {v * 5} {v == 0 ? minuteCapital : minutes}
                          </option>
                        ))}
                      </select>
                      {props.errObject?.clientWorkoutBreakTime &&
                        !props.clientWorkoutBreakTime && (
                          <div className="validation-error yellow-global">
                            {props.errObject.clientWorkoutBreakTime}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="travleContent">
                      <label htmlFor="travelExpenses">
                        {t("travel expenses")}
                      </label>
                      <div className={"travelExpensesInput"}>
                        <input
                          min={0}
                          disabled={Boolean(
                            !props.onSite || !props.onSiteClient
                          )}
                          value={props.travelExpenses}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              props.setTravelExpanses("");
                            } else {
                              props.setTravelExpanses(e.target.value);
                            }

                            /*if(Number(e.target.value) || e.target.value === "" || e.target.value === "" || e.target.value === "."){
                                                  if (Number(e.target.value) < 0) {
                                                    props.setTravelExpanses(0);
                                                  } else {
                                                    props.setTravelExpanses(Number(e.target.value));
                                                  }
                                                }*/
                          }
                          }
                        />
                        <span>
                          {currencySymbol}{" "}
                          {t("per")} {i18n.language == "en" ? minute : minuteCapital}
                        </span>

                        {props.errObject?.travelExpenses &&
                          props.travelExpenses <= 0 && (
                            <div className="validation-error yellow-global">
                              {props.errObject.travelExpenses}
                            </div>
                          )}
                      </div>
                      {props.travelExpenses.toString().replace(",", ".").split(".")[1]?.length > 1  ?
                        <div className="validation-error yellow-global">
                          {t("errorDecimal")}
                        </div> : null}

                      {isNaN(Number(props.travelExpenses.toString().replace(",", "."))) ?
                        <div className="validation-error yellow-global">
                          {t("this is not a valid number")}
                        </div> : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <hr />
            <div className="virtual">
              <h5>
                {i18n.language === "de" ? t("virtual").toLocaleUpperCase() : t("virtual")}
              </h5>
              <div className="form-row">
                <select
                  className={"allowVirtual"}
                  value={props.isVirtual}
                  onChange={handleIsVirtual}
                >
                  <option value={1}>{yes}</option>
                  <option value={0}>{no}</option>
                </select>
                {props.errObject?.isVirtual && !props.isVirtual && (
                  <div className="validation-error yellow-global">
                    {props.errObject.isVirtual}
                  </div>
                )}
              </div>
              {props.isVirtual ? (
                <>
                  <label htmlFor="virtualLink">
                    {t("Paste your streaming link here.")}
                    <span className="required">*</span>
                  </label>
                  <input
                    //required={props.isVirtual === 1}
                    name="virtualLink"
                    id="virtualLink"
                    placeholder={"https://www.zoom.com/"}
                    value={props.virtualLink}
                    onChange={(e) => props.setVirtualLink(e.target.value)}
                    disabled={Boolean(!props.isVirtual)}

                  />
                  {props.errObject?.virtualLink &&
                    !props.virtualLink && (
                      <div className="validation-error yellow-global">
                        {props.errObject.virtualLink}
                      </div>
                    )}

                  <div className="breakTime">
                    <label htmlFor="breakTime">
                      {" "}
                      {t("break time after sessions")}
                    </label>
                    <select
                      id={"breakTime"}
                      disabled={Boolean(!props.isVirtual)}
                      value={props.virtualBreakTime}
                      onChange={(e) =>
                        props.setVirtualBreakTime(e.target.value)
                      }
                    >
                      {breakTimeArray.map((v) => (
                        <option value={v * 5} key={v * 5}>
                          {v * 5} {(v === 0 && i18n.language == "de") ? minuteCapital : ((v === 0 && i18n.language == "en") ? minute : minutes)}
                        </option>
                      ))}
                    </select>
                    {props.errObject?.virtualBreakTime &&
                      !props.virtualBreakTime && (
                        <div className="validation-error yellow-global">
                          {props.errObject.virtualBreakTime}
                        </div>
                      )}
                  </div>
                </>
              ) : null}
              {props?.isSubmitButton && (
                <div className="breakTime">
                  <button type={"submit"}>
                    {t("save")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TrainerOnboardingProcessBusinessTrainingsOptions;
