import {serviceTypeEnum} from '../../../enums/enum';
import {DateTime} from "luxon";
import services from '../../../shared/services';

export type ServiceArrayType = {
  id: number,
  category:number,
  name:string,
  description:string,
  serviceTypes: Array<number>,
  disciplines: Array<string>,
  durationInMinutes : Array<number>,
  onsiteFee:number,
  virtualFee:number,
  enabled:boolean,
  bookings: Array<any>
  groupPrice: number,
  date: string,
  startTime: string,
  maximumParticipants: number,
  maximumParticipantsInWaitingList: number,
  difficultyLevel:number,
  dayOfWeeks: Array<number>,
  weekRepeats: number,
  location: string,
  address:string,
  postalCode: string,
  city: string,
  country: string
  houseNumber: string,
}

//TODO Implement type of initState


const initState  = {
  hasService: false,
  services: {
    tab: 0,
    serviceArray: [],
    selectedForBooking: {
      address: "",
      bookings: [],
      category: 0,
      city: -1,
      country: -1,
      description: "",
      disciplines: [],
      durationInMinutes: [],
      enabled: false,
      houseNumber: "",
      id: -1,
      name: "",
      onsiteFee: -1,
      postalCode: -1,
      selectedDuration: -1,
      selectedServiceType: -1,
      serviceTypes: [],
      virtualFee: -1,
    },
    smallGroup: {
      category: 1,
      name: "",
      description: "",
      disciplines: [],
      isRecurring: false,
      date: "",
      startTime: 0,
      durationInMinutes: 0,
      dayOfWeeks: [],
      weekRepeats: 1,
      classWillEnd: 0,
      maximumParticipants: 2,
      serviceType: -1,
      groupPrice: 0,
      difficultyLevel: 0,
      recurringDays: [],
      address: "",
      postalCode: "",
      houseNumber: "",
      city: "",
      country: 0,
      maximumParticipantsInWaitingList: 0,
    },
    personalTraining: {
      nameOfService: "",
      description: "",
      disciplines: [],
      duration: [],
      virtual: {
        isChecked: false,
        price: 0,
      },
      onSite: {
        isCheckedCoach: false,
        isCheckedClient: false,
        price: 0,
      },
    },
    editService:
    {
      id: -1,
      category: 0,
      name: null,
      description: null,
      serviceTypes: [],
      disciplines: [],
      durationInMinutes: [],
      onsiteFee: 0,
      virtualFee: 0,
      enabled: true,
      bookings: [],
      groupPrice: null,
      date: null,
      startTime: null,
      maximumParticipants: null,
      maximumParticipantsInWaitingList: null,
      difficultyLevel: null,
      dayOfWeeks: [],
      weekRepeats: 0,
      location: null,
      address: null,
      postalCode: null,
      city: null,
      country: null,
      houseNumber: null
    }
  },
  calendar: {
    dayOfWeek: { 0: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] } },
    weeklyView: [],
    dayOfWeekLoader: true,
    overrideData:[]
  },
  selectedCalendarItem: {
    id: -1,
    category: -1,
    serviceType: -1,
    name: "",
    startDate: "",
    endDate: "",
    duration: -1,
    maximumParticipants: -1,
    ParticipantsCount: 5,
    description: "",
    difficultyLevel: -1,
  },
  promoCode: {
    code: '',
    description: '',
    discountType: 1,
    amountOff: 1,
    percentOff: 1,
    promotionCodeExpiresAt: '',
    stripePromotionCodeUsageType: 0
  },
  activePromoCodes: [],
};

const BusinessCenterReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "hasService":{
      return { ...state, hasService: action.payload };
    }
    case "getOverrideData":
      return { ...state, calendar:{...state.calendar, overrideData: action.payload} };
    case "updateService":
      return { ...state, ...action.payload };
    case "updateCreateServicePersonalTraining":
      return {
        ...state,
        services: {
          ...state.services,
          personalTraining: {
            ...state.services.personalTraining,
            ...action.payload,
          },
          smallGroup: { ...state.services.smallGroup },
        },
      };
    case "editDiscipline":
      return {
        ...state,
        services: {
          ...state.services,
          personalTraining: {
            ...state.services.personalTraining,
            disciplines: action.payload,
          },
          smallGroup: { ...state.services.smallGroup },
        },
      };
    case "editDuration":
      return {
        ...state,
        services: {
          ...state.services,
          personalTraining: {
            ...state.services.personalTraining,
            duration: action.payload,
          },
          smallGroup: { ...state.services.smallGroup },
        },
      };
    case "editVirtual":
      return {
        ...state,
        services: {
          ...state.services,
          personalTraining: {
            ...state.services.personalTraining,
            virtual: { ...action.payload },
          },
          smallGroup: { ...state.services.smallGroup },
        },
      };
    case "editOnSite":
      return {
        ...state,
        services: {
          ...state.services,
          personalTraining: {
            ...state.services.personalTraining,
            onSite: {
              ...state.services.personalTraining.onSite,
              ...action.payload,
            },
          },
          smallGroup: { ...state.services.smallGroup },
        },
      };
    case "updateCreateServiceGroup":
      return {
        ...state,
        services: {
          ...state.services,
          smallGroup: { ...state.services.smallGroup, ...action.payload },
          personalTraining: { ...state.services.personalTraining },
        },
      };
    case "editDisciplineGroup":
      return {
        ...state,
        services: {
          ...state.services,
          smallGroup: {
            ...state.services.smallGroup,
            disciplines: action.payload,
          },
          personalTraining: { ...state.services.personalTraining },
        },
      };
    case "editIsRecurring":
      return {
        ...state,
        services: {
          ...state.services,
          smallGroup: {
            ...state.services.smallGroup,
            isRecurring: action.payload,
          },
          personalTraining: { ...state.services.personalTraining },
        },
      };
    case "getServices":
      return {
        ...state,
        services: {
          ...state.services,
          serviceArray: action.payload,
        },
      };
    case "setSelectedForBooking":
      return {
        ...state,
        services: {
          ...state.services,
          selectedForBooking: { ...action.payload[0] },
        },
      };
    case "getWeeklyRoutine":
      return {
        ...state,
        calendar: {
          ...state.calendar,
          dayOfWeek: action.payload,
        },
      };
    case "setDayOfWeekLoader":
      return {
        ...state,
        calendar: {
          ...state.calendar,
          dayOfWeekLoader: action.payload,
        },
      };

    case "addDayOfWeekToArray":
      return {
        ...state,
        calendar: {
          ...state.calendar,
          dayOfWeek: {
            ...state.calendar.dayOfWeek,
          },
        },
      };
    case "setWeeklyView":
      return {
        ...state,
        calendar: {
          ...state.calendar,
          weeklyView: action.payload,
        },
      };
    case "setSelectedCalendarItem":
      return {
        ...state,
        selectedCalendarItem: action.payload,
      };
    case "setServiceToUpdate":
      return {
        ...state,
        services: {
          ...state.services,
          smallGroup: action.payload,
        },
      };
    case "setEditService":

      const isActive = (serviceType : number) => (action.payload.serviceTypes || []).indexOf(serviceType) > -1;
      const hasVirtualService = isActive(serviceTypeEnum.virtual);
      const hasOnSiteTrainer = isActive(serviceTypeEnum.onSiteCoach)
      const hasOnSiteCustomer = isActive(serviceTypeEnum.onSiteClient);

      return {
        ...state,
        services: {
          ...state.services,
          editService: action.payload,
          personalTraining: {
            ...state.services.personalTraining,
            nameOfService: action.payload.name,
            description: action.payload.description,
            duration: action.payload.durationInMinutes.map((duration: number, index: number) => { return {key: "dur" + Date.now() + index, value: duration}}),
            disciplines: action.payload.disciplines.map((discipline: string, index: number) => { return {key: "dis" + Date.now() + index, value: discipline}}),
            virtual: {isChecked: hasVirtualService, price: hasVirtualService ? action.payload.virtualFee : 0},
            onSite: {isCheckedCoach: hasOnSiteTrainer, isCheckedClient: hasOnSiteCustomer,  price : (hasOnSiteTrainer || hasOnSiteCustomer) ? action.payload.onsiteFee : 0 }
          }
        }
      }
    case "setEditServiceGroup":
      return {
        ...state,
        services: {
          ...state.services,
          editService: action.payload,
          smallGroup: {
            ...state.services.smallGroup,
            name: action.payload.name,
            description: action.payload.description,
            disciplines: action.payload.disciplines,
            groupPrice: action.payload.groupPrice,
            difficultyLevel: action.payload.difficultyLevel,
            serviceTypes: action.payload.serviceTypes,
            serviceType: (action.payload.serviceTypes || [0])[0],
            maximumParticipants: action.payload.maximumParticipants,
            startTime: action.payload.startTime,
            date: DateTime.fromISO(action.payload.date).toFormat("yyyy-MM-dd"),
            durationInMinutes:  (action.payload.durationInMinutes || [0])[0],
            id: action.payload.id,
            dayOfWeeks: (action.payload.dayOfWeeks || []),
            isRecurring: action.payload.dayOfWeeks.length > 0,
            weekRepeats: action.payload.weekRepeats,
          },
        }
      }
    case "resetEditService":
      return {
        ...state,
        services: {
          ...state.services,
          editService: initState.services.editService,
          personalTraining: initState.services.personalTraining,
        }
      }
    case "removedCancelledBooking":
        return {
          ...state,
          calendar: {
            ...state.calendar,
            weeklyView: state.calendar.weeklyView.filter(booking => booking['bookingId'] !== action.payload.bookingId),
          },
    };
    case "setPromoCode":

      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          ...action.payload,
        }
      }
    case "getPromoCodes":
      return {
        ...state,
          activePromoCodes: action.payload,
        }
    case "resetPromoCode":
        return {
          ...state,
          promoCode: initState.promoCode,
        }
    default:
      return { ...state };

    case "setPackages" :
      return {...state, services:{
        ...state.services,
          packages: action.payload
        }}

    case "resetServicesFields" :
      return {
        ...state,
        services: {
          ...state.services,
          personalTraining: {
            ...state.services.personalTraining,
            ...initState.services.personalTraining,
          },
        }
      };

    case "resetSmallGroupFields" :
      return {
        ...state,
        services: {
          ...state.services,
          smallGroup: initState.services.smallGroup,
          ...initState.services.smallGroup,
        },
      };

    case "setStartTime" :
      let hour = action.payload.hour < "10" ? ("0" + action.payload.hour).toString() : action.payload.hour.toString();
      let minute = action.payload.minute < "10" ? ("0" + action.payload.minute).toString() : action.payload.minute.toString();

      return{
        smallGroup: {
          ...state.services.smallGroup,
            startTime: (hour + ":" + minute + ":00"),
          },
        }

  }
};

export default BusinessCenterReducer;
