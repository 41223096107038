import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { changeNavigation, setIsLoading } from "../../redux/actions/layout/layoutAction";
import { loadCustomerData } from "../../redux/actions/customer/customerAction";
import { countrysEnum, imageUploadType, ModalEnum } from "../../enums/enum";
import { setTrainerData } from "../../redux/actions/registration/OnboardingAction";
import AuthContext from "../../utility/context/auth-context";
import { DateTime } from "luxon";
import ModalContext from "../../utility/context/modal-context";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de"


const CustomerPersonalInformation = () => {

  const dispatch = useDispatch();
  const customerData = useSelector(state => state.customer);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext<any>(ModalContext);
  const { i18n , t} = useTranslation();


  useEffect(() => {
    dispatch(changeNavigation(1));
    dispatch(loadCustomerData());
  }, []);


  const handleSubmit = async (values: customerFieldsValues) => {


    const data = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      addressPostalCode: formik.values.addressPostalCode,
      addressCity: formik.values.addressCity,
      addressCountry: Number(formik.values.addressCountry),
      address: formik.values.address,
      houseNumber: formik.values.houseNumber,
      dateOfBirth: DateTime.fromISO(formik.values.dateOfBirth).toUTC(),
      preferredPronoun: Number(formik.values.preferredPronoun),
      companyName: formik.values.companyName
    };



    dispatch(setIsLoading(true));

    const response = await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/customers/user`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          Authorization: "Bearer " + authCtx.token,
          "Content-Type": "application/json"
        }
      }
    );
    const res = await response.json();
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response.ok) {


      dispatch(setTrainerData());
      dispatch(loadCustomerData());
      modalCtx.setModalType(ModalEnum.InfoModal)
      modalCtx.setMessage(res.message)
      modalCtx.setIsActive(true)

    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t(res.message));
      modalCtx.setIsActive(true);
    }


  };

  const uploadImageFile = async (e: any) => {
    let data = new FormData();
    data.append("file", e.target.files[0]);
    dispatch(setIsLoading(true));

    const response = await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/customers/upload?type=${imageUploadType.CustomerProfile}`,
      {
        method: "PUT",
        body: data,
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          Authorization: "Bearer " + authCtx.token
        }
      }
    );
    setTimeout(() => dispatch(setIsLoading(false)),400)

    const res = await response.json();

    if (response.ok) {
      dispatch(setTrainerData());
      dispatch(loadCustomerData());
      modalCtx.setModalType(ModalEnum.InfoModal)
      modalCtx.setMessage(res.message)
      modalCtx.setIsActive(true)
    } else {

      modalCtx.setModalType(0);
      modalCtx.setMessage(t(res.message));
      modalCtx.setIsActive(true);
    }
  };



  registerLocale('de',de);

  const handleFiledHasError = (name: string): string | undefined => {
    const formikNew = formik as any;
    return (formikNew.touched[name] && formikNew.errors[name]) || undefined;
  };

  interface customerFieldsValues {
    firstName: string;
    lastName: string;
    addressPostalCode: string;
    addressCity: string;
    addressCountry: number;
    address: string;
    houseNumber: string;
    preferredPronoun: number;
    dateOfBirth: string;
    companyName: string;
  }

  const initialValues: customerFieldsValues = {
    firstName: customerData.firstName,
    lastName: customerData.lastName,
    addressPostalCode: customerData.addressPostalCode,
    addressCity: customerData.addressCity,
    addressCountry: Number(customerData.addressCountry) || 4,
    address: customerData.address,
    houseNumber: customerData.houseNumber,
    dateOfBirth: DateTime.fromISO(customerData.dateOfBirth).year > 1900 ?  DateTime.fromISO(customerData.dateOfBirth).toLocal().toFormat("yyyy-MM-dd") : "1999-02-02" ,
    preferredPronoun: customerData.preferredPronoun > -1 ? customerData.preferredPronoun : 0,
    companyName: customerData.companyName
  };

  useEffect(() => {
    formik.setFieldValue("firstName",customerData.firstName || "");
    formik.setFieldValue("lastName",customerData.lastName || "");
    formik.setFieldValue("addressPostalCode",customerData.addressPostalCode || "");
    formik.setFieldValue("addressCity",customerData.addressCity || "");
    formik.setFieldValue("address",customerData.address || "");
    formik.setFieldValue("houseNumber",customerData.houseNumber || "");
    
    if(customerData.addressCountry != ""){
      formik.setFieldValue("addressCountry",Number(customerData.addressCountry));
    }
    if(customerData.dateOfBirth != ""){
      if (DateTime.fromISO(customerData.dateOfBirth).year < 1900)  {
        formik.setFieldValue("dateOfBirth", "2000-01-01");
      } else {
        formik.setFieldValue("dateOfBirth",customerData.dateOfBirth);
      }
    }
    if(customerData.preferredPronoun != -1){
      formik.setFieldValue("preferredPronoun",Number(customerData.preferredPronoun));
    }
  },[customerData])

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup
      .string()
      .required(t("last name is required")),
    addressPostalCode: yup
      .string()
      .required(t("postal code is required")),
    addressCity: yup
      .string()
      .required(t("city is required")),
    addressCountry: yup
      .number()
      .required(t("country is required")),
    address: yup.string()
      .required(t("address is required")),
    houseNumber: yup
      .string()
      .required(t("house number is required")),
    dateOfBirth: yup
      .string()
      .required(t("errBirthDate")),
    preferredPronoun: yup
      .number()
      .required(t("errPronoun"))
  });

  const firstnamePlaceholder = t("firstnamePlaceholder");
  const lastnamePlaceholder = t("lastnamePlaceholder");
  const addressPlaceholder = t("addressPlaceholder");
  const houseNumberPlaceholder = t("houseNumberPlaceholder");
  const cityPlaceholder = t("cityPlaceholder");

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      handleSubmit(values);
    }
  });
  return (
    <div className="customerPersonalInformation">
      <div className="initialView">
        <div className="content">
          <div className="card">
            <div className="card_content">
              <div className="card_header">
                <h4>{t("personalInformation")}</h4>
                <hr />
              </div>

              <div className="profile_picture">
                <div className="image_circle">
                  <img
                    src={customerData.profilePictureUri != "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${customerData.profilePictureUri}/download` : "https://intern-creativemindz.de/wp-content/uploads/user3.svg"}
                    alt={"profilePicture"} />
                </div>
                <div className="button-container">
                  <p>
                    {t("profilePicture")}
                  </p>
                  <label
                    htmlFor="upload_image"
                    className={`upload_image ${"border-red"}`}
                  >
                    <p>
                      {t("uploadImage")}
                    </p>
                    <input
                      accept=".png,.jpg,.jpeg"
                      type="file"
                      id={"upload_image"}
                      onChange={uploadImageFile}
                      placeholder={"upload image"}
                    />
                  </label>
                  <p className="muted">
                    {t("profilePictureDimensions")}
                  </p>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="firstName">
                      {t("firstname")}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id={"firstName"}
                      {...formik.getFieldProps("firstName")}
                      placeholder={firstnamePlaceholder}
                    />
                    {handleFiledHasError("firstName") ? (
                      <div className="error">{handleFiledHasError("firstName")}</div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">
                      {t("lastname")}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id={"lastName"}
                      {...formik.getFieldProps("lastName")}
                      placeholder={lastnamePlaceholder}
                    />
                    {handleFiledHasError("lastName") ? (
                      <div className="error">{handleFiledHasError("lastName")}</div>
                    ) : null}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="address">
                      {t("address")}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id={"address"}
                      {...formik.getFieldProps("address")}
                      placeholder={addressPlaceholder}
                    />
                    {handleFiledHasError("address") ? (
                      <div className="error">{handleFiledHasError("address")}</div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="houseNumber">
                      {t("house number")}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id={"houseNumber"}
                      {...formik.getFieldProps("houseNumber")}
                      placeholder={houseNumberPlaceholder}
                    />
                    {handleFiledHasError("houseNumber") ? (
                      <div className="error">{handleFiledHasError("houseNumber")}</div>
                    ) : null}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="addressPostalCode">
                      {t("postal code")}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id={"addressPostalCode"}
                      {...formik.getFieldProps("addressPostalCode")}
                      placeholder={"45290"}
                    />
                    {handleFiledHasError("addressPostalCode") ? (
                      <div className="error">
                        {handleFiledHasError("addressPostalCode")}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="addressCity">
                      {t("city")}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id={"addressCity"}
                      {...formik.getFieldProps("addressCity")}
                      placeholder={cityPlaceholder}
                    />
                    {handleFiledHasError("addressCity") ? (
                      <div className="error">{handleFiledHasError("addressCity")}</div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="addressCountry">
                      {t("country")}{" "}
                      <span className="required">*</span>
                    </label>
                    <select id={"addressCountry"}
                            {...formik.getFieldProps("addressCountry")}
                    >

                      <option value={4}>{t(countrysEnum[4])} </option>
                      <option value={5}>{t(countrysEnum[5])} </option>
                      <option value={8}>{t(countrysEnum[8])} </option>
                      <option value={10}>{t(countrysEnum[10])} </option>
                    </select>
                    {handleFiledHasError("addressCountry") ? (
                      <div className="error">
                        {handleFiledHasError("addressCountry")}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="dateOfBirth">
                      {t("date of birth")}{" "}
                      <span className="required">*</span>
                    </label>
                    <DatePicker
                      id={"dateOfBirth"}

                      onChange={(date) => {
                        if(date !== null){
                          formik.setFieldValue("dateOfBirth",(DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")))
                        }
                      }}
                      selected={DateTime.fromISO(formik.values.dateOfBirth).toJSDate()}
                      dateFormat="dd.MM.yyyy"
                      locale={i18n.language === "de" ? de : ""}
                      calendarStartDay={1}
                    />

                    {handleFiledHasError("dateOfBirth") ? (
                      <div className="error">{handleFiledHasError("dateOfBirth")}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="preferredPronoun">
                      {t("preferred pronoun")}{" "}
                      <span className="required">*</span>
                    </label>
                    <select
                      id={"preferredPronoun"}
                      {...formik.getFieldProps("preferredPronoun")}
                    >
                      <option value={1}>{t("she/her")}</option>
                      <option value={2}>{t("he/his")}</option>
                      <option value={3}>{t("diverse")}</option>
                    </select>
                    {handleFiledHasError("preferredPronoun") ? (
                      <div className="error">
                        {handleFiledHasError("preferredPronoun")}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="companyName">
                      {t("company name")}{" "}
                    </label>
                    <input
                      type="text"
                      id={"companyName"}
                      {...formik.getFieldProps("companyName")}
                      placeholder={t("companynamePlaceholder")}
                    />
                    {handleFiledHasError("companyName") ? (
                      <div className="error">{handleFiledHasError("companyName")}</div>
                    ) : null}
                  </div>
                  <button type={"submit"}>{t("save")}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>);

};


export default CustomerPersonalInformation;
