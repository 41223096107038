import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPublicTrainerData,
  setOnboardingStatusAction
} from "../../../redux/actions/registration/OnboardingAction";
import { changeNavigation } from "../../../redux/actions/layout/layoutAction";
import { useHistory } from "react-router-dom";
import ServiceTypeOnSiteClient from "./ServiceTypeOnSiteClient";
import ServiceTypeOnSiteCoach from "./ServiceTypeOnSiteCoach";
import ModalContext from "../../../utility/context/modal-context";
import {
  getServiceById,
  getServices,
  selectedForBookingAction
} from "../../../redux/actions/businessCenter/serviceActions";
import Discipline from "./Discipline";
import { countrysEnum, currencySymbolEnum } from "../../../enums/enum";
import BookingSmallGroupCalendar from "./BookingSmallGroupCalendar";
import {
  eraseSelectedTime,
  getBookingPolicyPublic, getBookingSmallGroupCalendarItems,
  getTravelData, setSelectingFromCancellation,
  setSelectingFromPackage
} from "../../../redux/actions/booking/bookingAction";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

const TrainerBookingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalCtx = useContext<any>(ModalContext);
  const { i18n, t } = useTranslation();


  const serviceArray = useSelector(state => state.businessCenter.services.serviceArray);
  const selectedForBooking = useSelector(state => state.businessCenter.services.selectedForBooking);
  const publicTrainerData = useSelector((state) => state.onboarding.publicTrainerData);
  const selectedPackageItem = useSelector(state => state.customer.selectedPackageItem);
  const selectedTime = useSelector(state => state.booking.selectedTime);
  const selectingFromPackage = useSelector(state => state.booking.selectingFromPackage);
  const selectingFromCancellation: boolean = useSelector(state => state.booking.selectingFromCancellation);
  const selectedCreditItem = useSelector(state => state.customer.selectedCreditItem);
  const customerData = useSelector(state => state.customer);

  const [selectedDiscipline, setSelectedDiscipline] = useState<{ id: number, value: string }>({ id: -1, value: "" });
  const [trainType, setTrainType] = useState(0);
  const [serviceType, setServiceType] = useState(-1);
  const [duration, setDuration] = useState<number>(-1);


  const [address, setAddress] = useState<string>(customerData.address);
  const [houseNumber, setHouseNumber] = useState<string>(customerData.houseNumber);
  const [city, setCity] = useState<string>(customerData.city);
  const [postalCode, setPostalCode] = useState<string>(customerData.postalCode);
  const [country, setCountry] = useState<number>(customerData.country);

  const selectServiceType = t("Select your service type…");
  const onSiteClient = t("on site client");
  const onSiteCoach = t("on site coach");
  const virtual = t("virtual");

  const [cookiesSelectedTime, setCookieSelectedTime, removeCookieSelectedTime] = useCookies(["SelectedTimeCookie", "travelData"]);
  const [cookiesSelectedPackage, setCookieSelectedPackage, removeCookieSelectedPackage] = useCookies(["SelectedPackageCookie", "SelectedPackageForBooking"]);
  const [cookiesSelectedForBooking, setCookieSelectedForBooking, removeCookieSelectedForBooking] = useCookies(["SelectedForBookingCookie"]);

  useEffect(() => {
    dispatch(getPublicTrainerData(history.location.pathname.split("/")[2]));
    dispatch(setOnboardingStatusAction(2));
    dispatch(getBookingPolicyPublic(history.location.pathname.split("/")[2]));
    dispatch(changeNavigation(1));
    //dispatch(getBookingSmallGroupCalendarItems(publicTrainerData.trainerSpace.spaceUrl));


    removeCookieSelectedForBooking("SelectedForBookingCookie", { path: "/me" });
    removeCookieSelectedTime("SelectedTimeCookie", { path: "/me" });
    removeCookieSelectedTime("travelData", { path: "/me" });
    removeCookieSelectedPackage("SelectedPackageCookie", { path: "/me" });
    removeCookieSelectedPackage("SelectedPackageForBooking", { path: "/me" });

    if (!selectingFromPackage) {
      dispatch(getServices(publicTrainerData.id, true, { id: -1, value: "" }, -1, -1, 0));
      dispatch(getBookingSmallGroupCalendarItems(publicTrainerData.trainerSpace.spaceUrl));

    } else if (selectingFromPackage && selectedPackageItem.category === 0) {
      dispatch(getServiceById({trainerId: publicTrainerData.id, enabled: undefined, serviceId: selectedPackageItem.id , category: 0}));
    } else if (selectingFromPackage && selectedPackageItem.category === 1) {
      setTrainType(1);
      //dispatch(getServiceById(publicTrainerData.id, true, selectedPackageItem.packageId, 1));
      dispatch(getBookingSmallGroupCalendarItems(publicTrainerData.trainerSpace.spaceUrl, selectedPackageItem.id, true));
    }
    if (selectingFromCancellation) {
      setTrainType(selectedCreditItem.serviceCategory);
      dispatch(getBookingSmallGroupCalendarItems(publicTrainerData.trainerSpace.spaceUrl, selectedCreditItem.service.id, true));

      const slideInterval = setTimeout(() => {
        if (selectedCreditItem.customerAddress) {
          const { address, city, country, postalCode, houseNumber } = selectedCreditItem.customerAddress;
          setAddress(address);
          setCountry(country);
          setCity(city);
          setPostalCode(postalCode);
          setHouseNumber(houseNumber);
        }

        setTimeout(() => {
          setServiceType(selectedCreditItem.serviceCategory);
        }, 100);

        if (selectedCreditItem.serviceCategory === 0) {
          setSelectedDiscipline({ id: selectedCreditItem.service.id, value: selectedCreditItem.service.name });

          setTimeout(() => {
            setServiceType(selectedCreditItem.serviceType);
          }, 100);
        }
      }, 1000);
    }

    return () => {
      dispatch(setSelectingFromPackage(false));
      dispatch(setSelectingFromCancellation(false));
    };

  }, [publicTrainerData.id]);

  useEffect(() => selectedServiceByID(selectedDiscipline.id), [selectedDiscipline, duration]);


  const updateFilter = () => {
    if (publicTrainerData.id != null && !selectingFromPackage) {
      if (selectingFromCancellation) {
        // dispatch(getServiceById(publicTrainerData.id, true, selectedCreditItem.service.id, selectedCreditItem.serviceCategory));
        dispatch(getServiceById({trainerId: publicTrainerData.id, enabled: undefined, serviceId: selectedCreditItem.service.id, category: selectedCreditItem.serviceCategory}));
        dispatch(getBookingSmallGroupCalendarItems(publicTrainerData.trainerSpace.spaceUrl, selectedCreditItem.service.id, true));
        return;
      }
      dispatch(getServices(publicTrainerData.id, true, selectedDiscipline, serviceType, duration, trainType));
      dispatch(getBookingSmallGroupCalendarItems(publicTrainerData.trainerSpace.spaceUrl));

    } else if (selectingFromPackage && selectedPackageItem.category === 0) {

      dispatch(getServiceById({trainerId: publicTrainerData.id, enabled: undefined, serviceId: selectedPackageItem.id, category: 0}));
    } else if (selectingFromPackage && selectedPackageItem.category === 1) {
      dispatch(getBookingSmallGroupCalendarItems(publicTrainerData.trainerSpace.spaceUrl, selectedPackageItem.id, true));
    }
  };


  const selectedServiceByID = (id: number) => {
    const resetObject = [{
      address: "",
      bookings: [],
      category: 0,
      city: -1,
      country: -1,
      description: "",
      disciplines: [],
      durationInMinutes: [],
      enabled: false,
      houseNumber: -1,
      id: -1,
      name: "",
      onsiteFee: -1,
      postalCode: -1,
      selectedDuration: -1,
      selectedServiceType: -1,
      serviceTypes: [],
      virtualFee: -1
    }];

    if (id === -1) {
      dispatch(selectedForBookingAction(resetObject));

    } else {
      const filteredService = serviceArray.filter((s: any) => s.id === id)[0];
      const defaultDuration = filteredService?.durationInMinutes.length > 0 ? filteredService.durationInMinutes[0] : 0;
      setDuration(defaultDuration);

      const result = [{
        ...filteredService,
        selectedDuration: defaultDuration,
        selectedServiceType: serviceType,
        address: address,
        houseNumber: houseNumber,
        city: city,
        postalCode: postalCode,
        country: country,
        disciplineAsString: selectedDiscipline.value,
        count: 2,
        bookings: []
      }];

      dispatch(selectedForBookingAction(result));

    }
  };


  useEffect(updateFilter, [trainType, serviceType]);

  useEffect(() => {
    if (Number(serviceType) === 2) {
      setAddress(customerData.address);
      setHouseNumber(customerData.houseNumber);
      setCity(customerData.addressCity);
      setPostalCode(customerData.addressPostalCode);
      setCountry(customerData.addressCountry || 4);
    }
  }, [serviceType]);


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (serviceType === 2) {

      if (selectingFromCancellation) {
        history.replace(`/me/${publicTrainerData.trainerSpace?.spaceUrl}/trainer-bookingTwo`);
        return;
      }
      //dispatch(getTravelData(publicTrainerData.id, address, houseNumber, city, postalCode, countrysEnum[country]));
      try {
        const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/travel-data?trainerId=${publicTrainerData.id}&Street=${address}&Housenumber=${houseNumber}&City=${city}&PostalCode=${postalCode}&Country=${countrysEnum[country]}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

            accept: "text/plain"
          }
        });
        const res = await response.json();

        if (response.ok) {
          dispatch({
            type: "getTravelData",
            payload: res
          });

          history.replace(`/me/${publicTrainerData.trainerSpace?.spaceUrl}/trainer-bookingTwo`);

        } else {

          modalCtx.setModalType(0);
          modalCtx.setMessage(res.message);
          modalCtx.setIsActive(true);
        }

      } catch (err) {
      }

    } else {
      if (selectedTime[0]?.service.category === 1 && selectedTime.length > 0) {

        let isValide = true;
        selectedTime.forEach(e => {
          //TODO: e.maximumParticipants <= e.currentParticipants
          if (e.maximumParticipants <= e.currentParticipants) {
            isValide = false;
          }
        });

        if (isValide) {
          history.replace(`/me/${publicTrainerData.trainerSpace?.spaceUrl}/trainer-bookingTwo`);
        } else {
          modalCtx.setModalType(0);
          modalCtx.setMessage(t("You have selected a full session. You are welcome to join the waiting list."));
          modalCtx.setIsActive(true);
        }

      } else {
        history.replace(`/me/${publicTrainerData.trainerSpace?.spaceUrl}/trainer-bookingTwo`);

      }
    }

  };

  const getDisciplinesStringFromArray = (array: Array<string>) => {
    let disciplineString: string = "";
    array.forEach((e: string, i: number, array: Array<string>) => {
      if (i < array.length - 1)
        disciplineString += `${e} / `;
      else
        disciplineString += `${e}`;

    });
    return disciplineString;
  };

  const getDurationArray = () => {
    let durationArray: Array<number> = [];
    const selected = serviceArray.filter((s: any) => s.id === selectedDiscipline.id);
    if (selected.id !== -1) {
      selected[0]?.durationInMinutes?.forEach((duration: number) => {
        if (!durationArray.includes(duration)) {
          durationArray.push(duration);

        }

      });
    }
    return durationArray;
  };

  const getServiceTypeArray = () => {
    let serviceTypeArray: Array<number> = [];
    serviceArray.forEach((e: any) => {
      e.serviceTypes?.forEach((serviceType: number) => {
        if (!serviceTypeArray.includes(serviceType)) {
          serviceTypeArray.push(serviceType);
        }
      });
    });
    return serviceTypeArray;
  };


  const currencySymbol = Object.values(currencySymbolEnum)[publicTrainerData.currency];

  return (
    <div className="trainerBooking">

      <div className="initialView">
        <div className="content">
          <div className="card">
            {/*            <div className="bookingNotAllowed" style={bookingIsAllowed ? {display: "none"} : {}}>
              <div className="blurBackground">
                <div className="modal">
                  <h1>Booking Policy</h1>
                  <p>the trainer allows bookings only between 10:00 and 12:00 </p>
                </div>
              </div>
            </div>*/}
            <div className="card-content">
              <div className="card_heading">
                <h2>
                  {trainType === 0 ? <h2>{t("bookingTitlePrivate")}</h2> : <h2>{t("bookingTitleGroup")}</h2>}
                  {"\t" + publicTrainerData.firstName} {publicTrainerData.lastName}
                </h2>
                <hr />
              </div>
              <form className="booking-grid" onSubmit={handleSubmit}>
                <div className={"form-steps done"}>1</div>
                <div className={"empty"} />
                <div className={"vl-container"}>
                  <span className="vl done-line" />
                </div>
                <div className={"trainType"}>
                  <p>{t("How do you want to train?")}</p>
                  <div className="form-row">
                    <input type="radio" id={"privateSession"} name={"trainType"}
                           checked={trainType === 0}
                           value={0}
                           disabled={(selectedPackageItem.category === 1 && selectingFromPackage) || (selectedCreditItem.serviceCategory === 1 && selectingFromCancellation)}

                           onChange={(e) => {
                             setTrainType(Number(0));
                             setSelectedDiscipline({ id: -1, value: "" });
                             setDuration(-1);
                             setServiceType(-1);
                             selectedServiceByID(-1);
                             dispatch(eraseSelectedTime());


                           }}
                    />
                    <label htmlFor="privateSession">{t("privateSession")}</label>
                    <input type="radio" id={"smallGroup"} name={"trainType"}
                           disabled={(selectedPackageItem.category === 0 && selectingFromPackage) || (selectedCreditItem.serviceCategory === 0 && selectingFromCancellation)}
                           checked={trainType === 1}
                           value={1}
                           onChange={(e) => {
                             setTrainType(Number(1));
                             selectedServiceByID(-1);
                             setSelectedDiscipline({ id: -1, value: "" });
                             setDuration(-1);
                             setServiceType(-1);
                             dispatch(eraseSelectedTime());


                           }}
                    />
                    <label htmlFor="smallGroup">{t("Small Group")}</label>
                  </div>
                </div>
                <div className={`form-steps ${serviceType !== -1 ? "done" : "active"}`}>2</div>
                <div className={"empty"} />
                <div className={"vl-container"}>
                  <span className={`vl ${serviceType !== -1 ? "done-line" : ""} `} />
                </div>
                {trainType === 0 ? <>
                    <div className={"serviceType"}>
                      <p>{t("service type")}</p>
                      <div className="form-row ">
                        <select disabled={selectingFromCancellation} name="serviceType" id="serviceTypeSelection"
                                value={serviceType}
                                onChange={(e) => {
                                  setServiceType(Number(e.target.value));
                                  setSelectedDiscipline({ id: -1, value: "" });
                                  setDuration(-1);
                                  selectedServiceByID(-1);
                                  if (Number(e.target.value) === 1) {
                                    setAddress(publicTrainerData.trainerWorkoutAddress);
                                    setCity(publicTrainerData.trainerWorkoutCity);
                                    setHouseNumber(publicTrainerData.trainerWorkoutHouseNumber);
                                    setPostalCode(publicTrainerData.trainerWorkoutPostalCode);
                                    setCountry(Number(publicTrainerData.trainerWorkoutCountry));
                                  } else {
                                    setAddress("");
                                    setCity("");
                                    setHouseNumber("");
                                    setPostalCode("");
                                    setCountry(4);
                                  }


                                }}>

                          {/*     {getServiceTypeArray().map((e: number) => {
                                                if (e === 0) {
                                                    return (<option key={e} value={e}>{virtual}</option>)
                                                } else if (e === 1)
                                                    return (<option key={e} value={e}>{onSiteCoach}</option>)
                                                else if (e === 2)
                                                    return (<option key={e} value={e}>{onSiteClient}</option>)


                                            })}*/}
                          <option value={-1}>{selectServiceType}</option>
                          {publicTrainerData.isVirtual && !selectingFromPackage ?
                            <option value={0}>{virtual}</option> : null}
                          {publicTrainerData.isVirtual && selectingFromPackage && selectedPackageItem?.serviceTypes.includes(0) ?
                            <option value={0}>{virtual}</option> : null}

                          {publicTrainerData.onSiteCoach && !selectingFromPackage ?
                            <option value={1}>{onSiteCoach}</option> : null}
                          {publicTrainerData.onSiteCoach && selectingFromPackage && selectedPackageItem?.serviceTypes.includes(1) ?
                            <option value={1}>{onSiteCoach}</option> : null}

                          {publicTrainerData.onSiteClient && !selectingFromPackage ?
                            <option value={2}>{onSiteClient}</option> : null}
                          {publicTrainerData.onSiteCoach && selectingFromPackage && selectedPackageItem?.serviceTypes.includes(2) ?
                            <option value={2}>{onSiteClient}</option> : null}
                        </select>
                      </div>
                      {serviceType === 2 ? (<ServiceTypeOnSiteClient
                        address={address}
                        setAddress={setAddress}
                        city={city}
                        setCity={setCity}
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        country={country}
                        setCountry={setCountry}
                        houseNumber={houseNumber}
                        setHouseNumber={setHouseNumber}
                        setSelectedDiscipline={setSelectedDiscipline}
                        setDuration={setDuration}
                      />) : (null)}
                      {serviceType === 1 ? (<ServiceTypeOnSiteCoach
                        address={address}
                        setAddress={setAddress}
                        city={city}
                        setCity={setCity}
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        country={country}
                        setCountry={setCountry}
                        houseNumber={houseNumber}
                        setHouseNumber={setHouseNumber}
                      />) : (null)}
                    </div>
                    <div
                      className={`form-steps ${selectedDiscipline.id !== -1 ? "done" : serviceType !== -1 ? "active" : ""}`}>3
                    </div>
                    <div className={"empty"} />
                    <div className={"vl-container  "}>
                      <span className={`vl ${selectedDiscipline.id !== -1 ? "done-line" : ""} `} />
                    </div>
                    <div className={"chooseDiscipline"}>
                      <p>{t("chooseService")}</p>
                      <div className="form-row checkbox-row">
                        {serviceArray !== null && serviceArray.length >= 0 ? serviceArray.map((e: any) => {
                          return (
                            <Discipline
                              disabled={serviceType === -1 || (selectingFromCancellation && (e.id !== selectedCreditItem.service.id))}
                              key={e.id}
                              selectedDiscipline={selectedDiscipline}
                              setSelectedDiscipline={setSelectedDiscipline}
                              value={e.name}
                              id={e.id}
                              selectedServiceByID={selectedServiceByID}
                              setDuration={setDuration}
                            />
                          );
                        }) : null}
                      </div>
                    </div>
                    <div
                      className={`form-steps ${duration !== -1 ? "done" : selectedDiscipline.id !== -1 ? "active" : ""}`}>4
                    </div>
                    <div className={"empty"} />
                    <div className={"vl-container"}>
                      <span className={`vl ${duration !== -1 ? "done-line" : ""} `} />
                    </div>
                    <div className={"duration"} onFocus={() => getDurationArray()}>
                      <p>{t("duration")}</p>
                      <div className="form-row">
                        <div
                          className="duration-text"> {selectedDiscipline.id !== -1 && `${duration} ${t("minutes")}`}</div>
                      </div>
                    </div>
                    <div></div>
                    <div className="priceAndNext">

                      {!selectingFromCancellation && !selectingFromPackage && <p
                        className="amount-container">{t("session price")}: {selectedForBooking.virtualFee > -1 && selectedForBooking.onsiteFee > -1 ? serviceType === 0 ? selectedForBooking.virtualFee : selectedForBooking.onsiteFee : "0 "}{currencySymbol}
                      </p>}
                      <button type={"submit"} disabled={duration === -1}>{t("next")}
                      </button>
                    </div>
                  </> :
                  (
                    <BookingSmallGroupCalendar serviceArray={serviceArray}
                                               getDisciplinesStringFromArray={getDisciplinesStringFromArray}
                                               updateFilter={updateFilter} />
                  )


                }
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerBookingPage;
