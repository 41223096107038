import { Dispatch } from "react";
import i18n from "i18next";
import { DateTime } from "luxon";
import { setIsLoading } from "../layout/layoutAction";

export const getCalendar = () => async (dispatch: Dispatch<any>) => {
  let url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/dayofweek`;

  dispatch({
    type: "setDayOfWeekLoader",
    payload: true
  });

  dispatch(setIsLoading(true));


  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

      accept: "text/plain",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  });
  const data = await response.json();
  let helperObject: any = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };
  //.filter((s: any) => s.dayOfWeek === 0)
  data.forEach((item: any) => {
    helperObject[item.dayOfWeek].push({
      id: item.id,
      dayOfWeek: item.dayOfWeek,
      startTime: item.startTime,
      endTime: item.endTime,
      allowedVirtual: item.allowedVirtual,
      allowedOnSite: item.allowedOnSite,
      isUpdate: true
    });
  });
  dispatch({
    type: "getWeeklyRoutine",
    payload: [helperObject]
  });
  dispatch({
    type: "setDayOfWeekLoader",
    payload: false
  });
  setTimeout(() => dispatch(setIsLoading(false)),1000)
};

export const addDayOfWeekToArray = (object: any) => {
  return {
    type: "addDayOfWeekToArray",
    payload: object
  };
};

export const setWeeklyView = (startDate="", endDate ="" ) => async (dispatch: Dispatch<any>) => {
  const url = `${process.env.REACT_APP_BE_BASEURL}/api/calendar${startDate !== "" && endDate !== "" ? `?startDate=${startDate}&endDate=${endDate}` : ""}`;
  //startDate=2022-03-04T22%3A22%3A22&endDate=2022-03-04T22%3A22%3A22

  let helperArray: Array<any> = [];

  dispatch(setIsLoading(true));

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

      "Content-Type": "text/plain",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  });

  const res = await response.json();
  setTimeout(() => dispatch(setIsLoading(false)),1000)

  res.forEach((res: any) => {
    if (res.booking !== null && res.smallGroupService === null) {
      helperArray.push({
        start: res.startDate,
        startDate: res.startDate,
        end: res.endDate,
        endDate: res.endDate,
        customer: res.booking.customer,
        id: res.id,
        title: res.booking.service.name,
        serviceType: res.booking.serviceType,
        category: res.booking.serviceCategory,
        durationSession: res.booking.durationInMinutes,
        description: res.booking.service.description,
        difficultyLevel: res.booking.service.difficultyLevel,
        bookingId: res.booking.id,
        booking: res.booking,
      });

    }
    //TODO: Show small group bookings and recurring sessions
    else if (res.booking === null && res.smallGroupService !== null) {
      helperArray.push({
        id: res.id,
        start: DateTime.fromISO(res.startDate).toLocal().toISO(),
        startDate: res.startDate,
        end: res.endDate,
        endDate: res.endDate,
        serviceId: res.smallGroupService.id,
        name: res.name,
        title: res.smallGroupService.name,
        serviceType: res.smallGroupService.serviceTypes[0],
        isRecurring: res.smallGroupService.isRecurring,
        description: res.smallGroupService.description,
        category: res.smallGroupService.category,
        difficultyLevel: res.smallGroupService.difficultyLevel,
        maximumParticipants: res.smallGroupService.maximumParticipants,

        durationSession: res.smallGroupService.durationInMinutes[0]

      });
    } else {
      helperArray.push({
        start: res.startDate,
        end: res.endDate,
        id: res.id,
        name: res.name,
        title: res.name,
        serviceType: -1,
        category: -1,
        durationSession: -1
      });
    }
  });
  dispatch({
    type: "setWeeklyView",
    payload: helperArray
  });
};

export const setSelectedCalendarItem = (payload: any) => {
  return {
    type: "setSelectedCalendarItem",
    payload: payload
  };
};

export const getOverrideData = (date: string) => async (dispatch: Dispatch<any>) => {

  let url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/dateoverride/${date}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

      accept: "text/plain",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  });

  if (response.ok) {
    const res = await response.json();

    dispatch({
      type: "getOverrideData",
      payload: res
    });

  }
};

export const cancelBooking = (bookingId: string) => async (dispatch: Dispatch<any>) => {

  let url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/${bookingId}/trainer-cancels-booking`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

      accept: "text/plain",
      Authorization: "Bearer " + localStorage.getItem("token")
    }
  });

  dispatch({
    type: "setSelectedCalendarItem",
    payload: { id: -1 }
  });

  if (response.ok) {
    dispatch({
      type: "removedCancelledBooking",
      payload: { bookingId }
    });
  }
};

export const cancelGroupSession = (calendarId: Number) => async (dispatch: Dispatch<any>) => {
  if (calendarId !== null) {

    let url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/trainer-cancel-group/${calendarId}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        accept: "text/plain",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });

    dispatch({
      type: "setSelectedCalendarItem",
      payload: { id: -1 }
    });



  }


};
