import { currencySymbolEnum, feeTypeValue } from "../../../enums/enum";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropSelectedTime, updateSelectedTime } from "../../../redux/actions/booking/bookingAction";
import { ReactComponent as DeleteXSVG } from "./../../../images/component_svg/deleteX.svg";
import { ReactComponent as QuestionSVG } from "./../../../images/component_svg/Question.svg";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

const OrderItem = ({ item }: any) => {
    const selectedService = useSelector(state => state.businessCenter.services.selectedForBooking);
    const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
    const travelData = useSelector(state => state.booking.travelData);
    const selectedPackageItem = useSelector(state => state.customer.selectedPackageItem);
    const selectedTime = useSelector(state => state.booking.selectedTime);
    const recalculation = useSelector(state => state.booking.recalculation);
    const isRecalculate = useSelector(state => state.booking.isRecalculate);
    const selectedCreditItem  = useSelector(state => state.customer.selectedCreditItem);
    const [travelExpenses, setTravelExpenses] = useState<number>(0);
    const [servicePrice, setServicePrice] = useState<number>(0);
    const [serviceFee, setServiceFee] = useState<number>(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const initCalculation = () => {
      if (recalculation.length === 0) {
        if (selectedService.category === 0) {
          if (selectedService.selectedServiceType === 0) {
            setServicePrice(selectedService.virtualFee);
          } else {
            setServicePrice(selectedService.onsiteFee);
          }
        } else {
          setServicePrice(item.service.groupPrice);
        }

        if (selectedService.selectedServiceType === 2) {
          getTravelExpenses();
        }

        // FeeType == 0 -> no fees
        // FeeType == 1 -> PaidByTrainer
        // FeeType == 2 -> PaidByCustomer
        // FeeType == 3 -> Shared
        if (selectedPackageItem.id === -1) {
          if (publicTrainerData.feeType === feeTypeValue.NoFees || publicTrainerData.feeType === feeTypeValue.PaidByTrainer) {
            setServiceFee(0);
          } else if (publicTrainerData.feeType === feeTypeValue.PaidByCustomer) {
            setServiceFee((servicePrice + travelExpenses) * publicTrainerData.feeFactor);
          } else if (publicTrainerData.feeType === feeTypeValue.Shared) {
            setServiceFee((servicePrice + travelExpenses) * (publicTrainerData.feeFactor / 2));
          }
        } else {
          if (publicTrainerData.feeType === feeTypeValue.NoFees || publicTrainerData.feeType === feeTypeValue.PaidByTrainer) {
            setServiceFee(0);
          } else if (publicTrainerData.feeType === feeTypeValue.PaidByCustomer && travelExpenses > 0) {
            setServiceFee((travelExpenses) * publicTrainerData.feeFactor);
          } else if (publicTrainerData.feeType === feeTypeValue.Shared && travelExpenses > 0) {
            setServiceFee((travelExpenses) * (publicTrainerData.feeFactor / 2));
          }
        }
      }
    };

    useEffect(() => {
      initCalculation();

      if (selectedPackageItem.id === -1) {
        if (selectedService.selectedServiceType === 2) {
          const total = serviceFee + travelExpenses + servicePrice;
          dispatch(updateSelectedTime({
            startDate: item.startDate,
            totalPrice: total,
            serviceFee: serviceFee,
            servicePrice: servicePrice,
            travelExpense: travelExpenses,
            packageId: -1
          }));
        } else if (selectedCreditItem.id) {
          dispatch(updateSelectedTime({
            startDate: item.startDate,
            totalPrice: 0,
            serviceFee: 0,
            servicePrice: 0,
            travelExpense: 0,
            packageId: selectedPackageItem.packageId
          }));
        } else {
          const total = serviceFee + servicePrice;
          dispatch(updateSelectedTime({
            startDate: item.startDate,
            totalPrice: total,
            serviceFee: serviceFee,
            servicePrice: servicePrice,
            travelExpense: 0,
            packageId: -1
          }));

        }
      } else {

        if (selectedCreditItem.id) {
          dispatch(updateSelectedTime({
            startDate: item.startDate,
            totalPrice: 0,
            serviceFee: 0,
            servicePrice: 0,
            travelExpense: 0,
            packageId: selectedPackageItem.packageId
          }));
        } else if (selectedService.selectedServiceType === 2) {
          getTravelExpenses().then(() => {
            const total = travelExpenses + (travelExpenses * publicTrainerData.feeFactor);
            dispatch(updateSelectedTime({
              startDate: item.startDate,
              totalPrice: total,
              serviceFee: travelExpenses * publicTrainerData.feeFactor,
              servicePrice: 0,
              travelExpense: travelExpenses,
              packageId: selectedPackageItem.packageId
            }));
          });
        } else if (selectedService.selectedServiceType !== 2) {
          dispatch(updateSelectedTime({
            startDate: item.startDate,
            totalPrice: 0,
            serviceFee: 0,
            servicePrice: 0,
            travelExpense: 0,
            packageId: selectedPackageItem.packageId
          }));
        }
      }
    }, [travelExpenses, servicePrice, serviceFee, isRecalculate, publicTrainerData]);

    useEffect(() => {
      recalculation.forEach((calculation: any) => {
        if (DateTime.fromISO(calculation.startTime) === DateTime.fromISO(item.startDate)) {
          setTravelExpenses(calculation.travelExpense);
          setServiceFee(calculation.serviceFee);
          setServicePrice(calculation.serviceAmount);
        }
      });
    }, [recalculation]);

    const dropOrderItem = () => {
      dispatch(dropSelectedTime(item.startDate));
    };

    const getTravelExpenses = async () => {
      setTravelExpenses(publicTrainerData.clientWorkoutTravelExpense * (travelData.returnJourneyDuration + travelData.outwardJourneyDuration));
    };

    const currencySymbol = Object.values(currencySymbolEnum)[publicTrainerData.currency];

    return (
      <div className="order-item-group">
        <DeleteXSVG onClick={dropOrderItem} />
        <div className="order-item">
          <p>
            {/* {selectedService.category === 0 ?
            <p>{t("personalTrainingSession")}</p> :
            <p>{t("smallGroupSession")}</p> */}
            {item.serviceName || selectedService.name}
            {",\t"}<span style={{whiteSpace: "nowrap"}}>{selectedService.category === 1 ? item.service.durationInMinutes[0] : selectedService.selectedDuration} {t("minutes")}</span>,{"\t"}
           <p> {selectedService.selectedServiceType === 0 ?
              <span style={{whiteSpace: "nowrap"}} >{t("virtual")},</span> : null}
            {selectedService.selectedServiceType === 1 ?
              <span style={{whiteSpace: "nowrap"}}>{t("on site coach")},</span> : null}
            {selectedService.selectedServiceType === 2 ?
              <span style={{whiteSpace: "nowrap"}}>{t("At my place")},</span> : null} <span style={{whiteSpace: "nowrap"}}>{DateTime.fromISO(item.startDate).toFormat("dd.MM.yyyy")} | {DateTime.fromISO(item.startDate).toFormat("HH:mm")}</span></p>
          </p>
          <p>{selectedTime[0]?.packageId === -1 ? selectedCreditItem.id ? 0 : servicePrice.toFixed(2) : 1} {selectedTime[0]?.packageId === -1 ?
            <span>{currencySymbol}</span> : t("credit")}</p>
        </div>

        {selectedService.selectedServiceType === 2 ? <div className="order-item">
          <p>{t("travel expenses")} <span className={"info"}>
            <span className="messageContainer">{t("Traffic jams suck. It takes time for your coach to get to your place for a workout. For this, your coach will be compensated accordingly through a live time travel charge.")}</span>
            <QuestionSVG /></span></p>
          <p>{travelExpenses.toFixed(2)} {currencySymbol}</p>
        </div> : null}

        {selectedTime[0].packageId === -1 && serviceFee > 0 && !selectedCreditItem.id ?
          <div className="order-item">
            <p>{t("service fee")} {/*{feeTypeEnum[publicTrainerData.feeType]} */}<span className={"info"}>
            <span className="messageContainer">{t("Revenue from this fee allows us to power your experience and support your coach")}</span>
              <QuestionSVG /></span>
            </p>
            <p className={"price"}>{serviceFee.toFixed(2)} {currencySymbol}</p>
          </div>
          : null}

        {selectedTime[0].packageId != -1 && selectedService.selectedServiceType === 2 && !selectedCreditItem.id ?
          <div className="order-item">
            <p>{t("service fee")} {/*{feeTypeEnum[publicTrainerData.feeType]}*/} <span className={"info"}>
            <span className="messageContainer">{t("Revenue from this fee allows us to power your experience and support your coach")}</span>
            <QuestionSVG />
            </span>
            </p>
            <p className={"price"}>{serviceFee.toFixed(2)} {currencySymbol}</p>
          </div>
          : null}
      </div>
    );
  }
;
export default OrderItem;
