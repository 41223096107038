import i18n from "i18next";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { emailRegex, PlatformType } from "../../enums/enum";
import { ReactComponent as CongratiolationSvg } from "../../images/component_svg/Congratulations.svg";
import mailSVG from "../../images/component_svg/mail.svg";
import { loadCustomerData } from "../../redux/actions/customer/customerAction";
import { setTrainerData } from "../../redux/actions/registration/OnboardingAction";
import AuthContext from "./auth-context";

type IModalContext = {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  modalType: number;
  setModalType: Dispatch<SetStateAction<number>>;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  confirmationCallback: {
    cb: () => void;
  };
  setConfirmationCallback: Dispatch<SetStateAction<{
    cb: () => void;
  }>>;
  refundId: number;
  setRefundId: Dispatch<SetStateAction<number>>;
}

const defaultContextState: IModalContext = {
  isActive: false,
  setIsActive: () => {},
  modalType: 0,
  setModalType: () => {},
  message: 'message',
  setMessage: () => {},
  confirmationCallback: {
    cb: () => {},
  },
  setConfirmationCallback: () => {},
  refundId: 0,
  setRefundId: () => {},
};

const ModalContext = createContext<IModalContext>(defaultContextState);

export function ModalProvider({ children }: React.PropsWithChildren<{}>) {
  const [modalType, setModalType] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("message");
  const [refundId, setRefundId] = useState<number>(0);

  const [confirmationCallback, setConfirmationCallback] = useState({
    "cb": () => {
    }
  });

  /*  useEffect(() => {
      if (!isActive) {
        setModalType(0);
      }
    });*/

  return (
    <ModalContext.Provider
      value={{
        isActive,
        setIsActive,
        modalType,
        setModalType,
        message,
        setMessage,
        confirmationCallback,
        setConfirmationCallback,
        refundId,
        setRefundId

      }}
    >
      <div>
        <div className="contextModal" style={isActive ? { display: "" } : { display: "none" }}>
          <div className="blurBackground" onClick={() => setIsActive(false)} />
          {modalType === 0 ? (<InfoModal setIsActive={setIsActive} message={message} />) : null}
          {modalType === 1 ? <LoginModal isActive={isActive} setIsActive={setIsActive} /> : null}
          {modalType === 2 ? <ConfirmationModal confirmationCallback={confirmationCallback} message={message}
                                                setIsActive={setIsActive} /> : null}
          {modalType === 4 ?
            <CustomRefundModal refundId={refundId} message={message} setIsActive={setIsActive} /> : null}
          {modalType === 3 ? <CongratulationsModal setIsActive={setIsActive} /> : null}
        </div>
        {children}
      </div>
    </ModalContext.Provider>
  );
}

const InfoModal = ({
                     setIsActive,
                     message
                   }: {
  setIsActive: Dispatch<SetStateAction<boolean>>;
  message: string;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="infoModal">
      <h5>{message?.length > 0 ? message : t("Oops, an error occured")}</h5>
      <button onClick={() => setIsActive(false)}>ok</button>
    </div>
  );
};

const ConfirmationModal = ({
                             setIsActive,
                             message,
                             confirmationCallback
                           }: {
  setIsActive: Dispatch<SetStateAction<boolean>>;
  message: string;
  confirmationCallback: { "cb": () => void },
}) => {
  const { t } = useTranslation();

  return (
    <div className="infoModal confirmationModal">
      <h5>{message.length > 0 ? message : "Error"}</h5>
      <div className="infoModal-options">

        <button className="confirmationDecline" onClick={(e) => {
          e.preventDefault();
          confirmationCallback["cb"]?.();
          setIsActive(false);
        }}>{t("yes, please")} </button>
        <button className="confirmationDecline" onClick={() => setIsActive(false)}>{t("no, thanks")}</button>

      </div>
    </div>
  );
};

const CustomRefundModal = ({
                             setIsActive,
                             message,
                             refundId
                           }: {
  setIsActive: Dispatch<SetStateAction<boolean>>;
  message: string;
  refundId: number,
}) => {
  const { t } = useTranslation();
  const modalCtx = useContext<any>(ModalContext);
  const [amount, setAmount] = useState<number>(0);

  const triggerCustomRefund = async () => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/Payments/trainer-custom-refund?bookingId=${refundId}&refundAmount=${amount}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      const res = await response.json();

      if (response.ok) {
        modalCtx.setModalType(0);
        modalCtx.setMessage(res.message);
        modalCtx.setIsActive(true);

      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("error"));
        modalCtx.setIsActive(true);
      }
    } catch (error) {

    }
  };
  return (
    <div className="infoModal confirmationModal">
      <h5>{message.length > 0 ? message : "Bitte Tragen sie den gewünschten Rückzahlungsbetrag ein."}</h5>
      <div className="form-group">
        <label htmlFor={"amount"}>{t("amount")}</label>
        <input
          type="text"
          name="amount"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          placeholder={t("amount")}
        />
      </div>
      <div className="infoModal-options">

        <button className="confirmationDecline" onClick={(e) => {
          e.preventDefault();
          triggerCustomRefund();
          setIsActive(false);
        }}>{t("submit")} </button>
        <button className="confirmationDecline" onClick={() => setIsActive(false)}>{t("cancel")}</button>

      </div>
    </div>
  );
};


const CongratulationsModal = ({ setIsActive }: { setIsActive: Dispatch<SetStateAction<boolean>>; }) => {
  const { t } = useTranslation();

  return (
    <div className="infoModal CongratulationsModal">
      <h3>{t("thankYou")}</h3>
      <CongratiolationSvg />
      <h5>{t("Congratulations")}</h5>
    </div>
  );
};

const LoginModal = ({
                      setIsActive,
                      isActive
                    }: {
  setIsActive: Dispatch<SetStateAction<boolean>>;
  isActive: boolean,
}) => {

  useEffect(() => {
    setForgotPwdModal(false);

    setEmailSentMessage(false);
  }, [isActive]);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch<any>();
  const modalCtx = useContext<any>(ModalContext);
  const { t } = useTranslation();

  const [isForgotPwdModal, setForgotPwdModal] = useState(false);
  const [emailErr, setEmailErr] = useState<string>("");
  const [emailSentMessage, setEmailSentMessage] = useState<boolean>(false);
  const publicTrainerData = useSelector(
    (state) => state.onboarding.publicTrainerData);

  const onSubmitReset = async () => {
    if (!email) {
      setEmailErr(t("email is required"));
      return;
    }
    if (!email.match(emailRegex)) {
      setEmailErr(t("errEmailInvalid"));
      return;
    }
    setEmailErr("");


    const url = `${process.env.REACT_APP_BE_BASEURL}/api/User/forgotPassword`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email })
    });
    const { message } = await response.json();

    if (response.ok) {
      setEmailSentMessage(message);
    }else{
      modalCtx.setModalType(0);
      modalCtx.setMessage(message);
      modalCtx.setIsActive(true);
    }
  };
  const onSubmitLogin = async (e: any) => {
    e.preventDefault();

    if (!email) {
      setEmailErr(t("email is required"));
      return;
    }
    if (!email.match(emailRegex)) {
      setEmailErr(t("errEmailInvalid"));
      return;
    }

    const url = `${process.env.REACT_APP_BE_BASEURL}/api/User/token`;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
        returnSecureToken: true
      }),
      headers: {
        "Content-Type": "application/json",
        "PlatformType": PlatformType.Web,
      }
    });
    const data = await response.json();

    if (response.ok) {
      let expirationTime = new Date();
      expirationTime.setHours(expirationTime.getHours() + 43200);
      setIsActive(false);
      const userData = {
        userId: data.userId,
        email: data.email,
        role: data.roles[0],
        userName: data.userName
      };
      // @ts-ignore
      authCtx.login(data.token, userData, expirationTime.toISOString());
      if (userData.role === "Trainer") {
        dispatch(setTrainerData());
      } else if (userData.role === "User") {
        dispatch(loadCustomerData());
      }
    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(data.message);
      modalCtx.setIsActive(true);
      let errorMessage = data.message;
      throw new Error(errorMessage);
    }
  };

  return (
    <div className="loginModal">
      <h5>{!isForgotPwdModal ? t("Login") : emailSentMessage ? t("instructionSent") : t("Password reset")}</h5>
      {!isForgotPwdModal ? (<form onSubmit={onSubmitLogin}>
          <div className="form-group">
            <label htmlFor={"email"}>
              {t("email")}
            </label>
            <input
              value={email}
              id={"email"}
              type="email"
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailErr ? <div className="error">{emailErr}</div> : null}
          </div>
          <div className="form-group">
            <label htmlFor={"password"}>
              {t("password")}
            </label>
            <input
              value={password}
              id={"password"}
              type="password"
              required={true}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="forgotPassword">
            <p
              onClick={() => setForgotPwdModal(true)}
            >
              {t("Forgot your password?")}
            </p>
          </div>

          <button type={"submit"}>
            {t("signIn")}
          </button>

          {window.location.pathname.split("/")[3] === "trainer-bookingTwo" ?
          <div className="login-popup-not-registered" onClick={() => setIsActive(false)}>
            <a className="login-popup-register-link" href="#bookingSignUp">{t("Not registered yet?")}</a>
            <a className="login-popup-register-link" href="#bookingSignUp">{t("Not registered yet line2 word1")} <span style={{color:"yellow"}}> {" " + t("Not registered yet line2 word2") + " "} </span> {t("Not registered yet line2 word3")}</a>
          </div>
          :
          <div className="login-popup-not-registered" onClick={() => setIsActive(false)}>
            <a className="login-popup-register-link" href={"/me/" + publicTrainerData.trainerSpace.spaceUrl + "/trainer-booking"}>{t("Not registered yet?")}</a>
            <a className="login-popup-register-link" href={"/me/" + publicTrainerData.trainerSpace.spaceUrl + "/trainer-booking"}>{t("Please book your first session.")}</a>
          </div>
          }
        </form>) :

        emailSentMessage ? (
            <div className="loginInstructions">
              <img src={mailSVG} alt="" />
              <p>{t("instructionsHowToResetPassword")}</p>
              <p>{email}</p>

              <button onClick={() => onSubmitReset()}>
                {t("resendInstruction")}
              </button>
            </div>
          ) :
          (
            <div className="resetPasswordContainer">
              <div className="form-group">
                <label htmlFor="password">
                  {t("enter email")}{" "}
                </label>
                <input
                  type="email"
                  id={"password"}
                  required={true}
                  value={email}
                  placeholder={t("enter email")}
                  onChange={(e) =>
                    setEmail(e.target.value)
                  }
                />
                {emailErr ? <div className="error">{emailErr}</div> : null}
              </div>
              <button onClick={() => onSubmitReset()}>
                {t("send instructions to this mail")}
              </button>
            </div>
          )}
    </div>
  );
};


export default ModalContext;
