import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { setIsLoading } from "../layout/layoutAction";


export const loadCustomerData = (customerID  : number = -1) => async (dispatch: Dispatch<any>) => {

  if (customerID !== -1 || customerID !== null) {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/customers/user`;
    dispatch(setIsLoading(true));

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        accept: "text/plain",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    });
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response.ok) {
      const res = await response.json();

      dispatch({
        type: "setCustomerObject",
        payload: { ...res.data }
      });
    }
  }
};


export const loadCustomerPackages = (customerId?: number) => async (dispatch: Dispatch<any>) => {

  if (customerId !== -1) {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/packages/customer`;
    dispatch(setIsLoading(true));

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      if (response.ok) {
        const res = await response.json();
        let resData = res.data;
        dispatch({
          type: "loadCustomerPackages",
          payload: (resData)
        });
      }
    } catch (error) {
    }
  }
};

export const loadUpcomingSessions = (userId: number) => async (dispatch: Dispatch<any>) => {

  if (userId !== -1) {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/upcoming?customerId=${userId}`;
    dispatch(setIsLoading(true));

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      if (response.ok) {
        const res = await response.json();
        dispatch({
          type: "loadUpcomingSessions",
          payload: res
        });
      }
    } catch (error) {
    }
  }
};

export const loadCancelledBookings = (userId: number) => async (dispatch: Dispatch<any>) => {


  if (userId !== -1) {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/cancelled-bookings`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });

      if (response.ok) {
        const res = await response.json();
        dispatch({
          type: "loadCancelledBookings",
          payload: res
        });
      }
    } catch (error) {
    }
  }
};


export const setSelectedPackageItemForBooking =  (payload : any) => {
  return {
    type: "setSelectedPackageItem",
    payload: payload
  }
}
export const setSelectedCreditItemForBooking =  (payload : any) => {
  return {
    type: "setSelectedCreditItem",
    payload: payload
  }
}

export const eraseSelectedPackageItemForBooking = () => {
  const payload = {
    id: -1,
    category: -1,
    name: "",
    description: "",
    serviceTypes:[],
    disciplines: [],
    durationInMinutes: [],
    onsiteFee: -1,
    virtualFee: -1,
    enabled: false,
    bookings: [],
    groupPrice: -1,
    date: "",
    startTime: "",
    maximumParticipants: -1,
    maximumParticipantsInWaitingList: -1,
    difficultyLevel: -1,
    dayOfWeeks: [],
    weekRepeats: -1,
    location: "",
    address: "",
    postalCode: -1,
    city: "",
    country: -1,
    houseNumber: ""
  }
  return {
    type: "setSelectedPackageItem",
    payload: payload
  }
}
