import React from "react";

const StartingPageContent = () => {

  return (
    <section className={'startingPageContent'} >
        <div className="skulp_background_init_page">
            <div className="image"/>
        </div>
    </section>
  );
};

export default StartingPageContent;
