import './TrainerReviewCard.style.scss';
import { ReactComponent as StarFilledSvg } from "../../../images/component_svg/report_start_fill.svg";
import { ReactComponent as StarNotFilledSvg } from "../../../images/component_svg/report_star_not_filled.svg";
import React from "react";
import { reviewItem } from "./index";

const TrainerReviewCard = ({ review }: { review: reviewItem }) => {


  const profilePicturePlaceholder =
    "https://intern-creativemindz.de/wp-content/uploads/user3.svg";
  return (
    <div className="card trainer-review-card  ">
      <div className="reviewContent">
        <div className="customerDetails">
          <img src={review.customerProfilePictureUri !== "" ?  `${process.env.REACT_APP_BE_BASEURL}/api/files/${review.customerProfilePictureUri}/download` : profilePicturePlaceholder} className="avatar" alt="..." />
          <div className="nameAndRating">
            <p className="card-text review-heading">{review.headline}</p>
            <p className="username">{review.customerFirstName} {review.customerLastName}</p>
              <div className="start_row">
                {review.rating >= 1 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                {review.rating >= 2 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                {review.rating >= 3 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                {review.rating >= 4 ? <StarFilledSvg /> : <StarNotFilledSvg />}
                {review.rating >= 5 ? <StarFilledSvg /> : <StarNotFilledSvg />}
              </div>
          </div>
        </div>

        <div className="vr"></div>

        <div className="review-text">
          <div className="card-body p-0">
            <p className="card-text review-heading">{review.headline}</p>
            <p className="card-text">{review.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerReviewCard;
