import { range } from "../../../utility/hooks/Utils";
import React, { useEffect, useState } from "react";
import { ServiceArrayType } from "../../../redux/reducers/businessCenter/businessCenterReducer";
import { useDispatch } from "react-redux";
import {
  addServiceCountList,
  dropServiceCountList,
  editServiceCountList,
} from "../../../redux/actions/packages/packagesAction";
import { useTranslation } from "react-i18next";
import { PackageLimit } from "../../../enums/enum";

const SelectableDiscipline = ({ item }: { item: ServiceArrayType }) => {
  const [isActive, setIsActive] = useState(false);
  const [count, setCount] = useState<string>("1");

  const { t } = useTranslation();
  const unlimited = t("unlimited");
  const errCount = t("errCount");
  const dispatch = useDispatch();

  useEffect(() => {
    if (isActive) {
      dispatch(
        addServiceCountList({
          serviceId: item.id,
          count: count,
        })
      );
    } else if (!isActive) {
      dispatch(
        dropServiceCountList({
          serviceId: item.id,
          count: count,
        })
      );
    }
  }, [isActive]);

  return (
    <div className="selectableDiscipline">
      <div className="checkbox_pink">
        <input
          type="checkbox"
          id={item.id.toString()}
          checked={isActive}
          onChange={(event) => setIsActive(event.target.checked)}
        />
        <label htmlFor={item.id.toString()}></label>
        <p>{item.name}</p>
      </div>
      <select
        disabled={!isActive}
        value={count}
        onChange={(e) => {
          setCount(e.target.value);
          dispatch(
            editServiceCountList({
              serviceId: item.id,
              count: Number(e.target.value) ? Number(e.target.value) : null,
            })
          );
        }}
      >
        {range(PackageLimit + 1).map((item) =>
          item !== 0 ? (
            <option key={item} value={item}>
              {item}
            </option>
          ) : null
        )}
        <option value={"null"}>{unlimited}</option>
      </select>
      
    </div>
  );
};

export default SelectableDiscipline;
