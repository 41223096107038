import React, {useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeNavigation, setIsLoading } from "../../redux/actions/layout/layoutAction";
import { useTranslation } from "react-i18next";
import i18n, { changeLanguage } from "i18next";
import { useHistory, useLocation } from "react-router-dom";

const ExternalStatusPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [statusMessage, setStatusMessage] = useState<string>("");
  const history = useHistory();

  const getStatusMessage = async (statusCode: string | null) => {

    if (statusCode !== null) {
      const url = `${process.env.REACT_APP_BE_BASEURL}/api/user/status-message?statusCode=${statusCode}`;
      dispatch(setIsLoading(true));
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          accept: "text/plain",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      });
      const res = await response.json();

      if (response.ok) {
        setStatusMessage(res.message);
        setTimeout(() => dispatch(setIsLoading(false)), 400);
      } else {
        setTimeout(() => dispatch(setIsLoading(false)), 400);
      }
    }
  };
  useEffect(() => {
    if(localStorage.getItem("language")){
      changeLanguage(localStorage.getItem("language")!)
    }else{
      localStorage.setItem("language",i18n.language)
    }

    },[])
  const query = useQuery();
  const type = query.get("type");
  const status = query.get("status");
  const returnUrl = query.get("returnUrl");
  const error : number = Number(query.get("error"));


  useEffect(() => {
    dispatch(changeNavigation(-1));
    if( window.location.href.split("&").length !== 3){
      history.replace("/")
    }

    getStatusMessage(status);

   setTimeout(() => window.location.href = returnUrl!, 10000 );
  }, [status,type,returnUrl,error]);

  return (
    <div className="externalStatusPage">
      <div className="initialView">
        <div className="content">
          <div className="card">
            <div className="card_heading"><h1>{error ? t("Operation failed!") : t("The operation was successful!")}</h1>
            </div>
            <div className="cardContent">
              <p>{statusMessage}</p>
              <p >{t("You will be redirected in a few seconds")}</p>
              <p className={"redirectionText"}>{t("if you haven't been redirected in 10 seconds, please click")} <a href={"#"} onClick={() => window.location.href = returnUrl!} >{t("here")}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
export default ExternalStatusPage;
