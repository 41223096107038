import { useState, useEffect, useRef} from "react";
import BusinessCenterServiceCreate from "./BusinessCenterServiceCreate";
import BusinessCenterServiceEnable from "./BusinessCenterServiceEnable";
import BusinessCenterServiceDisable from "./BusinessCenterServiceDisable";
import {useDispatch, useSelector} from "react-redux";
import { resetEditService } from "../../../redux/actions/businessCenter/serviceActions";
import { useTranslation } from "react-i18next";

const BusinessCenterService = () => {
  const [tab, setTab] = useState<number>(0);
  const [sessionType, setSessionType] = useState<number>(0);
  const { t } = useTranslation();


  const editServiceSelector = useSelector(state => state.businessCenter.services.editService)
  const dispatch = useDispatch();
  const prevTabRef = useRef<number>();

  useEffect(() => {
    prevTabRef.current = tab;
  }, [tab]);

  const handleSetTab = (tabNum: number) => {
    if (prevTabRef.current === 0 && editServiceSelector.id !== -1) {
      dispatch(resetEditService())
    }
    setTab(tabNum);
  }

  return (
    <div className="businessCenterService">
      <div className="businessCenterHeader">
        <h1>Services</h1>
        <hr />
      </div>
      <div className="serviceTabs">
        <div
          className={`crateNewServiceTab ${tab === 0 ? "active" : ""}`}
          onClick={() => handleSetTab(0)}
        >
          <p>
            {t(editServiceSelector.id === -1 ? "createService" : "edit service")}
          </p>
        </div>
        <div
          className={`enableServiceTab ${tab === 1 ? "active" : ""}`}
          onClick={() => handleSetTab(1)}
        >
          <p>
            {t("enableService")}
          </p>
        </div>
        <div
          className={`disableServiceTab ${tab === 2 ? "active" : ""}`}
          onClick={() => handleSetTab(2)}
        >
          <p>
            {t("disabledServices")}
          </p>
        </div>
      </div>
      {tab === 0 ? (
        <BusinessCenterServiceCreate
          sessionType={sessionType}
          setSessionType={setSessionType}
        />
      ) : null}

      {tab === 1 ? (
        <BusinessCenterServiceEnable
          sessionType={sessionType}
          setSessionType={setSessionType}
          setTab={setTab}
        />
      ) : null}

      {tab === 2 ? <BusinessCenterServiceDisable /> : null}
    </div>
  );
};
export default BusinessCenterService;
