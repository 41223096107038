import { Dispatch } from "react";
import { DateTime } from "luxon";
import i18n from "i18next";
import { setIsLoading } from "../layout/layoutAction";
import { serializeQueryParams } from '../../../utility/url';

export const getAvailableTimesDuration = (
  trainerUrl: string,
  date: string = "-1",
  service: number = -1,
  duration: number = -1,
  outwardJourneyDuration: number = -1,
  returnJourneyDuration: number = -1
) =>
  async (dispatch: Dispatch<any>) => {

    const regEx = new RegExp(":", "g");
    const refactorDate = date.replace(regEx, "%3A");

    const url = `${
      process.env.REACT_APP_BE_BASEURL
    }/api/bookings/available-times-duration/${trainerUrl}/public?${
      date != "-1" ? `startDate=${refactorDate}` : ""
    }${duration != -1 ? `&duration=${duration}` : ""}${
      service != -1 ? `&service=${service}` : ""
    }${
      outwardJourneyDuration != -1 ? `&outwardJourneyDuration=${outwardJourneyDuration}` : ""
    }${
      returnJourneyDuration != -1 ? `&returnJourneyDuration=${returnJourneyDuration}` : ""
    }`;

    if (trainerUrl !== null && trainerUrl !== "") {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          accept: "text/plain"
          //'Authorization': 'Bearer ' + localStorage.getItem('token')
        }

      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      const data = await response.json();
      dispatch({
        type: "availableTimesDuration",
        payload: [...data]
      });
    }


  };
export const setSelectedTime = (payload: { startDate: string, endDate: string, totalPrice?: number, serviceFee?: number, servicePrice?: number, travelExpense?: number }) => {
  return {
    type: "setSelectedTime",
    payload: payload
  };
};

export const addSelectedTime = (item: { startDate: string, endDate: string, totalPrice?: number, serviceFee?: number, servicePrice?: number, travelExpense?: number, packageId?: number, id?: number }) => {

  return {
    type: "addSelectedTime",
    payload: {
      startDate: item.startDate,
      endDate: item.endDate

    }
  };
};

export const updateSelectedTime = (payload: { startDate: string, totalPrice: number, serviceFee: number, servicePrice: number, travelExpense: number, packageId: number }) => {

  return {
    type: "updateSelectedTime",
    payload: payload
  };
};

export const dropSelectedTime = (payload: string) => {
  return {
    type: "dropSelectedTime",
    payload: payload
  };
};
export const eraseSelectedTime = () => {
  return {
    type: "eraseSelectedTime",
    payload: []
  };
};


export const setTotalPrice = (payload: number) => {

  return {
    type: "setTotalPrice",
    payload: payload
  };

};

export const setServiceFee = (payload: number) => {

};

export const setTravelExpenses = (payload: number) => {

};

export const getBookingPolicy = () => async (dispatch: Dispatch<any>) => {
  if (localStorage.getItem("token")) {
    try {
      const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/allowedtime`;
      dispatch(setIsLoading(true));
      const response = await fetch(url, {
        method: "GET",
        headers: {

          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          accept: "text/plain",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      if (response.ok) {
        const res = await response.json();
        dispatch({
          type: "getBookingPolicy",
          payload: res
        });
      }

    } catch (err) {
    }
  }
};
export const getBookingPolicyPublic = (spaceUrl: string) => async (dispatch: Dispatch<any>) => {
  try {

    const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/allowedtime/${spaceUrl}/public`;
    dispatch(setIsLoading(true));

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        accept: "text/plain",
        "Authorization": "Bearer " + localStorage.getItem("token")
      }
    });
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response.ok) {
      const res = await response.json();
      dispatch({
        type: "getBookingPolicy",
        payload: res
      });
    }

  } catch (err) {
  }
};
export const getTravelData = (trainerId: number, street: string, housenumber: string, city: string, postalcode: string, country: string) => async (dispatch: Dispatch<any>) => {
  try {

    const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/travel-data?trainerId=${trainerId}&Street=${street}&Housenumber=${housenumber}&City=${city}&PostalCode=${postalcode}&Country=${country}`;
    dispatch(setIsLoading(true));

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        accept: "text/plain"
      }
    });
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response.ok) {
      const res = await response.json();
      dispatch({
        type: "getTravelData",
        payload: res
      });
    } else {
      dispatch({
        type: "getTravelData",
        payload: {
          outwardJourneyDuration: -1,
          returnJourneyDuration: -1
        }
      });
    }

  } catch (err) {
  }
};

export const setTravelData = (payload: { outwardJourneyDuration: number, returnJourneyDuration: number }) => {
  return {
    type: "getTravelData",
    payload: payload
  };
};

export const getInvoices = (dateFrom: DateTime, dateTo: DateTime) => async (dispatch: Dispatch<any>) => {
  try {

    const url = `${process.env.REACT_APP_BE_BASEURL}/api/trainers/invoices?dateFrom=${dateFrom.toUTC().toISO()}&dateTo=${dateTo.toUTC().toISO()}`;
    dispatch(setIsLoading(true));

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        accept: "text/plain",
        "Authorization": "Bearer " + localStorage.getItem("token")

      }
    });
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response.ok) {
      const res = await response.json();
      dispatch({
        type: "getInvoices",
        payload: res
      });
    }
  } catch (err) {
  }
};

export const getBookingSmallGroupCalendarItems = (spaceName: string, serviceId = -1, includeDisabled = false) => async (dispatch: Dispatch<any>) => {
  try {
    let params = {
      serviceId: serviceId === -1 ? undefined : serviceId,
      includeDisabled: includeDisabled
    }
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/available-times-small-group/${spaceName}/public?${serializeQueryParams(params)}`;

    if (spaceName !== null) {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          accept: "text/plain",
          "Authorization": "Bearer " + localStorage.getItem("token")

        }
      });


      if (response.ok) {
        const res = await response.json();
        dispatch({
          type: "availableSmallGroupSessions",
          payload: res
        });
      }
    }
  } catch (err) {
  }
};

export const setSelectingFromPackage = (payload: boolean) => {
  return {
    type: "selectingFromPackage",
    payload: payload
  };
};

export const setSelectingFromCancellation = (payload: any) => {
  return {
    type: "selectingFromCancellation",
    payload: payload
  };
};


