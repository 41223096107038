import { ReactComponent as DownloadSvg } from "../../../images/component_svg/download.svg";
import React from "react";
import {useSelector } from "react-redux";
import { InvoiceItemType } from "../../../redux/reducers/booking/bookingReducer";
import { DateTime } from "luxon";
import { currencySymbolEnumFromIndex } from "../../../enums/enum";





const InvoiceItem = ({ item, isCustomer }: { item:InvoiceItemType,isCustomer?:boolean }) => {


  const downloadInvoice =  () => {
    // @ts-ignore
    window.open(item.invoiceUrl,"_blank").focus()
  }




  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)

  const regEx = new RegExp(',', "g");

  return (
    <div className="invoiceItem">
      <p>{item.name.replace(regEx, ',\n') || "Service Name" } </p>
      <p>{DateTime.fromISO(item.invoiceDate).toFormat("dd.MM.yyyy") }</p>
      {isCustomer ? null :<p>{item.customerFirstName} {item.customerLastName} </p>}
      <p>{Boolean(item?.isVoucher) ? "-":""}{item.netAmount?.toString().split(".")[0]}
        <sup>{item.netAmount?.toString().split(".")[1]}</sup>{"\t"}{currencySymbolEnumFromIndex[publicTrainerData.currency]}</p>
      <p><DownloadSvg onClick={downloadInvoice}/></p>
    </div>
  );


};


export default InvoiceItem;
