import { ReactComponent as ArrowSvg } from "../../../../images/component_svg/arrowDownSVG.svg";
import { ReactComponent as DeleteSvg } from "../../../../images/component_svg/delete.svg";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import ModalContext from "../../../../utility/context/modal-context";
import { setIsLoading } from "../../../../redux/actions/layout/layoutAction";
import { useDispatch } from "react-redux";


type itemType = {
  customer: {
    id: number,
    bookings: Array<any>,
    firstName: string,
    lastName: string
  }
}

const CRMTableRow = ({ item ,getCustomerCredits }: { item: itemType, getCustomerCredits:any }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const modalCtx = useContext<any>(ModalContext);
  const dispatch = useDispatch();

  useEffect(() => {
  },[])


  const triggerRefund = async (id: number) => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/Payments/trainer-refund?bookingId=${id}`;
    //
    try {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      const res = await response.json();
      dispatch(setIsLoading(false));

      if (response.ok) {
        modalCtx.setModalType(0);
        modalCtx.setMessage(res.message)
        modalCtx.setIsActive(true)
        getCustomerCredits();
      } else {
        getCustomerCredits();

        modalCtx.setModalType(0);
        modalCtx.setMessage(t("error"))
        modalCtx.setIsActive(true)
      }
    } catch (error) {
      dispatch(setIsLoading(false));

    }
  };

  const triggerCustomRefund = async (id: number) => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/Payments/trainer-custom-refund?bookingId=${id}&refundAmount=${2}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      const res = await response.json();

      if (response.ok) {
        modalCtx.setModalType(0);
        modalCtx.setMessage(res.message)
        modalCtx.setIsActive(true)
        getCustomerCredits();
      } else {
        getCustomerCredits();

        modalCtx.setModalType(0);
        modalCtx.setMessage(t("error"))
        modalCtx.setIsActive(true)
      }
    } catch (error) {

    }
  };

  const submitRefund = async (item:any) => {
      modalCtx.setModalType(2);
      modalCtx.setMessage(t("refund credit"));
      modalCtx.setConfirmationCallback({ cb: () => triggerRefund(item.id) });
      modalCtx.setIsActive(true);

    //NOTE: we are not supporting a refund for multiple bookings that where booked with a Promocode
    /*else{
      modalCtx.setModalType(4);
      modalCtx.setMessage(t("enter refund amount"));
      modalCtx.setRefundId(item.id);
      modalCtx.setIsActive(true);
    }*/
  }


  useEffect(() => {
  }, []);
  return (
    <div className="table-row">
      <div className="row-head">
        <p>{item?.customer.firstName} {item?.customer.lastName}</p>
        <p>{item?.customer.bookings.length}</p>
        <ArrowSvg style={isOpen ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }}
                  onClick={() => setIsOpen(!isOpen)} />
      </div>
      <div className="row-content-container" style={isOpen ? { height: "100%" } : { height: 0 }}>
        {item?.customer.bookings?.map((item) => {
          if (true) {
            return (
              <div className="row-content">
                <div></div>
                <p>{item.service.name}</p>
                <div className="options">
                  <div className="date">{DateTime.fromISO(item.startTime).toFormat("dd.MM.yyyy")}</div>
                  {/*<DeleteSvg />*/}
                  {item.refundStatus  === 1  ? <button onClick={() => submitRefund(item)}>{t("payout")}</button> : <button style={{color: "gray"}} disabled={true} onClick={() => submitRefund(item)}>{t("payout")}</button>}
                </div>
              </div>
            );
          }
        })}

      </div>
    </div>

  );
};

export default CRMTableRow;

