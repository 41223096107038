import { useTranslation } from "react-i18next";
const TrainerNotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="trainer-not-found">


            <div className="trainer-not-found-content">
                <h1> {t("trainerNotFound")}  </h1>
                <h2> {t("checkIfTrainerExists")}  </h2>
            </div>

        </div>
    );


};

export default TrainerNotFound;