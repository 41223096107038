import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { ReactComponent as DeleteSVG } from "../../../images/component_svg/delete.svg";
import { ReactComponent as AddSVG } from "../../../images/component_svg/add.svg";
import { ReactComponent as VirtualSVG } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as LocationSVG } from "../../../images/component_svg/locationSVG.svg";
import { useMutation } from "react-query";
import BookingsService from "../../../shared/services/BookingsService";
import AuthContext from "../../../utility/context/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { getBookingPolicy } from "../../../redux/actions/booking/bookingAction";
import { DateTime } from "luxon";
import { getOverrideData } from "../../../redux/actions/businessCenter/calendarAction";
import { bool } from "yup";
import { useTranslation } from "react-i18next";
import InputTimerPicker from "../../common/InputTimerPicker";
import ModalContext from "../../../utility/context/modal-context";
import axios from "axios";

const CalendarOptions = () => {

  type slotType = {
    date: string,
    isAvailable: boolean,
    startTime: string,
    endTime: string,
    allowedVirtual: boolean,
    allowedOnSite: boolean
  }

  const modalCtx = useContext<any>(ModalContext);

  const { isLoading, mutate } = useMutation((data: any) =>
      BookingsService.dateOverride(data.formData, data.date), {
      onSuccess() {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("changes have been saved"));
        modalCtx.setIsActive(true);
      },
      onError(err, variable) {
        if (axios.isAxiosError(err)) {
          modalCtx.setModalType(0);
          modalCtx.setMessage(err.response?.data.message);
          modalCtx.setIsActive(true);
        } else {
          modalCtx.setModalType(0);
          modalCtx.setMessage(t("Oops, an error occured"));
          modalCtx.setIsActive(true);
        }
      }
    }
  );

  const [date, setDate] = useState<Date>(DateTime.now().set({hour:0,minute:0,second:0}).toJSDate() );
  const { t, i18n } = useTranslation();
  const yes = t("yes");
  const no = t("no");
  const dispatch = useDispatch();
  const overrideData = useSelector(state => state.businessCenter.calendar.overrideData);
  const [slots, setSlots] = useState<any>([
    {
      date: date,
      isAvailable: true,
      startTime: "00:00:00",
      endTime: "00:00:00",
      allowedVirtual: true,
      allowedOnSite: true
    }
  ]);
  const bookingPolicy = useSelector(state => state.booking.bookingPolicy);

  useEffect(() => {
    getSlotsToUpdate();
    dispatch(getBookingPolicy());
  }, [slots]);

  useEffect(() => {
    dispatch(getOverrideData(DateTime.fromJSDate(date).toUTC().toJSON()));

  }, [date]);

  useEffect(() => {
    initSlots(overrideData);
  }, [overrideData]);

  useEffect(() => {
    setLatestBooking(bookingPolicy.endTime || "00:00:00");
    setEarliestBooking(bookingPolicy.startTime || "00:00:00");
  }, [bookingPolicy]);

  const [latestBooking, setLatestBooking] = useState(bookingPolicy.endTime || "00:00:00");
  const [earliestBooking, setEarliestBooking] = useState(bookingPolicy.startTime || "00:00:00");
  const [startSlot, setStartSlot] = useState(DateTime.now());

  const userData = useSelector(state => state.onboarding.userData);
  const authCtx = useContext(AuthContext);


  const initSlots = (initSlots: Array<slotType>) => {
    const cloneSlots: Array<slotType> = [];
    initSlots?.forEach(slot => {
      cloneSlots.push({
        date: slot.date,
        isAvailable: slot.isAvailable,
        startTime: slot.startTime,
        endTime: slot.endTime,
        allowedVirtual: slot.allowedVirtual,
        allowedOnSite: slot.allowedOnSite
      });
    });
    if (initSlots.length > 0) {
      setSlots(cloneSlots);
    } else {
      setSlots([{

        date: date,
        isAvailable: true,
        startTime: "00:00:00",
        endTime: "00:00:00",
        allowedVirtual: true,
        allowedOnSite: true

      }]);
    }

  };

  const handleAddSlot = () => {
    const cloneSlots = [...slots];
    cloneSlots.push({
      date: DateTime.fromJSDate(date).toUTC().toJSON(),
      isAvailable: true,
      startTime: "00:00:00",
      endTime: "00:00:00",
      allowedVirtual: true,
      allowedOnSite: true
    });
    setSlots(cloneSlots);
  };

  const handleDeleteSlot = (i: number) => {
    let cloneSlots = [...slots];
    cloneSlots = cloneSlots.slice(i);
    setSlots(cloneSlots);
  };

  const handleUpdate = (e: any, i: number, type: string) => {
    let cloneSlots = [...slots];
    const val = e.target.value;

    if (e === null || e === "") {
      return;
    }

    if (val === null || val === "" || val == "Invalid DateTime") {
      return;
    }

    switch (type) {
      case "availabilty":
        cloneSlots[i].isAvailable = !!+val;
        break;
      case "startTime":
        cloneSlots[i].startTime = val;
        break;
      case "endTime":
        cloneSlots[i].endTime = val;
        break;
      case "virtual":
        cloneSlots[i].allowedVirtual = !cloneSlots[i].allowedVirtual;
        break;
      case "location":
        cloneSlots[i].allowedOnSite = !cloneSlots[i].allowedOnSite;
        break;
      default:
        break;
    }
    cloneSlots[i]["isUpdate"] = true;
    setSlots(cloneSlots);
  };

  /* const saveBookingPolicy = async (e: any) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/allowedtime`;

    const data = {
      userId: String(userData.id),
      startTime: earliestBooking,
      endTime: latestBooking
    };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authCtx.token
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
     dispatch(getBookingPolicy());
    }
  }; */

  const getSlotsToUpdate = () => {
    const nslots: any = [];
    slots.forEach((slot: any) => {
      if (slot.isUpdate || true) {
        nslots.push({
          date: DateTime.fromJSDate(date).toJSON(),
          isAvailable: slot.isAvailable,
          startTime: DateTime.now().set({
            hour: Number(slot.startTime.substring(0, 2)),
            minute: Number(slot.startTime.substring(3, 5)),
            second: 0
          }).toISOTime().substring(0, 8),
          endTime: DateTime.now().set({
            hour: Number(slot.endTime.substring(0, 2)),
            minute: Number(slot.endTime.substring(3, 5)),
            second: 0
          }).toISOTime().substring(0, 8),
          allowedVirtual: slot.allowedVirtual,
          allowedOnSite: slot.allowedOnSite
        });
      }
    });
    return nslots;
  };

  return (
    <div className="bookingOptions">
      {/*<div className="card policy">
        <form onSubmit={saveBookingPolicy}>
          <div className="card_content">
            <div className="card_header">
              <h4>{t("booking policy")}</h4>
              <p>{t("select your session booking options")} </p>
              <hr />
            </div>
            <div className="form-group">
              <label htmlFor={"latestBookingOption"}>
                {t("latest booking option for sessions for next day")}
              </label>
              <InputTimerPicker
              time={ DateTime.fromFormat(latestBooking, "HH:mm:ss")}
              setTime={(t: any) => {setLatestBooking(t.toFormat('HH:mm:ss'));}}
              onError={(err: any, temp: any) => console.log("error" , err)}
        />
            </div>
            <div className="form-group">
              <label htmlFor={"latestBookingOption"}>
                {t("earliest bookable session for next day")}
              </label>
              <InputTimerPicker
              time={ DateTime.fromFormat(earliestBooking, "HH:mm:ss")}
              setTime={(t: any) => {setEarliestBooking(t.toFormat('HH:mm:ss'));}}
              onError={(err: any, temp: any) => console.log("error" , temp)}
        />
            </div>
          </div>
          <button type={"submit"}>{t("save")}</button>
        </form>
      </div>*/}

      <div className="card override">
        <div className="card_content">
          <div className="card_header">
            <h4>
              {t("add data override")}
            </h4>
          </div>
          <div className="skulpCalendar">
            <div className="calendar">
              <Calendar locale={i18n.language} onChange={setDate} value={date} minDate={DateTime.now().set({hour:0,minute:0,second:0,millisecond:0}).toJSDate()} />
            </div>
          </div>
          {slots.map((slot: any, i: number) =>
            (
              <>
                <div className="form-group">
                  <label htmlFor="availableAtTime">
                    {t("AvailableAtCertainTime")}
                  </label>
                  <select
                    value={+slot.isAvailable}
                    onChange={(e) => handleUpdate(e, i, "availabilty")}
                  >
                    <option value={0}>{no}</option>
                    <option value={1}>{yes}</option>
                  </select>
                </div>

                {slot.isAvailable ? <div className="form-group">
                  <label>
                    {t("What hours are you available?")}
                  </label>
                  <div className="availableTime">
                    <InputTimerPicker
                      time={DateTime.fromFormat(slot.startTime, "HH:mm:ss")}
                      setTime={(t: any) => {
                        if (t != null) {
                          handleUpdate(
                            {
                              target: {
                                value: t.toFormat("HH:mm:ss")
                              }
                            },
                            i,
                            "startTime"
                          );
                        } else {
                          return;
                        }
                      }}
                      onError={(err: any, temp: any) => console.log("error", temp)}
                    />
                    <p>
                      {t("to")}
                    </p>

                    <InputTimerPicker
                      time={DateTime.fromFormat(slot.endTime, "HH:mm:ss")}
                      setTime={(t: any) => {
                        if (t != null) {
                          handleUpdate(
                            {
                              target: {
                                value: t.toFormat("HH:mm:ss")
                              }
                            },
                            i,
                            "endTime"
                          );
                        } else {
                          return;
                        }
                      }}
                      onError={(err: any, temp: any) => console.log("error", temp)}
                    />
                  </div>
                </div> : null}
                <div className="icon_row">
                  {slots.length > 1 && (
                    <DeleteSVG onClick={() => handleDeleteSlot(i)} />
                  )}
                  {/* <AddSVG onClick={handleAddSlot} /> */}
                  {
                    +slot.isAvailable ? (
                      <>
                        <VirtualSVG
                          onClick={(e) => handleUpdate(e, i, "virtual")}
                          className={slot.allowedVirtual ? "icon_active" : ""}
                        />
                        <LocationSVG
                          onClick={(e) => handleUpdate(e, i, "location")}
                          className={slot.allowedOnSite ? "icon_active" : ""}
                        />
                      </>
                    ) : null
                  }

                </div>
              </>
            )
          )}

          <button
            onClick={() =>
              mutate({
                formData: getSlotsToUpdate(),
                date: DateTime.fromJSDate(date).toUTC().toISO()
              })
            }
          >
            {isLoading ? t("saving...") : t("save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarOptions;
