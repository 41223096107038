import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { emailRegex } from "../../enums/enum";
import ModalContext from "../../utility/context/modal-context";

const AdminScreen = () => {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState<string>("");
    const [emailErr, setEmailErr] = useState<string>("");

    const [serviceFee, setServiceFee] = useState<string>("");
    const [serviceFeeErr, setServiceFeeErr] = useState<string>("");
    const modalCtx = useContext<any>(ModalContext);


    useEffect(() => {
        if (email && !email.match(emailRegex)) {
            setEmailErr(t("errEmailInvalid"))
        } else {
            setEmailErr("");
        }
        if (isNaN(Number(serviceFee.toString().replace(",", "").replace(".", ".")))) {
            return setServiceFeeErr(t("this is not a valid number"));
        } else {
            setServiceFeeErr("")
        }

        if ((serviceFee.toString().includes(",") || serviceFee.toString().includes("."))) {
            return setServiceFeeErr(t("errorInteger"));
        } else {
            setServiceFeeErr("");
        }
    }, [email, serviceFee]);


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!emailErr && !serviceFeeErr) {
            if (!email) {
                setEmailErr(t("email is required"));
            }
            if (!serviceFee) {
                setServiceFeeErr(t("service fee is required"));
            }
            if (email && serviceFee) {
                const url = `${process.env.REACT_APP_BE_BASEURL}/api/admins/service-fee`;
                const response = await fetch(url, {
                    method: "PUT",
                    body: JSON.stringify({
                        email: email,
                        serviceFee: parseInt(serviceFee),
                    }),
                    headers: {
                        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    modalCtx.setModalType(0);
                    modalCtx.setMessage(data.message);
                    modalCtx.setIsActive(true);
                } else {
                    const data = await response.json();

                    modalCtx.setModalType(0);
                    modalCtx.setMessage(data.message);
                    modalCtx.setIsActive(true);
                }
            }
        }
    };

    return (
        <div className="initialView">
            <div className="content adminScreen">
                <div className="adminScreen-card">
                <form onSubmit={handleSubmit} className={"registration adminScreen-form"} id={"form1"}>
                    <div className="form-group">
                        <label style={{color: 'white'}} htmlFor="email">
                            {t("email")}{" "}
                            <span className="required">*</span>
                        </label>
                        <input
                            type="email"
                            id={"email"}
                            value={email}
                            placeholder={t("enter email")}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {
                        }
                        {emailErr ?
                            <div className="validation-error yellow-global">
                                {emailErr}
                            </div> : null}
                        <label style={{color: 'white'}} htmlFor="email">
                            {t("service fee")}{" "}
                            <span className="required">*</span>
                        </label>
                        <input
                            type="serviceFee"
                            id={"serviceFee"}
                            value={serviceFee}
                            placeholder={t("enter service fee")}
                            onChange={(e) => setServiceFee(e.target.value)}
                        />
                        {serviceFeeErr ?
                            <div className="validation-error yellow-global">
                                {serviceFeeErr}
                            </div> : null}
                    </div>
                    <button disabled={Boolean(emailErr) || Boolean(serviceFeeErr)} type={"submit"}>
                        {t("save")}
                    </button>
                </form>
            </div>
            </div>
        </div>
    )
}

export default AdminScreen;