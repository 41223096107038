import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import bar from "../../../../images/component_svg/bar.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodes, resetPromoCode, setPromoCode } from "../../../../redux/actions/businessCenter/serviceActions";
import { DateTime } from "luxon";
import ModalContext from "../../../../utility/context/modal-context";
import { currencySymbolEnum } from "../../../../enums/enum";
import { setIsLoading } from "../../../../redux/actions/layout/layoutAction";
import i18n from "i18next";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import PriceInput from "../../../common/priceInput";

const promoRegex = /^\w+$/;
const tomorrow = DateTime.now().plus({ days: 1 });

const PromoCode = () => {

  useEffect(() => {
    dispatch(getPromoCodes());
  }, []);


  const [click, setClick] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<boolean>(true);

  const [selectedCoupon, setSelectedCoupon] = useState<number>(-1);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modalCtx = useContext<any>(ModalContext);

  const userData = useSelector((state) => state.onboarding.userData);

  const promoCode = useSelector(
    (state) => state.businessCenter.promoCode
  );

  const activePromoCodes = useSelector(
    (state) => state.businessCenter.activePromoCodes
  );

  const currencySymbol = Object.values(currencySymbolEnum)[userData.currency];

  const isClicked = () => {
    setClick(!click);
  };

  useEffect(() => {
    if (promoCode.percentOff > 100) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("discounts above 100% are not allowed"));
      modalCtx.setIsActive(true);
      dispatch(setPromoCode({ "percentOff": Number(1.0) }))
      return;
    }
  },[promoCode.percentOff])


  const handleChange = (e?: any, type?: string, validate?: Function, name?: string, value?: any) => {
    if (!!e) {
      let { value, name } = e.target;
      if (type === "number") {
        value = Number(value);
      } else if (type === "date") {
        value = DateTime.fromISO(value);

        const t = value.diff(DateTime.now(), ["hours"]);

        if ((t.toObject().hours || 0) >= 0) {
          dispatch(setPromoCode({ [name]: value }));
        }
        return;
      }

      if (validate && (value || value === 0)) {
        if (validate(value)) {
          dispatch(setPromoCode({ [name]: value }));
        }
        return;
      }
      dispatch(setPromoCode({ [name]: value }));
    } else {
      if (type === "date" && !!name && !!value) {
        value = DateTime.fromISO(value);

        const t = value.diff(DateTime.now(), ["hours"]);

        if ((t.toObject().hours || 0) >= 0) {
          dispatch(setPromoCode({ [name]: value }));
        }
        return;
      }
    }
  };

  const handleSave = async (e: any) => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/trainers/promotion-code`;

    const { promotionCodeExpiresAt, discountType, amountOff, percentOff } = promoCode;

    const parsedPromoCode = {
      ...promoCode,
      promotionCodeExpiresAt: (promotionCodeExpiresAt || tomorrow).toUTC().toString(),
      amountOff: discountType === 1 ? 0 : amountOff,
      percentOff: discountType === 2 ? 0 : percentOff
    };

    try {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify(parsedPromoCode)
      });

      const data = await response.json();
      dispatch(setIsLoading(false));

      if (response.ok) {

        modalCtx.setModalType(0);
        modalCtx.setMessage(t("promo code created"));
        modalCtx.setIsActive(true);
        setClick(!click);
        dispatch(getPromoCodes());
        dispatch(resetPromoCode());
      } else {
        const { message } = data;
        modalCtx.setModalType(0);
        modalCtx.setMessage(message);
        modalCtx.setIsActive(true);
      }
    } catch (err) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(err);
      modalCtx.setIsActive(true);
      dispatch(setIsLoading(false));

    }
  };

  const handleDisableCoupon = async (id: number) => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/trainers/disable-promotion-code?code=${id}`;
    dispatch(setIsLoading(true));

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }

    });

    const data = await response.json();
    dispatch(setIsLoading(false));

    if (response.ok) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("promo code disabled"));
      modalCtx.setIsActive(true);
      setSelectedCoupon(-1);
      dispatch(getPromoCodes());
    } else {
      const { message } = data;
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("Error"));
      modalCtx.setIsActive(true);
    }
  };

  return (
    <>
      <div className="card">
        <div className="pro-code">
          <h3>
            {t("promoCodes")}
          </h3>
          <p>
            {t("promo codes allow you to offer flexible pricing for your classes, private sessions and packages.")}{" "}
          </p>
          <button
            className={`${click ? "remove-code" : "add-code"}`}
            onClick={isClicked}
          >
            <span>{`${click ? "x" : "+"}`}</span> {t("add promo codes")}
          </button>
          {click && (
            <div className="code-form">
              <div className="form-row mb30">
                <div className="form-group">
                  <label>{t("promo code")}</label>
                  <input
                    value={promoCode.code || ""}
                    type="text"
                    placeholder={t("e.g. Promo20")}
                    name="code"
                    onChange={(e) => handleChange(e, "", (value: string) => promoRegex.test(value))}

                  />
                </div>
                <div className="form-group">
                  <label>{t("discount")}</label>
                  <div className="form-row">
                    {promoCode.discountType === 1 ?
                      (/*<input
                        value={promoCode.percentOff || 0}
                        type="number"
                        name="percentOff"
                        max={100}
                        onChange={(e) => handleChange(e, "number", (value: Number) => value > 0)}

                      />*/
                        <PriceInput price={promoCode.percentOff}
                                    handleOnChange={(e) => dispatch(setPromoCode({ "percentOff": Number(e) }))}
                                    currency={-1} />

                      ) :
                      (
                        (/*<input
                          value={promoCode.amountOff || 0}
                          type="number"
                          name="amountOff"
                          onChange={(e) => handleChange(e, "number", (value: Number) => value > 0)}
                        />*/
                          <PriceInput price={promoCode.amountOff}
                                      handleOnChange={(e) => dispatch(setPromoCode({ "amountOff": Number(e) }))}
                                      currency={-1} />

                        )
                      )}
                    <select
                      name="discountType"
                      value={promoCode.discountType}
                      onChange={(e) => handleChange(e, "number")}
                    >
                      <option value={1}>%</option>
                      <option value={2}>{currencySymbol}</option>

                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{t("description")}</label>
                <textarea
                  value={promoCode.description}
                  onChange={handleChange}
                  id="description"
                  name="description"
                ></textarea>
              </div>

              <div className="code-app">

                <div className="form-row">
                  <div className="left">
                    <div className="form-group">
                      <label>{t("how this can be used")}:</label>
                      <select
                        name="stripePromotionCodeUsageType"
                        onChange={(e) => handleChange(e, "number")}
                        value={promoCode.stripePromotionCodeUsageType}
                      >
                        <option value={1}>{t("each client can use this once")}</option>
                        {/*  TODO: uncomment when backend support this
                       <option disabled={true} value={2}>A specified number of times per client</option>
 */}
                        <option value={3}>{t("unlimited uses")}</option>
                        <option value={4}>{t("only new clients may redeem this offer")}</option>
                      </select>
                    </div>
                  </div>
                  <div className="right">
                    <div className="form-group">
                      <label>{t("expire date")}</label>
                      <DatePicker
                        id={"dateOfBirth"}
                        name="promotionCodeExpiresAt"
                        onChange={(date) => {
                          if (date !== null) {
                            handleChange(null, "date", undefined, "promotionCodeExpiresAt", DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"));
                          }
                        }}
                        minDate={DateTime.now().toJSDate()}
                        selected={DateTime.fromISO(promoCode.promotionCodeExpiresAt || tomorrow).toJSDate()}
                        dateFormat="dd.MM.yyyy"
                        locale={i18n.language === "de" ? de : ""}
                        calendarStartDay={1}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-btn">
                  <button onClick={handleSave}>{t("save")}</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card">
        <div className="act-inact">
          <div className="form-row">
            <div className="form-group">
              <div
                onClick={() => setActiveTab(true)}
                className="checkbox-group">
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  value="active promo codes"
                  checked={activeTab}
                />
                <label>{t("active promo codes")}</label>
              </div>
            </div>
            <div className="form-group">
              <div
                onClick={() => setActiveTab(false)}
                className="checkbox-group">
                <input
                  type="checkbox"
                  id="inactive"
                  name="inactive"
                  value="inactive promo codes"
                  checked={!activeTab}
                />
                <label>{t("inactive promo codes")}</label>
              </div>
            </div>
          </div>
          <div className="table-cont">
            <table>

              {
                activeTab && activePromoCodes.filter((promoCode: any) => promoCode.isActive).map((promoCode: any) => (
                  <tr key={promoCode.id}>
                    <td>
                      {promoCode.code}
                    </td>

                    <td>
                      {`${promoCode.discountType === 1 ? promoCode.percentOff : promoCode.amountOff}  ${promoCode.discountType === 1 ? "%" : currencySymbol} ${t("discount")}`}
                    </td>

                    <td>
                      {DateTime.fromISO(promoCode.promotionCodeExpiresAt).toFormat("dd.MM.yyyy")}
                    </td>
                    <td>
                      {t("redemptions")}: {promoCode.promotionCodeRedemptions}
                    </td>
                    <td className="edit-element">
                      <img onClick={() => {
                        setSelectedCoupon(prevState => {
                          return prevState === promoCode.id ? -1 : promoCode.id;
                        });
                      }} src={bar} alt="bar" />
                      <div className="EditPopup" style={selectedCoupon === promoCode.id ? { display: "flex" } : {}}>

                        <p onClick={() => handleDisableCoupon(promoCode.code)}>{t("disable")}</p>
                      </div>
                    </td>

                  </tr>
                ))
              }
              {
                !activeTab && activePromoCodes.filter((promoCode: any) => !promoCode.isActive).map((promoCode: any) => (
                  <tr key={promoCode.id}>
                    <td>
                      {promoCode.code}
                    </td>

                    <td>
                      {`${promoCode.discountType === 1 ? promoCode.percentOff : promoCode.amountOff}  ${promoCode.discountType === 1 ? "%" : currencySymbol} ${t("discount")}`}
                    </td>

                    <td>
                      {DateTime.fromISO(promoCode.promotionCodeExpiresAt).toFormat("dd.MM.yyyy")}
                    </td>
                    <td>
                      {t("redemptions")}: {promoCode.promotionCodeRedemptions}
                    </td>
                  </tr>
                ))
              }
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default PromoCode;
