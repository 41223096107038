import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import { useSelector } from "react-redux";
import { store } from "../redux/storeConfig/store";
const en = require('../assets/data/locales/en.json');
const fr = require('../assets/data/locales/fr.json');
const de = require('../assets/data/locales/de.json');

export default i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: {
      en: {
        translation: {...en},
      },
      fr: {
        translation: {...fr},
      },
      de: {
        translation: {...de},
      },
    },
    fallbackLng: 'en',
    lng: 'de',
    interpolation: {
      escapeValue: false,
    },
  });
