import closeSVG from "../../../images/component_svg/close.svg";
import BusinessCenterServiceCreateRecurring from "./BusinessCenterServiceCreateRecurring";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalContext from "../../../utility/context/modal-context";
import { range } from "../../../utility/hooks/Utils";
import { useMutation } from "react-query";
import { serviceGroupType } from "../../../enums/enum";
import services from "../../../shared/services";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { currencySymbolEnum, durationInMinutes } from "../../../enums/enum";
import PriceInput from "../../common/priceInput";
import { DateTime } from "luxon";
import InputTimerPicker from "../../common/InputTimerPicker";
import i18n from "i18next";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import { resetEditService } from "../../../redux/actions/businessCenter/serviceActions";

const intErrObject = {
  name: "",
  difficultyLevel: "",
  groupPrice: "",
  serviceType: "",
  maximumParticipants: "",
  weekRepeats: "",
  date: "",
  durationInMinutes: "",
  startTime: "",
  description: "",
  disciplines: "",
  dayOfWeeks: ""
};

const BusinessCenterServiceCreateSmallGroup = () => {
  const [serviceType, setServiceType] = useState<number>(-1);
  const [errObject, setErrObject] = useState<any>(intErrObject);
  const [disciplineSelection, setDisciplineSelection] = useState<number>(0);

  const smallGroupState = useSelector(
    (state) => state.businessCenter.services.smallGroup
  );

  const editServiceSelector = useSelector(state => state.businessCenter.services.editService);

  const isEditingService = editServiceSelector.id !== -1;

  const userData = useSelector((state) => state.onboarding.userData);
  const modalCtx = useContext<any>(ModalContext);
  const { t } = useTranslation();

  const selectDisciplinePlaceholder = t(
    "selectDiscipline"
  );
  const descriptionPlaceholder = t(
    "descriptionPlaceholder"
  );
  const serviceNamePlaceholder = t(
    "serviceNamePlaceholder"
  );
  const minutes = t("minutes");
  const selectDuration = t("selectDuration");
  const virtual = t("virtual");
  const onSiteCoachMessage = t("on site coach");
  const expert = t("expert");
  const advanced = t("Advanced");
  const basic = t("Basic");
  const dispatch = useDispatch();

  const { onSiteCoach, isVirtual } = userData;

  useEffect(() => {
    if (!smallGroupState.id) {
      if (serviceType === serviceGroupType["on site"] && !isVirtual) {
        dispatch({
          type: "updateCreateServiceGroup",
          payload: { serviceType: serviceGroupType["on site coach"] }
        });
      }
    }
  }, []);

  const { isLoading, mutate, isError } = useMutation((data: any) =>
    services.createSmallGroup(data, dispatch)
  );
  const { mutate: updateMutate, isSuccess } = useMutation((data: any) =>
    services.updateSmallGroup(data, dispatch)
  );

  const handleStateChanges = (event?: any, name?: string, value?: any) => {
    if (!!event) {
      switch (event.target.name) {
        case "date": {
          dispatch({
            type: "updateCreateServiceGroup",
            payload: { [event.target.name]: event.target.value }
          });
          return null;
        }
        case "serviceType": {
          dispatch({
            type: "updateCreateServiceGroup",
            payload: { [event.target.name]: Number(event.target.value) }
          });
          return null;
        }
        case "durationInMinutes": {
          dispatch({
            type: "updateCreateServiceGroup",
            payload: { [event.target.name]: Number(event.target.value) }
          });
          return null;
        }
        case "maximumParticipants": {
          dispatch({
            type: "updateCreateServiceGroup",
            payload: { [event.target.name]: Number(event.target.value) }
          });
          return null;
        }
        case "difficultyLevel": {
          dispatch({
            type: "updateCreateServiceGroup",
            payload: { [event.target.name]: Number(event.target.value) }
          });
          return null;
        }
        case "maximumParticipantsInWaitingList": {
          dispatch({
            type: "updateCreateServiceGroup",
            payload: { [event.target.name]: Number(event.target.value) }
          });
          return;
        }
        default: {
          dispatch({
            type: "updateCreateServiceGroup",
            payload: { [event.target.name]: event.target.value }
          });
        }
      }
    } else {
      if (!!name) {
        dispatch({
          type: "updateCreateServiceGroup",
          payload: { [name]: value }
        });
      }
    }
  };

  const handleAddDiscipline = (event: any) => {
    event.preventDefault();
    if (event.target.value !== "0") {
      const disciplines = smallGroupState.disciplines;
      let isDuplicated: boolean = false;

      disciplines.forEach((e: string) => {
        if (e.toString() === event.target.value) {
          isDuplicated = true;
        }
      });
      if (!isDuplicated) {
        dispatch({
          type: "editDisciplineGroup",
          payload: [...disciplines, event.target.value]
        });
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("discipline already selected"));
        modalCtx.setIsActive(true);
      }
    }
  };

  const handleDeleteDiscipline = (event: any, v: string) => {
    const disciplines = smallGroupState.disciplines;
    const newArray = disciplines.filter((item: any) => item !== v);

    dispatch({
      type: "editDisciplineGroup",
      payload: newArray
    });
  };

  const handleErrorMessage = (req: { success: boolean; message?: string }) => {
    if (req.success) {
      modalCtx.setModalType(0);
      smallGroupState.id
        ? modalCtx.setMessage(t("smallGroupModified"))
        : modalCtx.setMessage(t("smallGroupCreated"));
      modalCtx.setIsActive(true);
      dispatch({ type: "resetSmallGroupFields" });
    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(req.message || "An error ocurred");
      modalCtx.setIsActive(true);
    }
    dispatch(setIsLoading(false));
  };

  // Validation all required inputs
  const handleValidateInputs = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let status = true;
      const errros = JSON.parse(JSON.stringify(intErrObject));
      if (!smallGroupState?.name?.trim()) {
        errros.name = t("errName");
        status = false;
      }
      if (!smallGroupState?.description?.trim()) {
        errros.description = t("errDescription");
        status = false;
      }

      if (smallGroupState.disciplines?.length < 1) {
        errros.disciplines = t("errDisciplines");
        status = false;
      }
      if (!smallGroupState.date) {
        errros.date = t("errDate");
        status = false;
      }
      if (!smallGroupState.startTime) {
        errros.startTime = t("errStartTime");
        status = false;
      }
      if (!smallGroupState.durationInMinutes) {
        errros.durationInMinutes = t(
          "errDuration"
        );
        status = false;
      }
      if (smallGroupState.isRecurring) {
        if (smallGroupState.dayOfWeeks?.length < 1) {
          errros.dayOfWeeks = t(
            "errDayOfWeeks"
          );
          status = false;
        }
        if (!smallGroupState.weekRepeats) {
          errros.weekRepeats = t(
            "errWeekRepeats"
          );
          status = false;
        }
      }

      if (!smallGroupState.maximumParticipants) {
        errros.maximumParticipants = t(
          "errMaximumParticipants"
        );
        status = false;
      }

      if (smallGroupState.groupPrice < 0.1) {
        errros.groupPrice = t(
          "errGroupPrice"
        );
        status = false;
      }

      if (smallGroupState.difficultyLevel === "") {
        errros.difficultyLevel = t(
          "errDifficultyLevel"
        );
        status = false;
      }
      if (
        smallGroupState.serviceType === "" ||
        smallGroupState.serviceType === -1
      ) {
        errros.serviceType = t(
          "errServiceType"
        );
        status = false;
      }

      setErrObject(errros);
      resolve(status);
    });
  };

  const handleCreateOrUpdate = async () => {

    const isFormValid = await handleValidateInputs();
    if (!isFormValid) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("please fill all the required fields"));
      modalCtx.setIsActive(true);
      return;
    }

    if (smallGroupState.serviceType !== -1) {
      if (smallGroupState.id) {
        const cloneState = { ...smallGroupState };
        cloneState.durationInMinutes = [cloneState.durationInMinutes];
        if (!cloneState.isRecurring) {
          cloneState.dayOfWeeks = [];
        }

        const updateReq = await services.updateSmallGroup(cloneState, dispatch);
        handleErrorMessage(updateReq);
        dispatch(resetEditService());
      } else {
        const createReq = await services.createSmallGroup(smallGroupState, dispatch);
        handleErrorMessage(createReq);
      }
    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("errServiceType"));
      modalCtx.setIsActive(true);

    }

  };


  const currencySymbol = Object.values(currencySymbolEnum)[userData.currency];

  return (
    <div className="serviceCreationSmallGroup">
      <div className="row">
        <div className="left">
          <div className="form-group">
            <label htmlFor="name">
              {t("nameOfService")}
            </label>
            <input
              type="text"
              name={"name"}
              value={smallGroupState.name}
              placeholder={serviceNamePlaceholder}
              onChange={handleStateChanges}
            />
            {errObject?.name && !smallGroupState.name && (
              <div className="validation-error yellow-global">
                {errObject?.name}
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {t("disciplines")}
            </label>
            <select
              id={"disciplines"}
              name={"disciplines"}
              value={disciplineSelection}
              onChange={handleAddDiscipline}
            >
              <option value={0}>{selectDisciplinePlaceholder}</option>
              {userData.disciplines.map((v: any) => (
                <option value={v}>{v}</option>
              ))}
            </select>
            <div className="specalties_added">
              {smallGroupState.disciplines.map((v: any) => (
                <div key={v.key} className="specaltie">
                  {v}
                  <span
                    key={v}
                    onClick={(e) => {
                      handleDeleteDiscipline(e, v);
                    }}
                  >
                    <img src={closeSVG} alt="deleteSVG" />
                  </span>
                </div>
              ))}
            </div>
            {errObject?.disciplines &&
              smallGroupState.disciplines?.length < 1 && (
                <div className="validation-error yellow-global">
                  {errObject?.disciplines}
                </div>
              )}
          </div>
        </div>
        <div className="right">
          <div className="form-group">
            <label htmlFor="name">
              {t("description")}
            </label>
            <textarea
              id={"description"}
              name={"description"}
              value={smallGroupState.description}
              placeholder={descriptionPlaceholder}
              onChange={handleStateChanges}
            />
            {errObject?.description && !smallGroupState.description && (
              <div className="validation-error yellow-global">
                {errObject?.description}
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="frequency">
        <p>
          {t("selectFrequency")}
        </p>
        <div className="form-row">
          <input
            className={isEditingService ? "frequencyDisabled" : ""}
            disabled={isEditingService}
            type={"radio"}
            value={smallGroupState.isRecurring}
            id={"oneTime"}
            checked={!smallGroupState.isRecurring}
            name={"isRecurring"}
            onClick={(e) => {
              dispatch({ type: "editIsRecurring", payload: false });
            }}
          />
          <label
            className={isEditingService ? "frequencyDisabled" : ""}
            htmlFor={"oneTime"}>
            {t("oneTime")}
          </label>
          <input
            disabled={isEditingService}
            type={"radio"}
            value={smallGroupState.isRecurring}
            id={"recurring"}
            name={"isRecurring"}
            checked={smallGroupState.isRecurring}
            onClick={(e) => {
              dispatch({ type: "editIsRecurring", payload: true });
            }}
          />
          <label
            className={isEditingService ? "frequencyDisabled" : ""}
            htmlFor={"recurring"}>

            {t("recurring")}
          </label>
        </div>
      </div>
      <div className="form-row mt-3 dateAndStartTime">
        <div className="form-group">
          <label htmlFor="date">
            {i18n.language == "en" ? t("date") : t("Date")}
          </label>

          <DatePicker
            id={"date"}
            name={"date"}
            minDate={DateTime.now().toJSDate()}
            onChange={(date) => {
              if (date !== null) {
                handleStateChanges(null, "date", DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"));
              } else {
                handleStateChanges(null, "date", "");
              }
            }}
            disabled={isEditingService}
            selected={smallGroupState.date === "" ? null : DateTime.fromISO(smallGroupState.date).toJSDate()}
            dateFormat="dd.MM.yyyy"
            locale={i18n.language === "de" ? de : ""}
            calendarStartDay={1}
            placeholderText={t("choose a date")}
          />
          {errObject?.date && !smallGroupState.date && (
            <div className="validation-error yellow-global">
              {errObject?.date}
            </div>
          )}
        </div>
        <div className="form-group">
          <label>
            {t("startTime")}
          </label>

          <InputTimerPicker
            time={DateTime.fromFormat(smallGroupState.startTime || "00:00:00", "HH:mm:ss")}
            setTime={(t: any) => {
              if (t != null) {
                dispatch({
                  type: "updateCreateServiceGroup",
                  payload: { startTime: t.toFormat("HH:mm:ss") }

                });
              }
            }}
            onError={(err: any, temp: any) => console.log("error", temp)}
            disabled={isEditingService}
          />
          {errObject?.startTime && !smallGroupState.startTime && (
            <div className="validation-error yellow-global">
              {errObject?.startTime}
            </div>
          )}
        </div>
        <div className="form-group">
          <label>
            {t("duration")}
          </label>
          <select
            disabled={isEditingService}
            name="durationInMinutes"
            id="duration"
            onChange={handleStateChanges}
            value={smallGroupState.durationInMinutes}
          >
            <option value={0}>{selectDuration}</option>
            {durationInMinutes.map(duration => (
              <option value={duration}> {duration} {minutes} </option>
            ))}
          </select>
          {errObject?.durationInMinutes &&
            !smallGroupState.durationInMinutes && (
              <div className="validation-error yellow-global">
                {errObject?.durationInMinutes}
              </div>
            )}
        </div>
      </div>

      {smallGroupState.isRecurring ? (
        <BusinessCenterServiceCreateRecurring
          startDate={smallGroupState.date}
          weekRepeats={smallGroupState.weekRepeats}
          errObject={errObject}
        />
      ) : null}

      <hr />
      <div className="participants">
        <div className="form-group">
          <label>
            {t("maximumParticipants")}
          </label>
          <select
            disabled={isEditingService}
            name="maximumParticipants"
            id="maximumParticipants"
            onChange={handleStateChanges}
            value={smallGroupState.maximumParticipants}
          >
            {range(98).map((i) => (
              <option value={i + 2}>{i + 2}</option>
            ))}
          </select>
          {errObject?.maximumParticipants &&
            !smallGroupState.maximumParticipants && (
              <div className="validation-error yellow-global">
                {errObject?.maximumParticipants}
              </div>
            )}
        </div>
      </div>
      <hr />
      <div className="serviceTypeInput">
        <div className="chooseServiceType">
          <div className="form-group">
            <label>
              {t("service type")}
            </label>
            <select
              disabled={isEditingService}
              name="serviceType"
              id="serviceTypeSelect"
              value={smallGroupState.serviceType}
              onChange={handleStateChanges}
            >
              <option value={-1}>{t("errServiceType")}</option>
              <option disabled={!isVirtual} value={0}>
                {virtual}
              </option>
              <option disabled={!onSiteCoach} value={1}>
                {onSiteCoachMessage}
              </option>
            </select>
            {(smallGroupState.serviceType === -1 ||
                smallGroupState.serviceType === "") &&
              errObject?.serviceType && (
                <div className="validation-error yellow-global">
                  {errObject?.serviceType}
                </div>
              )}
          </div>

          <div className="bottom">

            <div className="price">
              <PriceInput
                price={smallGroupState.groupPrice}
                handleOnChange={(value: string) => dispatch({
                  type: "updateCreateServiceGroup",
                  payload: { groupPrice: value }
                })}
                currency={userData.currency}
                errObject={errObject?.groupPrice}
              />
            </div>

          </div>

        </div>
      </div>
      <hr />
      <div className="difficultyLevel">
        <div className="form-group">
          <label>
            {t("difficultyLevel")}
          </label>
          <select
            name="difficultyLevel"
            id="difficultyLevel"
            value={smallGroupState.difficultyLevel}
            onChange={handleStateChanges}
          >
            <option value={0}>{basic}</option>
            <option value={1}>{advanced}</option>
            <option value={2}>{expert}</option>
          </select>
          {errObject?.difficultyLevel &&
            smallGroupState.difficultyLevel === "" && (
              <div className="validation-error yellow-global">
                {errObject?.difficultyLevel}
              </div>
            )}
        </div>
        <button disabled={isLoading} onClick={handleCreateOrUpdate}>
          {t("save")}
        </button>
      </div>
    </div>
  );
};
export default BusinessCenterServiceCreateSmallGroup;
