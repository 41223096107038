import AuthContext from "../../utility/context/auth-context";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { SignUpFormsAction } from "../../redux/actions/registration/SignUpFormsAction";
import TrainerOnboardingTop from "../onboarding/TrainerOnboarding/TrainerOnboardingTop";
import { deleteUserDataFromStore } from "../../redux/actions/registration/OnboardingAction";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";


const NavigationBar = () => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const logoutHandler = () => {
    // dispatch(deleteUserDataFromStore())
    history.replace("/");
    authCtx.logout();
    setTimeout(() => dispatch(deleteUserDataFromStore()), 2000);


  };
  const handleRedirectionToHome = () => {
    history.replace("/");
    dispatch(SignUpFormsAction({ registrationStep: "login" }));
  };

  return (
    <div className="navigationBar">
      <h1 onClick={handleRedirectionToHome}>{t("skulp")}</h1>
      <div className="reverse_button">
        <div className="languageButton">
          <p
            className={i18n.language === "de" ? "active" : ""}
            onClick={() => {
              changeLanguage("de");
              localStorage.setItem("language", "de");
            }}
          >{t("DE")}</p>
          <p
            className={i18n.language === "en" ? "active" : ""}
            onClick={() => {
              changeLanguage("en");
              localStorage.setItem("language", "en");
            }}
          >{t("EN")}</p>
        </div>
        {isLoggedIn ?
          <button onClick={logoutHandler}>{t("Logout")}</button> :
          <button onClick={handleRedirectionToHome}>{t("Login")}</button>
        }
      </div>
      <TrainerOnboardingTop isLoggedIn={isLoggedIn} />
    </div>
  );

};

export default NavigationBar;
