import React, {useState} from "react";
import { useTranslation } from "react-i18next";


const TrainerOnboardingProcessAboutSocial = (props: any) => {
    const { t } = useTranslation();

    return (
        <div className="TrainerOnboardingProcessAboutSocial">
            <div className="card">
                <div className="card_content">
                    <div className="card_heading">
                        <h1>{t("social")}</h1>
                        <p>{t("simply type or copy and paste your username or handle")}</p>
                        <hr/>
                    </div>
                    <div className="social-inputs">
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="facebook">{t("aboutYouFacebook")}</label>
                                <input type="text" id={'facebook'} value={props.facebook} onChange={(e) => props.setFacebook(e.target.value)}
                                       placeholder={t("username")}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="instagram">{t("aboutYouInstagram")}</label>
                                <input type="text" id={'instagram'} placeholder={t("username")} value={props.instagram}
                                       onChange={(e) => props.setInstagram(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="twitter">{t("aboutYouTwitter")}</label>
                                <input type="text" id={'twitter'} placeholder={t("username")} value={props.twitter}
                                       onChange={(e) => props.setTwitter(e.target.value)}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="linkedin">{t("aboutYouLinkedIn")}</label>
                                <input type="text" id={'linkedin'} placeholder={t("username")}
                                       value={props.linkedIn} onChange={(e) => props.setLinkedIn(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="spotify">{t("aboutYouSpotify")}</label>
                                <input type="text" id={'spotify'} placeholder={t("username")} value={props.spotify}
                                       onChange={(e) => props.setSpotify(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default TrainerOnboardingProcessAboutSocial;
