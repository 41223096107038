import {useState} from "react";
import CreateNewPackage from "./CreateNewPackage";
import BusinessCenterPackagesEnable from "./BusinessCenterPackagesEnable";
import BusinessCenterPackagesDisable from "./BusinessCenterPackagesDisable";
import { useTranslation } from "react-i18next";

const BusinessCenterPackageAndSubscriptions = () => {

    const [tab, setTab] = useState<number>(0)
    const [category, setCategory] = useState<number>(0)
    const { t } = useTranslation();

    return (
        <div className="businessCenterPackage">
            <div className="businessCenterHeader">
                <h1>{t("Packages")}</h1>
                <hr/>
            </div>
            <div className="packageTabs">
                <div className={`createNewPackageTab ${tab === 0 ? 'active' : ''}`}
                     onClick={() => setTab(0)}
                >
                    <p>{t("createNewPackage")}</p>
                </div>
                <div className={`enablePackageTab ${tab === 1 ? 'active' : ''}`}
                     onClick={() => setTab(1)}>
                    <p>{t("enabledPackages")}</p>
                </div>
                <div className={`disablePackageTab ${tab === 2 ? 'active' : ''}`}
                     onClick={() => setTab(2)}
                >
                    <p>{t("disabledPackages")}</p>
                </div>
            </div>
            {tab === 0 ? <CreateNewPackage category={category} setCategory={setCategory}/>: null}
            {tab === 1 ? <BusinessCenterPackagesEnable />: null}
            {tab === 2 ? <BusinessCenterPackagesDisable />: null}
        </div>
    )
}
export default BusinessCenterPackageAndSubscriptions
