import {ReactComponent as FacebookSVG} from '../../../images/component_svg/facebookSVG.svg';
import {ReactComponent as InstagramSVG} from '../../../images/component_svg/instagramSVG.svg';
import TwitterPng from '../../../images/twitter.png';
import LinkedInPng from '../../../images/linkedin.png';
import SpotifyPng from '../../../images/spotify.png';
import { useEffect } from "react";

const SocialMediaAccount =  ({ media } : any) => {

  const handleClickSocialMedia = (media : any) =>{
    switch (media.socialMedia) {
      case 0:
        window.open("https://www.facebook.com/" + media.account,'_blank')
        break;

        case 1:
          window.open("https://www.instagram.com/" + media.account,"_blank")
          break;
        case 2:
          window.open("https://www.twitter.com/" + media.account,"_blank")
          break;
        case 3:
          window.open("https://www.linkedin.com/in/" + media.account,"_blank")
          break;
        case 4:
          window.open("https://www.spotify.com/" + media.account,"_blank")
          break;

      default:
        break;
    }
  }

  return(
    <div className="socialMedia" onClick={() => handleClickSocialMedia(media)}>
      <p className={"popup"}>{media.account}</p>
      {media.socialMedia === 0 ?
        <FacebookSVG />
        : null}
      {media.socialMedia === 1 ?
        <InstagramSVG />
        : null}
      {media.socialMedia === 2 ?
        <img src={TwitterPng} alt="twitter" />
        : null}
      {media.socialMedia === 3 ?
        <img src={LinkedInPng} alt="linkedin" />
        : null}
      {media.socialMedia === 4 ?
        <img src={SpotifyPng} alt="spotify" />
        : null}
    </div>
  );
}
export default SocialMediaAccount;
