import React, {useEffect} from "react";
import TrainerOnboardingSetPasswordForm
    from "../../components/onboarding/TrainerOnboarding/TrainerOnboardingSetPasswordForm";
import {setOnboardingStatusAction} from "../../redux/actions/registration/OnboardingAction";
import {useDispatch} from "react-redux";

const TrainerOnboardingSetPasswordPage = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setOnboardingStatusAction(-1))

    },[])

    return(
        <div className="trainerOnboardingSetPasswordPage">
            <div className="skulp_background_init_page">
                <div className="image"/>
            </div>
            <TrainerOnboardingSetPasswordForm />
        </div>
    )
}

export default TrainerOnboardingSetPasswordPage;
