import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BusinessCenterServiceCreatePersonalTraining from "./BusinessCenterServiceCreatePersonalTraining";
import BusinessCenterServiceCreateSmallGroup from "./BusinessCenterServiceCreateSmallGroup";

type BusinessCenterServiceCreateProps = {
  sessionType: number;
  setSessionType: any;
};
const BusinessCenterServiceCreate = ({
  sessionType,
  setSessionType,
}: BusinessCenterServiceCreateProps) => {
  const { t } = useTranslation();
  return (
    <div className="serviceCreation card">
      <div className="serviceType">
        <div className="form-row">
          <input
            type={"radio"}
            value={sessionType}
            id={"personalTrainingSelection"}
            checked={sessionType === 0}
            name={"serviceType"}
            onClick={(e: any) => setSessionType(0)}
          />
          <label htmlFor={"personalTrainingSelection"}>
            {t("personalTrainingSession")}
          </label>
          <input
            type={"radio"}
            value={sessionType}
            id={"smallGroupSelection"}
            name={"serviceType"}
            checked={sessionType === 1}
            onClick={(e: any) => setSessionType(1)}
          />
          <label htmlFor={"smallGroupSelection"}>
            {t("Small Group")}
          </label>
        </div>
      </div>

      {sessionType === 0 ? (
        <BusinessCenterServiceCreatePersonalTraining />
      ) : null}

      {sessionType === 1 ? <BusinessCenterServiceCreateSmallGroup /> : null}
    </div>
  );
};
export default BusinessCenterServiceCreate;
