import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import modalContext from '../../utility/context/modal-context';

export function ThankYouPage() {
  const modalCtx = useContext(modalContext);
  const spaceUrl = useSelector((state) => state.onboarding.publicTrainerData.trainerSpace.spaceUrl);

  useEffect(() => {
    modalCtx.setModalType(3); // 3 is the type for the Thank You modal
    modalCtx.setMessage('');
    modalCtx.setIsActive(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Redirect to={`/me/${spaceUrl || undefined}`} />;
}
