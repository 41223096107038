import React from "react";
import CustomImage from "../../../images/princeakachi.png";
import { useSelector } from "react-redux";
export default function CustomerAvatar() {

  const customerData = useSelector(state => state.customer);


  return (
    <div className="cust-img">
      <img
        src={customerData.profilePictureUri != "" ? `${process.env.REACT_APP_BE_BASEURL}/api/files/${customerData.profilePictureUri}/download` : "https://intern-creativemindz.de/wp-content/uploads/user3.svg"}
        alt="UserImage"
      />
    </div>
  );
}
