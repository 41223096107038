import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { loadCustomerData } from "../../redux/actions/customer/customerAction";

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  role:''
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedUserData = localStorage.getItem('userData');
  const storedExpirationDate = localStorage.getItem('expirationTime');
  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('pictureId');
    localStorage.removeItem('trainerId');
    localStorage.removeItem('personalSpaceImage');
    localStorage.removeItem('role')

    return null;
  }

  return {
    token: storedToken,
    userData: storedUserData,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  const dispatch = useDispatch();

  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  let initRole;
  
  if(tokenData){
    initRole = JSON.parse(tokenData.userData).role
  }

  const [token, setToken] = useState(initialToken);
  const [role, setRole] = useState(initRole);
  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    setRole(null)
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('pictureId');
    localStorage.removeItem('trainerId');
    localStorage.removeItem('personalSpaceImage');
    localStorage.clear()

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, userData, expirationTime) => {
    setToken(token);
    setRole(userData.role)
    localStorage.setItem('token', token);
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('expirationTime', expirationTime);

    if(userData.role ==="User"){
      dispatch(loadCustomerData(userData.userId))
    }

    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    role: role
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
