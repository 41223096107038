// ** Redux Imports
import { combineReducers } from 'redux'


// ** Reducers Imports
import auth from './auth'
import SignUpFormsReducer from "./registration/SignUpFormsReducer";
import onboardingReducer from "./onboarding/onboardingReducer";
import PersonalSpaceReducer from "./personalSpacePreview/personalSpaceReducer";
import LayoutReducer from "./layout/layoutReducer";
import BusinessCenterServiceReducer from "./businessCenter/businessCenterReducer";
import BookingReducer from "./booking/bookingReducer";
import CustomerReducer from "./customer/customerReducer";
import PackageReducer from "./packages/packagesReducer";
import ReportReducer from './reports/reportReducer';

const rootReducer = combineReducers({
  auth:auth,
  registration :SignUpFormsReducer,
  onboarding: onboardingReducer,
  spacePreview: PersonalSpaceReducer,
  layout : LayoutReducer,
  businessCenter: BusinessCenterServiceReducer,
  booking: BookingReducer,
  customer:CustomerReducer,
  package: PackageReducer,
  reports: ReportReducer,
})



export default rootReducer
