import React, { useContext, useEffect, useState } from "react";
import QuestionSVG from "../../../images/component_svg/Question.svg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currencySymbolEnum, feeTypeValue } from "../../../enums/enum";
import AuthContext from "../../../utility/context/auth-context";
import modalContext from "../../../utility/context/modal-context";
import OrderItem from "./OrderItem";
import PackageOrderItem from "./PackageOrderItem";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowSvg } from "../../../images/component_svg/arrowDownSVG.svg";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";

const PackageBookingSummary = ({isSummary} : {isSummary:boolean}) => {

  type bookingDataType = {
    packageId: number,
    customerId: number,
    promotionCodeCode:string,
    termsAccepted: boolean,
  }

  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const selectedPackageForBooking = useSelector(state => state.package.selectedPackageForBooking)
  const customerData = useSelector(state => state.customer)
  const dispatch = useDispatch();
  const history = useHistory()
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext<any>(modalContext);

  const { i18n , t} = useTranslation();

  const promotionCodePlaceholder = t("promotion code...");
  const forBookingYouHaveToBeLoggedIn = t("forBookingYouHaveToBeLoggedIn");

  const bookASession = async (e: any) => {
      const url = `${process.env.REACT_APP_BE_BASEURL}/api/packages/purchase`;
      let data: bookingDataType = {
        packageId: selectedPackageForBooking.id,
        customerId: customerData.id,
        promotionCodeCode: "",
        termsAccepted: dataPolicy,

      };

      if (authCtx.isLoggedIn) {
        dispatch(setIsLoading(true));

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
            "Content-Type": "application/json",
            Authorization: "Bearer " + authCtx.token
          },
          body: JSON.stringify(data)
        });
        setTimeout(() => dispatch(setIsLoading(false)),400)

        if (response.ok) {
          const res = await response.json();
          window.location.href =  res.data.packageCheckoutSessionUrl
          modalCtx.setModalType(0);
          modalCtx.setMessage(t("Your booking was successful."));
          modalCtx.setIsActive(true);
        } else {
          const res = await response.json();
          modalCtx.setModalType(0);
          modalCtx.setMessage(res.message);
          modalCtx.setIsActive(true);
        }
      } else {
        modalCtx.setModalType(0);
        modalCtx.setMessage(forBookingYouHaveToBeLoggedIn);
        modalCtx.setIsActive(true);
      }
  };

  const currencySymbol = Object.values(currencySymbolEnum)[publicTrainerData.currency];
  const [serviceFee, setServiceFee] = useState<number>(0);
  const [showPolicy,setShowPolicy] = useState<boolean>(false);

  useEffect(() => {
    if(publicTrainerData.feeType === feeTypeValue.PaidByTrainer){
      setServiceFee(0)
    }
    if(publicTrainerData.feeType === feeTypeValue.Shared){
      setServiceFee(selectedPackageForBooking.price * (publicTrainerData.feeFactor/2))
    }
    if(publicTrainerData.feeType === feeTypeValue.PaidByCustomer){
      setServiceFee(selectedPackageForBooking.price * (publicTrainerData.feeFactor))
    }
  }, [selectedPackageForBooking,publicTrainerData]);

  const [dataPolicy, setDataPolicy] = useState<boolean>(false);

  return (
    <div className={`bookingSummery ${isSummary ? "isSummary-Summary": ""}`}>
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h2>{t("Order summary")}</h2>
            <hr />
          </div>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (dataPolicy) {
              bookASession(e);
            }
          }}>
            <div className="summery">
              <div className="order-items">

                <PackageOrderItem serviceFee={serviceFee}  item={selectedPackageForBooking} />

                <div className="summery_bottom">
                  <hr />
                  <p>{t("total price")} : {serviceFee + selectedPackageForBooking.price}{"\t"}{currencySymbol}
                  </p>
                  <div className="agreePolicy">
                        <div className="agreeTerm">
                        <input
                          checked={dataPolicy}
                          onChange={() => setDataPolicy(!dataPolicy)}
                          type="checkbox"
                        />
                          {t("I agree with the")}
                          <div className="policy" onClick={() => window.open("https://skulp.de/agb","_blank")} > AGB </div> {t("and")}
                          <div className="policy" onClick={() => window.open("https://skulp.de/datenschutz","_blank")} > {t("Privacy Policy")}</div>
                          {t("agreed")}
                        </div>
                        <div className="agreeTerm">
                        </div>
                      </div>

                  <button className={!dataPolicy  ? "disabled" : ""} type={"submit"}>{t("bookPackage")}</button>
                  <div className="cancelationPlicy" onClick={() => setShowPolicy(!showPolicy)}>
                    <div className="parent">
                      <span  >{t("cancellationPolicy")}</span>
                      <ArrowSvg style={showPolicy ? {transform:"rotate(180deg)"}:{}} />
                    </div>
                    <div className="hidden_box" style={showPolicy ? {} : {height: 0}}>
                    {publicTrainerData.isCancellationAllowed ? <>
                        <p>{t("if you cancel {{hours}} hours before, you get 100% back.", {hours : publicTrainerData.cancellation100_Deadline})}</p>
                        <p>{t("if you cancel {{hours}} hours before, you get 50% back.", {hours : publicTrainerData.cancellation50_Deadline})}</p>
                        <p>{t("if you cancel within {{hours}} hours, you will get 0% back", {hours : publicTrainerData.cancellation50_Deadline})}</p> 
                      </> : 
                        <p>{t("In case of cancellation on your part, no money will be refunded")}</p> 
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PackageBookingSummary;
