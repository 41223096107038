import ContactInfoForm from "./ContactInfoForm";
import { useTranslation } from "react-i18next";

const ContactInformation = () => {
  const {t} = useTranslation();

  return (
    <div className="businessCenterContactInformation">
      <div className="businessCenterHeader">
        <h1>{t("Contact Information")}</h1>
        <hr />
        <ContactInfoForm/>
      </div>
    </div>
  )
}
export default ContactInformation
