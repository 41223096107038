import React, { useEffect, useState } from "react";
import WeeklyRoutine from "./WeeklyRoutine";
import CalendarOptions from "./CalendarOptions";
import { useDispatch, useSelector } from "react-redux";
import { getCalendar } from "../../../redux/actions/businessCenter/calendarAction";
import WeeklyView from "./WeeklyView";
import CalendarSynchronization from "./CalendarSynchronization";
import { getBookingPolicy } from "../../../redux/actions/booking/bookingAction";
import { useTranslation } from "react-i18next";

const BusinessCenterCalendar = () => {
  const [tab, setTab] = useState<number>(0);
  const calendarData = useSelector(state => state.businessCenter.calendar);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCalendar());

  }, [tab]);

  return (
    <div className="businessCenterCalendar">
      <div className="businessCenterHeader">
        <h1>{t("Calendar")}</h1>
        <hr />
      </div>
      <div className="serviceTabs">
        <div className={` ${tab === 0 ? "active" : ""}`}
             onClick={() => setTab(0)}
        >
          <p>{t("availabilities")}</p>
        </div>
        <div className={` ${tab === 1 ? "active" : ""}`}
             onClick={() => setTab(1)}>
          <p>{t("weekly view")}</p>
        </div>
       <div className={` ${tab === 2 ? "active" : ""}`}
             onClick={() => setTab(2)}
        >
          <p>{t("calendar sync")}</p>
        </div>
      </div>

      <div className={"calendarContent"}>
        <div className="content">
          {tab === 0 ? (
            <div className="row">
              <WeeklyRoutine />
              <CalendarOptions />
            </div>
          ) : null}
          {tab === 1 ? (
            <WeeklyView />
          ) : null}
          {tab === 2 ? (
            <CalendarSynchronization />
          ) : null}


        </div>
      </div>

    </div>
  );
};

export default BusinessCenterCalendar;
