import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SignUpFormsAction } from "../../../redux/actions/registration/SignUpFormsAction";
import ModalContext from "../../../utility/context/modal-context";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import { emailRegex } from "../../../enums/enum";

const TrainerLoginOrSignUpForm = () => {
  const [email, setEmail] = useState<string>("");
  const [emailErr, setEmailErr] = useState<string>("");
  const modalCtx = useContext<any>(ModalContext);
  const dispatch = useDispatch();
  const { i18n , t} = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!email) {
      setEmailErr(t("email is required"));
      return;
    }
    if (!email.match(emailRegex)) {
      setEmailErr(t("errEmailInvalid"));
      return;
    }
    setEmailErr("");
    register();
  };

  const register = () => {
    dispatch(setIsLoading(true));

    fetch(`${process.env.REACT_APP_BE_BASEURL}/api/Registration/trainer`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setTimeout(() => dispatch(setIsLoading(false)),400)

        if (res.ok) {
          dispatch(
            SignUpFormsAction({
              email: email,
              registrationStep: "emailverification",
            })
          );

          return res.json();
        } else {
          return res.json().then((data) => {
            modalCtx.setModalType(0);
            modalCtx.setMessage(data.message);
            modalCtx.setIsActive(true);
          });
        }
      })
      .catch((err) => {
      });
  };

  return (
    <div className="trainerLoginOrSignUpForm">
      <h1>{t("SignUp")}</h1>
      <p className={"haveAccount"}>
        {t("Already have an account?")}
        <a
          onClick={() =>
            dispatch(SignUpFormsAction({ registrationStep: "login" }))
          }
        >
          {" "}
          {t("Login")}
        </a>
      </p>
      <hr />
      <form onSubmit={handleSubmit} style={{ width: "100%" }} className={"registration"} id={"form1"}>
        <div className="form-group">
          <label htmlFor="email">
            {t("email")}{" "}
            <span className="required">*</span>
          </label>
          <input
            type="email"
            id={"email"}
            value={email}
            placeholder={t("enter email")}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailErr ? <div className="error">{emailErr}</div> : null}
        </div>
        <button type={"submit"}>
          {t("SignUp")}
        </button>
      </form>
      <p>
        <a href="https://skulp.de/datenschutz"  target={"_blank"}>
          {t("policyAndTermsOfUse")}
        </a>
      </p>
    </div>
  );
};

export default TrainerLoginOrSignUpForm;
