import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as OnSiteSvg } from "../../../images/component_svg/locationSVG.svg";
import { ReactComponent as VirtualSvg } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as EditSvg } from "../../../images/component_svg/edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../redux/actions/businessCenter/serviceActions";
import BusinessCenterPersonalTrainingServiceListItem from "./BusinessCenterPersonalTrainingServiceListItem";
import ModalContext from "../../../utility/context/modal-context";
import AuthContext from "../../../utility/context/auth-context";
import BusinessCenterGroupTrainingServiceListItem from "./BusinessCenterGroupTrainingServiceListItem";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";

type BusinessCenterServiceEnableProps = {};

const BusinessCenterServiceDisable = () => {


  const modalCtx = useContext<any>(ModalContext);
  const authCtx = useContext(AuthContext);
  const userData = useSelector((state) => state.onboarding.userData);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getServices(userData.id, false));
  }, []);

  const [serviceType, setServiceType] = useState(0);
  const [serviceSelected, setServiceSelected] = useState(-1);

  const serviceArray = useSelector(
    (state) => state.businessCenter.services.serviceArray
  );
  const { i18n, t } = useTranslation();

  const handleEnable = async (id: number) => {
    dispatch(setIsLoading(true));
    const response = await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/services/${id}/enable`,
      {
        method: "PUT",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token
        }
      }
    );
    dispatch(getServices(userData.id, false));
    const data = await response.json();
    setTimeout(() => dispatch(setIsLoading(false)), 200);
    if (response.ok) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(data.message);
      modalCtx.setIsActive(true);
    }
  };

  const handleSetServiceSelected = (id: any) => setServiceSelected(prevState => {
      if (prevState === id) return -1;
      return id;
    }
  );


  const handleDelete = async (id: number) => {
    dispatch(setIsLoading(true));
    const response = await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/services/${id}`,
      {
        method: "DELETE",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",

          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token
        }
      }
    );
    dispatch(getServices(userData.id, false));


    const data = await response.json();
    setTimeout(() => dispatch(setIsLoading(false)),200)


      modalCtx.setModalType(0);
      modalCtx.setMessage(data.message);
      modalCtx.setIsActive(true);
  };


  return (
    <div className="businessCenterServiceDisable">
      <div className="card">
        <div className="serviceType">
          <div className="form-row">
            <input
              type={"radio"}
              value={serviceType}
              id={"personalTrainingSelection"}
              checked={serviceType === 0}
              name={"serviceType"}
              onClick={(e: any) => setServiceType(0)}
            />
            <label htmlFor={"personalTrainingSelection"}>
              {t("personalTraining")}
            </label>
            <input
              type={"radio"}
              value={serviceType}
              id={"smallGroupSelection"}
              name={"serviceType"}
              checked={serviceType === 1}
              onClick={(e: any) => setServiceType(1)}
            />
            <label htmlFor={"smallGroupSelection"}>
              {t("Small Group")}
            </label>
          </div>
        </div>
        {serviceType === 0 ? (
          <div className="servicesListPersonalTraining">
            {serviceArray
              .filter((s: any) => s.category === 0)
              .map((e: any) => {
                if (!e.enabled) {
                  return (
                    <BusinessCenterPersonalTrainingServiceListItem
                      forEnabled={false}
                      handleDelete={() => handleDelete(e.id)}
                      handleEnable={() => handleEnable(e.id)}
                      key={e.key}
                      e={e}
                      setSelectedService={handleSetServiceSelected}
                      isPopupEnabled={serviceSelected === e.id}
                    />
                  );
                }
              })}
          </div>
        ) : null}
        {serviceType === 1 ? (
          <div className="servicesListGroup">
            {serviceArray
              .filter((s: any) => s.category === 1)
              .map((e: any) => {
                if (!e.enabled) {
                  return (
                    <BusinessCenterGroupTrainingServiceListItem
                      forEnabled={false}
                      handleDelete={() => handleDelete(e.id)}
                      handleEnable={() => handleEnable(e.id)}
                      key={e.key}
                      e={e}
                      setSelectedService={handleSetServiceSelected}
                      isPopupEnabled={serviceSelected === e.id}
                    />
                  );
                }
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default BusinessCenterServiceDisable;
