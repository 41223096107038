import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ResponsivePie } from "@nivo/pie";
import BarChart from "../common/BarChart";
import { currencyEnum } from "../../enums/enum";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../utility/context/auth-context";
import { DateTime } from "luxon";
import { setIsLoading } from "../../redux/actions/layout/layoutAction";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";

const DashboardReport = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const userData = useSelector(state => state.onboarding.userData);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const getMonthlyRevenues = async () => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/reports/monthly-revenues`;

    try {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          "Content-Type": "text/plain",
          Authorization: "Bearer " + authCtx.token
        }
      });

      setTimeout(() => dispatch(setIsLoading(false)),200)
      
      if (response.ok) {
        const res = await response.json();
        const helperArray: Array<number> = [];
        for (var propt in res) {
          helperArray.push(res[propt]);
        }
        setMonthlyRevenues(helperArray);
      }
    } catch (e) {
      setTimeout(() => dispatch(setIsLoading(false)),200)
    }
  };

  const getRevenuesByServiceCategory = async () => {
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/reports/revenues-by-service-category?dateFrom=${dateFrom.toUTC()}&dateTo=${dateTo.toUTC()}`;

    try {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          "Content-Type": "text/plain",
          Authorization: "Bearer " + authCtx.token
        }
      });
      setTimeout(() => dispatch(setIsLoading(false)),200)

      if (response.ok) {
        const res = await response.json();
        setRevenuesByServiceCategory(res);
      }
    } catch (e) {
      setTimeout(() => dispatch(setIsLoading(false)),200)
    }

  };

  const [dateFrom, setDateFrom] = useState(DateTime.now().minus({ day: 14 }).set({ hour: 0, minute: 0, second: 0 }));
  const [dateTo, setDateTo] = useState(DateTime.now().set({ hour: 0, minute: 0, second: 0 }));
  const [monthlyRevenues, setMonthlyRevenues] = useState<Array<number>>([]);
  const [revenuesByServiceCategory, setRevenuesByServiceCategory] = useState<{ privateSessionsRevenue: number, smallGroupsRevenue: number }>({
    privateSessionsRevenue: 0,
    smallGroupsRevenue: 0
  });
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const [reponsivePieMargin,setResponsivePieMargin] = useState(20);

  const handleResize = () => {
    if(window.innerWidth <500 ){
      setResponsivePieMargin(50);
    }else if(window.innerWidth <968){
      setResponsivePieMargin(40)
    }else if(window.innerWidth <1100){
      setResponsivePieMargin(55)
    }else if(window.innerWidth <1250){
      setResponsivePieMargin(60)
    }else if(window.innerWidth <1600){
      setResponsivePieMargin(65)
    }else{
      setResponsivePieMargin(20)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize();
  }, []);

  useEffect(() => {
    getRevenuesByServiceCategory();
  }, [dateFrom, dateTo]);
  
  useEffect(() => {
    getMonthlyRevenues();
  }, []);

  const data = [
    {
      "id": "Private",
      "label": t("private"),
      "value": revenuesByServiceCategory.privateSessionsRevenue,
      "color": "#FDFC00"
    },
    {
      "id": "Small Group",
      "label": t("Small Group"),
      "value": revenuesByServiceCategory.smallGroupsRevenue,
      "color": "#FF045D"
    }
  ];

  const labels = [t("jan."), t("feb."), t("mar."), t("apr."), t("may."), t("jun."), t("jul."), t("aug."), t("sep."), t("oct."), t("nov."), t("dec.")];

  return (
    <div className="dashboardReport">

      <div className="card">
        <div className="card_heading">
          <div className="header">
            <h3>{t("Report")}</h3>
            <hr />
          </div>
          <div className="date-range-picker">

            <DatePicker
              id={"dateFrom"}
              onChange={(date) => {
                if(date !== null){
                  setDateFrom(DateTime.fromJSDate(date))
                }
              }}
              selected={dateFrom.toJSDate()}
              dateFormat="dd.MM.yyyy"
              locale={i18n.language === "de" ? de : ""}
              calendarStartDay={1}
            />
            <span> - </span>
            <DatePicker
              id={"dateTo"}

              onChange={(date) => {
                if(date !== null){
                  setDateTo(DateTime.fromJSDate(date))
                }
              }}
              selected={dateTo.toJSDate()}
              dateFormat="dd.MM.yyyy"
              locale={i18n.language === "de" ? de : ""}
              calendarStartDay={1}
            />
          </div>

        </div>
        <div className="charts">
          <BarChart
            values={monthlyRevenues}
            labels={labels}
          />
          <div className="pieChart">
            <p>{t("split revenue")}</p>
            <ResponsivePie
              data={data}
              margin={{ top: reponsivePieMargin, right: reponsivePieMargin, bottom: reponsivePieMargin, left: reponsivePieMargin }}
              innerRadius={0.5}
              padAngle={4}
              cornerRadius={0}
              renderWrapper={true}
              activeOuterRadiusOffset={5}
              borderWidth={0}
              enableArcLabels={false}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#e2e2e2"
              arcLinkLabelsThickness={5}
              arcLinkLabelsColor={(d) => d.color}
              arcLabelsSkipAngle={10}
              arcLinkLabel={d => `${d.label}  ⌀ ${d.value}  ${currencyEnum[Number(userData.currency)]}`}
              colors={["#FDFC00", "#FF045D"]}
              activeInnerRadiusOffset={0}
             arcLabel={""}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardReport;
