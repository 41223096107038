import { useTranslation } from "react-i18next";

const defaultImage =
  "https://intern-creativemindz.de/wp-content/uploads/user3.svg";
const TrainerOnboardingProcessAboutGeneral = (props: any) => {
  const { spokenLanguagesErr, whereFindErr, perofilePictureErr } = props;
  const hasError = () => {
    return !!(props.profilePictureURL === "defaultImage" && perofilePictureErr);
  };
  const { t } = useTranslation();

  return (
    <div className="TrainerOnboardingProcessAboutGeneral">
      <div className="card">
        <div className="card_content">
          <div className="profile_picture">
            <h1>
              {t("General")}
            </h1>
            <hr />
            <div className="image_circle">
              <img src={props.profilePictureURL} alt={"profilePicture"} />
            </div>
            <p>
              {t("profilePicture")}
            </p>
            <label
              htmlFor="upload_image"
              className={`upload_image ${hasError() && "border-red"}`}
            >
              <p>
                {t("uploadImage")}
              </p>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                id={"upload_image"}
                onChange={props.uploadImageFile}
                placeholder={"upload image"}
              />
            </label>
            <p className="muted">
              {t("profilePictureDimensions")}
            </p>
            {hasError() && (
              <div className="validation-error yellow-global">
                {perofilePictureErr}
              </div>
            )}
          </div>
          <hr className={"hr_Vertical"} />
          <div className="profole_form">
            <div className="language">
              <p>
                {t("spoken languages")}
                <span className="required">*</span>
              </p>
              <div className="form-row checkbox-row" ref={props.checkboxRow}>
                <input
                  type="checkbox"
                  id="en"
                  name="checkbox_language"
                  checked={props.spokenLanguages["0"]}
                  onChange={(e) =>
                    props.setSpokenLanguages({
                      ...props.spokenLanguages,
                      0: e.target.checked,
                    })
                  }
                />
                <label htmlFor="en" className={"languageLable"}>
                  {t("English")}
                </label>
                <input
                  type="checkbox"
                  id="fr"
                  name="checkbox_language"
                  checked={props.spokenLanguages["1"]}
                  onChange={(e) =>
                    props.setSpokenLanguages({
                      ...props.spokenLanguages,
                      1: e.target.checked,
                    })
                  }
                />
                <label htmlFor="fr" className={"languageLable"}>
                  {t("French")}
                </label>
                <input
                  type="checkbox"
                  id="de"
                  name="checkbox_language"
                  checked={props.spokenLanguages["2"]}
                  onChange={(e) =>
                    props.setSpokenLanguages({
                      ...props.spokenLanguages,
                      2: e.target.checked,
                    })
                  }
                />
                <label htmlFor="de" className={"languageLable"}>
                  {t("German")}
                </label>
              </div>
              {spokenLanguagesErr && (
                <div className="validation-error yellow-global">
                  {spokenLanguagesErr}
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="personalWebsite">
                  {t("personal website")}
                </label>
                <input
                  type="text"
                  id="personalWebsite"
                  value={props.personalWebsite}
                  onChange={(e) => props.setPersonalWebsite(e.target.value)}
                  placeholder="http://www.myspace.com/me"
                />
              </div>
              <div className="form-group">
                <label htmlFor="findUs">
                  {t("Where did you find us?")}
                  <span className="required">*</span>
                </label>
                <select
                  id="findUs"
                  required={true}
                  value={props.websiteDiscoverySource}
                  onChange={(e) =>
                    props.setWebsiteDiscoverySource(e.target.value)
                  }
                >
                  <option value={0}>
                    {t("From a friend")}
                  </option>
                  <option value={1}>
                    {t("Social media")}
                  </option>
                  <option value={2}>
                    {t("Internet")}
                  </option>
                  <option value={3}>
                    {t("Events")}
                  </option>
                  <option value={4}>
                    {t("Others")}
                  </option>
                </select>
                {whereFindErr && <div className="error">{whereFindErr}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerOnboardingProcessAboutGeneral;
