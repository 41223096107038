import { useContext, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import TrainerOnboardingProcessAboutGeneral from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessAboutGeneral";
import TrainerOnboardingProcessAboutSocial from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessAboutSocial";
import TrainerOnboardingProcessAboutExpertise from "../../onboarding/TrainerOnboarding/TrainerOnboardingProcessAboutExpertise";
import AuthContext from "../../../utility/context/auth-context";
import ModalContext from "../../../utility/context/modal-context";
import { useHistory } from "react-router-dom";
import { setTrainerData } from "../../../redux/actions/registration/OnboardingAction";
import { imageUploadType } from "../../../enums/enum";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import { DateTime } from "luxon";

type experiencesObject = {
  Activities: string;
  field: string;
  id: string;
  periodForm: string;
  periodTo: string;
  position: string;
  error: boolean;
};
type educationsObject = {
  // field: string;
  id: string;
  institution: string;
  yearOfDegree: string;
  degree: string;
  focus: string;
  error: boolean;
};

const formatDate = (d: any) => {
  let splitDate = d.split("-");
  // @ts-ignore
  let date = new Date();
  date.setFullYear(
    Number(splitDate[0]),
    Number(splitDate[1]) - 1,
    Number(splitDate[2])
  );
  return date;
};

const AboutYou = () => {
  const userData = useSelector((state) => state.onboarding.userData);
  const {i18n} = useTranslation();
  //General State
  const [profilePictureURL, setProfilePictureURL] = useState<string>(
    "https://intern-creativemindz.de/wp-content/uploads/user3.svg"
  );
  const [spokenLanguagesErr, setSpokenLanguagesErr] = useState<string>("");

  const [spokenLanguages, setSpokenLanguages] = useState({
    0: userData?.spokenLanguages.includes(0) ? true : false,
    1: userData?.spokenLanguages.includes(1) ? true : false,
    2: userData?.spokenLanguages.includes(2) ? true : false,
  });
  const [whereFindErr, setWhereFindErr] = useState<string>("");
  const [perofilePictureErr, setPerofilePictureErr] = useState<string>("");
  const [personalWebsite, setPersonalWebsite] = useState(
    userData.personalWebsite  || ""
  );
  const [disciplines,setDisciplines] = useState(userData.disciplines);
  const [websiteDiscoverySource, setWebsiteDiscoverySource] = useState(
    userData?.websiteDiscoverySource || 0
  );
  //Social State
  const getSocialMediaAcc = (type: number): string => {
    let value = "";
    if (
      !userData?.socialMediaAccounts ||
      userData?.socialMediaAccounts?.length < 1
    ) {
      return value;
    }
    for (const account of userData?.socialMediaAccounts) {
      if (account?.socialMedia === type) {
        value = account?.account;
        break;
      }
    }

    return value;
  };
  const [facebook, setFacebook] = useState(getSocialMediaAcc(0));
  const [instagram, setInstagram] = useState(getSocialMediaAcc(1));
  const [twitter, setTwitter] = useState(getSocialMediaAcc(2));
  const [linkedIn, setLinkedIn] = useState(getSocialMediaAcc(3));
  const [spotify, setSpotify] = useState(getSocialMediaAcc(5));
  //Expertise State
  const [specialties, setSpecialties] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const getExperienceObj = (): [experiencesObject] => {
    const data: [experiencesObject] = [
      {
        Activities: "",
        field: "",
        id: "",
        periodForm: "",
        periodTo: "",
        position: "",
        error: false,
      },
    ];
    if (!userData?.experiences.length) {
      return data;
    }
    data.splice(0, 1);
    for (const exp of userData?.experiences) {
      data.push({
        Activities: exp.field,
        field: exp.field,
        id: exp.id,
        periodForm: exp.from,
        periodTo: exp.to,
        position: exp.position,
        error: false,
      });
    }

    return data;
  };
  const [experiences, setExperiences] = useState<[experiencesObject]>(
    getExperienceObj()
  );

  const getEducationsObj = (): [educationsObject] => {
    const data: [educationsObject] = [
      {
        id: "0",
        institution: "",
        yearOfDegree: "",
        degree: "",
        focus: "",
        error: false,
      },
    ];
    if (!userData?.educations.length) {
      return data;
    }
    data.splice(0, 1);
    for (const edu of userData?.educations) {
      data.push({
        id: edu.id,
        institution: edu.institution,
        yearOfDegree: edu.yearOfDegree,
        degree: edu.degree,
        focus: edu.focus,
        error: false,
      });
    }
    return data;
  };
  const [educations, setEducations] = useState<[educationsObject]>(
    getEducationsObj()
  );

  const [visionAndMission, setVisionAndMission] = useState(
    userData?.visionAndMission || ""
  );
  const checkboxRow = useRef<any>();
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext<any>(ModalContext);
  const history = useHistory();

  const placeholderImage =
    "https://intern-creativemindz.de/wp-content/uploads/user3.svg";
  const localStoragePictureIdIfNoImageWasSet = "user3.svg";
  const { t } = useTranslation();

  useEffect(() => {
    if (
/*      localStorage.getItem("pictureId") !==
        localStoragePictureIdIfNoImageWasSet &&*/
      userData.profilePictureUri !== placeholderImage &&
      userData.profilePictureUri !== ""
    ) {
      setProfilePictureURL(
        `${process.env.REACT_APP_BE_BASEURL}/api/files/${userData.profilePictureUri}/download`
      );
    } else {
      setProfilePictureURL(
        "https://intern-creativemindz.de/wp-content/uploads/user3.svg"
      );
    }
    dispatch(setTrainerData());
  }, [userData.profilePictureUri]);

  //Upload image file
  const uploadImageFile = async (e: any) => {
    let data = new FormData();
    data.append("file", e.target.files[0]);
    dispatch(setIsLoading(true));

    const response = await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/trainers/upload?type=${imageUploadType.TrainerProfile}`,
      {
        method: "PUT",
        body: data,
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          Authorization: "Bearer " + authCtx.token,
        },
      }
    ).catch((e) => e);
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response.ok) {
      dispatch(setTrainerData());

      const res = await response.json();
      setProfilePictureURL(
        `${process.env.REACT_APP_BE_BASEURL}/api/files/${res.data.id}/download`
      );
    } else {
      const res = await response.json();


      modalCtx.setModalType(0);
      modalCtx.setMessage(t(res.message));
      modalCtx.setIsActive(true);
    }
  };

  const validateLang = (): Array<number> => {
    const val: number[] = [];
    Object.entries(spokenLanguages).map((item) => {
      if (item[1] === true) {
        val.push(Number(item[0]));
      }
    });
    return val;
  };

  const handleExpFormValidation = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let valid = true;
      const process = experiences.map((item) => {
        item.error = false;
        if (
          !item.Activities ||
          !item.periodForm ||
          !item.periodTo ||
          !item.position
        ) {
          valid = false;
          item.error = true;
        }
        return item;
      });
      Promise.all(process).then((res) => {
        const result = res as any;
        setExperiences(result);
        resolve(valid);
      });
    });
  };

  const handleEduFormValidation = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let valid = true;
      const process = educations.map((item) => {
        item.error = false;
        if (
          !item.institution ||
          !item.degree ||
          !item.yearOfDegree ||
          !item.focus
        ) {
          valid = false;
          item.error = true;
        }
        return item;
      });
      Promise.all(process).then((res) => {
        const result = res as any;
        setEducations(result);
        resolve(valid);
      });
    });
  };

  const handleValidateInputs = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let status = true;
      if (validateLang().length < 1) {
        setSpokenLanguagesErr(t("errSpokenLang"));
        status = false;
      } else {
        setSpokenLanguagesErr("");
      }

      if (websiteDiscoverySource === null) {
        setWhereFindErr(
          t("errWebsiteDiscoverySource")
        );
        status = false;
      } else {
        setWhereFindErr("");
      }
 /*     if (!userData.profilePictureUri) {
        setPerofilePictureErr("Profile picture required");
        status = false;
      } else {
        setPerofilePictureErr("");
      }*/
      //const validateExp = await handleExpFormValidation();
      //const validateEdu = await handleEduFormValidation();
      //if (!validateExp || !validateEdu) {
      //  status = false;
      //}
        resolve(status);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isFormValid = await handleValidateInputs();
    let urlRegEx = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');

    let facebookRegex = urlRegEx.test(String(facebook));
    let instagramRegex = urlRegEx.test(String(instagram));
    let twitterRegex = urlRegEx.test(String(twitter));
    let linkedInRegex = urlRegEx.test(String(linkedIn));
    let spotifyRegex = urlRegEx.test(String(spotify));

    if((facebookRegex && facebook.length !== 0) ||
    (instagramRegex && instagram.length !== 0) ||
    (twitterRegex && twitter.length !== 0) ||
    (linkedInRegex && linkedIn.length !== 0) ||
    (spotifyRegex && spotify.length !== 0)){
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("please enter a valid social media account username"));
      modalCtx.setIsActive(true);
      return;
    }
    if (!isFormValid) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("please fill all the required fields"));
      modalCtx.setIsActive(true);
      return;
    }

    let selection: number[] = validateLang();
    let experiencesArray: any = [];
    let educationArray: any = [];
    let sozialMediaObject: any = [];

    // @ts-ignore
    experiences.forEach((e, index) => {
      experiencesArray.push({
        id: index.toString(),
        position: e.position,
        periodFrom: formatDate(e.periodForm),
        periodTo: formatDate(e.periodTo),
        field: e.Activities,
      });
    });

    // @ts-ignore
    educations.forEach((e, index) => {
      educationArray.push({
        id: index.toString(),
        field: index.toString(),
        institution: e.institution,
        yearOfDegree: !e.yearOfDegree ? 0 : e.yearOfDegree,
        degree: Number(e.degree),
        focus: e.focus,
      });
    });

    const media = [facebook, instagram, twitter, linkedIn, spotify];
    media.forEach((e, i) => {
      if (e !== "")
        sozialMediaObject.push({
          socialMedia: i,
          account: e,
        });
    });

    const data = {
      ...userData,
      onboardingStatus: 5,
      spokenLanguages: [...selection],
      websiteDiscoverySource: Number(websiteDiscoverySource),
      socialMediaAccounts: sozialMediaObject,
      //disciplines: disciplines,
      yearsOfExperience: Number(yearsOfExperience),
      experiences: [...experiencesArray],
      educations: [...educationArray],
      visionAndMission: visionAndMission,
      personalWebsite: personalWebsite,
    };
    dispatch(setIsLoading(true));

    await fetch(
      `${process.env.REACT_APP_BE_BASEURL}/api/trainers/user`,
      {
        method: "PUT",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify(data),
      }
    )
      .then(async (data) => {
        const res = await data.json();
        setTimeout(() => dispatch(setIsLoading(false)),400)


        if (data.status === 200) {
          dispatch(setTrainerData());
          modalCtx.setModalType(0);
          modalCtx.setMessage(res.message);
          modalCtx.setIsActive(true);
        } else {
          modalCtx.setModalType(0);
          modalCtx.setMessage(res.message);
          modalCtx.setIsActive(true);


        }
      })
      .catch( (e) => {
        modalCtx.setModalType(0);
        modalCtx.setMessage(e);
        modalCtx.setIsActive(true);


      });
  };
  useEffect(() => {
    //init values after reload
    setSpokenLanguages({
      0: userData?.spokenLanguages.includes(0) ? true : false,
      1: userData?.spokenLanguages.includes(1) ? true : false,
      2: userData?.spokenLanguages.includes(2) ? true : false,
    })
    setPersonalWebsite(userData?.personalWebsite || "");
    setWebsiteDiscoverySource(   userData?.websiteDiscoverySource || 0)
    setFacebook(getSocialMediaAcc(0))
    setInstagram(getSocialMediaAcc(1))
    setTwitter(getSocialMediaAcc(2))
    setLinkedIn(getSocialMediaAcc(3))
    setSpotify(getSocialMediaAcc(5))

    setExperiences(getExperienceObj())
    setEducations(getEducationsObj())
    setVisionAndMission(userData?.visionAndMission || "")


  },[userData.id])

  return (
    <div className="businessCenterService">
      <div className="businessCenterHeader">
        <h1>{t("aboutYouCapital")}</h1>
        <hr />
      </div>

      <div className={"aboutYouContent"}>
        <div className="content">
          <form onSubmit={handleSubmit}>
            <TrainerOnboardingProcessAboutGeneral
              spokenLanguagesErr={spokenLanguagesErr}
              whereFindErr={whereFindErr}
              profilePictureURL={profilePictureURL}
              uploadImageFile={uploadImageFile}
              spokenLanguages={spokenLanguages}
              setSpokenLanguages={setSpokenLanguages}
              personalWebsite={personalWebsite}
              setPersonalWebsite={setPersonalWebsite}
              websiteDiscoverySource={websiteDiscoverySource}
              setWebsiteDiscoverySource={setWebsiteDiscoverySource}
              checkboxRow={checkboxRow}
              perofilePictureErr={perofilePictureErr}
            />
            <TrainerOnboardingProcessAboutSocial
              facebook={facebook}
              setFacebook={setFacebook}
              instagram={instagram}
              setInstagram={setInstagram}
              twitter={twitter}
              setTwitter={setTwitter}
              linkedIn={linkedIn}
              setLinkedIn={setLinkedIn}
              spotify={spotify}
              setSpotify={setSpotify}
            />
            <TrainerOnboardingProcessAboutExpertise
              specialties={specialties}
              setSpecialties={setSpecialties}
              yearsOfExperience={yearsOfExperience}
              setYearsOfExperience={setYearsOfExperience}
              experiences={experiences}
              setExperiences={setExperiences}
              educations={educations}
              setEducations={setEducations}
              visionAndMission={visionAndMission}
              setVisionAndMission={setVisionAndMission}
              isSubmitButton={true}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AboutYou;
