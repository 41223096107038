import DayofWeekRow from "./DayofWeekRow";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
// @ts-ignore
import BookingsService from "../../../shared/services/BookingsService";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalContext from "../../../utility/context/modal-context";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";
import { getCalendar } from "../../../redux/actions/businessCenter/calendarAction";

const WeeklyRoutine = () => {
  const { t } = useTranslation();
  const modalCtx = useContext<any>(ModalContext);
  const dispatch = useDispatch();
  const { isLoading, mutate } = useMutation((data: any) =>
      BookingsService.createDayOfWeek(data), {
      onSuccess() {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("changes have been saved"));
        modalCtx.setIsActive(true);
      },
      onError() {
        modalCtx.setModalType(0);
        modalCtx.setMessage(t("Oops, an error occured"));
        modalCtx.setIsActive(true);
      }
    }
  );
  const { isVirtual, onSite } = useSelector(state => state.onboarding.userData);

  const dayOfWeek = useSelector(
    (state: any) => state.businessCenter.calendar.dayOfWeek
  );
  const loader = useSelector(
    (state: any) => state.businessCenter.calendar.dayOfWeekLoader
  );

  const isLoadingGlobal: boolean = useSelector(
    (state: any) => state.layout.isLoading
  );


  const [dayWiseSlots, setDayWiseSlot] = useState<any>([
    {
      labelId: "monday",
      dayOfWeek: 1,
      isActive: false,
      slots: [
        {
          dayOfWeek: 1,
          startTime: "00:00:00",
          endTime: "01:00:00",
          allowedVirtual: true,
          allowedOnSite: true
        }
      ]
    },
    {
      labelId: "tuesday",
      dayOfWeek: 2,
      isActive: false,
      slots: [
        {
          dayOfWeek: 2,
          startTime: "00:00:00",
          endTime: "01:00:00",
          allowedVirtual: true,
          allowedOnSite: true
        }
      ]
    },
    {
      labelId: "wednesday",
      dayOfWeek: 3,
      isActive: false,
      slots: [
        {
          dayOfWeek: 3,
          startTime: "00:00:00",
          endTime: "01:00:00",
          allowedVirtual: true,
          allowedOnSite: true
        }
      ]
    },
    {
      labelId: "thursday",
      dayOfWeek: 4,
      isActive: false,
      slots: [
        {
          dayOfWeek: 4,
          startTime: "00:00:00",
          endTime: "01:00:00",
          allowedVirtual: true,
          allowedOnSite: true
        }
      ]
    },
    {
      labelId: "friday",
      dayOfWeek: 5,
      isActive: false,
      slots: [
        {
          dayOfWeek: 5,
          startTime: "00:00:00",
          endTime: "01:00:00",
          allowedVirtual: true,
          allowedOnSite: true
        }
      ]
    },
    {
      labelId: "saturday",
      dayOfWeek: 6,
      isActive: false,
      slots: [
        {
          dayOfWeek: 6,
          startTime: "00:00:00",
          endTime: "01:00:00",
          allowedVirtual: true,
          allowedOnSite: true
        }
      ]
    },
    {
      labelId: "sunday",
      dayOfWeek: 0,
      isActive: false,
      slots: [
        {
          dayOfWeek: 0,
          startTime: "00:00:00",
          endTime: "01:00:00",
          allowedVirtual: true,
          allowedOnSite: true
        }
      ]
    }
  ]);

  useEffect(() => {
    const dayWiseSlotsClone = [...dayWiseSlots];
    Object.keys(dayOfWeek[0]).forEach((key: string) => {
      if (dayOfWeek[0][key].length > 0) {
        if (key === "0") {
          dayWiseSlotsClone[dayWiseSlotsClone.length - 1].slots =
            dayOfWeek[0][key];
          dayWiseSlotsClone[dayWiseSlotsClone.length - 1].isActive = true;
        } else {
          dayWiseSlotsClone[parseInt(key) - 1].slots = dayOfWeek[0][key];
          dayWiseSlotsClone[parseInt(key) - 1].isActive = true;
        }
      }
    });
    setDayWiseSlot(dayWiseSlotsClone);
  }, [dayOfWeek, loader]);
  useEffect(() => {
    setTimeout(() => dispatch(getCalendar()), 1000);
  }, []);

  const pushDayWiseSlot = (index: number) => {
    const cloneSlots = [...dayWiseSlots];
    cloneSlots[index].slots.push({
      dayOfWeek: cloneSlots[index].dayOfWeek,
      startTime: "00:00:00",
      endTime: "01:00:00",
      allowedVirtual: true,
      allowedOnSite: true,
      isUpdate: true
    });
    setDayWiseSlot(cloneSlots);
  };

  const deleteSlot = (dayIndex: number, slotIndex: number) => {
    let cloneSlots = [...dayWiseSlots];
    if (cloneSlots[dayIndex].slots.length > 1) {
      cloneSlots[dayIndex].slots = cloneSlots[dayIndex].slots.slice(slotIndex);
    }
    setDayWiseSlot(cloneSlots);
  };

  const handleSlotsToUpdate = (
    dayIndex: number,
    slotIndex: number,
    startTime: string,
    endTime: string
  ) => {
    const cloneSlots = [...dayWiseSlots];
    cloneSlots[dayIndex].slots[slotIndex].startTime = startTime;
    cloneSlots[dayIndex].slots[slotIndex].endTime = endTime;
    cloneSlots[dayIndex].slots[slotIndex]["isUpdate"] = true;
    setDayWiseSlot(cloneSlots);
  };

  const getSlotsToUpdate = () => {
    const slots: any = [];
    dayWiseSlots.forEach((day: any) => {
      if (day.isActive) {
        day.slots.forEach((slot: any) => {
          if (slot.isUpdate) {
            slots.push({
              dayOfWeek: day.dayOfWeek,
              startTime: slot.startTime,
              endTime: slot.endTime,
              allowedVirtual: slot.allowedVirtual,
              allowedOnSite: slot.allowedOnSite
            });
          }
        });
      }
    });
    return slots;
  };

  const handleSlotsToggle = (
    parentIndex: number,
    slotIndex: number,
    value: boolean,
    type: string
  ) => {
    const cloneSlots = [...dayWiseSlots];
    if (type === "virtual") {
      cloneSlots[parentIndex].slots[slotIndex].allowedVirtual = value;
    } else {
      cloneSlots[parentIndex].slots[slotIndex].allowedOnSite = value;
    }
    cloneSlots[parentIndex].slots[slotIndex]["isUpdate"] = true;
    setDayWiseSlot(cloneSlots);
  };

  const updateDayState = (index: number, state: boolean) => {
    const cloneSlots = [...dayWiseSlots];
    cloneSlots[index].isActive = state;
    if (state) {
      cloneSlots[index].slots = cloneSlots[index].slots.map((slot: any) => {
        slot.isUpdate = true;
        return slot;
      });
    }
    setDayWiseSlot(cloneSlots);
  };

  return !loader ? (
    <div className="weeklyRoutine">
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h5>{t("weekly routine")}</h5>
            <p>
              {t("choose your availabilities")}
            </p>
          </div>
          {dayWiseSlots.map((day: any, i: number) => (
            <DayofWeekRow
              index={i}
              handleSlotsToUpdate={handleSlotsToUpdate}
              slots={day.slots}
              deleteSlot={deleteSlot}
              newSlot={pushDayWiseSlot}
              labelId={day.labelId}
              dayOfWeek={day.dayOfWeek}
              handleSlotsToggle={handleSlotsToggle}
              updateDayState={updateDayState}
            />
          ))}
          <button onClick={() => mutate(getSlotsToUpdate())}>
            {isLoading ? t("saving...") : t("save")}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default WeeklyRoutine;
