import StartingPageContent from "../components/startingPage/StartingPageContent";
import TrainerLoginOrSignUpForm from "../components/onboarding/TrainerOnboarding/TrainerLoginOrSignUpForm";
import TrainerLoginForm from "../components/onboarding/TrainerOnboarding/TrainerLoginForm";
import { useContext, useEffect } from "react";
import EMailVerificationForm from "../components/onboarding/TrainerOnboarding/EMailVerificationForm";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingStatusAction } from "../redux/actions/registration/OnboardingAction";
import { changeNavigation } from "../redux/actions/layout/layoutAction";
import ResetPassword from "../components/onboarding/TrainerOnboarding/ResetPassword";
import ResendPasswordInstructions from "../components/onboarding/TrainerOnboarding/ResendPasswordInstructions";
import { useHistory } from "react-router-dom";
import ModalContext from "../utility/context/modal-context";
import { useTranslation } from "react-i18next";

const HomePage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const modalCtx = useContext<any>(ModalContext);
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(changeNavigation(0));
        dispatch(setOnboardingStatusAction(-1));
    }, []);

    useEffect(() => {

        if(history.location.search.split("=")[1] === "false" || history.location.search.split("%3D")[1]==="false"){
            modalCtx.setModalType(0);
            modalCtx.setMessage(t("your verification link has expired or has already been used"))
            modalCtx.setIsActive(true)
        }
    },[])

    const registrationStep = useSelector(
        (state) => state.registration.registrationStep
    );
    const handleForms = () => {
        switch (registrationStep) {
            case "login":
                return <TrainerLoginForm />;
            case "signup":
                return <TrainerLoginOrSignUpForm />;
            case "emailverification":
                return <EMailVerificationForm />;
            case "resetPassword":
                return <ResetPassword />
            case "resendInstructions":
                return <ResendPasswordInstructions />
        }
    };

    return (
        <div className="homepage">
            <StartingPageContent />
            {handleForms()}
        </div>
    );
};

export default HomePage;
//<TrainerLoginOrSignUpForm />
