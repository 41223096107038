import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { countrysEnum, currencySymbolEnum, feeTypeEnum, ModalEnum } from "../../../enums/enum";
import AuthContext from "../../../utility/context/auth-context";
import modalContext from "../../../utility/context/modal-context";
import { DateTime } from "luxon";
import OrderItem from "./OrderItem";
import { setTotalPrice } from "../../../redux/actions/booking/bookingAction";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowSvg } from "../../../images/component_svg/arrowDownSVG.svg";
import { setIsLoading } from "../../../redux/actions/layout/layoutAction";



const BookingSummary = ({ isSummary } : {isSummary:boolean} ) => {


  type serviceBookingRequest = {
    serviceId?: number,
    serviceType?: number,
    disciplines?: Array<string>,
    durationInMinutes?: number,
    startTime: string,
    endTime: string,
    serviceCategory?: number,
    serviceAmount: number,
    serviceFee: number,
    travelExpense: number
    totalPrice: number
  }
  type bookingDataType = {
    serviceBookingRequests: Array<serviceBookingRequest>
    promotionCodeCode: string,
    packageId?: number | null,
    customerAddress?: {},
    termsAccepted?: boolean,
  }

  const selectedService = useSelector(state => state.businessCenter.services.selectedForBooking);
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const selectedTime = useSelector(state => state.booking.selectedTime);
  const selectedPackageItem = useSelector(state => state.customer.selectedPackageItem);
  const selectedCreditItem  = useSelector(state => state.customer.selectedCreditItem);
  const { totalPrice } = useSelector(state => state.booking);
  const needUpdate = useSelector(state => state.booking.needUpdate);
  const isRecalculate = useSelector(state => state.booking.isRecalculate);
  const travelData = useSelector(state => state.booking.travelData);


  const dispatch = useDispatch();

  useEffect(() => {
    let amount: number = 0;
    selectedTime.forEach((item) => {
      amount += Number(item.totalPrice);
    });
    dispatch(setTotalPrice(amount));

  }, [selectedTime, isRecalculate]);


  const authCtx = useContext(AuthContext);
  const modalCtx = useContext<any>(modalContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const promotionCodePlaceholder = t("promotion code...");
  const forBookingYouHaveToBeLoggedIn = t("forBookingYouHaveToBeLoggedIn");
  const [showPolicy,setShowPolicy] = useState<boolean>(false)

  const [dataPolicy, setDataPolicy] = useState<boolean>(false);


  const bookASession = async (e: any) => {
    e.preventDefault();
    let url;
    if(selectedService.selectedServiceType === 2){
      url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/multiple?forWaitingList=false&outwardJourneyDuration=${travelData.outwardJourneyDuration}&returnJourneyDuration=${travelData.returnJourneyDuration}`;
    }else{
      url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/multiple?forWaitingList=false`;

    }

    let data: bookingDataType = {
      promotionCodeCode: "",
      packageId: null,
      serviceBookingRequests: [],
      termsAccepted: dataPolicy
    };

    if (selectedPackageItem.packageId !== -1) {
      data = { ...data, packageId: selectedPackageItem.packageId };
    }
    if (selectedService.selectedServiceType === 2) {
      data = {
        ...data, customerAddress: {
          houseNumber: selectedService.houseNumber,
          address: selectedService.address,
          city: selectedService.city,
          postalCode: selectedService.postalCode,
          country: String(selectedService.country)
        }
      };
    }

    selectedTime.forEach(item => {
      if (selectedService.category === 0 && selectedPackageItem.id === -1) {
        data.serviceBookingRequests.push({
          serviceId: selectedService.id,
          serviceType: selectedService.selectedServiceType,
          disciplines: selectedService.disciplines,
          durationInMinutes: selectedService.selectedDuration,
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: selectedService.category,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: Number(item.travelExpense),
          totalPrice: totalPrice
        });
      }
      if (selectedService.category === 1 && selectedPackageItem.id === -1) {
        data.serviceBookingRequests.push({
          serviceId: item.service.id,
          serviceType: item.service.serviceTypes[0],
          disciplines: item.service.disciplines,
          durationInMinutes: item.service.durationInMinutes[0],
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: 1,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: 0,
          totalPrice: totalPrice
        });
      }
      if (selectedPackageItem.category === 0 && selectedPackageItem.id !== -1) {
        data.serviceBookingRequests.push({
          serviceId: selectedService.id,
          serviceType: selectedService.selectedServiceType,
          disciplines: selectedService.disciplines,
          durationInMinutes: selectedService.selectedDuration,
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: selectedService.category,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: Number(item.travelExpense),
          totalPrice: totalPrice
        });
      }
      if (selectedPackageItem.category === 1 && selectedPackageItem.id !== -1) {
        data.serviceBookingRequests.push({
          serviceId: selectedService.id,
          serviceType: selectedService.selectedServiceType,
          disciplines: selectedService.disciplines,
          durationInMinutes: selectedService.selectedDuration,
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: selectedService.category,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: Number(item.travelExpense),
          totalPrice: totalPrice
        });
      }
    });


    if (selectedTime[0].packageId !== -1) {
      data = { ...data, packageId: selectedTime[0].packageId };
    }
    if (selectedTime.length <= 0) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("choose a time"));
      modalCtx.setIsActive(true);
    }
    if (authCtx.isLoggedIn) {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token
        },
        body: JSON.stringify(data)
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      if (response.ok) {


        const res = await response.json();
        if (res.message != null) {
          window.location.href = res.message;
          /*         modalCtx.setModalType(ModalEnum.InfoModal);
                   modalCtx.setMessage(t("Your booking was successful."));
                   modalCtx.setIsActive(true);*/
        } else {
          modalCtx.setModalType(3);
          modalCtx.setMessage("");
          modalCtx.setIsActive(true);
          history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}`);
        }

      } else {
        const res = await response.json();
        modalCtx.setModalType(0);
        modalCtx.setMessage(res.message);
        modalCtx.setIsActive(true);

      }

    } else {
      modalCtx.setModalType(0);
      modalCtx.setMessage(forBookingYouHaveToBeLoggedIn);
      modalCtx.setIsActive(true);

    }


  };

  const rebookASession = async (e: any) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/${selectedCreditItem.id}/rebook?startTime=${encodeURIComponent(selectedTime[0].startDate)}`;

    dispatch(setIsLoading(true));

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Accept-Language": i18n.language === "de" ? "de-DE" : "en-US",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token
      }
    });
    setTimeout(() => dispatch(setIsLoading(false)),400)

    if (response && response.status === 200) {
      modalCtx.setModalType(3);
      modalCtx.setMessage("");
      modalCtx.setIsActive(true);
      history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}`);
    } else {
      const { Message } = await response.json();
      modalCtx.setModalType(0);
      modalCtx.setMessage(Message ? Message : "Error");
      modalCtx.setIsActive(true);
      //history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}`)
    }

  };

  const handleCalculate = async (e: any, fullAddress: string) => {
    e.preventDefault();
    let url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/recalculate?forWaitingList=false`;
    if(selectedService.selectedServiceType === 2){
      url = `${process.env.REACT_APP_BE_BASEURL}/api/bookings/recalculate?clientFullAddress=${selectedService.address} ${selectedService.houseNumber} ${selectedService.postalCode} ${selectedService.city} ${countrysEnum[selectedService.country]}&forWaitingList=false`;
    }

    let data: bookingDataType = { promotionCodeCode: "", serviceBookingRequests: [] };

    if (selectedPackageItem.id !== -1) {
      data = { ...data, packageId: selectedPackageItem.packageId };
    }


    selectedTime.forEach(item => {
      if (selectedService.category === 0 && selectedPackageItem.id === -1) {
        data.serviceBookingRequests.push({
          serviceId: selectedService.id,
          serviceType: selectedService.selectedServiceType,
          disciplines: selectedService.disciplines,
          durationInMinutes: selectedService.selectedDuration,
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: selectedService.category,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: Number(item.travelExpense),
          totalPrice: totalPrice
        });
      }
      if (selectedService.category === 1 && selectedPackageItem.id === -1) {
        data.serviceBookingRequests.push({
          serviceId: item.service.id,
          serviceType: item.service.serviceTypes[0],
          disciplines: item.service.disciplines,
          durationInMinutes: item.service.durationInMinutes[0],
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: 1,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: 0,
          totalPrice: totalPrice
        });
      }
      if (selectedPackageItem.category === 0 && selectedPackageItem.id !== -1) {
        data.serviceBookingRequests.push({
          serviceId: selectedService.id,
          serviceType: selectedService.selectedServiceType,
          disciplines: selectedService.disciplines,
          durationInMinutes: selectedService.selectedDuration,
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: selectedService.category,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: Number(item.travelExpense),
          totalPrice: totalPrice
        });
      }
      if (selectedPackageItem.category === 1 && selectedPackageItem.id !== -1) {
        data.serviceBookingRequests.push({
          serviceId: selectedService.id,
          serviceType: selectedService.selectedServiceType,
          disciplines: selectedService.disciplines,
          durationInMinutes: selectedService.selectedDuration,
          startTime: DateTime.fromISO(item.startDate).toUTC().toISO(),
          endTime: DateTime.fromISO(item.endDate).toUTC().toISO(),
          serviceCategory: selectedService.category,
          serviceAmount: Number(item.servicePrice),
          serviceFee: Number(item.serviceFee),
          travelExpense: Number(item.travelExpense),
          totalPrice: totalPrice
        });
      }
    });

    try {
      dispatch(setIsLoading(true));

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token
        },
        body: JSON.stringify(data)
      });
      setTimeout(() => dispatch(setIsLoading(false)),400)

      if (response.ok) {
        const res = await response.json();
        dispatch({
          type: "setRecalculation",
          payload: res
        });

      } else {
        const res = await response.json();
        modalCtx.setModalType(0);
        modalCtx.setMessage(res.message);
        modalCtx.setIsActive(true);
      }
    } catch (e) {
      modalCtx.setModalType(0);
      modalCtx.setMessage(t("recalculationErr"));
      modalCtx.setIsActive(true);
    }
  };

  const currencySymbol = Object.values(currencySymbolEnum)[publicTrainerData.currency];

  const activeButton = selectedTime.length > 0 && dataPolicy;

  return (
    <div className={`bookingSummery ${isSummary ? "isSummary-Summary": ""}`}>
      <div className="card">
        <div className="card_content">
          <div className="card_heading">
            <h2>{t("Order summary")}</h2>
            <hr />
          </div>
          <form onSubmit={ (e) => {
            e.preventDefault();
            if (activeButton) {
              if (selectedCreditItem.id) {
                rebookASession(e)
              } else if (needUpdate) {
                handleCalculate(e, `${selectedService.address} ${selectedService.houseNumber} ${selectedService.postalCode} ${selectedService.city} ${countrysEnum[selectedService.country]}`);
              } else {
                bookASession(e);
              }
            }
          }

            }>
            <div className="summery">
              <div className="order-items">
                {
                  selectedTime.map((item: { startDate: string, endDate: string, totalPrice: number }) => {
                    return (
                      <OrderItem key={item.startDate} item={item} />
                    );
                  })
                }
                <div className="summery_bottom">
                  {/*   <input type="text" className="promocode" placeholder={promotionCodePlaceholder} />*/}
                  <hr />
                  <p>
                    {t("total price")}: {totalPrice.toFixed(2)}{"\t"}{currencySymbol}
                  </p>
                      <div className="agreePolicy">
                        <div className="agreeTerm">
                        <input
                          checked={dataPolicy}
                          onChange={() => setDataPolicy(!dataPolicy)}
                          type="checkbox"
                        />
                          {t("I agree with the")}
                          {/*//TODO: move static links to a config file*/}
                          <div className="policy" onClick={() => window.open("https://skulp.de/agb","_blank")} > AGB </div> {t("and")}
                          <div className="policy" onClick={() => window.open("https://skulp.de/datenschutz","_blank")} > {t("Privacy Policy")}</div>
                          {t("agreed")}
                        </div>
                      </div>
                  {(!needUpdate || selectedCreditItem.id) ? <button className={!activeButton  ? "disabled" : ""} disabled={!activeButton} type={"submit"}>{t("bookSession")}</button>
                    : <button  className={!activeButton  ? "disabled" : ""} disabled={!activeButton} type={"submit"}>{t("calculate")}</button>}

                  <div className="cancelationPlicy" onClick={() => setShowPolicy(!showPolicy)}>
                    <div className="parent">
                      <span  >{t("cancellationPolicy")}</span>
                      <ArrowSvg style={showPolicy ? {transform:"rotate(180deg)"}:{}} />
                    </div>
                    <div className="hidden_box" style={showPolicy ? {} : {height: 0}}>

                    {/* Check if cancellation is allowed or not */}
                      {publicTrainerData.isCancellationAllowed ? <>
                        <p>{t("if you cancel {{hours}} hours before, you get 100% back.", {hours : publicTrainerData.cancellation100_Deadline})}</p>
                        <p>{t("if you cancel {{hours}} hours before, you get 50% back.", {hours : publicTrainerData.cancellation50_Deadline})}</p>
                        <p>{t("if you cancel within {{hours}} hours, you will get 0% back", {hours : publicTrainerData.cancellation50_Deadline})}</p>
                      </> :
                        <p>{t("In case of cancellation on your part, no money will be refunded")}</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingSummary;
