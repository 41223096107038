import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PackagesAndSubscriptionOverview = () => {

  const selectedPackageForBooking = useSelector(state => state.package.selectedPackageForBooking)
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);
  const history = useHistory()
  const { t } = useTranslation();
  const daysAfterFirstBooked = t("daysAfterFirstBooked")
  useEffect(() => {
    if(selectedPackageForBooking.id === -1){
      history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/packages`)
    }
  },[])

  const getTotalCount = () => {
    let count :number = 0
    selectedPackageForBooking.packageServices.forEach((item:any) => {
      count += item.totalCount;
    })
    return count
  }

  return (
    <div className="packageAndSubscriptionOverview">
      <div className="overview">
        <div className="left-overview-items">
          <div className="overview-item">
            <div className="muted">{t("Name")}</div>
            <p>{selectedPackageForBooking.name}</p>
          </div>
          <div className="overview-item">
            <div className="muted">{t("content")}</div>
            <p>{getTotalCount()} Credits for Small Group</p>
            {selectedPackageForBooking.packageServices.map((item: any) => {
              return (
                <p style={{
                  fontSize:"15px",
                  marginBottom: "2px",
                }}> { ` ${item.totalCount !== null ? item.totalCount + ' x ' : t('unlimited') } ${item.service.name}` } </p>
              );
            })}
          </div>
          <div className="overview-item">
            <div className="muted">{t("description")}</div>
            <p>{selectedPackageForBooking.description}
            </p>
          </div>
        </div>
        <div className="right-overview-items">
          <div className="overview-item">
            <div className="muted">{t("startDate")}</div>
            <p>{t("afterFirstBookedSession")}</p>
          </div>
          <div className="overview-item">
            <div className="muted">{t("expirationDate")}</div>
            <p>{selectedPackageForBooking.canExpire ? `${selectedPackageForBooking.validForNumberOfWeeks * 7} ${daysAfterFirstBooked} ` : "never"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PackagesAndSubscriptionOverview;
