import React, { useEffect, useState } from "react";
import search from "../../../images/component_svg/search.svg";
import { ReactComponent as Downarrow } from "../../../images/component_svg/downarrow.svg";
import CancelSessionModal from "../CancelSessionModal";
import UpcomingSessionItem from "../upcomingSessionItem";
import { useDispatch, useSelector } from "react-redux";
import { loadCancelledBookings, loadCustomerData, loadUpcomingSessions } from "../../../redux/actions/customer/customerAction";
import { upcomingBookingsType, upcomingSessionsType } from "../../../redux/reducers/customer/customerReducer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function SessionsList() {
  const dispatch = useDispatch();

  const upcomingSessions = useSelector(state => state.customer.upcomingSessions);
  const customerData = useSelector(state => state.customer);
  const [expand, setExpand] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)
  useEffect(() => {
    if (customerData.id === -1) {
      dispatch(loadCustomerData(customerData.id));
    }
    if (customerData.upcomingSessions.upcomingBookings.length <= 0) {
      dispatch(loadUpcomingSessions(customerData.id));
    }
    if (customerData.cancelledBookings.length <= 0 ) {
      dispatch(loadCancelledBookings(customerData.id));
    }
  }, [customerData.id]);



  return (
    <div className="upcoming-ses">
      <div className="card">
        <p>
          {t("upcoming sessions")}
        </p>
        <div className="upcom-table">
          {upcomingSessions.upcomingBookings.length > 0 ? upcomingSessions.upcomingBookings.slice(0, expand ? upcomingSessions.upcomingBookings.length : 3).map((item: upcomingBookingsType, index: number) => {
            return (
              <UpcomingSessionItem
              key={index + item?.service?.name}
              item={item} />
            );
          }) : null}
          {upcomingSessions.upcomingBookings.length <= 0 ? (
            <div className="noBooking">
              <h5>{t("Currently, you don't have any upcoming sessions")}</h5>
              <h5>{t("Click on the button below to book your next session.")}</h5>
              <button onClick={() => history.replace(`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking`) }>{t("bookNextSession")}</button>
            </div>
          ) : null}


        </div>
        <div className="show-tr">
          <button type="button">
            {upcomingSessions.upcomingBookings.length > 0 ? <Downarrow style={expand ? {
              transform: "rotate(180deg)",
              transition: "200ms ease-in"
            } : { transform: "rotate(0deg)", transition: "200ms ease-in" }}
                                                                       onClick={() => setExpand(!expand)} /> : null}
          </button>
        </div>
      </div>
    </div>
  );
}
