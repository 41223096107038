import React, { useEffect } from "react";
import BusinessCenterPackageServiceListItem from "./BusinessCenterPackageServiceListItem";
import { getTrainerPackages } from "../../../redux/actions/packages/packagesAction";
import { useDispatch, useSelector } from "react-redux";
import { packageObject } from "../../../redux/reducers/packages/packagesReducer";

const BusinessCenterPackagesDisable = () => {

  const dispatch = useDispatch();
  const disablePackages = useSelector(state => state.package.enabledOrDisablePackages)

  useEffect(() =>{
    dispatch(getTrainerPackages(false))
  } ,[]);

  return (
    <div className="businessCenterPackagesDisable">
      <div className="card">
        <div className="servicesListPackages">
        {/*  <BusinessCenterPackageServiceListItem forEnabled={false} />
          <BusinessCenterPackageServiceListItem forEnabled={false} />
          <BusinessCenterPackageServiceListItem forEnabled={false} />*/}
          {(disablePackages || []).map((item:packageObject) => (
            <BusinessCenterPackageServiceListItem forEnabled={false} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default BusinessCenterPackagesDisable;

