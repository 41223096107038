import React, {useEffect} from "react";


const Discipline = ({
                        selectedDiscipline, setSelectedDiscipline,
                        value, id,selectedServiceByID,setDuration,disabled
                    }: any) => {
    useEffect(() => {
    },[value])

    return (
        <>
            <input
                type="radio"
                id={id}
                disabled={disabled}
                name="discipline"
                value={value}
                checked={selectedDiscipline.id === id}
                onChange={(e) =>{
                    setSelectedDiscipline({id:id,value:value})
                    setDuration(-1)
                }
                }
            />
            <label htmlFor={id}>
                <div className="labelContent">
                    <p >
                        {value}
                    </p>
                </div>
            </label>
        </>
    )

}
export default Discipline;
