import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPackageItemForBooking } from "../../redux/actions/customer/customerAction";
import { useHistory } from "react-router-dom";
import { setSelectingFromPackage } from "../../redux/actions/booking/bookingAction";
import { useTranslation } from "react-i18next";

const ServiceDropdownListItem = ({ item,packageId,count }: { item:any,packageId: number, count:number }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData)
  const { t } = useTranslation();
  const isUnlimited = item.remainingCount === null && item.totalCount === null;

  const handleBooking = (item:any) => {
    dispatch(setSelectingFromPackage(true))
    dispatch(setSelectedPackageItemForBooking({ packageId: packageId,count: count,...item.service, isUnlimited }))
    history.push(`/me/${publicTrainerData.trainerSpace.spaceUrl}/trainer-booking`)
  }

  return(
    <div className="dropdownListItem">
      <p>{item.service.name}</p><p>{isUnlimited ?  t("unlimited") : item.remainingCount === null ? item.totalCount :item.remainingCount }{!isUnlimited && '/'}{item.totalCount}</p><p onClick={ ()  => handleBooking(item)} className={"book"}>{t("book")}</p>
    </div>
  )
}

export default ServiceDropdownListItem;
