import { ReactComponent as VirtualSvg } from "../../../images/component_svg/virtual.svg";
import { ReactComponent as EditSvg } from "../../../images/component_svg/edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { setEditServiceGroup } from "../../../redux/actions/businessCenter/serviceActions";
import { useTranslation } from "react-i18next";
import { currencySymbolEnumFromIndex } from "../../../enums/enum";

const
  BusinessCenterGroupTrainingServiceListItem = ({
                                                      e,
                                                      handleDisable,
                                                      handleEnable,
                                                      forEnabled,
                                                      handleDelete,
                                                      setSelectedService,
                                                      isPopupEnabled,
                                                      setTab,
                                                      setSessionType
                                                    }: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const publicTrainerData = useSelector(state => state.onboarding.publicTrainerData);

  const handleEdit = () => {
    dispatch(setEditServiceGroup(e));
    setSelectedService(e.id);
    setTab(0);
    setSessionType(1);
  };

  return (
    <div key={e.id} className="serviceRow">
      <p>{e.name}</p>
      <p> {e.durationInMinutes[0]} min</p>
      <p>{DateTime.fromFormat(e.startTime, "HH:mm:ss").toLocaleString(DateTime.TIME_24_SIMPLE)}</p>

      <p>
        {(e.difficultyLevel === 0 && t("basic")) ||
          (e.difficultyLevel === 1 && t("advanced")) ||
          (e.difficultyLevel === 2 && t("expert"))}
      </p>
      <p>{DateTime.fromISO(e.date).toFormat("dd.MM.yyyy")}</p>
      <VirtualSvg />
      <div className="price">{e.groupPrice}{currencySymbolEnumFromIndex[publicTrainerData.currency]}</div>
      <div className="edit" onClick={() => setSelectedService(e.id)}>
        <EditSvg />
      </div>
      <div className="EditPopup" style={isPopupEnabled ? { display: "flex" } : {}}>
        {forEnabled ? (
            <p onClick={handleEdit}>{t("edit")}</p>
          ) :
          (<p onClick={handleDelete}>{t("delete")}</p>)
        }
        <hr />
        {forEnabled ? (
          <p onClick={handleDisable}>{t("disable")}</p>
        ) : (
          <p onClick={handleEnable}>{t("enable")}</p>
        )}
      </div>
    </div>
  );

};

export default BusinessCenterGroupTrainingServiceListItem;
