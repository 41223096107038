import { ReactComponent as CopySvg } from "../../images/component_svg/copy.svg";
import { ReactComponent as ShareSvg } from "../../images/component_svg/share.svg";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodes } from "../../redux/actions/businessCenter/serviceActions";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { currencySymbolEnum } from "../../enums/enum";

const DashboardMarketing = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPromoCodes());
  }, [])
  
  const { t } = useTranslation();
  const userData = useSelector((state) => state.onboarding.userData);

  const promoCodes = useSelector(state => state.businessCenter.activePromoCodes);
  const currencySymbol = Object.values(currencySymbolEnum)[userData.currency];

  const copyText = (copyText: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(copyText);
  };

  return (
    <div className="dashboardMarketing">
      <div className="card">
        <div className="image-container" />
        <div className="card_heading">
          <h2>{t("Marketing")}</h2>
          <hr />
        </div>
        <div className={"copyLink"}>
          <p>{t("share your space")}</p>
          <div className="link">
            <p>{`${process.env.REACT_APP_FE_BASEURL}/me/`}{userData.trainerSpace.spaceUrl}</p>
            <div className="icons">
              {/*<ShareSvg />*/}
              <CopySvg onClick={() => copyText(`${process.env.REACT_APP_FE_BASEURL}/me/${userData.trainerSpace.spaceUrl}`)} />
            </div>
          </div>
        </div>
        <div className="promotionCodes">
          <p>{t("share your promo codes")}</p>
          {
            promoCodes.filter((promoCode: any) => promoCode.isActive).map((promoCode: any) => {
              const { id, code, promotionCodeExpiresAt, percentOff, amountOff, discountType, promotionCodeRedemptions } = promoCode;

              return (
                <div key={promoCode.id} className="promoCodeItem">
                  <p>{code}</p>
                  <p>
                    {`${discountType === 1 ? percentOff : amountOff}  ${discountType === 1 ? '%' : currencySymbol} ${t("discount")}`}
                  </p>
                  <p>{DateTime.fromISO(promotionCodeExpiresAt).toFormat("dd.MM.yyyy")}</p>
                  <p>{t("redemptions")}: {promotionCodeRedemptions}</p>
                  <div className="icons">
                    <CopySvg onClick={() => copyText(code) } />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );

};

export default DashboardMarketing;
