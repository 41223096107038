import { currencySymbolEnum } from "../../enums/enum";

const PriceInput = ({
                      price,
                      handleOnChange,
                      currency,
                      errObject,
                      label,
                      disabled
                    }: { price: number, handleOnChange: (e: string) => void, currency: number, errObject?: string, label?: string, disabled?: boolean }) => {
  const currencySymbol = Object.values(currencySymbolEnum)[currency];
  const regex = /^\d*(\.|,|(\.\d{1,2})|(,\d{1,2}))?$/;
  return (
    <div className="form-group">
      {label && <label htmlFor="offerPackage">
        {label}
      </label>}
      <div className="currency">
        <input
          type="number"
          step="any"
          value={price}
          onChange={(e) => {
            if (regex.test(e.target.value)) {
              handleOnChange(e.target.value);
            }
          }
          }
          disabled={disabled}
        />
        {currencySymbol}
      </div>
      {errObject && price < 0.1 && (
        <div className="validation-error yellow-global col-12">
          {errObject}
        </div>
      )}
    </div>
  );
};

export default PriceInput;
